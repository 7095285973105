import { useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { useDebounce, vFetch } from '../../helpers'
import SearchResultsQuestion from './SearchResultsQuestion'
import SearchResultsArticle from './SearchResultsArticle'

export default function KnowledgeSearch({
    setView,
    departments,
    setSelectedArticle,
}: {
    setView: any
    departments: any
    setSelectedArticle: any
}) {
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 500)
    const [questions, setQuestions] = useState<any>([])
    const [articles, setArticles] = useState<any>([])
    const [isFetching, setIsFetching] = useState(true)
    const [selectedQuestion, setSelectedQuestion] = useState<undefined | any>()

    useEffect(() => {
        if (debouncedSearch.length) {
            setIsFetching(true)
            vFetch(`/knowledge/questions?search=${debouncedSearch}&limit=5`, {
                cb: (res: any) => {
                    // setIsFetching(false)
                    if (res.success) {
                        setQuestions(res.questions)
                    }
                },
            })
            vFetch(`/knowledge/articles?search=${debouncedSearch}&limit=5`, {
                cb: (res: any) => {
                    setIsFetching(false)
                    if (res.success) {
                        setArticles(res.articles)
                    }
                },
            })
        } else {
            setQuestions([])
        }
    }, [debouncedSearch])

    return (
        <div className='relative flex border-b dark:border-none dark:bg-darkness'>
            <CiSearch className='ml-1 h-[24px] w-[24px] mt-2 text-grey' />
            <input // top search bar
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder='Search'
                className='w-full overflow-hidden bg-transparent p-[8px] focus:outline-none dark:rounded-t dark:text-offwhite'
            ></input>
            {search.length > 0 && (
                <>
                    {isFetching ? (
                        <div className='shadow-md absolute p-2 rounded-b top-[100%] left-0 w-full bg-white dark:bg-darkness'>
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <div className='max-h-[640px] overflow-auto shadow-md flex flex-col gap-4 absolute p-2 rounded-b top-[100%] left-0 w-full bg-white dark:bg-darkness'>
                            {questions?.map((questionRow: any) => (
                                <SearchResultsQuestion
                                    questionRow={questionRow}
                                    setView={setView}
                                    setSearch={setSearch}
                                />
                            ))}
                            <div className=''>
                                {articles?.map((article: any) => (
                                    <SearchResultsArticle
                                        article={article}
                                        departments={departments}
                                        setSelectedArticle={setSelectedArticle}
                                        setView={setView}
                                        setSearch={setSearch}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
