import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { CustomerAddressType, CustomerType } from 'customersV2/Customers.types'
import {
    CA_MULTI_REGIONAL_TAX_EXEMPTION_CODES,
    CA_REGIONAL_TAX_EXEMPTION_CODES,
    US_TAX_EXEMPTION_CODES,
} from 'customersV2/constants'
import { vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import Textarea from 'procurement/components/Textarea'
import { useEffect, useRef, useState } from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'

const usTaxExemptionOptions = US_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const state = codeArray[1]
    const exemptionName = codeArray
        .slice(2)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country}-${state} ${exemptionName}`,
        value: code,
    }
})

const caRegionalTaxExemptionOptions = CA_REGIONAL_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const state = codeArray[1]
    const exemptionName = codeArray
        .slice(2)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country}-${state} ${exemptionName}`,
        value: code,
    }
})

const caTaxExemptionOptions = CA_MULTI_REGIONAL_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const exemptionName = codeArray
        .slice(1)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country} ${exemptionName}`,
        value: code,
    }
})

export default function CreateCustomerModal({ toggleModal }: { toggleModal: () => void }) {
    const tagListRef = useRef<HTMLDivElement>(null)
    const modalRef = useRef<HTMLDivElement>(null)
    const taxExemptionsRef = useRef<HTMLDivElement>(null)
    const [_searchParams, setSearchParams] = useSearchParams()
    const [showTags, setShowTags] = useState(false)
    const [tagSearch, setTagSearch] = useState('')
    const [exemptionSearch, setExemptionSearch] = useState('')
    const [showTaxExemptions, setShowTaxExemptions] = useState(false)
    const tagsQuery = useQuery({
        queryKey: ['customers', 'tags'],
        queryFn: () => vFetch(`/v2/customers/tags`),
    })
    const [customer, setCustomer] = useState<CustomerType>({} as CustomerType)
    const [address, setAddress] = useState<CustomerAddressType>({} as CustomerAddressType)
    const tags: string[] =
        tagsQuery?.data?.tags
            ?.map((tag: any) => tag.tag)
            .filter(
                (tag: string) =>
                    tag.toLowerCase() !== tagSearch.toLowerCase() &&
                    !customer.tags?.split(',').includes(tag) &&
                    tag.toLowerCase().includes(tagSearch.toLowerCase())
            ) || []
    const queryClient = useQueryClient()
    const updateCustomerMutation = useMutation({
        mutationFn: async ({ customer }: { customer: CustomerType }) =>
            vFetch('/v2/customers', {
                method: 'POST',
                body: JSON.stringify({
                    ...customer,
                    addresses: [address],
                }),
            }),
        onSuccess: (data) => {
            setTagSearch('')
            setShowTags(false)
            toggleModal()
            setSearchParams((prev) => {
                prev.set('customer_id', data.customer_id)
                return prev
            })
            return queryClient.invalidateQueries({ queryKey: ['customers'] })
        },
    })

    const handleChangeCustomer = ({
        target,
    }: {
        target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    }) => {
        setCustomer((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }
    const handleChangeAddress = ({
        target,
    }: {
        target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    }) => {
        setAddress((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }

    const handleToggleTag = (tag: string) => {
        setCustomer((prev) => {
            const newState = structuredClone(prev)
            const tagsArray = newState.tags?.split(',') || []
            if (tagsArray.includes(tag)) {
                return {
                    ...newState,
                    tags: tagsArray.filter((currentTag) => currentTag !== tag).join(','),
                }
            }
            return {
                ...newState,
                tags: [...tagsArray, tag].join(','),
            }
        })
    }

    const handleToggleTaxExemption = (code: string) => {
        setCustomer((prev) => {
            const newState = structuredClone(prev)
            if (newState.tax_exemptions?.includes(code)) {
                return {
                    ...newState,
                    tax_exemptions: newState.tax_exemptions?.filter((exemption) => exemption !== code),
                }
            }
            return {
                ...newState,
                tax_exemptions: [...(newState.tax_exemptions || []), code],
            }
        })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!tagListRef.current?.contains(e.target as HTMLElement)) {
                setShowTags(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                toggleModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!taxExemptionsRef.current?.contains(e.target as HTMLElement)) {
                setShowTaxExemptions(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black/50'
        >
            <div className='p-4 rounded shadow-md relative bg-bg1 dark:bg-darkbg1 text-sm flex flex-col'>
                <button className='absolute top-1 right-1 text-base' onClick={toggleModal}>
                    <FaTimes className='text-red dark:text-lightred' />
                </button>
                <h2 className='font-bold text-lg mb-2'>Create New Customer</h2>
                <div className='flex gap-2'>
                    <div className='flex flex-col gap-1 min-w-[350px]'>
                        <h2 className='font-bold mb-2'>Contact Info</h2>
                        <Input
                            id='first_name'
                            name='first_name'
                            label='First Name'
                            type='text'
                            value={customer.first_name || ''}
                            onChange={handleChangeCustomer}
                        />
                        <Input
                            id='last_name'
                            name='last_name'
                            label='Last Name'
                            type='text'
                            value={customer.last_name || ''}
                            onChange={handleChangeCustomer}
                        />
                        <Input
                            id='email'
                            name='email'
                            label='Email'
                            type='email'
                            value={customer.email || ''}
                            onChange={handleChangeCustomer}
                            required={true}
                        />
                        <Input
                            id='phone'
                            name='phone'
                            label='Phone'
                            type='text'
                            value={customer.phone || ''}
                            onChange={handleChangeCustomer}
                        />
                        <Textarea
                            outerClassName='flex flex-col gap-1 w-full'
                            id='note'
                            name='note'
                            label='Note'
                            value={customer.note || ''}
                            onChange={handleChangeCustomer}
                        />
                        <div ref={tagListRef} onClick={() => setShowTags(true)} className={`relative z-[5]`}>
                            {showTags && (
                                <button
                                    className='absolute top-0 right-0 z-10'
                                    onClick={() =>
                                        setTimeout(() => {
                                            setShowTags(false)
                                        }, 10)
                                    }
                                >
                                    <FaTimes />
                                </button>
                            )}
                            <Input
                                type='text'
                                value={tagSearch}
                                onChange={({ target }) => setTagSearch(target.value)}
                                id='tagSearch'
                                name='tagSearch'
                                placeholder='Add tags...'
                                label='Tags'
                            />
                            {showTags && (
                                <div className='w-full flex gap-1 flex-wrap max-h-[100px] overflow-auto absolute top-full left-0 bg-white dark:bg-darkbg1 shadow-md rounded-b p-2 border border-t-0 border-lightgrey dark:border-darkgrey'>
                                    {tagSearch && (
                                        <button
                                            onClick={() => handleToggleTag(tagSearch)}
                                            className='font-bold text-xs py-1 px-2 rounded-[99vw] bg-lightgrey dark:bg-darkbg2 flex gap-1 items-center'
                                        >
                                            <span>{tagSearch}</span>
                                            <FaPlus className='text-[8px]' />
                                        </button>
                                    )}
                                    {tags.map((tag) => (
                                        <button
                                            onClick={() => handleToggleTag(tag)}
                                            className='font-bold text-xs py-1 px-2 rounded-[99vw] bg-lightgrey dark:bg-darkbg2 flex gap-1 items-center'
                                        >
                                            <span>{tag}</span>
                                            <FaPlus className='text-[8px]' />
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={`flex flex-wrap gap-1 `}>
                            {customer.tags
                                ?.split(',')
                                .filter((v) => v)
                                .map((tag) => (
                                    <div className='font-bold text-xs py-1 px-2 rounded-[99vw] bg-lightgrey dark:bg-darkbg2 flex gap-1 items-center'>
                                        <span>{tag}</span>
                                        <button onClick={() => handleToggleTag(tag)}>
                                            <FaTimes className='text-[8px]' />
                                        </button>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className='flex flex-col gap-1 min-w-[350px]'>
                        <h2 className='font-bold mb-2'>Default Address</h2>
                        <Input
                            id='company'
                            name='company'
                            label='Company'
                            type='text'
                            value={address.company || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='first_name'
                            name='first_name'
                            label='First Name'
                            type='text'
                            value={address.first_name || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='last_name'
                            name='last_name'
                            label='Last Name'
                            type='text'
                            value={address.last_name || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='phone'
                            name='phone'
                            label='Phone'
                            type='text'
                            value={address.phone || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='address1'
                            name='address1'
                            label='Address'
                            type='text'
                            value={address.address1 || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='address2'
                            name='address2'
                            label='Address Line 2'
                            type='text'
                            value={address.address2 || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='city'
                            name='city'
                            label='City'
                            type='text'
                            value={address.city || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='province_code'
                            name='province_code'
                            label='State'
                            type='text'
                            value={address.province_code || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='zip'
                            name='zip'
                            label='Zip Code'
                            type='text'
                            value={address.zip || ''}
                            onChange={handleChangeAddress}
                        />
                        <Input
                            id='country'
                            name='country'
                            label='Country'
                            type='text'
                            value={address.country || ''}
                            onChange={handleChangeAddress}
                        />
                    </div>
                    <div className='flex flex-col gap-1 min-w-[350px]'>
                        <h2 className='font-bold mb-2'>Tax Exemptions</h2>
                        <div className='flex gap-2 items-center mb-4'>
                            <input
                                id='tax-exempt'
                                type='checkbox'
                                checked={customer.tax_exempt === 1}
                                onChange={({ target }) =>
                                    setCustomer((prev) => ({ ...prev, tax_exempt: target.checked ? 1 : 0 }))
                                }
                            />
                            <label htmlFor='tax-exempt' className='font-bold'>
                                Tax Exempt
                            </label>
                        </div>
                        <div ref={taxExemptionsRef} onClick={() => setShowTaxExemptions(true)} className='relative'>
                            {showTaxExemptions && (
                                <button
                                    className='absolute top-0 right-0 z-10'
                                    onClick={() =>
                                        setTimeout(() => {
                                            setShowTaxExemptions(false)
                                        }, 10)
                                    }
                                >
                                    <FaTimes />
                                </button>
                            )}
                            <Input
                                label='Other Exemptions'
                                id='search'
                                name='search'
                                type='text'
                                placeholder='Search...'
                                value={exemptionSearch}
                                onChange={({ target }) => setExemptionSearch(target.value)}
                            />
                            {showTaxExemptions && (
                                <div className='w-full max-h-[200px] overflow-auto absolute top-full left-0 bg-bg1 dark:bg-darkbg1 p-2 rounded-b shadow-md border border-lightgrey dark:border-darkgrey border-t-0 flex flex-col gap-1'>
                                    <p className='font-bold border-b border-lightgrey dark:border-darkgrey pb-1 mb-1'>
                                        US Tax Emeptions
                                    </p>
                                    {usTaxExemptionOptions
                                        .filter((exemption) =>
                                            exemption.value.toLowerCase().includes(exemptionSearch.toLowerCase())
                                        )
                                        .map((option: any) => (
                                            <button
                                                onClick={() => handleToggleTaxExemption(option.value)}
                                                className='flex gap-2 items-center whitespace-nowrap'
                                            >
                                                <span
                                                    className={`rounded-full w-4 h-4 ${
                                                        customer.tax_exemptions?.includes(option.value)
                                                            ? 'border-4 border-blue dark:border-darkaccent1'
                                                            : 'border border-grey/50 dark:darkgrey'
                                                    }`}
                                                >
                                                    &nbsp;
                                                </span>
                                                <span>{option.label}</span>
                                            </button>
                                        ))}
                                    <p className='font-bold border-b border-lightgrey dark:border-darkgrey pb-1 my-1'>
                                        CA Tax Exemptions
                                    </p>
                                    {caRegionalTaxExemptionOptions
                                        .filter((exemption) =>
                                            exemption.value.toLowerCase().includes(exemptionSearch.toLowerCase())
                                        )
                                        .map((option: any) => (
                                            <button
                                                onClick={() => handleToggleTaxExemption(option.value)}
                                                className='flex gap-2 items-center whitespace-nowrap'
                                            >
                                                <span
                                                    className={`rounded-full w-4 h-4 ${
                                                        customer.tax_exemptions?.includes(option.value)
                                                            ? 'border-4 border-blue dark:border-darkaccent1'
                                                            : 'border border-grey/50 dark:darkgrey'
                                                    }`}
                                                >
                                                    &nbsp;
                                                </span>
                                                <span>{option.label}</span>
                                            </button>
                                        ))}
                                    {caTaxExemptionOptions
                                        .filter((exemption) =>
                                            exemption.value.toLowerCase().includes(exemptionSearch.toLowerCase())
                                        )
                                        .map((option: any) => (
                                            <button
                                                onClick={() => handleToggleTaxExemption(option.value)}
                                                className='flex gap-2 items-center whitespace-nowrap'
                                            >
                                                <span
                                                    className={`rounded-full w-4 h-4 ${
                                                        customer.tax_exemptions?.includes(option.value)
                                                            ? 'border-4 border-blue dark:border-darkaccent1'
                                                            : 'border border-grey/50 dark:darkgrey'
                                                    }`}
                                                >
                                                    &nbsp;
                                                </span>
                                                <span>{option.label}</span>
                                            </button>
                                        ))}
                                </div>
                            )}
                        </div>
                        <div className='mb-4'>
                            {customer.tax_exemptions?.map((exemption) => (
                                <div className='flex justify-between items-center p-2 border-b border-lightgrey dark:border-darkgrey'>
                                    <p>{exemption}</p>
                                    <button onClick={() => handleToggleTaxExemption(exemption)}>
                                        <FaTimes />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Button
                    onClick={() =>
                        updateCustomerMutation.mutate({
                            customer: { ...customer, addresses: [address] } as any,
                        })
                    }
                    className='font-bold mx-auto mt-4'
                    variant='outline'
                >
                    Create Customer
                </Button>
            </div>
        </div>
    )
}
