import { useEffect, useState } from 'react'
import { buildEmailFromTemplate } from '../helpers/index'
import { ReactCheckboxSelect } from '../custom_components/ReactCheckboxSelect'
import ReactSelect from '../custom_components/ReactSelect'
import { Signature } from './Signature.js'
import { EmailManufacturerModalProps } from './emailModal.types'
import { AiFillInfoCircle } from 'react-icons/ai'

export default function EmailManufacturerModal({
    templates,
    selectedTemplate,
    setSelectedTemplate,
    email,
    setEmail,
    handleSendEmail,
    submitText,
    vendorList,
    vendorContactMap,
    legacyVendorContactMap,
    selectedVendor,
    setSelectedVendor,
    order,
    customer,
}: EmailManufacturerModalProps) {
    const [showSendTo, setShowSendTo] = useState(false)

    useEffect(() => {
        if (selectedTemplate) {
            const { subject, body, greeting, html } = buildEmailFromTemplate(selectedTemplate, {
                order,
                customer,
                supplier: { name: vendorList[selectedVendor] || '' },
            })
            setEmail((previousState: any) => ({
                ...email,
                subject,
                body: body.replaceAll('<br>', '\n'),
                greeting:
                    greeting ||
                    `Hi${
                        (vendorList.length > 0 && vendorList[selectedVendor] && ` ${vendorList[selectedVendor]}`) || ''
                    },`,
                html,
            }))
        }
    }, [selectedTemplate])

    const vendorEmailArray = vendorContactMap[vendorList[selectedVendor]]
        ? vendorContactMap[vendorList[selectedVendor]].filter((e: any) => e.email).map((e: any) => e.email)
        : []
    const legacyVendorEmailArray = legacyVendorContactMap[vendorList[selectedVendor]]
        ? legacyVendorContactMap[vendorList[selectedVendor]].filter((e: any) => e.email).map((e: any) => e.email)
        : []
    const combinedUniqueEmails = Array.from(new Set([...vendorEmailArray, ...legacyVendorEmailArray]))

    return (
        <>
            <div className='flex flex-col w-full'>
                <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='template'>
                    Vendor
                </label>
                {vendorList.length === 0 ? (
                    <>
                        <p className='dark:text-offwhite mb-[4px]'>
                            Vendor contact info cannot be retrieved. Email must be entered manually.
                        </p>
                    </>
                ) : (
                    <select
                        value={selectedVendor}
                        onChange={(e) => setSelectedVendor(e.target.value)}
                        className='p-[4px] px-[6px] w-full border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                    >
                        {vendorList.map((vendor, index) => (
                            <option value={index}>{vendorList[index]}</option>
                        ))}
                    </select>
                )}
            </div>
            <div className='flex gap-[8px]'>
                <div className='flex flex-col w-full z-index-1'>
                    <ReactSelect
                        name={'template'}
                        options={templates.map((t) => {
                            return t.name === 'Select a template'
                                ? { option: t.name, value: t }
                                : {
                                      option: t.name,
                                      value: t,
                                      symbol: <AiFillInfoCircle className='fill-grey dark:fill-white cursor-help' />,
                                      hoverText: [t.greeting, t.html].join('\n\n'),
                                  }
                        })}
                        selected={selectedTemplate}
                        setSelected={(template) => {
                            setSelectedTemplate(template)
                        }}
                    />
                </div>
                <div className='flex flex-col w-full'>
                    <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='respond'>
                        Respond To
                    </label>
                    <select
                        value={email.respond_to}
                        onChange={({ target }) => setEmail({ ...email, respond_to: target.value })}
                        name='respond_to'
                        className='p-[4px] px-[6px] w-full border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                    >
                        <option value='accounting@factorypure.com'>accounting@factorypure.com</option>
                        <option value='orders@factorypure.com'>orders@factorypure.com</option>
                        <option value='sales@factorypure.com'>sales@factorypure.com</option>
                    </select>
                </div>
            </div>
            <div className='flex flex-col w-full'>
                {vendorList.length === 0 ? (
                    <>
                        <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='send_to'>
                            Send To
                        </label>
                        <input
                            className='p-[4px] px-[6px] border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                            onChange={({ target }) => {
                                setEmail({ ...email, send_to: target.value })
                            }}
                        ></input>
                    </>
                ) : (
                    <ReactCheckboxSelect
                        name='send_to'
                        options={combinedUniqueEmails}
                        selected={email.send_to}
                        setSelected={(selected: any) => {
                            setEmail({ ...email, send_to: selected })
                        }}
                    />
                )}
            </div>
            <div className='flex flex-col w-full'>
                <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='cc'>
                    CC
                </label>
                <input
                    value={email.cc}
                    onChange={({ target }) => setEmail({ ...email, cc: target.value })}
                    name='cc'
                    className='p-[4px] px-[6px] border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                    type='email'
                />
            </div>
            <div className='flex flex-col w-full'>
                <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='subject'>
                    Subject
                </label>
                <input
                    value={email.subject}
                    onChange={({ target }) => setEmail({ ...email, subject: target.value })}
                    name='subject'
                    className='p-[4px] px-[6px] border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                    type='text'
                />
            </div>
            <div className='flex flex-col w-full mb-[8px]'>
                <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='body'>
                    Body
                </label>
                <div className='w-full rounded-[4px] p-[8px] border border-grey dark:border-blue'>
                    <input
                        className='p-[4px] w-full px-[6px] outline-none border border-grey dark:border-blue dark:bg-darkness rounded-[4px]focus:outline-none dark:text-white mb-[4px] rounded'
                        value={email.greeting}
                        placeholder='Write your greeting here'
                        onChange={({ target }) => {
                            setEmail({ ...email, greeting: target.value })
                        }}
                    />
                    <textarea
                        className='mb-[8px] min-h-[200px] p-[4px] px-[6px] border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                        placeholder='Write your message here'
                        value={email.body}
                        name='body'
                        onChange={({ target }) => setEmail({ ...email, body: target.value })}
                    ></textarea>
                    <Signature />
                </div>
            </div>
            <button
                onClick={() => handleSendEmail()}
                className='py-[4px] px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold mx-auto'
                type='button'
            >
                {submitText}
            </button>
        </>
    )
}
