import { useSelector } from 'react-redux'
import { vFetch } from '../../helpers'
import { ShopifyProduct } from '../ProductSetup.types'
import { useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FaTrash } from 'react-icons/fa'
import { RxDragHandleDots2 } from 'react-icons/rx'

export default function Images({ product }: { product: ShopifyProduct }) {
    const fileInputRef = useRef<any>(null)
    const user = useSelector((state: any) => state.user)
    const [files, setFiles] = useState<FileList | null>(null)
    const [draggedItem, setDraggedItem] = useState(-1)
    const [draggedOverItem, setDraggedOverItem] = useState(-1)

    const { data: images } = useQuery({
        queryKey: ['product', 'images'],
        queryFn: () => vFetch(`/v2/products/images?product_id=${product.id}`).then((res) => res.images),
    })
    const queryClient = useQueryClient()

    const deleteImage = useMutation({
        mutationFn: async (image: any) => {
            return await vFetch('/v2/products/images', {
                method: 'DELETE',
                body: JSON.stringify({ ...image, product_id: product.id }),
            })
        },
        onSuccess: (_data, image) => {
            queryClient.setQueryData(['product', 'images'], (previousImages: any) => {
                const newArray = structuredClone(previousImages)
                return newArray.filter((img: any) => JSON.stringify(img) !== JSON.stringify(image))
            })
        },
    })

    const updatePosition = useMutation({
        mutationFn: async ({ image, position }: { image: any; position: number }) => {
            return await vFetch('/v2/products/images', {
                method: 'PUT',
                body: JSON.stringify({ ...image, position, product_id: product.id }),
            })
        },
        onSuccess: (_data, variables) => {
            queryClient.setQueryData(['product', 'images'], (previousImages: any) => {
                const newImages = structuredClone(previousImages)
                newImages.splice(draggedItem, 1)
                newImages.splice(draggedOverItem, 0, variables.image)
                setDraggedItem(-1)
                setDraggedOverItem(-1)
                return newImages
            })
        },
    })

    const handleUploadImages = async () => {
        if (files) {
            const data = new FormData()
            for (const file of Array.from(files)) {
                data.append('images', file, file.name)
            }
            await vFetch(`/v2/products/images?product_id=${product.id}&user_id=${user.id}`, {
                method: 'POST',
                body: data,
                contentType: 'skip',
            }).then((res) => {
                if (res.success) {
                    if (fileInputRef.current) {
                        fileInputRef.current.value = null
                    }
                    setFiles(null)
                    queryClient.setQueryData(['product', 'images'], (previousImages: any) => {
                        return [
                            ...previousImages,
                            ...res.uploaded_images.map((img: string, index: number) => ({
                                src: img,
                                media_image_gid: res.shopify_media[index].id,
                            })),
                        ]
                    })
                }
            })
        }
    }
    const handleDeleteImage = (image: any) => {
        deleteImage.mutate(image)
    }
    const handleDrop = () => {
        if (draggedItem !== draggedOverItem) {
            updatePosition.mutate({ image: images[draggedItem], position: draggedOverItem + 1 })
        }
    }

    const isPending = deleteImage.isPending || updatePosition.isPending
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
            <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                Images
            </p>
            <div className={`flex gap-4 flex-wrap ${isPending ? 'opacity-50 pointerevents-none' : ''}`}>
                {images?.map((image: any, index: number) => (
                    <div
                        onDragStart={(e) => setDraggedItem(index)}
                        onDragEnter={(e) => setDraggedOverItem(index)}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                        draggable
                        key={image.id || image.media_image_gid}
                        className={`relative border border-lightgrey dark:border-darkgrey rounded-md p-2 group ${
                            index === draggedOverItem && index !== draggedItem
                                ? 'shadow-[0px_0px_10px_rgb(255,165,0)]'
                                : ''
                        } ${index === draggedItem ? 'opacity-50' : ''}`}
                    >
                        <div className='absolute opacity-0 group-hover:opacity-100 transition-all pointer-events-none top-0 left-0 w-full h-full bg-black/25'></div>
                        <div className='opacity-0 group-hover:opacity-100 transition-all absolute top-0 left-0 rounded-br-md cursor-grab bg-lightgrey dark:bg-darkgrey'>
                            <RxDragHandleDots2 className='text-xl' />
                        </div>
                        <img
                            className='w-[200px] h-[200px] object-contain'
                            src={image.src}
                            width='200'
                            height='200'
                            alt='Product image'
                        />
                        <button
                            className='opacity-0 group-hover:opacity-100 transition-all text-red dark:text-lightred absolute bottom-2 right-2'
                            onClick={() => handleDeleteImage(image)}
                        >
                            <FaTrash className='w-4 h-4' />
                        </button>
                    </div>
                ))}
            </div>
            <h2 className='font-medium mt-4 mb-1'>Upload New Images</h2>
            <div className='flex gap-4'>
                <div className='p-2 rounded-md border border-fire w-fit'>
                    <input
                        ref={fileInputRef}
                        type='file'
                        accept='image/*'
                        multiple
                        onChange={({ target }) => setFiles(target.files)}
                    />
                </div>
                {files?.length && (
                    <button
                        className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] font-bold uppercase'
                        onClick={handleUploadImages}
                    >
                        Upload
                    </button>
                )}
            </div>
        </div>
    )
}
