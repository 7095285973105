import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn, useDebounce } from 'helpers'
import FilterToolBar from 'procurement/components/FilterToolBar'
import React, { useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useGetProductsQuery } from './api/useQueries'
import ProductsTable from './ProductsTable'

export default function ProductsTableContainer({ vendorNames }: { vendorNames: string[] }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const after = searchParams.get('after')
    const before = searchParams.get('before')
    const searchParam = searchParams.get('search') || ''
    const page = searchParams.get('page') || 1
    const vendorsString = searchParams.get('vendors') || ''
    const statusesString = searchParams.get('statuses') || ''
    const missingInfoString = searchParams.get('missing_info') || ''

    const [initRender, setInitRender] = useState(true)
    const [cursorList, setCursorList] = useState<any>([0])
    const [search, setSearch] = useState(searchParam)
    const debouncedSearch = useDebounce(search.trim(), 800)

    function idSort(a: any, b: any) {
        return a.id - b.id
    }

    const sortedVendorNames = [...vendorNames]?.sort((a: any, b: any) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1
    })

    const vendors = decodeURIComponent(vendorsString)
        .split(',')
        .filter((v) => v)
    const statuses = decodeURIComponent(statusesString)
        .split(',')
        .filter((v) => v)
    const missing_info = decodeURIComponent(missingInfoString) === 'true' ? true : false

    const productsQuery = useGetProductsQuery({ vendors, statuses, missing_info, after, before }, searchParam)
    const { products } = productsQuery?.data || {}

    if (products?.length > 2) {
        products.sort(idSort)
    }

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: sortedVendorNames?.map((vendorName: string) => {
                    return {
                        value: vendorName,
                        label: vendorName,
                        icon: undefined,
                    }
                }),
                title: 'Vendors',
                field: 'vendors',
                values: vendors,
                searchToggle: true,
                editSearchParams: true,
            },
            {
                component: FilterBarSelectorButton,
                options: ['Active', 'Draft', 'Archived'].map((status: string) => {
                    return {
                        value: status,
                        label: status,
                        icon: undefined,
                    }
                }),
                title: 'Status',
                field: 'statuses',
                values: statuses,
                searchToggle: false,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('vendors')
                prev.delete('statuses')
                prev.delete('missing_info')
                prev.set('page', '1')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
        }
    }, [debouncedSearch])
    useEffect(() => {
        if (!initRender) {
            setSearch(searchParam)
        }
    }, [searchParam])

    if (!productsQuery.isFetching && !productsQuery.isLoading) {
        if (after && !(after[2] === null)) {
            if (!cursorList.includes(after)) {
                setCursorList((prev: any[]) => {
                    return [...prev, after]
                })
            }
        }
    }

    function handlePageIncrease() {
        if (products?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.set('after', products[49].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.set('before', products[0].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.delete('before')
                prev.set('page', '1')
                return prev
            })
        }
    }
    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('after')
            prev.delete('search')
            prev.delete('before')
            prev.set('page', '1')
            return prev
        })
        setSearch('')
    }

    type ProductInfo = {
        id: string | number
        company_id: number | string
        vendor: string
        title: string
        status: string
        sku: string
        product_type: string
        has_identifying_info: boolean
        featured_image: string
    }

    const tableData = products?.length > 0 ? products : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<ProductInfo>()

    const productsColumns = [
        // {
        //     enableGlobalFilter: false,
        //     id: 'select-col',
        //     header: ({ table }: { table: any }) => (
        //         <div className='flex items-center justify-center mx-auto'>
        //             <input
        //                 className='h-[15px] w-[15px]'
        //                 type='checkbox'
        //                 checked={table.getIsAllRowsSelected()}
        //                 onChange={table.getToggleAllRowsSelectedHandler()}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }: { row: any }) => (
        //         <div
        //             onClick={row.getToggleSelectedHandler()}
        //             className='h-full w-fit flex items-center justify-center mx-auto'
        //         >
        //             <input
        //                 className='h-[15px] w-[15px] '
        //                 type='checkbox'
        //                 checked={row.getIsSelected()}
        //                 disabled={!row.getCanSelect()}
        //                 onChange={row.getToggleSelectedHandler()}
        //             />
        //         </div>
        //     ),
        //     size: 20,
        // },
        columnHelper.accessor('featured_image', {
            enableGlobalFilter: false,
            header: '',
            cell: ({ row }: { row: any }) => (
                <div className='flex items-center justify-center mx-auto w-[50px] h-[50px] border border-darkgrey rounded-md'>
                    <img className='object-scale-down rounded-md w-full h-full' src={row.original.featured_image}></img>
                </div>
            ),
            size: 65,
        }),
        columnHelper.accessor('title', {
            minSize: 600,
            header: 'Title',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        <Link
                            draggable={false}
                            to={`/products/${info.row.original.id}`}
                            state={{ previousLocationPathname: location.pathname }}
                            className={cn('underline flex gap-1 items-center')}
                        >
                            {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                        </Link>
                    </div>
                )
            },
        }),
        // columnHelper.accessor('id', {
        //     minSize: 600,
        //     header: 'Id',
        //     cell: (info) => {
        //         return (
        //             <div className='flex flex-col gap-2 '>
        //                 <div className={cn('underline flex gap-1 items-center')}>
        //                     {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
        //                 </div>
        //             </div>
        //         )
        //     },
        // }),

        columnHelper.accessor('has_identifying_info', {
            header: 'Info',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        {!info.getValue() && (
                            <div className='group relative '>
                                <ExclamationTriangleIcon className='text-danger z-[4] ' />
                                <div className='hidden group-hover:flex border absolute top-[100%] right-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                                    Product has no UPC, model number or supplier sku
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: 30,
        }),
        columnHelper.accessor('sku', {
            header: 'Sku',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('status', {
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2'>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
            maxSize: 60,
        }),
        columnHelper.accessor('product_type', {
            header: 'Product Type',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2'>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('vendor', {
            cell: (info) => {
                return (
                    <div className='flex justify-between items-center gap-2 '>
                        <span className='font-semibold'>{info.getValue()}</span>
                        <Link
                            to={`https://admin.shopify.com/store/factorypure/products/${info.row.original.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FaExternalLinkAlt />
                        </Link>
                    </div>
                )
            },
        }),
    ]

    const columnCount = productsColumns?.length.toString() || 10

    return (
        <>
            <FilterToolBar
                search={search}
                setSearch={setSearch}
                handleClearSearch={handleClearSearch}
                filterBarObject={companyFilterBarObject}
            >
                <FetchingSpinner isFetching={productsQuery.isPending} />

                <Button
                    className={cn(missing_info && 'border-accent2')}
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => {
                        setSearchParams((prev: URLSearchParams) => {
                            missing_info ? prev.delete('missing_info') : prev.set('missing_info', 'true')
                            prev.set('page', '1')
                            prev.delete('after')
                            prev.delete('before')
                            return prev
                        })
                    }}
                >
                    Filter for Missing Info
                </Button>
                {companyFilterBarObject.params.length &&
                    companyFilterBarObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                editSearchParams={param.editSearchParams}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={companyFilterBarObject.setFunction}
                            />
                        )
                    })}
            </FilterToolBar>
            <div>
                <>
                    <div className='w-full'>
                        {tableDataMemo && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <ProductsTable
                                    columns={productsColumns}
                                    tableData={tableDataMemo}
                                    columnCount={columnCount}
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={productsQuery.isLoading || productsQuery.isFetching || products?.length < 50}
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            </div>
        </>
    )
}
