import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import KnowledgeSearch from '../components/KnowledgeSearch'
import Question from '../components/Question'
import { useSearchParams } from 'react-router-dom'
import QuestionsView from './QuestionWithAnswersView'
import QuestionWithAnswersView from './QuestionWithAnswersView'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export default function QuestionsAndAnswersView({ setView }: any) {
    const [searchParams, setSearchParams] = useSearchParams()
    const questionID = searchParams.get('questionID')
    const [selectedQuestion, setSelectedQuestion] = useState<undefined | any>()
    const [showAskQuestion, setShowAskQuestion] = useState(false)
    const [question, setQuestion] = useState('')
    const [questionList, setQuestionList] = useState([]) //
    const [isLoading, setIsLoading] = useState(true) //
    const [isError, setIsError] = useState(false)
    const queryClient = useQueryClient()

    const getQuestions = async () => {
        vFetch('/knowledge/questions', {
            cb: (res: any) => {
                setIsLoading(false)
                if (!res.success) {
                    setIsError(true)
                }
                if (res.success) {
                    const foundQuestion = res.questions.find((question: any) => question.id == questionID)
                    if (foundQuestion) {
                        setSelectedQuestion(foundQuestion)
                    }

                    setQuestionList(res.questions)
                }
            },
        })
    }
    const questionsQuery = useQuery({
        queryKey: ['knowledge', 'questions'],
        queryFn: async () => {
            return await vFetch('/knowledge/questions')
        },
    })
    const { questions: questionList2 }: { questions: any[] } = questionsQuery?.data || {}

    useEffect(() => {
        getQuestions() //
        setSearchParams((prev: any) => {
            prev.set('section', 'qna')
            prev.delete('questionID')
            prev.delete('departmentView')
            prev.delete('articleID')
            prev.delete('myEditArticle')
            prev.delete('myArticleID')
            return prev
        })
        return setView('qna')
    }, [])
    const handleSubmit = () => {
        vFetch('/knowledge/questions', {
            method: 'POST',
            body: JSON.stringify({ question }),
            cb: () => {
                setShowAskQuestion(false)
                setQuestion('')
                getQuestions() //
                return queryClient.invalidateQueries({
                    queryKey: ['knowledge', 'questions'],
                })
            },
        })
    }

    return (
        <>
            {questionID && selectedQuestion && (
                <QuestionWithAnswersView
                    setView={setView}
                    selectedQuestion={selectedQuestion}
                    getQuestions={getQuestions}
                />
            )}
            {!questionID && (
                <div className='flex flex-col h-full'>
                    {!showAskQuestion && (
                        <div className='flex items-center justify-between gap-2'>
                            <h2 className='py-6 shrink-0 whitespace-nowrap bg-transparent dark:text-offwhite text-2xl font-bold hover:cursor-default'>
                                Recent Questions
                            </h2>
                            <button
                                onClick={() => setShowAskQuestion(true)}
                                className='py-2 px-2 shrink-0 whitespace-nowrap bg-blue text-offwhite dark:bg-accent dark:text-darkaccent font-bold rounded'
                            >
                                Ask A Question
                            </button>
                        </div>
                    )}
                    {showAskQuestion && (
                        <div className='flex items-center gap-2 py-6'>
                            <input
                                placeholder='Type your question here'
                                value={question}
                                onChange={(event) => setQuestion(event.target.value)} // 2
                                className='border-b dark:border-none w-full overflow-hidden bg-transparent p-[8px] focus:outline-none dark:rounded-t  dark:text-offwhite dark:bg-darkness'
                            ></input>
                            <button
                                onClick={handleSubmit} // 3
                                className='py-2 px-2 shrink-0 whitespace-nowrap text-offwhite bg-blue dark:bg-accent dark:text-darkaccent font-bold rounded'
                            >
                                Submit Question
                            </button>
                            <button
                                onClick={() => setShowAskQuestion(false)} // 4
                                className='py-2 px-2 shrink-0 whitespace-nowrap bg-darkgrey text-offwhite font-bold rounded'
                            >
                                Cancel
                            </button>
                        </div>
                    )}

                    {isLoading && (
                        <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                            <p>Loading...</p>
                        </div>
                    )}
                    {isError && (
                        <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                            <p>Unable to load. Please refresh and try again</p>
                        </div>
                    )}

                    {!isError && !isLoading && (
                        <div className='h-full overflow-auto pr-2'>
                            {questionList2?.map((questionRow: any) => (
                                <Question
                                    questionRow={questionRow}
                                    getQuestions={getQuestions} //
                                    setView={setView}
                                    setSelectedQuestion={setSelectedQuestion}
                                    setQuestionList={setQuestionList}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
