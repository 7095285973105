import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { EmailTemplateType, FilterRulesType, defaultEmailTemplate } from 'followUpEmail/FollowUpEmail.types'
import EmailEditor from 'followUpEmail/components/EmailEditor'
import EmailSettingsEditor from 'followUpEmail/components/EmailSettingsEditor'
import useModal from 'followUpEmail/hooks/useModal'
import { vFetch } from 'helpers'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TimeHandler } from 'helpers'
import OrderTagsEditor from 'followUpEmail/components/OrderTagsEditor'
import { FaTimes } from 'react-icons/fa'

export default function CreateTemplateModal() {
    const toggleModal = () =>
        setSearchParams((prev) => {
            prev.delete('view')
            return prev
        })
    const timeHandler = new TimeHandler()
    const modalRef = useModal({
        toggleModal,
    })
    const [_searchParams, setSearchParams] = useSearchParams()
    const [template, setTemplate] = useState(defaultEmailTemplate)
    const [rules, setRules] = useState<FilterRulesType>({})
    const [delay, setDelay] = useState({
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
    })
    const delayInMS =
        (delay.weeks || 0) * 7 * 24 * 60 * 60 * 1000 +
        (delay.days || 0) * 24 * 60 * 60 * 1000 +
        (delay.hours || 0) * 60 * 60 * 1000 +
        (delay.minutes || 0) * 60 * 1000

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setTemplate((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }
    const queryClient = useQueryClient()
    const createTemplateMutation = useMutation({
        mutationFn: async ({ template }: { template: EmailTemplateType }) =>
            vFetch('/marketing/email/templates', {
                method: 'POST',
                body: JSON.stringify(template),
            }),
        onSuccess: () => {
            toggleModal()
            return queryClient.invalidateQueries({ queryKey: ['marketing'] })
        },
    })
    const handleCreate = () => {
        const [year, month, date] = template.starts_on.split('-')
        const starts_on =
            year && month && date ? timeHandler.getISOString(Number(year), Number(month), Number(date)) : ''
        const parsedRules = structuredClone(rules)
        Object.entries(parsedRules).forEach(([_key, val]) => {
            for (const [_tableKey, rules] of Object.entries(val)) {
                if (rules.operator === 'IN' || rules.operator === 'NOT IN') {
                    rules.value = rules.value.split(',')
                }
            }
        })
        createTemplateMutation.mutate({
            template: {
                ...template,
                starts_on,
                delay: delayInMS,
                filter_rules: parsedRules,
            },
        })
    }

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center z-50'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-4 max-w-[90%] max-h-[90%] rounded shadow-md relative'>
                <button onClick={toggleModal} className='absolute top-2 right-2 text-lg text-red dark:text-lightred'>
                    <FaTimes />
                </button>
                <h2 className='font-bold mb-2'>Create New Automated Email</h2>
                <div className='flex gap-2'>
                    <div className='flex flex-col gap-2 w-[350px]'>
                        <EmailSettingsEditor
                            template={template}
                            editedFields={template}
                            setTemplate={setTemplate}
                            delay={delay}
                            editedDelayFields={delay}
                            setDelay={setDelay}
                        />
                    </div>
                    <div className='flex flex-col gap-2 min-w-[350px]'>
                        <EmailEditor template={template} editedFields={template} handleChange={handleChange} />
                    </div>
                    <div className='flex flex-col gap-2 min-w-[350px]'>
                        <h3 className='font-bold text-sm'>Rules</h3>
                        <OrderTagsEditor event_type={template.event_type} rules={rules} setRules={setRules} />
                    </div>
                </div>
                <Button onClick={handleCreate}>Create</Button>
            </div>
        </div>
    )
}
