import { BsThreeDotsVertical } from 'react-icons/bs'
import { useSelector } from 'react-redux'

export default function ExampleArticleCards() {
    const currentUser = useSelector((state: any) => state.user) //

    return (
        <div className='mt-4 mb-4 grid grid-cols-2 gap-2'>
            {/* EXAMPLE CARD PENDING */}
            <div className='relative flex items-center border bg-neutral-50 dark:bg-darkbg1 border-lightgrey dark:border-none shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden p-4'>
                <div className='absolute top-[6px] right-[35px] opacity-[70%] dark:opacity-[80%] dark:text-white'>
                    <div className=' bg-yellow-300/75 border border-yellow-400/75 rounded px-2'>Pending</div>
                </div>
                <div className='absolute right-2 top-2'>
                    <div className='text-black dark:text-offwhite text-xl font-bold'>
                        <BsThreeDotsVertical />
                    </div>
                </div>
                <div className='pr-4 pt-4 pl-2'>
                    <button className='mt-2 text-md font-semibold leading-tight text-left'>
                        Any articles you create will appear here
                    </button>
                    <div className='mt-1 leading-tight text-left text-sm'>
                        All articles will be pending until reviewed by an admin. To read your review, click on an
                        article from this page and scroll to the bottom.
                    </div>
                    <div className=' p-1  w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                    <div className='flex items-center gap-2'>
                        <div>
                            <div className='mt-4 font-semibold text-sm leading-none'>
                                <div className='mt-4 font-semibold text-sm leading-none'></div>
                            </div>
                            <div className='mt-0.5 flex flex-row items-center gap-2'></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* EXAMPLE CARD REJECTED */}
            <div className='relative flex items-center border bg-neutral-50 dark:bg-darkbg1 border-lightgrey dark:border-none shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden p-4'>
                <div className='absolute top-[6px] right-[35px] opacity-[70%] dark:opacity-[80%] dark:text-white'>
                    <div className='bg-red/50 border border-red/75 rounded px-2'>Rejected</div>
                </div>
                <div className='absolute right-2 top-2'>
                    <div className='text-black dark:text-offwhite text-xl font-bold'>
                        <BsThreeDotsVertical />
                    </div>
                </div>
                <div className='pr-4 pt-4 pl-2'>
                    <button className='mt-2 text-md font-semibold leading-tight text-left'>
                        Articles will be reviewed, then either approved or rejected
                    </button>
                    <div className='mt-1 leading-tight text-left text-sm'>
                        If rejected, read the article review, then edit your article to put it back through the approval
                        process.
                        {/* If rejected, don't sweat it, read the article review, then edit your article to put it back
                        through the approval process. */}
                    </div>
                    <div className=' p-1  w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                    <div className='flex items-center gap-2'>
                        <div>
                            <div className='mt-4 font-semibold text-sm leading-none'>
                                <div className='mt-4 font-semibold text-sm leading-none'></div>
                            </div>
                            <div className='mt-0.5 flex flex-row items-center gap-2'></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* EXAMPLE CARD PUBLISHED */}
            <div className='relative flex items-center border bg-neutral-50 dark:bg-darkbg1 border-lightgrey dark:border-none shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden p-4'>
                <div className='absolute top-[6px] right-[35px] opacity-[70%] dark:opacity-[80%] dark:text-white'>
                    <div className=' bg-green-300/50 border border-green-400/25 rounded px-2'>Published</div>
                </div>
                <div className='absolute right-2 top-2'>
                    <div className='text-black dark:text-offwhite text-xl font-bold'>
                        <BsThreeDotsVertical />
                    </div>
                </div>
                <div className='pr-4 pt-4 pl-2'>
                    <button className='mt-2 text-md font-semibold leading-tight text-left'>
                        If approved, your article will be published for all to see!
                    </button>
                    <div className='mt-1 leading-tight text-left text-sm'>
                        It will appear on the 'All Articles' page, and in its corresponding department.
                    </div>
                    <div className=' p-1  w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                    <div className='flex items-center gap-2'>
                        <div>
                            <div className='mt-4 font-semibold text-sm leading-none'>
                                <div className='mt-4 font-semibold text-sm leading-none'></div>
                            </div>
                            <div className='mt-0.5 flex flex-row items-center gap-2'></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* EXAMPLE CARD PUBLISHED AND HIDDEN */}
            <div className='relative flex items-center border bg-neutral-50 dark:bg-darkbg1 border-lightgrey dark:border-none shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden p-4'>
                <div className='absolute top-[6px] right-[35px] opacity-[70%] dark:opacity-[80%] dark:text-white'>
                    <div className=' bg-green-300/50 border border-green-400/25 rounded px-2'>Published</div>
                </div>
                <div className='absolute top-[6px] right-[130px] opacity-[70%] dark:opacity-[80%] dark:text-white'>
                    <div className=' bg-grey/75 border border-grey-/75 rounded px-2'>Hidden</div>
                </div>
                <div className='absolute right-2 top-2'>
                    <div className='text-black dark:text-offwhite text-xl font-bold'>
                        <BsThreeDotsVertical />
                    </div>
                </div>
                <div className='pr-4 pt-4 pl-2'>
                    <button className='mt-2 text-md font-semibold leading-tight text-left'>
                        You can choose to make your article hidden
                    </button>
                    <div className='mt-1 leading-tight text-left text-sm'>
                        Once hidden, your article will only appear on this page. You can change visibility at any time.{' '}
                        <br></br>
                        <br></br>Keep in mind, it will still go through the approval process and admins will be able to
                        see it regardless of visibility.
                    </div>
                    <div className=' p-1  w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                    <div className='flex items-center gap-2'>
                        <div>
                            <div className='mt-4 font-semibold text-sm leading-none'>
                                <div className='mt-4 font-semibold text-sm leading-none'></div>
                            </div>
                            <div className='mt-0.5 flex flex-row items-center gap-2'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
