import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { FaCaretDown } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { buildEmailFromTemplate, cn, vFetch } from '../../../../helpers'
import { ProcurementEmailTemplateType } from '../../../ProcurementEmailTemplates'
import { useCompanyContactsQuery, useCreateEmail, useUpdateLineItems } from '../../../api/useQueries'
import { ProcurementContext, ProcurementContextType } from '../../../helpers'
import { unfulfilledLineItemType } from 'procurement/components/lists/UnfulfilledOrdersList'

export default function UnfulfilledOrderEmail({
    showQuickModal,
    setShowQuickModal,
    unfulfilledLineItems,
}: {
    showQuickModal: any
    setShowQuickModal: any
    unfulfilledLineItems: unfulfilledLineItemType[]
}) {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const uniqueOrderNames = Array.from(new Set(unfulfilledLineItems.map((lineItem) => lineItem.name)))
    const lineItemIds = unfulfilledLineItems.map((lineItem) => lineItem.id)
    const btnRef = useRef<HTMLButtonElement>(null)
    const listRef = useRef<HTMLDivElement>(null)
    const user = useSelector<any, any>((state) => state.user)
    const [emailTemplates, setEmailTemplates] = useState<ProcurementEmailTemplateType[]>([])
    const [email, setEmail] = useState<any>({
        from: `${user.firstName} ${user.lastName} <${user.email}>`,
        send_to: '',
        cc: '',
        subject: `Unfulfilled Products on Orders:  ${uniqueOrderNames.join(', ')}`,
        body: '',
        greeting: 'Hi,',
        html: '',
    })
    const [showContacts, setShowContacts] = useState(false)

    const companyContactsQuery = useCompanyContactsQuery({ companyId: selectedCompany.id })
    const { contacts } = companyContactsQuery.data || {}
    const latestContacts = contacts?.filter((contact: any) => contact.updated_at != '2024-03-29T20:41:47.000Z')

    const createEmail = useCreateEmail(setShowQuickModal)
    const updateLineItems = useUpdateLineItems()
    const { isPending } = createEmail

    const handleSubmit = () => {
        createEmail.mutate(
            { email, companyId: selectedCompany.id, user },
            {
                onSuccess: () => {
                    updateLineItems.mutate({ lineItemIds })
                },
                onSettled: () => {
                    return setShowQuickModal(false)
                },
            }
        )
    }

    useEffect(() => {
        setEmail({
            ...email,
            subject: `Unfulfilled Products on Orders:  ${uniqueOrderNames.join(', ')}`,
        })
    }, [unfulfilledLineItems])

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setEmail((previousState: any) => ({
            ...previousState,
            [target.name]: target.value,
        }))
    }

    const handleAddSendTo = (email: string) => {
        setEmail((previousState: any) => {
            const newState = structuredClone(previousState)
            const existingEmails = newState.send_to ? newState.send_to.split(/[ ,]+/) : []
            if (existingEmails.includes(email)) {
                newState.send_to = existingEmails.filter((existingEmail: string) => existingEmail !== email).join(', ')
                return newState
            } else {
                newState.send_to = [...existingEmails, email].join(', ')
                return newState
            }
        })
    }

    const handleSetTemplate = ({ target }: { target: HTMLSelectElement }) => {
        if (target.value !== '') {
            const selectedTemplate = emailTemplates[parseInt(target.value)]
            const templatedEmail = buildEmailFromTemplate(
                {
                    ...selectedTemplate,
                    html: selectedTemplate.body,
                },
                { company: selectedCompany }
            )
            setEmail({
                ...email,
                ...templatedEmail,
            })
        }
    }

    useEffect(() => {
        vFetch(`/procurement/emailTemplates`, {
            cb: (res: any) => {
                setEmailTemplates(res.emailTemplates || [])
            },
        })

        const listenToWindow = (e: MouseEvent) => {
            if (!btnRef.current?.contains(e.target as Element) && !listRef.current?.contains(e.target as Element)) {
                setShowContacts(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <>
            <div
                className={cn(
                    showQuickModal === 'task' ? 'w-[725px]' : 'w-[700px]',
                    'fixed h-fit bottom-0 left-[50%] translate-x-[-50%] z-[50] bg-white shadow-small rounded-t-[4px] overflow-hidden dark:bg-darkaccent flex flex-col border border-darkgrey dark:border-grey border-b-0'
                )}
            >
                <button
                    onClick={() => {
                        setShowQuickModal(undefined)
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>

                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                    <h3 className='leading-[1] font-bold'>Send Fulfillment Email</h3>
                </div>
                <div className={cn(createEmail.isPending && 'opacity-50', 'p-[16px] flex flex-col gap-[8px]')}>
                    <div className='flex flex-col gap-[4px]'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Template
                        </label>
                        <select
                            onChange={handleSetTemplate}
                            className='bg-transparent dark:bg-darkness rounded-[4px] p-[4px] focus:outline-none'
                            disabled={isPending}
                        >
                            <option className='dark:bg-darkness/90' value=''>
                                Select a template
                            </option>
                            {emailTemplates.map((template, index) => (
                                <option key={template.name} className='dark:bg-darkness/90' value={index}>
                                    {template.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-col gap-[4px] w-full relative'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            To
                        </label>
                        <input
                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            id='send_to'
                            name='send_to'
                            onChange={handleChange}
                            value={email.send_to}
                            disabled={isPending}
                        />
                        <button
                            ref={btnRef}
                            onClick={() => setShowContacts((previousState) => !previousState)}
                            className='absolute w-[16px] h-[16px] right-[8px] top-[50%]'
                            disabled={isPending}
                        >
                            <FaCaretDown className={`w-full h-full ${showContacts ? 'rotate-180' : ''}`} />
                        </button>
                        {showContacts && (
                            <div
                                ref={listRef}
                                className='absolute top-[100%] left-0 w-full overflow-auto max-h-[150px] flex flex-col bg-white dark:bg-darkaccent shadow-small'
                            >
                                {selectedCompany.email && (
                                    <button
                                        onClick={() => handleAddSendTo(selectedCompany.email)}
                                        className='flex items-center gap-[8px] text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                        disabled={isPending}
                                    >
                                        <span
                                            className={`inline-block w-[16px] h-[16px] shrink-0 rounded-[4px] border border-darkgrey dark:border-offwhite ${
                                                email.send_to.includes(selectedCompany.email)
                                                    ? 'bg-darkgrey dark:bg-offwhite'
                                                    : ''
                                            }`}
                                        ></span>
                                        {selectedCompany.name} - {selectedCompany.email}
                                    </button>
                                )}
                                {latestContacts.map((contact: any) => (
                                    <button
                                        key={contact.email}
                                        onClick={() => handleAddSendTo(contact.email)}
                                        className='flex items-center gap-[8px] text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                        disabled={isPending}
                                    >
                                        <span
                                            className={`inline-block w-[16px] h-[16px] shrink-0 rounded-[4px] border border-darkgrey dark:border-offwhite ${
                                                email.send_to.includes(contact.email)
                                                    ? 'bg-darkgrey dark:bg-offwhite'
                                                    : ''
                                            }`}
                                        ></span>
                                        {contact.contact_type} - {contact.name} - {contact.email}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            CC
                        </label>
                        <input
                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            id='cc'
                            name='cc'
                            onChange={handleChange}
                            value={email.cc}
                            disabled={isPending}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            From
                        </label>
                        <input
                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            id='from'
                            name='from'
                            onChange={handleChange}
                            value={email.from}
                            readOnly
                            disabled={isPending}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Subject
                        </label>
                        <input
                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            id='subject'
                            name='subject'
                            onChange={handleChange}
                            value={email.subject}
                            disabled={isPending}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Body
                        </label>
                        <textarea
                            className='w-full p-[16px] bg-lightgrey dark:bg-darkness border-none focus:outline-none h-[150px]'
                            name='body'
                            value={email.body}
                            onChange={handleChange}
                            disabled={isPending}
                        ></textarea>
                    </div>
                </div>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex justify-center'>
                    <button
                        disabled={createEmail.isPending ? true : false}
                        onClick={handleSubmit}
                        className='py-[4px] px-[8px] rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                    >
                        {createEmail.isPending ? 'Sending Email' : 'Send Email'}
                    </button>
                </div>
            </div>
        </>
    )
}
