import { useEffect, useState } from 'react'
import { BsFillTrash3Fill } from 'react-icons/bs'
import { GrDocumentUpdate } from 'react-icons/gr'
import { vFetch } from '../../helpers'

export default function RedirectComponent({
    redirects,
    setRedirects,
    redirect_id,
    from_path,
    to_path,
    is_new,
    refresh,
}) {
    const [redirectClone, setRedirectClone] = useState({ from_path, to_path })
    const [edited, setEdited] = useState(false)
    const handleChange = ({ target }) => {
        setRedirectClone({
            ...redirectClone,
            [target.name.replace(`${redirect_id}_`, '')]: target.value,
        })
    }
    useEffect(() => {
        if (from_path !== redirectClone.from_path || to_path !== redirectClone.to_path) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [redirectClone])
    const handleDelete = () => {
        vFetch(`/redirects`, {
            method: 'DELETE',
            body: JSON.stringify({
                redirect_id,
            }),
        })
        setRedirects(redirects.filter((r) => r.redirect_id !== redirect_id))
    }
    const handleUpdate = () => {
        vFetch(`/redirects`, {
            method: 'PUT',
            body: JSON.stringify({
                redirect_id,
                from_path: redirectClone.from_path,
                to_path: redirectClone.to_path,
            }),
            cb: (res) => {
                if (res.success) {
                    refresh()
                    setEdited(false)
                }
            },
        })
    }
    const handleCreate = () => {
        vFetch(`/redirects`, {
            method: 'POST',
            body: JSON.stringify({
                redirect_id,
                from_path: redirectClone.from_path,
                to_path: redirectClone.to_path,
            }),
            cb: (res) => {
                if (res.success) {
                    refresh()
                    setEdited(false)
                }
            },
        })
    }
    return (
        <div className='redirects__redirect' style={edited ? { outline: '2px solid orange' } : {}}>
            <div className='redirects__redirect__input-container'>
                <input
                    className='redirects__redirect__input'
                    type='text'
                    id={`${redirect_id}_from_path`}
                    name={`${redirect_id}_from_path`}
                    value={redirectClone.from_path}
                    onChange={handleChange}
                    placeholder='From path'
                />
            </div>
            <div className='redirects__redirect__input-container'>
                <input
                    className='redirects__redirect__input'
                    type='text'
                    id={`${redirect_id}_to_path`}
                    name={`${redirect_id}_to_path`}
                    value={redirectClone.to_path}
                    onChange={handleChange}
                    placeholder='To path'
                />
                <button
                    onClick={handleDelete}
                    className='redirects__redirect__button redirects__redirect__button--delete'
                >
                    <BsFillTrash3Fill />
                </button>
                {edited && (
                    <button
                        onClick={is_new ? handleCreate : handleUpdate}
                        className='redirects__redirect__button redirects__redirect__button--update'
                    >
                        <GrDocumentUpdate />
                    </button>
                )}
            </div>
        </div>
    )
}
