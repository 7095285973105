import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const productSetup = createQueryKeys('productSetup', {
    inventory: ({
        emailIdParam,
        filters,
        search,
    }: {
        emailIdParam?: string | undefined
        filters?: string[] | undefined
        search?: string | undefined
    }) => ({
        queryKey: [emailIdParam, filters, search],
        queryFn: (context) =>
            service.getInventoryEmails({
                emailIdParam,
                filters,
                search,
            }),
    }),
    products: (filters: any, search: string) => ({
        queryKey: [filters, search],
        queryFn: () => service.getProducts(filters, search),
    }),
    variants: (product_id: any) => ({
        queryKey: [product_id],
        queryFn: () => service.getVariants(product_id),
    }),
    privateEvents: () => ({
        queryKey: ['privateEvents'],
        queryFn: () => service.getProductSetupPrivateEvents(),
    }),
})
export const productSetupQueries = mergeQueryKeys(productSetup)
