import { useState } from 'react'
import { vFetch } from '../../helpers'
import { DraftOrderInit } from '../../orders/orders.types'

type TagsProps = {
    tags: string[]
    setTags: Function
    order: DraftOrderInit
}

export default function DraftTags({ order, tags, setTags }: TagsProps) {
    const [newTag, setNewTag] = useState<string>('')


    function tagUpdate(tags: string[]) {
        vFetch(`/draftOrders/${order.id}`, {
            method: 'PUT',
            body: JSON.stringify({ id: order.id, fields: { tags } }),
        })
    }

    return (
        <div className='grid bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
            <div>
                <div className='flex justify-between'>
                    <h2 className='text-[20px] font-bold mb-[4px]'>Tags</h2>

                </div>
                <div className='flex flex-wrap gap-[16px] mt-[16px]'>
                    <form
                        className='w-[100%]'
                        onSubmit={(e) => {
                            tagUpdate([...tags, newTag])
                            e.preventDefault()
                            setTags([...tags, newTag])
                            setNewTag('')
                        }}
                    >
                        <input
                            value={newTag}
                            onChange={(e) => {
                                setNewTag(e.target.value)
                            }}
                            className='w-[100%] border-[1px] dark:border-darkgrey p-[8px] rounded dark:bg-darkness'
                            placeholder='Find or create tags'
                        />
                    </form>
                    {tags.length > 0 && tags.map(
                        (tag) =>
                            tag && (
                                <span className='relative bg-[lightgrey] dark:bg-darkness px-[8px] py-[2px] text-[14px] rounded'>
                                    {tag}
                                    <button
                                        className="absolute grid bg-grey top-[-10px] right-[-7px] h-[16px] w-[16px] leading-1 content-['x'] rounded-full"
                                        onClick={() => {
                                            setTags(tags.filter((iTag) => iTag !== tag))
                                            tagUpdate(tags.filter((iTag) => iTag !== tag))

                                        }}
                                        aria-label='delete tag'
                                    >
                                        <span className='absolute top-[-3px] right-[4px] leading-1 h-[0] font-semibold text-black'>
                                            x
                                        </span>
                                    </button>
                                </span>
                            )
                    )}
                </div>
            </div>
        </div>
    )
}
