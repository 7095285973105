import { useEffect, useState } from 'react'
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin'
import { useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { EmailInit } from '../emails/email.types'
import { closePopout, createOrderNotesArray, fetchAllUsers, parseResObject, sendToast, vFetch } from '../helpers'
import {
    DraftOrderInit,
    LineItemInit,
    OrderAction,
    OrderTimelineEvent,
    defaultDraftOrder,
} from '../orders/orders.types'
import {
    PrivateEventType,
    TaskCreateInit,
    TaskEditInit,
    TaskTimelineEventV2,
    defaultTaskCreate,
} from '../tasks/tasks.types'
import { UserInit } from '../users/users.types'
import DraftCustomer from './components/DraftCustomer'
import { DraftEvents } from './components/DraftEvents'
import DraftOrderHeader from './components/DraftOrderHeader'
import DraftOrderOverView from './components/DraftOrderOverview'
import DraftTags from './components/DraftTags'
import DraftTaskEditCreateModal from './components/DraftTaskEditCreateModal'
import { draftOrderRefreshTasks } from './helpers/draftOrders.helpers'

export default function DraftOrderHubScreen() {
    const { orderId } = useParams()
    const [params, setSearchParams] = useSearchParams()
    const [showDiscount, setShowDiscount] = useState<string>('')
    const user = useSelector<any, any>((state) => state.user)
    const navigate = useNavigate()

    const [orderLoaded, setOrderLoaded] = useState(false)
    const [eventsLoaded, setEventsLoaded] = useState(false)
    const [emailsLoaded, setEmailsLoaded] = useState(false)

    const [order, setOrder] = useState<DraftOrderInit>(defaultDraftOrder)
    const [draftOrderEventsPrivate, setDraftOrderEventsPrivate] = useState<PrivateEventType[]>([])

    const [draftOrderTags, setDraftOrderTags] = useState<string[]>([])

    const [userList, setUserList] = useState<UserInit[]>([])
    const [selectedTab, setSelectedTab] = useState<string>('tasks')
    const [tasks, setTasks] = useState<(TaskEditInit | TaskCreateInit)[]>([])

    const [orderEvents, setOrderEvents] = useState<OrderTimelineEvent[]>([]) // shopify timeline events
    const [events, setEvents] = useState<any>([])
    const [taskEvents, setTaskEvents] = useState<TaskTimelineEventV2[]>([])
    const [emails, setEmails] = useState<EmailInit[]>([])
    const [draftOrderEventPrivateLogged, setDraftOrderEventPrivateLogged] = useState(false)

    // const [files, setFiles] = useState([])

    const activeUserList = userList.filter((user) => {
        if (user.roles.includes('suspended')) {
            return
        }
        return user
    })

    const eventSource: {
        foreign_id: number | undefined | null
        foreign_table: string
    } = {
        foreign_id: order.id,
        foreign_table: 'draft_orders',
    }
    const tabs: any = {
        middle: {
            tasks: tasks,
            // hidiing this for now since files is not set up
            // files: files,
        },
        right: {},
    }

    const canCreateTask = tasks.length > 0 ? false : true

    const newTask = {
        ...defaultTaskCreate,
        assigned_to: [user.user_id],
        customer: order.customer,
        draft_order_id: order.id,
        draft_order_name: order.name,
        order_notes: order.note || '',
        task_type: 'Sales',
        topic: 'Lead',
    }
    function addNewTask() {
        if ((tasks as TaskEditInit[]).some((t) => !t.id))
            return sendToast({ message: 'Please only create 1 new task at a time.' })
        if (canCreateTask) return setTasks([...tasks, newTask])
        else
            return sendToast({
                message: 'Sorry, no more tasks can be created. All task types are being used for this type of order.',
            })
    }

    const orderActions: OrderAction[] = []

    useEffect(() => {
        if (!draftOrderEventPrivateLogged) {
            if (user && order) {
                vFetch(`/draftOrderEventsPrivate`, {
                    skipToast: true,
                    method: 'POST',
                    body: JSON.stringify({
                        draft_order_id: orderId,
                        user_id: user.id,
                        event_type: 'viewed',
                    }),
                    cb: (res: any) => {
                        if (res.success) {
                            setDraftOrderEventPrivateLogged(true)
                        }
                    },
                })
            }
        }
    }, [])

    function refresh() {
        setOrderLoaded(false)
        fetchAllUsers(setUserList)
        vFetch(`/draftOrderEventsPrivate?draft_order_id=${orderId}`, {
            cb: (res: any) => setDraftOrderEventsPrivate(res.draftOrderEventsPrivate),
        })

        vFetch(`/draftOrders/${orderId}`, {
            cb: (res: any) => {
                if (res.success) {
                    setOrderEvents(
                        res.events.map((event: any) => {
                            return { ...event.node, normalized_event_type: 'Shopify Timeline' }
                        })
                    )
                    const resOrder = parseResObject(res.draft_order)
                    if (res.task) {
                        parseResObject(res.task)
                        res.task.due_at = new Date(res.task.due_at.toISOString().slice(0, -1))
                        setTasks([res.task])
                        vFetch(`/taskEvents`, {
                            skipToast: true,
                            method: 'POST',
                            body: JSON.stringify({
                                task_id: res.task.id,
                                user_id: user.id,
                                event_type: 'viewed',
                            }),
                        })
                    }
                    if (res.task?.task_events_public) {
                        setTaskEvents(
                            res.task.task_events_public.map((e: any) => {
                                return { ...parseResObject(e), normalized_event_type: 'Task Events Public' }
                            })
                        )
                    }

                    vFetch(
                        `/products/limited/handles?gid=${resOrder.line_items.map(
                            (i: LineItemInit) => `gid://shopify/Product/${i.product_id}`
                        )}`,
                        {
                            cb: (pRes: any) => {
                                if (pRes.success) {
                                    pRes.products.forEach(
                                        (p: any) =>
                                            (resOrder.line_items.find(
                                                (i: LineItemInit) =>
                                                    i.product_id == p.gid.replace('gid://shopify/Product/', '')
                                            ).handle = p.handle)
                                    )
                                    setOrder(resOrder)
                                    if (resOrder.tags) {
                                        setDraftOrderTags([...resOrder.tags])
                                    }
                                    setOrderLoaded(true)
                                    setEventsLoaded(true)
                                }
                            },
                        }
                    )
                } else {
                    navigate(`/draftOrders`)
                }
            },
        })
    }

    useEffect(() => {
        refresh()
        const listenToWindow = (e: MouseEvent) => closePopout(e, ['js-order__discount'], setShowDiscount)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        setEvents([...orderEvents, ...taskEvents, ...createOrderNotesArray(order.note || '', userList), ...emails])
    }, [order, orderEvents, taskEvents, emails])
    useEffect(() => {
        setEmailsLoaded(false)
        if (orderId)
            vFetch(`/email?table_id=draft_orders=${orderId}`, {
                cb: (res: any) => {
                    if (res.success) {
                        setEmails(
                            res.emails.map((email: any) => {
                                return { ...parseResObject(email), normalized_event_type: 'Email' }
                            })
                        )
                        setEmailsLoaded(true)
                    }
                },
            })
    }, [])

    function onEmailSubmit(): any {
        vFetch(`/email?table_id=draft_orders=${orderId}`, {
            cb: (res: any) => {
                if (res.success) {
                    setEmails(
                        res.emails.map((email: any) => {
                            return { ...parseResObject(email), normalized_event_type: 'Email' }
                        })
                    )
                }
            },
        })
    }
    const allLoaded = orderLoaded ? (eventsLoaded ? (emailsLoaded ? true : false) : false) : false
    return (
        <div className='flex flex-col gap-[8px] text-black dark:text-offwhite'>
            {!allLoaded && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            {allLoaded && (
                <>
                    <DraftOrderHeader
                        userList={userList}
                        draftOrderEventsPrivate={draftOrderEventsPrivate}
                        order={order}
                        tasks={tasks.filter((t) => !!(t as TaskEditInit).id) as TaskEditInit[]}
                        actions={orderActions}
                        addNewTask={addNewTask}
                    />
                    {/* Info columns */}
                    <div className='flex gap-[8px] items-start justify-start'>
                        {/* left column */}
                        <div className='flex flex-col gap-[8px] basis-[calc(33.3%-32px)] grow min-w-[450px]'>
                            <DraftOrderOverView order={order} events={events} />
                            <DraftCustomer order={order} eventSource={eventSource} onEmailSubmit={onEmailSubmit} />
                            <DraftTags order={order} tags={draftOrderTags} setTags={setDraftOrderTags} />
                        </div>
                        <div className='flex flex-wrap gap-[8px] basis-[calc(66.6%-32px)] grow items-start justify-start'>
                            {/* middle column  */}
                            <div className='flex flex-col gap-x-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                                <div className='relative top-[1px] flex gap-[8px] ml-[8px] border-b-darkgrey'>
                                    {Object.keys(tabs.middle).map((tab) => (
                                        <button
                                            className={`border-black dark:border-darkgrey border-[1px] border-b-0 ${
                                                selectedTab === tab
                                                    ? 'bg-white dark:bg-darkness z-index-1'
                                                    : '!border-transparent'
                                            } rounded-t px-[4px] capitalize`}
                                            onClick={() => setSelectedTab(tab)}
                                        >
                                            {tab} ({tabs.middle[tab].length})
                                        </button>
                                    ))}
                                </div>
                                <div className='grid gap-[8px] border-[1px] border-black dark:border-darkgrey rounded p-[8px]'>
                                    {selectedTab === 'tasks' && (
                                        <div className='flex flex-col gap-[8px]'>
                                            {tasks.map((task, i) => (
                                                <DraftTaskEditCreateModal
                                                    refreshProp={() =>
                                                        draftOrderRefreshTasks(order, setTasks, setTaskEvents)
                                                    }
                                                    task={task}
                                                    onTaskDelete={onEmailSubmit}
                                                    setTask={(task: TaskEditInit) =>
                                                        setTasks(
                                                            tasks.map((t) =>
                                                                (t as TaskEditInit).id === task.id ? task : t
                                                            )
                                                        )
                                                    }
                                                    order={order}
                                                    setOrder={setOrder}
                                                    possibleTypes={['Sales']}
                                                    userList={activeUserList}
                                                />
                                            ))}
                                            {canCreateTask && (
                                                <div className='flex justify-start items-center gap-[8px]'>
                                                    <button
                                                        className='px-[8px] py-[2px] uppercase text-fire border-[2px] border-fire font-semibold rounded'
                                                        onClick={addNewTask}
                                                    >
                                                        + Task
                                                    </button>
                                                    {tasks.length === 0 && <p>This order currently has no tasks.</p>}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* right column */}
                            <div className='flex flex-col gap-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                                <DraftEvents
                                    events={events}
                                    userList={userList}
                                    tasks={tasks}
                                    addNewTask={addNewTask}
                                    order={order}
                                    setOrder={setOrder}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
