import { InventoryEmailType } from 'edi/EDIScreens.types'
import { vFetch } from '../../helpers'

// Inventory Email Service
export const getInventoryEmails = async ({
    emailIdParam,
    filters,
    search,
}: {
    emailIdParam?: string | undefined
    filters: any | undefined
    search: string | undefined
}) => {
    const queryParams = [
        emailIdParam ? `emailId=${emailIdParam}` : null,
        filters ? `filters=${encodeURIComponent(JSON.stringify(filters))}` : null,
        search ? `search=${encodeURIComponent(search)}` : null,
    ]
        .filter((v) => v)
        .join('&')

    const response = await vFetch(`/email/inventoryEmail?${queryParams}`, {
        cb: (res: any) => {
            res.inventoryEmails = res.inventoryEmails.map((inventoryEmail: InventoryEmailType) => {
                return {
                    ...inventoryEmail,
                    text_html: JSON.parse(inventoryEmail.text_html),
                    text_plain: JSON.parse(inventoryEmail.text_plain),
                    updated_items: JSON.parse(inventoryEmail.updated_items),
                    update_failures: JSON.parse(inventoryEmail.update_failures),
                }
            })
            return res
        },
    })
    return response
}
export const manualInventorySheetUpload = async (
    keyMap: any,
    src: string,
    vendors: string[],
    emailId: string | number
) => {
    return await vFetch('/v2/products/inventorySheets', {
        method: 'POST',
        body: JSON.stringify({ keyMap, src, vendors, emailId }),
        reThrowErr: true,
        reThrowMessage: 'Failed to initiate manual inventory update',
    })
}
export const saveInventoryMap = async (keyMap: any, companyIds: string[] | number[], headingString: string) => {
    return await vFetch('/email/inventoryEmailMaps', {
        method: 'PUT',
        body: JSON.stringify({ keyMap, companyIds, headingString }),
        reThrowErr: true,
        reThrowMessage: 'Failed to save inventory map',
    })
}
export const addCompanySenderAddress = async (from: string, companyId: string | number) => {
    return await vFetch('/email/senderAddresses', {
        method: 'POST',
        body: JSON.stringify({ from, companyId }),
        reThrowErr: true,
        reThrowMessage: 'Failed to create sender address connection',
    })
}
export const deleteCompanySenderAddress = async (from: string, companyId: string | number) => {
    return await vFetch('/email/senderAddresses', {
        method: 'DELETE',
        body: JSON.stringify({ from, companyId }),
        reThrowErr: true,
        reThrowMessage: 'Failed to remove sender address connection',
    })
}
export const deleteInventoryEmailMap = async (id: string | number) => {
    return await vFetch('/email/inventoryEmailMaps', {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        reThrowErr: true,
        reThrowMessage: 'Failed to delete inventory email map',
    })
}
export const updateEmailStatus = async (emailId: string | number, status: string) => {
    return await vFetch('/email/inventoryEmail', {
        method: 'PUT',
        body: JSON.stringify({ emailId, status }),
        reThrowErr: true,
        reThrowMessage: 'Failed to update email status',
    })
}
export const updateVariantFields = async (variant: any) => {
    return await vFetch('/v2/variants/identifiers', {
        method: 'PUT',
        body: JSON.stringify(variant),
        reThrowErr: true,
        reThrowMessage: 'Failed to update variant options',
    })
}

export const getProductSetupPrivateEvents = async () => {
    const response = await vFetch(`/productSetupEventsPrivate/latest`, {})
    return response
}
export const getProducts = async (filters: any, search: string) => {
    const queryParams = [
        filters.id?.length > 0 ? `id=${filters.id.join(',')},` : null,
        filters.statuses.length > 0 ? `statuses=${filters.statuses.join(',')},` : null,
        filters.vendors.length > 0 ? `vendors=${encodeURIComponent(filters.vendors.join(','))},` : null,
        search ? `search=${search}` : null,
        filters.missing_info ? `missing_info=${filters.missing_info}` : null,
        filters.after ? `after=${filters.after}` : null,
        filters.before ? `before=${filters.before}` : null,
    ]
        .filter((v) => v)
        .join('&')

    const res = await vFetch(`/v2/products?${queryParams}`, {
        cb: (res: any) => {
            res.products.forEach((product: any) => {
                product.has_identifying_info = false
                if (product.custom_supplier_model_number || product.custom_supplier_sku || product.custom_upc) {
                    product.has_identifying_info = true
                }
            })
            return res
        },
        reThrowErr: true,
        reThrowMessage: 'Failed to load products',
    })
    return res
}
export const getVariants = async (product_id: any) => {
    const res = await vFetch(`/v2/variants?product_id=${product_id}`, {
        reThrowErr: true,
        reThrowMessage: 'Failed to load variants',
    })
    return res
}
