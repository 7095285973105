import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCreateFile } from '../../../api/useQueries'
import { CompanyContext, CompanyContextType } from '../../../helpers'
import { cn } from '../../../../helpers'

export default function UploadFileV2({ setShowQuickModal }: { setShowQuickModal: any }) {
    const { editableCompany } = useContext<CompanyContextType>(CompanyContext)
    const user = useSelector<any, any>((state) => state.user)
    const [files, setFiles] = useState<FileList | null>(null)
    const [types, setTypes] = useState<string[]>([])

    const createFile = useCreateFile(setShowQuickModal)
    const { isPending } = createFile

    const handleSubmit = async () => {
        createFile.mutate(
            { files, types, companyId: editableCompany.id, user },
            {
                onSettled: () => {
                    return setShowQuickModal(undefined)
                },
            }
        )
    }

    useEffect(() => {
        if (files) {
            setTypes(Array(files.length).fill(''))
        }
    }, [files])

    return (
        <>
            <div className={cn(createFile.isPending && 'opacity-50')}>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex gap-[8px] items-center'>
                    <h3 className='leading-[1] font-bold'>New Upload</h3>
                </div>
                <div className='p-[16px] w-full min-h-[100px] flex flex-col gap-[4px] items-center justify-center text-center'>
                    {files &&
                        Array.from(files).map((file, index) => (
                            <div className='flex gap-[8px] w-full items-center'>
                                <p className='w-1/2 text-left break-all'>{file.name}</p>
                                <select
                                    className='bg-transparent rounded-[4px] border border-darkgrey focus:outline-none w-1/2'
                                    disabled={isPending}
                                    onChange={({ target }) =>
                                        setTypes((previousState) => {
                                            const newState = structuredClone(previousState)
                                            newState[index] = target.value
                                            return newState
                                        })
                                    }
                                >
                                    <option className='dark:bg-darkness/90' value=''>
                                        Choose A Type
                                    </option>
                                    <option className='dark:bg-darkness/90' value='FP Import Template'>
                                        FP Import Template
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Intake Form'>
                                        Intake Form
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Dealer Application'>
                                        Dealer Application
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Price List / SKUs'>
                                        Price List / SKUs
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Price List / SKUs'>
                                        Product Reviews
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Inventory'>
                                        Inventory
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Brochure'>
                                        Brochure
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Credit Card / ACH Info'>
                                        Credit Card / ACH Info
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Shipping Weight & Dimensions'>
                                        Shipping Weight &amp; Dimensions
                                    </option>
                                    <option className='dark:bg-darkness/90' value='Warranty & Returns'>
                                        Warranty &amp; Returns
                                    </option>
                                </select>
                            </div>
                        ))}
                    <label
                        className='bg-darkgrey cursor-pointer text-white dark:text-offwhite font-bold py-[4px] px-[8px] rounded-[4px]'
                        htmlFor='file'
                    >
                        {files ? 'Change Files' : 'Choose Files'}
                    </label>
                    <input
                        className='appearance-none w-full h-full hidden'
                        id='file'
                        onChange={({ target }) => setFiles(target.files)}
                        type='file'
                        multiple
                        disabled={isPending}
                    />
                </div>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex justify-center'>
                    <button
                        onClick={handleSubmit}
                        disabled={!files || createFile.isPending}
                        className='disabled:opacity-50 py-[4px] px-[8px] rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                    >
                        {createFile.isPending ? 'Uploading...' : 'Upload Files'}
                    </button>
                </div>
            </div>
        </>
    )
}
