import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import UsersScreenModal from './UsersScreenModal'
import User from './User'
import './UsersScreen.css'
import CreateUserModal from './CreateUserModal'
import RoleManager from './RoleManager'
import { sortByAlphanumeric, vFetch } from '../helpers'
// const stores = [{
//     name: 'factorypure',
//     id: '2'
// }, {
//     name: 'generators',
//     id: '3'
// }]

const accessList = [
    '*',
    'product-setup',
    'processing-times',
    'cost-adjuster',
    'procurement',
    'price-cuts',
    'edi',
    'shipping',
    'sales',
    'calls',
    'users',
    'scrape',
    'admin',
]
export default function UsersScreen() {
    const user = useSelector((state) => state.user)
    const [search, setSearch] = useState('')
    const [allUsers, setAllUsers] = useState([])
    const [users, setUsers] = useState([])
    const [focus, setFocus] = useState({})
    const [showCreate, setShowCreate] = useState(false)
    const [showManageRoles, setShowManageRoles] = useState(false)
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const navigate = useNavigate()
    const [roles, setRoles] = useState([])
    const [currentShownStore, setCurrentShownStore] = useState('All Stores')
    const [stores, setStores] = useState([])

    const isSuperAdmin = user.roles.includes('developer') || user.type === 'SuperAdmin'
    const shownStores = [{ name: 'All Stores', id: 'All' }]
    stores.forEach((store) => {
        shownStores.push({
            name: store.name,
            id: store.id,
        })
    })

    useEffect(() => {
        if (!user.access.includes('*')) {
            navigate('/home')
        } else if (needsRefresh) {
            vFetch(`/users`, {
                cb: (res) => {
                    if (res.success) {
                        const resUsers = res.users
                            .map((user) => {
                                return {
                                    ...user,
                                    access: JSON.parse(user.access),
                                    roles: JSON.parse(user.roles || '[]'),
                                    store_ids: (user.store_ids || '').split(','),
                                }
                            })
                            .sort((a, b) => sortByAlphanumeric(a, b, 'last_name', 'first_name'))
                        setAllUsers(resUsers)
                        setUsers(resUsers)
                    }
                    setCurrentShownStore(stores[0])
                    setNeedsRefresh(false)
                },
            })
            vFetch(`/stores`, {
                cb: (res) => {
                    if (res.success) {
                        const stores = res.allStoresInfo
                        setStores(stores)
                    }
                },
            })
        }
    }, [needsRefresh])
    useEffect(() => {
        const tempRoles = []
        allUsers.forEach((user) =>
            user.roles.forEach((role) => {
                if (!tempRoles.includes(role)) tempRoles.push(role)
            })
        )
        setRoles(tempRoles.sort((a, b) => sortByAlphanumeric(a, b)))
    }, [allUsers])
    useEffect(() => {
        const st = search.trim().toLowerCase()
        if (st) {
            const anyOfTheseWords = st.split(' ')
            // filter allUsers by any of the "space" separated terms. Users are sorted by lastName, firstName. Not yet sorted by search relevancy.
            setUsers(
                allUsers.filter(
                    (u) =>
                        anyOfTheseWords.some((w) => u.first_name.toLowerCase().includes(w)) ||
                        anyOfTheseWords.some((w) => u.last_name.toLowerCase().includes(w))
                )
            )
        } else {
            setUsers(allUsers)
        }
    }, [search])

    function handleShownStoreChange(target) {
        setCurrentShownStore(target.value)
        setUsers(allUsers)
        if (target.value === 'All') {
        } else {
            const filteredUsers = []
            allUsers.forEach((user) => {
                if (user.store_ids.includes(target.value)) {
                    filteredUsers.push(user)
                }
            })
            setUsers(filteredUsers)
        }
    }

    return (
        <>
            {user && (
                <div className='users'>
                    <div className='flex justify-between mb-[8px] dark:text-offwhite'>
                        <div className='relative flex items-center gap-[16px] '>
                            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Users</h1>
                            <input
                                className='w-[300px] border-b-[1px] border-[#4a4a4a] dark:bg-darkaccent rounded px-[4px]'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                type='text'
                                id='search'
                                name='search'
                                placeholder='search'
                            />
                            <button onClick={() => setShowCreate(true)}>Create</button>
                            {showCreate && (
                                <CreateUserModal
                                    accessList={accessList}
                                    setShowCreate={setShowCreate}
                                    roles={roles}
                                    stores={stores}
                                />
                            )}
                        </div>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                            <div className='flex gap-4'>
                                <div className='place-self-center'>Users for:</div>
                                <select
                                    onChange={({ target }) => {
                                        handleShownStoreChange(target)
                                    }}
                                    className='bg-[whitesmoke] dark:bg-darkaccent grow rounded max-w-[200px] mr-[50px]'
                                    value={currentShownStore}
                                    name='type'
                                    id='type'
                                >
                                    {shownStores &&
                                        shownStores.map((store) => {
                                            return (
                                                <option
                                                    className='capitalize'
                                                    value={`${store.id}`}
                                                >{`${store.name}`}</option>
                                            )
                                        })}
                                </select>
                            </div>
                        )}
                        <div>
                            <button
                                className='px-[8px] py-[2px] font-bold uppercase bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80 text-white dark:text-black rounded'
                                onClick={() => setShowManageRoles(!showManageRoles)}
                            >
                                Manage Roles
                            </button>
                        </div>
                    </div>
                    <div className='users__list'>
                        {users &&
                            users.map((u) => (
                                <User showDeveloperSetRoles={isSuperAdmin} user={u} setFocus={setFocus} />
                            ))}
                    </div>
                </div>
            )}
            {showManageRoles && (
                <RoleManager
                    roles={roles}
                    users={allUsers}
                    setShowManageRoles={setShowManageRoles}
                    setNeedsRefresh={setNeedsRefresh}
                    showDeveloperSetRoles={isSuperAdmin}
                />
            )}
            {focus.email && (
                <UsersScreenModal
                    stores={stores}
                    focus={focus}
                    setFocus={setFocus}
                    setNeedsRefresh={setNeedsRefresh}
                    roles={roles}
                    showDeveloperSetRoles={isSuperAdmin}
                />
            )}
        </>
    )
}
