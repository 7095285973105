import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { vFetch } from '../helpers'
import IndexSync from './components/IndexSync'

export default function TableSync() {
    const navigate = useNavigate()
    const [table, setTable] = useState('')
    const [truncate, setTruncate] = useState(false)
    const [direction, setDirection] = useState('download')
    const [tableNames, setTableNames] = useState([])
    const sessionToken = localStorage.getItem('session_token')

    useEffect(() => {
        if (!window.location.origin.includes('localhost')) {
            navigate('/home')
        }
        vFetch(`/stream`, {
            cb: (res: any) => {
                if (res.success) {
                    setTableNames(res.tableNames)
                    setTable(res.tableNames[0])
                }
            },
        })
    }, [])

    const handleSubmit = () => {
        fetch(`https://server.fpdash.com/stream/${direction}?table=${table}&truncate=${truncate}`, {
            headers: {
                Authorization: sessionToken,
            } as HeadersInit,
        })
        setTruncate(false)
    }

    return (
        <div className='flex flex-col gap-[64px]'>
            <div className='flex flex-col gap-[16px]'>
                <h1 className='text-[24px] tracking-[2px] uppercase font-[300] text-darkgrey dark:text-offwhite'>
                    Get Data
                </h1>
                <div className='flex gap-[16px] w-full items-center'>
                    <div className='flex flex-col gap-[4px] w-1/3'>
                        <label
                            className='text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                            htmlFor='direction'
                        >
                            Direction
                        </label>
                        <select
                            className='bg-lightgrey rounded-[4px] py-[4px] px-[8px] dark:bg-faintplus dark:text-white'
                            value={direction}
                            onChange={({ target }) => setDirection(target.value)}
                        >
                            <option value='download' className='dark:bg-darkness/90'>
                                Download
                            </option>
                            <option value='upload' className='dark:bg-darkness/90'>
                                Upload
                            </option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-[4px] w-1/3'>
                        <label
                            className='text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                            htmlFor='table'
                        >
                            Table
                        </label>
                        <select
                            className='bg-lightgrey rounded-[4px] py-[4px] px-[8px] dark:bg-faintplus dark:text-white'
                            name='table'
                            id='table'
                            value={table}
                            onChange={({ target }) => setTable(target.value)}
                        >
                            {tableNames.map((tn) => (
                                <option className='dark:bg-darkness/90' value={tn}>
                                    {tn}
                                </option>
                            ))}
                        </select>
                    </div>
                    {direction === 'download' && (
                        <div className='flex flex-col gap-[4px]'>
                            <label
                                className='text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                htmlFor='truncate'
                            >
                                Truncate
                            </label>
                            <input
                                className='w-[36px] h-[32px] mr-auto block'
                                type='checkbox'
                                name='truncate'
                                id='truncate'
                                checked={truncate}
                                onChange={({ target }) => setTruncate(target.checked)}
                            />
                        </div>
                    )}
                    <button
                        className='mx-auto mt-auto block py-[4px] px-[16px] rounded-[4px] bg-blue text-white dark:bg-accent dark:text-darkness uppercase font-bold'
                        onClick={handleSubmit}
                    >
                        SYNC
                    </button>
                </div>
            </div>
            {/* <IndexSync /> */}
        </div>
    )
}
