import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { InventoryEmailType } from 'edi/EDIScreens.types'
import { formatDate } from 'procurement/helpers'
import { Link } from 'react-router-dom'

export default function SuccessfulItemsList({ email }: { email: InventoryEmailType }) {
    const identifier = email?.updated_items[0]?.identifier
    return (
        <div>
            <div className=''>
                <p>{`Inventory update completed on ${formatDate(new Date(email?.success_at))}`}</p>
            </div>
            <div className='w-5/6 relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                    Updated Products
                </p>
                <div className='flex flex-col gap-1 rounded-[4px] dark:text-offwhite'>
                    <div className='flex flex-col'></div>
                </div>
                <div>
                    <div className='grid grid-cols-[220px_220px_220px_120px_120px] border-b'>
                        <p>{identifier}</p>
                        <p>SKU</p>
                        <p>Variant Id</p>
                        <p>Quantity</p>
                        <p>Previous</p>
                    </div>
                    {email?.updated_items?.map((item: any) => {
                        const variantURL = `https://admin.shopify.com/store/factorypure/products/${item?.product_gid?.split(
                            '/'
                        )[item?.product_gid.split('/').length - 1]}/variants/${item?.variant_id}`
                        return (
                            <div className='grid grid-cols-[220px_220px_220px_120px_120px]'>
                                <p>{item[identifier]}</p>
                                <p>{item?.sku}</p>
                                <Link
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    to={variantURL}
                                    draggable={false}
                                    className='underline flex gap-2 items-center'
                                >
                                    {item?.variant_id}
                                    <ExternalLinkIcon />
                                </Link>
                                <p>{item?.quantity}</p>
                                <p>{item?.previous_quantity}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
