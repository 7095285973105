import { useQuery } from '@tanstack/react-query'
import { EmailTemplateType } from 'followUpEmail/FollowUpEmail.types'
import { getDelayInEnglish } from 'followUpEmail/helpers'
import { vFetch } from 'helpers'
import { useSearchParams } from 'react-router-dom'
export default function TemplateList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const emailTeplatesQuery = useQuery({
        queryKey: ['marketing', 'email', 'templates'],
        queryFn: async () => vFetch('/marketing/email/templates'),
    })
    const emailTemplates: EmailTemplateType[] = emailTeplatesQuery.data?.emailTemplates || []
    return (
        <>
            <h2 className='font-semibold leading-none text-lg mb-2'>Email Templates</h2>
            <div className='bg-bg1 dark:bg-darkbg1 shadow-sm rounded overflow-hidden border border-lightgrey dark:border-darkgrey text-sm mb-4'>
                <div className='grid grid-cols-4 bg-blue text-white dark:text-offwhite dark:bg-darkbg2 font-bold'>
                    <p className='p-2'>Name</p>
                    <p className='p-2'>Status</p>
                    <p className='p-2'>Event Type</p>
                    <p className='p-2'>Delay</p>
                </div>
                {emailTemplates.map((template) => (
                    <button
                        className='w-full text-left grid grid-cols-4 border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkbg2'
                        onClick={() => {
                            setSearchParams((prev) => {
                                prev.set('view', 'edit-template')
                                prev.set('template_id', template.id!.toString())
                                return prev
                            })
                        }}
                    >
                        <p className='p-2 capitalize'>{template.name}</p>
                        <p className='p-2 capitalize'>{template.status}</p>
                        <p className='p-2 capitalize'>{template.event_type.replaceAll('-', ' ')}</p>
                        <p className='p-2 capitalize'>{getDelayInEnglish(template.delay || 0)}</p>
                    </button>
                ))}
            </div>
        </>
    )
}
