import { useQuery } from '@tanstack/react-query'
import { FollowUpEmailType } from 'followUpEmail/FollowUpEmail.types'
import { vFetch } from 'helpers'

export default function ScheduledEmailList({
    id,
    template_id,
    customer_id,
    order_id,
}: {
    id?: string
    template_id?: string
    customer_id?: string
    order_id?: string
}) {
    const params = Object.entries({
        id,
        template_id,
        customer_id,
        order_id,
    })
        .filter(([_key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    const emailsQuery = useQuery({
        queryKey: ['marketing', 'email', params],
        queryFn: () => vFetch(`/marketing/email${params.length ? `?${params}` : ''}`),
        staleTime: 0,
    })
    const emails: FollowUpEmailType[] = emailsQuery?.data?.emails || []
    const keys = new Set<string>()
    for (const email of emails) {
        for (const [key, val] of Object.entries(email)) {
            if (val !== null) {
                keys.add(key)
            }
        }
    }
    return (
        <div>
            <div
                style={{ gridTemplateColumns: `repeat(${Object.keys(emails[0] || {}).length}, 1fr)` }}
                className='grid bg-accent1 dark:bg-darkbg2 text-white dark:text-offwhite sticky top-[-16px]'
            >
                {Array.from(keys).map((key) => (
                    <div className='p-2 font-bold text-sm capitalize'>{key.replaceAll('_', ' ')}</div>
                ))}
            </div>
            {emails.map((email) => (
                <div
                    style={{ gridTemplateColumns: `repeat(${Object.keys(emails[0] || {}).length}, 1fr)` }}
                    className='grid border-b border-lightgrey dark:border-darkgrey'
                >
                    {Array.from(keys).map((key) => {
                        const value = email[key as keyof typeof email]
                        return (
                            <div className='p-2 text-sm break-all'>
                                {key.toString().includes('_at') ? new Date(value).toDateString() : value}
                            </div>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}
