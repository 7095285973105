import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import { useSearchParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ArticleUnderReviewCard from '../components/ArticleUnderReviewCard'

export default function AdminPanel({ setView, setSelectedArticle }: { setView: any; setSelectedArticle: any }) {
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const articleID = searchParams.get('articleID')

    const articlesQuery = useQuery({
        queryKey: ['knowledge', 'articles', 'adminPending'],
        queryFn: async () => {
            return await vFetch(
                '/knowledge/articles?status=Pending&fields=title,excerpt,featured_image,id,created_at,user_id,department_id',
                {
                    cb: (res: any) => {
                        setIsLoading(false)
                        if (!res.success) {
                            setIsError(true)
                        }
                        if (res.success) {
                            const foundArticle = res.articles.find((article: any) => article.id == articleID)
                            if (foundArticle) {
                                setSelectedArticle(foundArticle)
                            }
                        }
                        return res.articles
                    },
                }
            )
        },
    })
    const pendingArticles = articlesQuery?.data || []

    useEffect(() => {}, [])
    return (
        <div>
            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}
            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}
            <>
                {!isLoading && (
                    <div>
                        {pendingArticles.length > 0 && (
                            <div>
                                <div className='font-bold text-[20px] px-2 my-6'>Articles Under Review</div>
                                <div className='grid grid-cols-3 gap-2'>
                                    {pendingArticles?.map((article: any, index: number) => (
                                        <ArticleUnderReviewCard
                                            article={article}
                                            setSelectedArticle={setSelectedArticle}
                                            setSelectedArticleView={''}
                                            setView={setView}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                        {pendingArticles.length === 0 && (
                            <div className='text-2xl mt-6 ml-4 dark:text-offwhite'>
                                <p>No pending articles</p>
                            </div>
                        )}
                    </div>
                )}
            </>
        </div>
    )
}
