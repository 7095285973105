import { useQuery } from '@tanstack/react-query'
import { vFetch } from '../../helpers'

export default function CacheScreen() {
    const cacheVitalsQuery = useQuery({
        queryKey: ['cacheVitals'],
        queryFn: () => vFetch('/cache'),
        refetchInterval: 1000,
        staleTime: 0,
    })
    const cacheVitals = cacheVitalsQuery?.data?.cache || {}

    const dataGraph = {}
    let currentNode = cacheVitals.LIST
    while (currentNode && currentNode.key) {
        const parsedKey = JSON.parse(currentNode.key)
        const graphNode = buildPath(dataGraph, parsedKey)
        graphNode.key = parsedKey
        currentNode = currentNode.next
    }
    function buildPath(dataGraph: any, path: string[]): any {
        if (!dataGraph[path[0]]) {
            dataGraph[path[0]] = {}
        }
        if (path.length === 1) {
            return dataGraph[path[0]]
        }
        return buildPath(dataGraph[path[0]], path.slice(1))
    }
    function GraphLevel({ dataGraph, level }: { dataGraph: any; level: number }) {
        const entries = Object.entries(dataGraph).filter(([key]: any) => key !== 'key')
        return (
            <>
                {entries.map(([key, children]: any, index: number) => {
                    const invalidateKey = () =>
                        vFetch(`/cache`, {
                            method: 'DELETE',
                            body: JSON.stringify({
                                key: children.key || [key],
                            }),
                        })
                    const isLastLeaf = Object.keys(children).filter((key) => key !== 'key').length === 0
                    return (
                        <>
                            <div className='flex gap-2 mb-2 text-sm font-bold'>
                                {level > 0 && (
                                    <>
                                        {index === entries.length - 1 && isLastLeaf ? (
                                            <div className='w-[33px] ml-[-17px] mb-[-8px] bg-lightgrey dark:bg-darkbg1 flex flex-col'>
                                                <div className='rounded-bl w-full h-1/2 border-l border-b border-darkgrey dark:border-offwhite'>
                                                    &nbsp;
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='ml-[-16px] w-8 h-[1px] bg-darkgrey dark:bg-offwhite shrink-0 self-center'>
                                                &nbsp;
                                            </div>
                                        )}
                                    </>
                                )}

                                <button
                                    onClick={invalidateKey}
                                    className='break-all p-2 rounded bg-white dark:bg-darkbg2 shadow-md'
                                >
                                    {key}
                                </button>
                            </div>
                            {Object.keys(children).filter((key) => key !== 'key').length > 0 && (
                                <div className='flex mb-2'>
                                    <div className='ml-8 pl-4 border-l border-darkgrey dark:border-offwhite'>
                                        <GraphLevel dataGraph={children} level={level + 1} />
                                    </div>
                                </div>
                            )}
                        </>
                    )
                })}
            </>
        )
    }
    return (
        <div>
            <h1 className='text-xl font-bold mb-2'>Cache Information</h1>
            <div className='flex gap-2 items-center'>
                <p className='font-bold text-sm mb-2'>
                    Size: {((cacheVitals.CACHE_SIZE || 0) / 1024 / 1024).toFixed(2)} mb{' '}
                </p>
                <p className='font-bold text-sm mb-2'>|</p>
                <p className='font-bold text-sm mb-2'>Node count: {cacheVitals.LIST_SIZE || 0}</p>
                <p className='font-bold text-sm mb-2'>|</p>

                <p className='font-bold text-sm mb-2'>Request count: {cacheVitals.REQUEST_COUNT || 0}</p>
                <p className='font-bold text-sm mb-2'>|</p>

                <p className='font-bold text-sm mb-2'>Cache hits: {cacheVitals.CACHE_HITS || 0}</p>
                <p className='font-bold text-sm mb-2'>|</p>

                <p className='font-bold text-sm mb-2'>
                    Hit rate: {(((cacheVitals.CACHE_HITS || 0) / (cacheVitals.REQUEST_COUNT || 1)) * 100).toFixed(2)}%
                </p>
            </div>
            <p className='font-bold text-sm mb-2'>Graph:</p>
            <div className='bg-lightgrey dark:bg-darkbg1 p-4 shadow-md rounded'>
                <GraphLevel dataGraph={dataGraph} level={0} />
            </div>
        </div>
    )
}
