import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'

export default function ControlTowerScreen() {
    const [leaderServerDetails, setLeaderServerDetails] = useState({
        success: false,
        leaders: [],
        fpDashAppState: [],
    })

    useEffect(() => {
        getLeaderServer()

        const fetchInterval = setInterval(() => {
            getLeaderServer()
        }, 1000)

        return () => clearInterval(fetchInterval)
    }, [])

    function getLeaderServer() {
        vFetch(`/controlTower/leaderServer`, {
            cb: (res: any) => {
                if (!res.success) {
                    setLeaderServerDetails({
                        success: false,
                        leaders: [],
                        fpDashAppState: [],
                    })
                    return
                }
                setLeaderServerDetails(res)
            },
        })
    }
    return (
        <div className='dark:text-offwhite'>
            <div className='flex gap-[8px] items-center'>
                <p>Leader Server Alive:</p>
                <p>{leaderServerDetails.success ? 'TRUE' : 'FALSE'}</p>
            </div>
            <div className='flex gap-[8px] items-center'>
                <p>Leader Machines:</p>
                <div>
                    {leaderServerDetails.leaders.map((leader: any) => (
                        <p>
                            {leader.group_id} - {leader.machine_id} - {leader.last_seen_active}
                        </p>
                    ))}
                </div>
            </div>
            <div className='flex gap-[8px] items-center'>
                <p>User Paths:</p>
                <div>
                    {leaderServerDetails.fpDashAppState?.map((user: any) => (
                        <p>
                            {user.user_id} - {user.path} - {user.last_seen_active}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    )
}
