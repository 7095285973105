import { CustomerType } from 'customersV2/Customers.types'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import {
    US_TAX_EXEMPTION_CODES,
    CA_MULTI_REGIONAL_TAX_EXEMPTION_CODES,
    CA_REGIONAL_TAX_EXEMPTION_CODES,
} from 'customersV2/constants'
import Input from 'procurement/components/Input'
import { Button } from 'custom_components/component_Basics/Button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { vFetch } from 'helpers'

const usTaxExemptionOptions = US_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const state = codeArray[1]
    const exemptionName = codeArray
        .slice(2)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country}-${state} ${exemptionName}`,
        value: code,
    }
})

const caRegionalTaxExemptionOptions = CA_REGIONAL_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const state = codeArray[1]
    const exemptionName = codeArray
        .slice(2)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country}-${state} ${exemptionName}`,
        value: code,
    }
})

const caTaxExemptionOptions = CA_MULTI_REGIONAL_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const exemptionName = codeArray
        .slice(1)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country} ${exemptionName}`,
        value: code,
    }
})

export default function EditTaxExemptionsModal({
    customer,
    toggleModal,
}: {
    customer: CustomerType
    toggleModal: () => void
}) {
    const taxExemptionsRef = useRef<HTMLDivElement>(null)
    const modalRef = useRef<HTMLDivElement>(null)
    const [selectedTaxExemptions, setSelectedTaxExemptions] = useState(customer.tax_exemptions || [])
    const [showTaxExemptions, setShowTaxExemptions] = useState(false)
    const [taxExempt, setTaxExempt] = useState(customer.tax_exempt === 1)
    const [search, setSearch] = useState('')
    const queryClient = useQueryClient()
    const updateCustomerMutation = useMutation({
        mutationFn: async ({ customer }: { customer: CustomerType }) =>
            vFetch('/v2/customers', {
                method: 'PUT',
                body: JSON.stringify({
                    ...customer,
                }),
            }),
        onSuccess: () => {
            toggleModal()
            return queryClient.invalidateQueries({ queryKey: ['customers', customer.id.toString()] })
        },
    })

    const handleToggleTaxExemption = (code: string) => {
        setSelectedTaxExemptions((prev) => {
            if (prev.includes(code)) {
                return prev.filter((exemption) => exemption !== code)
            }
            return [...prev, code]
        })
    }

    const handleSave = () => {
        updateCustomerMutation.mutate({
            customer: {
                id: customer.id,
                tax_exempt: taxExempt ? 1 : 0,
                tax_exemptions: selectedTaxExemptions,
            } as CustomerType,
        })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                toggleModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!taxExemptionsRef.current?.contains(e.target as HTMLElement)) {
                setShowTaxExemptions(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed w-full h-full top-0 left-0 flex items-center justify-center bg-black/50 z-50'
        >
            <div className='p-4 relative rounded shadow-sm bg-white dark:bg-darkbg1 text-sm min-w-[400px] flex flex-col'>
                <button className='absolute top-1 right-1 text-base' onClick={toggleModal}>
                    <FaTimes className='text-red dark:text-lightred' />
                </button>
                <h2 className='font-bold text-lg leading-none mb-4'>Manage Tax Exemptions</h2>
                <div className='flex gap-2 items-center mb-4'>
                    <input
                        id='tax-exempt'
                        type='checkbox'
                        checked={taxExempt}
                        onChange={({ target }) => setTaxExempt(target.checked)}
                    />
                    <label htmlFor='tax-exempt' className='font-bold'>
                        Tax Exempt
                    </label>
                </div>
                <div ref={taxExemptionsRef} onClick={() => setShowTaxExemptions(true)} className='relative'>
                    <Input
                        label='Other Exemptions'
                        id='search'
                        name='search'
                        type='text'
                        placeholder='Search...'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                    />
                    {showTaxExemptions && (
                        <div className='w-full max-h-[200px] overflow-auto absolute top-full left-0 bg-bg1 dark:bg-darkbg1 p-2 rounded-b shadow-md border border-lightgrey dark:border-darkgrey border-t-0 flex flex-col gap-1'>
                            <p className='font-bold border-b border-lightgrey dark:border-darkgrey pb-1 mb-1'>
                                US Tax Emeptions
                            </p>
                            {usTaxExemptionOptions
                                .filter((exemption) => exemption.value.toLowerCase().includes(search.toLowerCase()))
                                .map((option: any) => (
                                    <button
                                        onClick={() => handleToggleTaxExemption(option.value)}
                                        className='flex gap-2 items-center whitespace-nowrap'
                                    >
                                        <span
                                            className={`rounded-full w-4 h-4 ${
                                                selectedTaxExemptions.includes(option.value)
                                                    ? 'border-4 border-blue dark:border-darkaccent1'
                                                    : 'border border-grey/50 dark:darkgrey'
                                            }`}
                                        >
                                            &nbsp;
                                        </span>
                                        <span>{option.label}</span>
                                    </button>
                                ))}
                            <p className='font-bold border-b border-lightgrey dark:border-darkgrey pb-1 my-1'>
                                CA Tax Exemptions
                            </p>
                            {caRegionalTaxExemptionOptions
                                .filter((exemption) => exemption.value.toLowerCase().includes(search.toLowerCase()))
                                .map((option: any) => (
                                    <button
                                        onClick={() => handleToggleTaxExemption(option.value)}
                                        className='flex gap-2 items-center whitespace-nowrap'
                                    >
                                        <span
                                            className={`rounded-full w-4 h-4 ${
                                                selectedTaxExemptions.includes(option.value)
                                                    ? 'border-4 border-blue dark:border-darkaccent1'
                                                    : 'border border-grey/50 dark:darkgrey'
                                            }`}
                                        >
                                            &nbsp;
                                        </span>
                                        <span>{option.label}</span>
                                    </button>
                                ))}
                            {caTaxExemptionOptions
                                .filter((exemption) => exemption.value.toLowerCase().includes(search.toLowerCase()))
                                .map((option: any) => (
                                    <button
                                        onClick={() => handleToggleTaxExemption(option.value)}
                                        className='flex gap-2 items-center whitespace-nowrap'
                                    >
                                        <span
                                            className={`rounded-full w-4 h-4 ${
                                                selectedTaxExemptions.includes(option.value)
                                                    ? 'border-4 border-blue dark:border-darkaccent1'
                                                    : 'border border-grey/50 dark:darkgrey'
                                            }`}
                                        >
                                            &nbsp;
                                        </span>
                                        <span>{option.label}</span>
                                    </button>
                                ))}
                        </div>
                    )}
                </div>
                <div className='mb-4'>
                    {selectedTaxExemptions.map((exemption) => (
                        <div className='flex justify-between items-center p-2 border-b border-lightgrey dark:border-darkgrey'>
                            <p>{exemption}</p>
                            <button onClick={() => handleToggleTaxExemption(exemption)}>
                                <FaTimes />
                            </button>
                        </div>
                    ))}
                </div>
                <div className='flex gap-2 justify-center items-center'>
                    <Button onClick={toggleModal} className='font-bold'>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        className='font-bold bg-accent1 text-bg1 dark:bg-darkaccent1 dark:text-darkbg1'
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}
