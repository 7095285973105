import { useEffect, useState } from 'react'
import { sortByAlphanumeric } from '../../helpers'
import { renderOrderTimelineEvent } from '../../orderHubScreen/orderHubScreen.helpers'
import { TaskCreateInit, TaskEditInit, TaskTimelineEventV2 } from '../../tasks/tasks.types'
import { UserInit } from '../../users/users.types'
import { OrderInit, OrderNoteInit, OrderTimelineEvent } from '../orders.types'
import { OrderNote } from './OrderNote'
import NewNoteField from './NewNoteField'
import { EmailInit } from '../../emails/email.types'
import Email from '../../emails/Email'
import TaskPublicTimelineEvent from '../../tasks/components/TaskPublicTimelineEvent'

type EventsProps = {
    events: (OrderTimelineEvent | OrderNoteInit | TaskTimelineEventV2 | EmailInit)[]
    tasks: TaskEditInit[] | TaskCreateInit[]
    addNewTask: Function
    userList: UserInit[]
    order: OrderInit
    setOrder: Function
}

export function Events({ events, userList, tasks, addNewTask, order, setOrder }: EventsProps) {
    const savedActivityFilter: any =
        localStorage?.getItem('orderHubEventsFilter') !== null
            ? localStorage?.getItem('orderHubEventsFilter')?.split(',')
            : ['notes', 'order', 'task', 'email', 'order [all]', 'task [all]']
    const [activity, setActivity] = useState<string[]>(savedActivityFilter)
    const [sortedEvents, setSortedEvents] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])
    const typeChecker: any = {
        notes: {
            check: (e: OrderNoteInit) => isOrderNote(e),
        },
        email: {
            check: (e: EmailInit) => isEmail(e),
        },
        order: {
            check: (e: OrderTimelineEvent) =>
                isOrderTimelineEvent(e) && !e.message.match(/((edited|removed) the|added a) note (on|to) this order\./),
        },
        'order [all]': {
            check: (e: OrderTimelineEvent) => isOrderTimelineEvent(e),
        },
        task: {
            check: (e: TaskTimelineEventV2) => isTaskTimelineEvent(e) && !e.message.includes('edited this task'),
        },
        'task [all]': {
            check: (e: TaskTimelineEventV2) => isTaskTimelineEvent(e),
        },
    }

    const normalizedProperties = ['normalized_created_at', 'normalized_event_index']
    const normalize = (e: any, created_at: Date) => {
        return { ...e, normalized_created_at: created_at }
    }

    const isOrderTimelineEvent = (e: any) => e.normalized_event_type === 'Shopify Timeline'
    const isTaskTimelineEvent = (e: any) => e.normalized_event_type === 'Task Events Public'
    const isOrderNote = (e: any) => e.normalized_event_type === 'Order Note'
    const isEmail = (e: any) => e.normalized_event_type === 'Email'
    useEffect(() => {
        const orderEvents = events.filter((e) => isOrderTimelineEvent(e)) as OrderTimelineEvent[]
        const taskEvents = events.filter((e) => isTaskTimelineEvent(e)) as TaskTimelineEventV2[]
        const orderNotes = events.filter((e) => isOrderNote(e)) as OrderNoteInit[]
        const emails = events.filter((e) => isEmail(e)) as EmailInit[]
        const normalizedEvents: any = [
            ...orderEvents.map((e) => normalize(e, new Date(e.createdAt))),
            ...taskEvents.map((e) => normalize(e, new Date(e.created_at + 'Z'))),
            ...orderNotes.map((e) => normalize(e, e.created_at)),
            ...emails.map((e) => normalize(e, e.created_at)),
        ]
        normalizedEvents.sort((a: any, b: any) =>
            sortByAlphanumeric(
                a.normalized_created_at?.getTime() || Infinity,
                b.normalized_created_at?.getTime() || Infinity
            )
        )
        normalizedEvents.forEach((e: any, i: number) => (e.normalized_event_index = i + 1))
        setSortedEvents(normalizedEvents)
    }, [events])

    useEffect(() => {
        if (activity.includes('all')) setActivity(Object.keys(typeChecker))
    }, [activity])
    useEffect(() => {
        let filteredEvents = sortedEvents.filter((e) => activity.some((type: any) => typeChecker[type].check(e)))
        setFilteredEvents(filteredEvents)
    }, [sortedEvents, activity])

    function FilterOptionBubble({ option, filter, setFilter }: any) {
        return (
            <div
                className={`text-sm relative whitespace-nowrap h-fit text-black bg-grey dark:bg-darkgrey ${
                    filter.includes(option) && '!bg-blue text-offwhite'
                } px-2 py-1 rounded cursor-pointer`}
                onClick={() => {
                    localStorage.setItem('orderHubEventsFilter', option)
                    setFilter([option])
                }}
            >
                <button className='capitalize'>{option}</button>
            </div>
        )
    }

    const timeline_eventsList: any = []
    filteredEvents.map((event) => {
        if (isTaskTimelineEvent(event)) {
            timeline_eventsList.push(event)
        }
    })
    const indexArr = timeline_eventsList.reverse().map((e: any) => e.id)

    return (
        <div className='grid gap-[8px] dark:text-offwhite'>
            <div className='max-w-[100%] grid gap-[8px] bg-white dark:bg-darkaccent p-[16px] rounded shadow-small'>
                <NewNoteField
                    order={order}
                    setOrder={setOrder}
                    tasks={(tasks as TaskEditInit[]).filter((t) => t.id)}
                    addNewTask={addNewTask}
                />
                <div className='flex gap-[8px] text-[14px]'>
                    <h3 className='text-[16px] font-semibold'>Activity:</h3>
                    <div className='flex flex-col gap-[4px]'>
                        <div className='flex gap-[4px] flex-wrap items-center'>
                            <button
                                className='text-fire font-semibold border-[2px] border-fire rounded py-[2px] px-[8px]'
                                onClick={() => {
                                    localStorage.setItem('orderHubEventsFilter', Object.keys(typeChecker).join(','))
                                    setActivity(Object.keys(typeChecker))
                                }}
                            >
                                All
                            </button>
                            {Object.keys(typeChecker).map((o, i) => (
                                <FilterOptionBubble
                                    key={`order__filter-option${i}`}
                                    option={o}
                                    filter={activity}
                                    setFilter={setActivity}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className='grid gap-[8px] border-l-[1px] border-grey dark:border-darkgrey'>
                    {filteredEvents
                        .map((e: any, i) => (
                            <div key={`order-event__${i}`} className='flex gap-[0] items-center'>
                                <div className='relative left-[-14px] flex items-center justify-center text-[12px] min-w-[28px] min-h-[28px] border-[4px] border-white dark:border-darkaccent bg-white dark:bg-darkaccent basis-[1]'>
                                    <div className='absolute min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent z-index-1'>
                                        <span className='block text-center font-semibold min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent border-[1px] border-grey dark:border-accent rounded-full px-[2px]'>
                                            {e.normalized_event_index}
                                        </span>
                                    </div>
                                    <div className='absolute left-[20px] bg-grey dark:bg-accent h-[1px] w-[12px]' />
                                </div>
                                {isOrderTimelineEvent(e) && renderOrderTimelineEvent(e)}
                                {isOrderNote(e) && (
                                    <OrderNote
                                        index={e.normalized_event_index}
                                        note={e}
                                        userList={userList}
                                        state={order}
                                        setState={setOrder}
                                    />
                                )}
                                {isTaskTimelineEvent(e) && (
                                    <TaskPublicTimelineEvent
                                        key={e.id}
                                        task_type={
                                            tasks.find((t) => (t as TaskEditInit).id === e.task_id)?.task_type || ''
                                        }
                                        tasks={tasks}
                                        event={e}
                                        events={timeline_eventsList}
                                        indexArr={indexArr}
                                        user={userList.find((u) => u.id === e.user_id)}
                                    />
                                )}
                                {isEmail(e) && <Email email={e} />}
                            </div>
                        ))
                        .reverse()}
                </div>
            </div>
        </div>
    )
}
