import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import store from './redux/store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AnalyticsProvider } from 'use-analytics'
import analytics from './analytics/analytics'
import { sendToastErr, vFetch } from './helpers'
import { AUDIT_TABLE_QUERY_KEY } from './audit/api/useQueries'

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error, query) => {
            if (error.details) {
                error.details.json().then((err) => {
                    vFetch(`/slack`, {
                        skipToast: true,
                        method: 'POST',
                        body: JSON.stringify({
                            message: `Client Error Message: ${error.clientMessage} \nServer Error Message: ${err.message} \nQuery Key: ${query.queryHash}`,
                        }),
                    })
                })
                sendToastErr({ message: `Something went wrong: ${error.clientMessage}` })
            } else {
                vFetch(`/slack`, {
                    skipToast: true,
                    method: 'POST',
                    body: JSON.stringify({
                        message: `Front End Error in React Query Auto-Retry\n Client Error Message: ${error} \nQuery Key: ${query.queryHash}`,
                    }),
                })
                sendToastErr({ message: `Something went wrong: React Query Auto-retry ${error}` })
            }
        },
    }),
    mutationCache: new MutationCache({
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: AUDIT_TABLE_QUERY_KEY })
            queryClient.invalidateQueries({ queryKey: ['development'] })
            queryClient.invalidateQueries({ queryKey: ['companies', 'options'] })
        },
    }),
    defaultOptions: {
        queries: {
            staleTime: 30000,
            // throwOnError: true,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <QueryClientProvider client={queryClient}>
        <AnalyticsProvider instance={analytics}>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </AnalyticsProvider>
    </QueryClientProvider>
)
