import { useState } from 'react'
import { FetchingSpinner } from '../../custom_components/FetchingSpinner'
import { Button } from '../../custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from '../../custom_components/component_Basics/FilterBarSelectorButton'
import { cn } from '../../helpers'
import RenderProductSetupValue from '../../productSetupV2/components/RenderProductSetupValue'
import { UserInit } from '../../users/users.types'
import { useProductAuditHistoryQuery } from '../api/useQueries'
import { COMPANY_PRODUCT_FIELD_OPTIONS, companyProductsTable } from '../constants'
import { formatDateSimple, getFilterBarUsers } from '../helpers'
import { useAuditHistoryQuery } from '../../audit/api/useQueries'

export type auditHistoryType = {
    id: number | string
    record_id: number | string
    parent_id: number | string
    edited_fields: string
    old_value: any
    new_value: any
    created_by: number | string
    created_at: Date
    user_alias: string
}

export default function CompanyProductEditHistory({
    users,
    record_id,
}: {
    users: UserInit[]
    record_id: string | number
}) {
    const [filterValues, setFilterValues] = useState<{ user_ids: number[]; fields: string[] }>({
        fields: [],
        user_ids: [],
    })

    const productAuditHistoryQuery = useAuditHistoryQuery({
        table: companyProductsTable,
        record_id,
        filters: filterValues,
    })
    const { pages: auditHistoryEventPages }: { pages: any } = productAuditHistoryQuery?.data || {}
    const selectorButtonObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsers(users),
                title: 'Users',
                field: 'user_ids',
                values: filterValues?.user_ids || [],
                searchToggle: true,
            },
            {
                component: FilterBarSelectorButton,
                options: COMPANY_PRODUCT_FIELD_OPTIONS,
                title: 'Fields',
                field: 'fields',
                values: filterValues?.fields || [],
                searchToggle: true,
            },
        ],
        setFunction: setFilterValues,
        resetFunction: () => {
            setFilterValues({
                fields: [],
                user_ids: [],
            })
        },
    }

    return (
        <>
            <div className='col-span-3 p-2 pt-0 min-h-[200px] max-h-[200px] overflow-auto max-w-full '>
                <div className='grid grid-cols-[.4fr_.6fr_2.5fr]'>
                    <div className='sticky bg-lightgrey dark:bg-darkbg2 rounded-sm px-1 z-[4] top-0 grid mb-2 col-span-3 grid-cols-[.4fr_.4fr_.3fr_2.5fr]'>
                        <div>User</div>
                        <div>Date</div>
                        <div>Id</div>
                        <div>Edits</div>
                    </div>
                    {auditHistoryEventPages?.map((page: any) => {
                        return page?.auditHistory?.map((event: auditHistoryType) => {
                            return (
                                <div className='px-2 border-b border-lightgrey dark:border-darkgrey grid col-span-3 grid-cols-[.4fr_.4fr_.3fr_2.5fr]'>
                                    <div>{event.user_alias}</div>
                                    <div>{formatDateSimple(event.created_at)}</div>
                                    <div>{event.id}</div>
                                    <EditedFieldsHover event={event} />
                                </div>
                            )
                        })
                    })}
                    <div className='flex justify-center col-span-3 mt-2'>
                        <Button
                            className='flex gap-1'
                            size={'default'}
                            variant={'default'}
                            onClick={() => productAuditHistoryQuery.fetchNextPage()}
                            disabled={!productAuditHistoryQuery.hasNextPage}
                        >
                            load more
                            {productAuditHistoryQuery.isFetching && (
                                <FetchingSpinner isFetching={productAuditHistoryQuery.isFetching} />
                            )}
                        </Button>
                    </div>
                </div>
            </div>
            <div className='p-2 flex flex-col gap-2 items-start'>
                {selectorButtonObject.params.map((param: any) => {
                    return (
                        <param.component
                            key={param.field}
                            searchToggle={param.searchToggle}
                            title={param.title}
                            field={param.field}
                            options={param.options}
                            filterValues={param.values}
                            setFilterValues={selectorButtonObject.setFunction}
                            align={'start'}
                            preview={false}
                        />
                    )
                })}
            </div>
        </>
    )
}

export function EditedFieldsHover({ event }: { event: auditHistoryType }) {
    const editedFieldsArray = event.edited_fields.split(',')

    return (
        <div className='flex'>
            {editedFieldsArray.map((field: string) => {
                return (
                    <div className={`flex text-sm gap-3 bg-bg1 dark:bg-darkbg1 items-center `}>
                        <div className='flex flex-col relative  basis-[100%] gap-[8px]'>
                            <p className='uppercase dark:text-offwhite '>
                                <span
                                    className={cn(
                                        field !== 'created' && field !== 'deleted' && 'task-screen__field',
                                        'text-accent2 dark:text-darkaccent2 hover:underline'
                                    )}
                                >
                                    {field}
                                </span>
                                <p
                                    style={{ wordBreak: 'break-word' }}
                                    className='task-screen__field-body normal-case whitespace-p-wrap font-bai absolute text-[14px] top-[100%] w-[300px] left-[-125px] max-h-[300px]  overflow-y-scroll py-[8px] px-[16px] rounded border-[1px] border-[#a1a1a1] shadow-small bg-bg1 text-text1 dark:text-darktext1 dark:bg-darkbg1 dark:shadow-darkbg1 dark:border-darkbg2 z-[20]'
                                >
                                    {event?.new_value && event?.old_value && (
                                        <>
                                            <RenderProductSetupValue
                                                value={event?.new_value[field]}
                                                state='Updated Value -'
                                            />
                                            <br />
                                            <RenderProductSetupValue
                                                value={event?.old_value[field]}
                                                state='Previous Value -'
                                            />
                                        </>
                                    )}
                                </p>
                            </p>
                        </div>
                        <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'></div>
                    </div>
                )
            })}
        </div>
    )
}
