import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { cn, useDebounce } from '../../../../helpers'
import { useSearchCompanies, useTasksQuery } from '../../../api/useQueries'
import { DATE_TODAY, defaultCompanyFilterParams, defaultTaskFilterParams } from '../../../constants'
import ShortTask from '../../listItems/ShortTask'
import { formatDateSimple } from '../../../helpers'
import FilterToolBar from '../../FilterToolBar'

export default function TaskPopout({ setShowQuickModal }: { setShowQuickModal: any }) {
    const user = useSelector<any, any>((state) => state.user)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 100)
    let params: any = {
        filters: {
            ...defaultCompanyFilterParams,
            lastCursor: undefined,
            assigned_to: [],
            lead_type: [],
            lifecycle_status: [],
            pageParam: 1,
            sortOption: undefined,
        },
    }

    const companyFilterBarObject = {
        params: [],
    }
    const companiesSearchQuery = useSearchCompanies({ ...params, search: debouncedSearch })
    const { companies: companiesSearch, lastCursor: lastCursorSearch } = companiesSearchQuery?.data || {}
    return (
        <div className={cn('flex max-h-[385px] w-full text-text1 dark:text-darktext1 overflow-auto')}>
            <div className='flex flex-col w-full'>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                    <h3 className='leading-[1] font-bold'>Search - {formatDateSimple(new Date())}</h3>
                </div>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    filterBarObject={companyFilterBarObject}
                ></FilterToolBar>
                <div className='p-3'>
                    <div className='grid grid-cols-2 border-b border-lightgrey'>
                        <p>name</p>
                        <p>vendor</p>{' '}
                    </div>
                    {companiesSearch &&
                        companiesSearch?.map((company: any) => {
                            return (
                                <div className=' grid grid-cols-2 border border-lightgrey'>
                                    <p>{company.name}</p>
                                    <p>{company.shopify_vendor_name}</p>
                                </div>
                            )
                        })}
                </div>
                <div className='p-[16px] mt-auto w-full bg-lightgrey dark:bg-darkness flex justify-center'></div>
            </div>
        </div>
    )
}

export type CompanyTaskType = {
    id?: string | number
    title: string
    status: 'OPEN' | 'COMPLETED'
    due_at: string
    alert_at?: string
    send_slack_alert?: 0 | 1
    send_email_alert?: 0 | 1
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    updated_at?: string
    name?: string
    type?: string
}
