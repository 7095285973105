import { useEffect, useState } from 'react'
import { ActiveModifiers, useDayPicker } from 'react-day-picker'
import { useSelector } from 'react-redux'
import { useTasksQueryCalendar } from '../../procurement/api/useQueries'
import { cn } from '../../helpers'

export interface DayContentProps {
    date: Date
    displayMonth: Date
    activeModifiers: ActiveModifiers
}

export function CustomDayContentProcurementTasks(props: DayContentProps): JSX.Element {
    const user = useSelector<any, any>((state) => state.user)
    const [count, setCount] = useState(0)
    const {
        locale,
        formatters: { formatDay },
    } = useDayPicker()

    const day = new Date(props.date)
    day.setHours(0, 0, 0, 0)

    const tasksQuery = useTasksQueryCalendar({ userId: user.id, day })
    const { tasks } = tasksQuery?.data || {}

    return (
        <>
            <div className='p-0 flex flex-col w-full h-full'>
                <span className='self-start leading-3'>{formatDay(props.date, { locale })}</span>
                <div
                    className={cn(
                        tasks?.length ? 'opacity-100' : 'opacity-0',
                        'mt-[2px] w-5 h-5 self-center rounded-full bg-accent2 dark:bg-darkaccent2 text-text2 dark:text-darktext2'
                    )}
                >
                    {tasks?.length ? tasks?.length : ''}
                </div>
            </div>
        </>
    )
}
