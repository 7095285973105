import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './AdminScreen.css'
import { sendToast, vFetch } from '../helpers'
import Input from '../procurement/components/Input'

export default function AdminScreen() {
    const user = useSelector((state) => state.user)
    const [emailSyncInfo, setEmailSyncInfo] = useState({
        email: '',
        nextPageToken: '',
    })
    const [cacheResetPage, setCacheResetPage] = useState('')
    const [cacheResetDate, setCacheResetDate] = useState()
    const sessionToken = localStorage.getItem('session_token')

    const navigate = useNavigate()
    useEffect(() => {
        if (!user.access.includes('*') && !user.access.includes('admin')) {
            navigate('/home')
        }
    }, [])

    // Potentially dangerous functions.

    // const handleRefreshCollectionProducts = () => {
    //     const confirm = window.confirm('Are you sure? This can take up to ten minutes.')
    //     if (confirm) {
    //         fetch('https://www.fphooks.com/sync/products', {
    //             headers: {
    //                 Authorization: sessionToken,
    //             },
    //         })
    //             .then((res) => res.json())
    //             .then((res) => {
    //                 sendToast(res)
    //             })
    //             .catch((err) => {
    //                 sendToast(err)
    //             })
    //     }
    // }
    // const handleSyncMessages = () => {
    //     const confirm = window.confirm('Are you sure? This may take a few minutes.')
    //     if (confirm) vFetch(`/processingTimes/sync?products=true&collections=true&variants=true`)
    // }
    async function handleVersionRefresh() {
        await vFetch('/appVersions', { method: 'PUT', body: JSON.stringify({ version: 0 }) })
    }

    const viewRefresh = () => {
        vFetch('/stores/viewRefresh', {})
    }

    const handleChange = ({ target }) => {
        setEmailSyncInfo({ ...emailSyncInfo, [target.name]: target.value })
    }
    const restartEmailSync = () => {
        vFetch(`/v2/google/initialFullSync?email=${emailSyncInfo.email}&nextPageToken=${emailSyncInfo.nextPageToken}`)
    }

    const purgeCache = () => {
        vFetch('/nostra', {
            method: 'POST',
            body: JSON.stringify({
                path: cacheResetPage || undefined,
            }),
        }).then(() => {
            setCacheResetDate(undefined)
            setCacheResetPage('')
        })
    }

    return (
        <div className='admin text-black dark:text-offwhite'>
            <h1 className='text-[24px] tracking-[2px] mb-6 font-[300] dark:text-offwhite uppercase'>Refresh</h1>
            <div className='flex flex-col gap-[16px]'>
                <div className='flex flex-col gap-4'>
                    <p style={{ fontSize: '20px' }}>Views</p>
                    <button
                        className=' mt-auto w-fit block py-[4px] px-[16px] rounded-[4px] bg-blue text-white dark:bg-accent dark:text-darkness uppercase font-bold'
                        onClick={viewRefresh}
                    >
                        REFRESH VIEWS
                    </button>
                </div>
                <div className='flex flex-col gap-4'>
                    <h2 className='text-[20px]'>Nostra Cache</h2>
                    <div className='p-[8px] bg-white dark:bg-darkaccent border border-darkgrey max-w-[400px]'>
                        <Input
                            label='Path to refresh (leave blank for all paths)'
                            id='cache-path'
                            name='cache-path'
                            value={cacheResetPage}
                            onChange={({ target }) => setCacheResetPage(target.value)}
                        />
                    </div>
                    <button
                        className=' mt-auto w-fit block py-[4px] px-[16px] rounded-[4px] bg-blue text-white dark:bg-accent dark:text-darkness uppercase font-bold'
                        onClick={purgeCache}
                    >
                        PURGE CACHE
                    </button>
                </div>
                <div className='flex flex-col gap-4'>
                    <p style={{ fontSize: '20px' }}>Email Sync</p>
                    <div className='flex flex-col gap-2 max-w-[300px] dark:bg-darkbg2'>
                        <input
                            onChange={handleChange}
                            name='email'
                            className=' bg-grey dark:bg-darkbg1 p-1'
                            value={emailSyncInfo.email}
                            type='text'
                            placeholder='email'
                        />
                        <input
                            onChange={handleChange}
                            name='nextPageToken'
                            className=' bg-grey dark:bg-darkbg1 p-1'
                            value={emailSyncInfo.nextPageToken}
                            type='text'
                            placeholder='nextPageToken (optional)'
                        />
                    </div>

                    <button
                        className=' mt-auto w-fit block py-[4px] px-[16px] rounded-[4px] bg-blue text-white dark:bg-accent dark:text-darkness uppercase font-bold'
                        onClick={restartEmailSync}
                    >
                        BEGIN SYNC
                    </button>
                </div>
                {/* <div>
                    <p style={{ fontSize: '20px' }}>
                        Collection pages / compare app not reflecting updated product info?
                    </p>
                    <button
                        style={{ marginBottom: '16px' }}
                        className='admin__reset-products'
                        onClick={handleRefreshCollectionProducts}
                    >
                        REFRESH PRODUCTS
                    </button>
                </div> */}
                {/* <div>
                    <p style={{ fontSize: '20px' }}>
                        New products carrying old messages?
                        <br />
                        <span
                            style={{
                                fontSize: '14px',
                                display: 'inline-block',
                                fontWeight: 700,
                                lineHeight: '18px',
                                textTransform: 'uppercase',
                            }}
                        >
                            This may trigger a product refresh upon completion,
                            <br /> please give this at least 15 minutes to complete before triggering manually.
                        </span>
                    </p>
                    <button
                        style={{ marginBottom: '16px' }}
                        className='admin__reset-products'
                        onClick={handleSyncMessages}
                    >
                        REFRESH MESSAGES
                    </button>
                </div> */}
                <div>
                    <p style={{ fontSize: '20px' }}>App refreshing continuously?</p>
                    <button
                        style={{ marginBottom: '16px' }}
                        className='admin__reset-products'
                        onClick={handleVersionRefresh}
                    >
                        REFRESH APP VERSION
                    </button>
                </div>
            </div>
        </div>
    )
}
