import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

export default function NewOptionModal({
    setOptions,
    setShowModal,
    setEdited,
}: {
    setOptions: Dispatch<SetStateAction<{ key: string; value: string }[]>>
    setShowModal: Dispatch<SetStateAction<boolean>>
    setEdited: Dispatch<SetStateAction<boolean>>
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [option, setOption] = useState({
        key: '',
        value: '',
    })

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                setShowModal(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div ref={modalRef} className='fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'>
            <div className='min-w-[400px] bg-white dark:bg-darkaccent rounded-[4px] shadow-small p-[16px] relative'>
                <button
                    onClick={() => setShowModal(false)}
                    className='absolute top-[0px] right-[8px] text-red dark:text-lightred font-bold text-[32px] leading-[1]'
                >
                    &times;
                </button>
                <h2 className='text-[24px] font-light tracking-[2px] dark:text-offwhite uppercase leading-[1] mb-[16px]'>
                    New Option
                </h2>
                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                    <label
                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                        htmlFor='key'
                    >
                        Key
                    </label>
                    <div className='relative w-full flex gap-[8px]'>
                        <input
                            id='key'
                            type='text'
                            value={option.key}
                            name='key'
                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                            onChange={({ target }) => setOption({ ...option, [target.name]: target.value })}
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                    <label
                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                        htmlFor='value'
                    >
                        Value
                    </label>
                    <div className='relative w-full flex gap-[8px]'>
                        <input
                            id='value'
                            type='text'
                            value={option.value}
                            name='value'
                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                            onChange={({ target }) => setOption({ ...option, [target.name]: target.value })}
                        />
                    </div>
                </div>
                <button
                    className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                    onClick={() => {
                        setOptions((previousState) => [...previousState, option])
                        setShowModal(false)
                        setEdited(true)
                    }}
                >
                    SAVE
                </button>
            </div>
        </div>
    )
}
