import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { productSetupQueries } from './productSetupQueryKeys'
import * as service from './service'

export function useGetInventoryEmailsQuery({
    emailIdParam,
    filters = { vendors: undefined, statuses: undefined, after: undefined, before: undefined },
    search,
}: {
    emailIdParam?: string | undefined
    filters?: any | undefined
    search?: string | undefined
}): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.inventory({
            emailIdParam,
            filters,
            search,
        }),
        placeholderData: keepPreviousData,
    })

    return query
}
export function useManualInventorySheetUpload() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            keyMap,
            src,
            vendors,
            emailId,
        }: {
            keyMap: any
            src: string
            vendors: string[]
            emailId: string | number
        }) => {
            return service.manualInventorySheetUpload(keyMap, src, vendors, emailId)
        },

        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useSaveInventoryMap() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            keyMap,
            companyIds,
            headingString,
        }: {
            keyMap: any
            companyIds: string[] | number[]
            headingString: string
        }) => {
            return service.saveInventoryMap(keyMap, companyIds, headingString)
        },

        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useDeleteInventoryEmailMap() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ id }: { id: string | number }) => {
            return service.deleteInventoryEmailMap(id)
        },

        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useCreateCompanySenderAddress() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ from, companyId }: { from: string; companyId: string | number }) => {
            return service.addCompanySenderAddress(from, companyId)
        },

        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useDeleteCompanySenderAddress() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ from, companyId }: { from: string; companyId: string | number }) => {
            return service.deleteCompanySenderAddress(from, companyId)
        },

        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useUpdateEmailStatus() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ emailId, status }: { emailId: string | number; status: string }) => {
            return service.updateEmailStatus(emailId, status)
        },

        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useGetProductsQuery(filters: any, search: string, enabled?: boolean): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.products(filters, search),
        enabled: !enabled ? enabled : true,
    })

    return query
}
export function useGetProductVariantsQuery(product_id: any): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.variants(product_id),
    })

    return query
}
export function useGetProductSetupPrivateEvents(): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.privateEvents(),
    })

    return query
}
export function useUpdateVariantFields() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ variant }: { variant: any }) => {
            return service.updateVariantFields(variant)
        },

        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.products._def })
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.variants._def })
        },
    })
}
