import { TbCircle, TbCircleFilled, TbCircleHalf2 } from 'react-icons/tb'
import { CustomerInit } from '../customers/customers.types'
import { VscCircleSlash } from 'react-icons/vsc'
import { PriceSet } from '../helpers'

// types
export interface DraftLineItemInit extends LineItemInit {
    applied_discount: any
}
export interface DraftOrderInit extends OrderInit {
    applied_discount: any
    completed_at: any
    order_id: number | null
    representative_name: any
    shipping_line: {
        price: string
        title: string
        custom: boolean
        handle: any
    }
    status: string
}
export type FirstRiskEventInit = {
    NoFraud?: {
        display: boolean
        level: string
        message: string
        __typename: string
    }
    Shopify?: {
        display: boolean
        level: string
        message: string
        __typename: string
    }
}
export type FulfillmentLineItemInit = {
    fulfillable_quantity: number
    fulfillment_order_id: number
    id: number
    inventory_item_id: number
    line_item_id: number
    quantity: number
    shop_id: number
    variant_id: number
    variant_title?: string
}
export type FulfillmentOrderInit = {
    assigned_location: {
        address1: string
        address2: string
        city: string
        country_code: string
        location_id: number | undefined
        name: string
        phone: string
        province: string
        zip: string
    }
    assigned_location_id: number
    created_at: string
    delivery_method: {
        id: number | undefined
        max_delivery_date_time: null
        method_type: string
        min_delivery_date_time: null
    }
    destination: {
        address1: string
        address2: null
        city: string
        company: null
        country: string
        email: string
        first_name: string
        id: number | undefined
        last_name: string
        phone: string
        province: string
        zip: string
    }
    fulfillment_holds: any[]
    fulfill_at: string
    fulfill_by: null
    id: number | undefined
    international_duties: null
    line_items: FulfillmentLineItemInit[]
    merchant_requests: any[]
    order_id: number | undefined
    request_status: string
    shop_id: number | undefined
    status: string
    supported_actions: any[]
    updated_at: string
}
export type LineItemInit = {
    admin_graphql_api_id: string
    applied_discount: any[]
    discount_allocations: any[]
    duties: any[]
    fulfillable_quantity: number
    fulfillment_service: string
    fulfillment_status: null
    gift_card: boolean
    grams: number | undefined
    handle: string
    id: number
    name: string
    pre_tax_price: string
    pre_tax_price_set: PriceSet
    price: string
    price_set: PriceSet
    product_exists: boolean
    product_id: number
    properties: any[]
    quantity: number
    requires_shipping: boolean
    sku: string
    taxable: boolean
    tax_code: string
    tax_lines: {
        channel_liable: boolean
        price: string
        price_set: PriceSet
        rate: number | undefined
        title: string
    }[]
    title: string
    total_discount: string
    total_discount_set: PriceSet
    variant_id: number | undefined
    variant_inventory_management: null
    variant_title: string | null
    vendor: string
}

export type OrderNoteInit = {
    author: string
    created_at: Date
    from: {
        color?: string
        name: string
    }
    note: string
}
type OrderRefund = {
    admin_graphql_api_id: string
    created_at: string
    duties: any[]
    id: number | undefined
    note: null
    order_adjustments: any[]
    order_id: number | undefined
    processed_at: string
    refund_line_items: {
        id: number | undefined
        line_item: {
            admin_graphql_api_id: string
            discount_allocations: {
                amount: string
                amount_set: PriceSet
                discount_application_index: number | undefined
            }[]
            duties: any[]
            fulfillable_quantity: number | undefined
            fulfillment_service: string
            fulfillment_status: null
            gift_card: boolean
            grams: number | undefined
            id: number | undefined
            name: string
            pre_tax_price: string
            pre_tax_price_set: PriceSet
            price: string
            price_set: PriceSet
            product_exists: boolean
            product_id: number | undefined
            properties: any[]
            quantity: number | undefined
            requires_shipping: boolean
            sku: string
            taxable: boolean
            tax_lines: {
                channel_liable: boolean
                price: string
                price_set: PriceSet
                rate: number | undefined
                title: string
            }[]
            title: string
            total_discount: string
            total_discount_set: PriceSet
            variant_id: number | undefined
            variant_inventory_management: null
            variant_title: string
            vendor: string
        }
        line_item_id: number | undefined
        location_id: null
        quantity: number | undefined
        restock_type: string
        subtotal: number | undefined
        subtotal_set: PriceSet
        total_tax: number | undefined
        total_tax_set: PriceSet
    }[]
    restock: boolean
    total_duties_set: PriceSet
    transactions: TransactionInit[]
    user_id: number | undefined
}
export type OrderBillingAddress = {
    address1: string | null
    address2: string | null
    city: string | null
    company: string | null
    country: string | null
    country_code: string | null
    first_name: string | null
    last_name: string | null
    latitude?: number | undefined | null
    longitude?: number | undefined | null
    name: string | null
    phone: string | null
    province: string | null
    province_code: string | null
    zip: string | null
}
export type OrderCustomer = {
    accepts_marketing: boolean
    accepts_marketing_updated_at: string
    admin_graphql_api_id: string
    billing_address?: OrderBillingAddress
    created_at: string
    currency: string
    default_address: {
        address1: string | null
        address2: string | null
        city: string | null
        company: string | null
        country: string | null
        country_code: string | null
        country_name: string | null
        customer_id: number | undefined
        default: boolean
        first_name: string | null
        id: number | undefined
        last_name: string | null
        name: string | null
        phone: string | null
        province: string | null
        province_code: string | null
        zip: string | null
    }
    email: string
    email_marketing_consent: {
        consent_updated_at: string | null
        opt_in_level: string | null
        state: string | null
    }
    first_name: string
    id: number | undefined
    last_name: string
    marketing_opt_in_level: string | null
    multipass_identifier: null
    note: string | null
    phone: string | null
    shipping_address?: OrderShippingAddress
    sms_marketing_consent: null | {
        consent_collected_from: string | null
        consent_updated_at: string | null
        opt_in_level: string | null
        state: string | null
    }
    state: string
    tags: string
    tax_exempt: boolean
    tax_exemptions: any[]
    updated_at: string
    updateCustomer?: any
    verified_email: boolean
}
export type OrderDotFulfillment = {
    admin_graphql_api_id: string
    created_at: string
    id: number
    line_items: LineItemInit[]
    location_id: number
    name: string
    order_id: number
    origin_address: any
    receipt: any
    service: string
    shipment_status: string | null
    status: string
    tracking_company: string
    tracking_number: string
    tracking_numbers: string[]
    tracking_url: string
    tracking_urls: string[]
    updated_at: string
}
export type OrderInit = {
    admin_graphql_api_id: string
    app_id: string
    billing_address: OrderBillingAddress
    browser_ip: string
    buyer_accepts_marketing: number | undefined
    cancelled_at: Date | null
    cancel_reason: string | null
    cart_token: string
    checkout_id: string
    checkout_token: string
    client_details: {
        accept_language: string
        browser_height: null
        browser_ip: string
        browser_width: null
        session_hash: null
        user_agent: string
    }
    closed_at: null
    company: null
    confirmed: number | undefined
    contact_email: string
    created_at: Date
    created_at_date: string
    currency: string
    current_subtotal_price: number | undefined
    current_subtotal_price_set: PriceSet
    current_total_discounts: number | undefined
    current_total_discounts_set: PriceSet
    current_total_duties_set: null
    current_total_price: number
    current_total_price_set: PriceSet
    current_total_tax: number | undefined
    current_total_tax_set: PriceSet
    customer: OrderCustomer
    customer_locale: string
    device_id: null
    discount_applications: any[]
    discount_codes: any[]
    email: string
    estimated_taxes: number | undefined
    financial_status: string
    fulfillments: OrderDotFulfillment[]
    fulfillment_status: string | null
    gateway: string
    has_timeline_comment: number | undefined
    id: number | undefined
    landing_site: string
    landing_site_ref: null
    line_items: LineItemInit[]
    location_id: null
    merchant_of_record_app_id: null
    name: string
    note: string | null
    note_attributes: {
        name: string
        value: string
    }[]
    note_timeline: null
    number: number | undefined
    order_number: number | undefined
    order_status_url: string
    original_total_duties_set: null
    payment_details: null
    payment_gateway_names: string[]
    payment_terms: null
    phone: null
    presentment_currency: string
    processed_at: Date
    processing_method: string
    purchase_orders: null | string[]
    purchase_order_ids: number[] | null
    reference: string
    referring_site: string
    refunds: OrderRefund[]
    risk_events: {
        display: boolean
        level: string
        message: string
        __typename: string
    }[]
    risk_level: string
    shipping_address: OrderShippingAddress
    shipping_lines: {
        carrier_identifier: string
        code: string
        delivery_category: null
        discounted_price: string
        discounted_price_set: PriceSet
        discount_allocations: any[]
        id: number | undefined
        phone: null
        price: string
        price_set: PriceSet
        requested_fulfillment_service_id: null
        source: string
        tax_lines: {
            channel_liable: boolean
            price: string
            price_set: PriceSet
            rate: number | undefined
            title: string
        }[]
        title: string
    }[]
    source_identifier: string
    source_name: string
    source_url: null
    subtotal_price: number | undefined
    subtotal_price_set: PriceSet
    tags: string
    tasks: null | string[]
    taxes_included: number | undefined
    tax_lines: {
        channel_liable: boolean
        price: string
        price_set: PriceSet
        rate: number | undefined
        title: string
    }[]
    test: number | undefined
    token: string
    total_discounts: number | undefined
    total_discounts_set: PriceSet
    total_line_items_price: number | undefined
    total_line_items_price_set: PriceSet
    total_outstanding: number
    total_price: number
    total_price_set: PriceSet
    total_shipping_price_set: PriceSet
    total_tax: number | undefined
    total_tax_set: PriceSet
    total_tip_received: number | undefined
    total_weight: number | undefined
    updated_at: string
    user_id: null
}
export type OrderShippingAddress = {
    address1: string | null
    address2: string | null
    city: string | null
    company: string | null
    country: string | null
    country_code: string | null
    first_name: string | null
    last_name: string | null
    latitude?: number | undefined
    longitude?: number | undefined
    name: string | null
    phone: string | null
    province: string | null
    province_code: string | null
    zip: string | null
}
export type OrderTimelineEvent = {
    appTitle: string | null
    attributeToApp: boolean
    attributeToUser: boolean
    createdAt: string
    criticalAlert: boolean
    id: string
    message: string
    __typename: string
}
export type RiskEventInit = {
    display: boolean
    level: string
    message: string
    __typename: string
}
export type TaxLine = {
    channel_liable: boolean
    price: string
    price_set: PriceSet
    rate: number
    title: string
}
type TransactionInit = {
    admin_graphql_api_id: string
    amount: string
    authorization: string
    created_at: string
    currency: string
    device_id: null
    error_code: null
    gateway: string
    id: number | undefined
    kind: string
    location_id: null
    message: string
    order_id: number | undefined
    parent_id: number | undefined
    payments_refund_attributes: {
        acquirer_reference_number: null
        status: string
    }
    payment_details: {
        avs_result_code: string
        buyer_action_info: null
        credit_card_bin: string
        credit_card_company: string
        credit_card_expiration_month: number | undefined
        credit_card_expiration_year: number | undefined
        credit_card_name: string
        credit_card_number: string
        credit_card_wallet: null
        cvv_result_code: string
    }
    payment_id: string
    processed_at: string
    receipt: {
        amount: number | undefined
        balance_transaction: {
            exchange_rate: null
            id: string
            object: string
        }
        charge: {
            amount: number | undefined
            application_fee: string
            balance_transaction: string
            captured: boolean
            created: number | undefined
            currency: string
            failure_code: null
            failure_message: null
            fraud_details: {}
            id: string
            livemode: boolean
            metadata: {
                email: string
                manual_entry: string
                order_id: string
                order_transaction_id: string
                payments_charge_id: string
                shop_id: string
                shop_name: string
            }
            mit_params: {
                network_transaction_id: string
            }
            object: string
            outcome: {
                network_status: string
                reason: null
                risk_level: string
                seller_message: string
                type: string
            }
            paid: boolean
            payment_intent: string
            payment_method: string
            payment_method_details: {
                card: {
                    amount_authorized: number | undefined
                    brand: string
                    capture_before: number | undefined
                    checks: {
                        address_line1_check: string
                        address_postal_code_check: string
                        cvc_check: string
                    }
                    country: string
                    description: string
                    ds_transaction_id: null
                    exp_month: number | undefined
                    exp_year: number | undefined
                    extended_authorization: {
                        status: string
                    }
                    fingerprint: string
                    funding: string
                    iin: string
                    incremental_authorization: {
                        status: string
                    }
                    incremental_authorization_supported: boolean
                    installments: null
                    issuer: string
                    last4: string
                    mandate: null
                    moto: boolean
                    multicapture: {
                        status: string
                    }
                    network: string
                    network_token: {
                        used: boolean
                    }
                    network_transaction_id: string
                    overcapture: {
                        maximum_amount_capturable: number | undefined
                        status: string
                    }
                    overcapture_supported: boolean
                    payment_account_reference: null
                    three_d_secure: null
                    wallet: null
                }
                type: string
            }
            refunded: boolean
            source: null
            status: string
        }
        created: number | undefined
        currency: string
        id: string
        metadata: {
            order_transaction_id: string
            payments_refund_id: string
        }
        mit_params: {}
        object: string
        payment_method_details: {
            card: {
                acquirer_reference_number: null
                acquirer_reference_number_status: string
            }
            type: string
        }
        reason: null
        status: string
    }
    source_name: string
    status: string
    test: boolean
    user_id: number | undefined
}

// default objects
export const defaultOrder: OrderInit = {
    admin_graphql_api_id: '',
    app_id: '',
    billing_address: {
        address1: '',
        address2: null,
        city: '',
        company: null,
        country: '',
        country_code: '',
        first_name: '',
        last_name: '',
        latitude: undefined,
        longitude: undefined,
        name: '',
        phone: '',
        province: '',
        province_code: '',
        zip: '',
    },
    browser_ip: '',
    buyer_accepts_marketing: undefined,
    cancelled_at: null,
    cancel_reason: null,
    cart_token: '',
    checkout_id: '',
    checkout_token: '',
    client_details: {
        accept_language: '',
        browser_height: null,
        browser_ip: '',
        browser_width: null,
        session_hash: null,
        user_agent: '',
    },
    closed_at: null,
    company: null,
    confirmed: undefined,
    contact_email: '',
    created_at: new Date(),
    created_at_date: '',
    currency: '',
    current_subtotal_price: undefined,
    current_subtotal_price_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    current_total_discounts: undefined,
    current_total_discounts_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    current_total_duties_set: null,
    current_total_price: 0,
    current_total_price_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    current_total_tax: undefined,
    current_total_tax_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    customer: {
        accepts_marketing: false,
        accepts_marketing_updated_at: '',
        admin_graphql_api_id: '',
        created_at: '',
        currency: '',
        default_address: {
            address1: '',
            address2: null,
            city: '',
            company: null,
            country: '',
            country_code: '',
            country_name: '',
            customer_id: undefined,
            default: false,
            first_name: '',
            id: undefined,
            last_name: '',
            name: '',
            phone: '',
            province: '',
            province_code: '',
            zip: '',
        },
        email: '',
        email_marketing_consent: {
            consent_updated_at: '',
            opt_in_level: '',
            state: '',
        },
        first_name: '',
        id: undefined,
        last_name: '',
        marketing_opt_in_level: '',
        multipass_identifier: null,
        note: null,
        phone: null,
        sms_marketing_consent: null,
        state: '',
        tags: '',
        tax_exempt: false,
        tax_exemptions: [],
        updated_at: '',
        verified_email: false,
    },
    customer_locale: '',
    device_id: null,
    discount_applications: [],
    discount_codes: [],
    email: '',
    estimated_taxes: undefined,
    financial_status: '',
    fulfillments: [],
    fulfillment_status: null,
    gateway: '',
    has_timeline_comment: undefined,
    id: undefined,
    landing_site: '',
    landing_site_ref: null,
    line_items: [],
    location_id: null,
    merchant_of_record_app_id: null,
    name: '',
    note: null,
    note_attributes: [],
    note_timeline: null,
    number: undefined,
    order_number: undefined,
    order_status_url: '',
    original_total_duties_set: null,
    payment_details: null,
    payment_gateway_names: [],
    payment_terms: null,
    phone: null,
    presentment_currency: '',
    processed_at: new Date(),
    processing_method: '',
    purchase_orders: null,
    purchase_order_ids: null,
    reference: '',
    referring_site: '',
    refunds: [],
    risk_events: [],
    risk_level: '',
    shipping_address: {
        address1: '',
        address2: null,
        city: '',
        company: null,
        country: '',
        country_code: '',
        first_name: '',
        last_name: '',
        latitude: undefined,
        longitude: undefined,
        name: '',
        phone: '',
        province: '',
        province_code: '',
        zip: '',
    },
    shipping_lines: [],
    source_identifier: '',
    source_name: '',
    source_url: null,
    subtotal_price: undefined,
    subtotal_price_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    tags: '',
    tasks: null,
    taxes_included: undefined,
    tax_lines: [],
    test: undefined,
    token: '',
    total_discounts: undefined,
    total_discounts_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    total_line_items_price: undefined,
    total_line_items_price_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    total_outstanding: 0,
    total_price: 0,
    total_price_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    total_shipping_price_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    total_tax: undefined,
    total_tax_set: {
        presentment_money: {
            amount: '',
            currency_code: '',
        },
        shop_money: {
            amount: '',
            currency_code: '',
        },
    },
    total_tip_received: undefined,
    total_weight: undefined,
    updated_at: '',
    user_id: null,
}
export const defaultDraftOrder: DraftOrderInit = {
    ...defaultOrder,
    applied_discount: undefined,
    completed_at: undefined,
    order_id: null,
    representative_name: '',
    shipping_line: {
        price: '',
        title: '',
        custom: false,
        handle: undefined,
    },
    status: '',
}
export const defaultFulfillmentOrders: FulfillmentOrderInit[] = []
export const orderCustomerDefaultAddress = {
    address1: '',
    address2: null,
    city: '',
    company: '',
    country: '',
    country_code: '',
    first_name: '',
    last_name: '',
    latitude: undefined,
    longitude: undefined,
    name: '',
    phone: '',
    province: '',
    province_code: '',
    zip: '',
}

// maps
export type DeliveryStatusMap = {
    failure: 'bg-red !text-white'
    confirmed: 'bg-[#f8d990]'
    delivered: 'bg-[rgb(190,255,200)]'
}
export type FinancialStatusMap = {
    paid: MapStatus
    authorized: MapStatus
    expired: MapStatus
    overdue: MapStatus
    partially_paid: MapStatus
    partially_refunded: MapStatus
    pending: MapStatus
    refunded: MapStatus
    unpaid: MapStatus
    voided: MapStatus
}
export type FulfillmentStatusMap = {
    fulfilled: MapStatus
    unfulfilled: MapStatus
    partial: MapStatus
    restocked: MapStatus
}
export const deliveryStatusMap: DeliveryStatusMap = {
    failure: 'bg-red !text-white',
    confirmed: 'bg-[#f8d990]',
    delivered: 'bg-[rgb(190,255,200)]',
}
type MapStatus = {
    abbr?: string
    status: string
    bg: string
    symbol: React.JSX.Element
}
export type OrderAction = {
    name: string
    action: Function
}
export const financialStatusMap: FinancialStatusMap = {
    paid: { abbr: 'Paid', bg: 'bg-[rgb(190,255,200)]', status: 'Paid', symbol: <TbCircleFilled /> },
    authorized: { abbr: 'Auth', bg: 'bg-[#f8d990]', status: 'Authorized', symbol: <TbCircleHalf2 /> },
    expired: { abbr: 'Exp', bg: 'bg-[#8a0f0f]', status: 'Expired', symbol: <TbCircle /> },
    overdue: { abbr: 'OD', bg: 'bg-[#8a0f0f]', status: 'Overdue', symbol: <VscCircleSlash /> },
    partially_paid: { abbr: 'Prt P', bg: 'bg-[#f8d990]', status: 'Partially Paid', symbol: <TbCircleHalf2 /> },
    partially_refunded: { abbr: 'Prt R', bg: 'bg-[#f8d990]', status: 'Partially Refunded', symbol: <TbCircleHalf2 /> },
    pending: { abbr: 'Pend', bg: 'bg-[#f8d990]', status: 'Pending', symbol: <TbCircleHalf2 /> },
    refunded: { abbr: 'R', bg: 'bg-[rgb(190,255,200)]', status: 'Refunded', symbol: <TbCircleFilled /> },
    unpaid: { abbr: 'U', bg: 'bg-fire', status: 'Unpaid', symbol: <TbCircle /> },
    voided: { abbr: 'Void', bg: 'bg-grey', status: 'Void', symbol: <TbCircle /> },
}
export const fulfillmentStatusMap: FulfillmentStatusMap = {
    fulfilled: { status: 'Fulfilled', bg: 'bg-[rgb(190,255,200)]', symbol: <TbCircleFilled /> },
    unfulfilled: { status: 'Unfulfilled', bg: 'bg-[#f8d990]', symbol: <TbCircle /> },
    partial: { status: 'Partially Fulfilled', bg: 'bg-[#f8d990]', symbol: <TbCircleHalf2 /> },
    restocked: { status: 'Restocked', bg: 'bg-[rgb(190,255,200)]', symbol: <TbCircleFilled /> },
}
