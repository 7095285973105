import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { BiBarcodeReader } from 'react-icons/bi'
import { FaCaretLeft, FaCaretUp, FaExternalLinkAlt, FaLink, FaPen } from 'react-icons/fa'
import { cn } from '../../helpers'
import LabelWithInfo from '../../productSetupV2/components/LabelWithInfo'
import Input from './Input'
import Select from './Select'
import Textarea from './Textarea'
import ProductCommentsModal from './modals/ProductCommentsModal'

export default function CompanyProductView({ product: originalProduct }: { product: any }) {
    const weblinksRef = useRef<HTMLDivElement>(null)
    const productTypesRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<HTMLInputElement>(null)
    const identifiersRef = useRef<HTMLDivElement>(null)

    const [editableFields, setEditableFields] = useState<any>({})
    const [editablePricingValues, setEditablePricingValues] = useState<any>({})

    const [noteExpand, setNoteExpand] = useState(false)
    const [showIdentifiers, setShowIdentifiers] = useState(false)
    const [showWeblinks, setShowWeblinks] = useState(false)
    const [editWeblinks, setEditWeblinks] = useState(false)
    const [showProductTypes, setShowProductTypes] = useState(false)

    const [showCommentModal, setShowCommentModal] = useState(false)

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {}
    const handlePricingValueChange = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditablePricingValues({
            ...editablePricingValues,
            [target.name]: target.value,
        })
    }

    const handleChangeWeblink = (
        { target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        weblinkIndex: number
    ) => {
        let weblinks: any[] = []
        if (!editableFields.weblinks) {
            weblinks = product.weblinks
            setEditableFields((previousState: any) => {
                const newState = structuredClone(previousState)
                newState.weblinks = weblinks
                if (newState.weblinks && (newState.weblinks as any[])[weblinkIndex]) {
                    ;(newState.weblinks as any[])[weblinkIndex] = {
                        ...(newState.weblinks as any[])[weblinkIndex],
                        [target.name]: target.value,
                    }
                }
                return newState
            })
        } else {
            setEditableFields((previousState: any) => {
                const newState = structuredClone(previousState)
                if (newState.weblinks && (newState.weblinks as any[])[weblinkIndex]) {
                    ;(newState.weblinks as any[])[weblinkIndex] = {
                        ...(newState.weblinks as any[])[weblinkIndex],
                        [target.name]: target.value,
                    }
                }
                return newState
            })
        }
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!weblinksRef.current?.contains(e.target as Element)) {
                setShowWeblinks(false)
            }
            if (!identifiersRef.current?.contains(e.target as Element)) {
                setShowIdentifiers(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    })

    const product = originalProduct
    const weblinks = product.weblinks
    const profit = (product.list_price - product.cost - product.shipping_fee).toFixed(2)
    const margin = (((product.list_price - product.cost - product.shipping_fee) / product.list_price) * 100).toFixed(2)
    const profitMarginInsert = `$${profit} (${margin}%)`
    const editableProfit = (
        (editablePricingValues.list_price ?? product.list_price) -
        (editablePricingValues.cost ?? product.cost) -
        (editablePricingValues.shipping_fee ?? product.shipping_fee)
    ).toFixed(2)
    const editableMargin = (
        (((editablePricingValues.list_price ?? product.list_price) -
            (editablePricingValues.cost ?? product.cost) -
            (editablePricingValues.shipping_fee ?? product.shipping_fee)) /
            (editablePricingValues.list_price ?? product.list_price)) *
        100
    ).toFixed(2)
    const editableProfitMarginInsert = `$${editableProfit} (${editableMargin}%)`

    return (
        <div
            className={cn(
                `border rounded-[4px] shadow-small grid grid-cols-[1.5fr_3.25fr_0.75fr_1.5fr_32px] text-[14px] relative
            ${!product.supplier_sku && !product.upc && !product.model_number && 'border-danger dark:border-darkdanger'}
            `
            )}
        >
            <div className={cn('flex flex-col gap-[8px] p-[8px]')}>
                <div className='flex gap-[8px] items-end relative'>
                    <Input
                        label={`shopify SKU`}
                        value={product.sku || ''}
                        id='sku'
                        name='sku'
                        onChange={handleChange}
                        type='text'
                        readOnly={true}
                    />
                    <button
                        onClick={() => {
                            setShowWeblinks((previousState) => !previousState)
                            setShowIdentifiers(false)
                        }}
                        title='Weblinks'
                        className='mb-[8px]'
                    >
                        {showWeblinks ? (
                            <FaCaretLeft className='w-[20px] h-[20px] text-red dark:text-lightred' />
                        ) : (
                            <FaLink className='w-[16px] h-[16px]' />
                        )}
                    </button>
                    {showWeblinks && (
                        <div
                            ref={weblinksRef}
                            className='p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px] flex flex-col gap-[8px] absolute top-0 left-[calc(100%-24px)] bg-white dark:bg-darkaccent z-[5] shadow-small w-[300px]'
                        >
                            <div className='dark:text-offwhite uppercase border-b py-2'>
                                <LabelWithInfo
                                    label='Weblinks'
                                    info='Where we are pulling product information'
                                    htmlFor=''
                                />
                            </div>
                            <ul
                                className={`${!editWeblinks ? `ml-[12px] pl-[12px]` : ''} ${
                                    product.weblinks?.length > 0 ? 'my-[8px]' : ''
                                }`}
                            >
                                {weblinks?.map((weblink: any, weblinkIndex: number) => {
                                    if (editWeblinks) {
                                        return (
                                            <li key={`Weblink-${weblinkIndex}`} className='flex gap-[8px] items-end'>
                                                <Input
                                                    label='Title'
                                                    value={weblink.title}
                                                    id='weblink-title'
                                                    name='title'
                                                    onChange={(e) => handleChangeWeblink(e, weblinkIndex)}
                                                    type='text'
                                                    readOnly={true}
                                                />
                                                <Input
                                                    label='Link'
                                                    value={weblink.link}
                                                    id='weblink-link'
                                                    name='link'
                                                    onChange={(e) => handleChangeWeblink(e, weblinkIndex)}
                                                    type='text'
                                                    readOnly={true}
                                                />
                                            </li>
                                        )
                                    }
                                    return (
                                        <li className='list-disc'>
                                            <a
                                                target='_blank'
                                                key={`Weblink-${weblinkIndex}`}
                                                className='font-bold border-b w-fit flex items-center gap-[8px]'
                                                href={weblink.link}
                                            >
                                                {weblink.title}
                                                <FaExternalLinkAlt />
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>

                            <div className='flex items-center justify-center gap-[8px]'>
                                {product.weblinks?.length > 0 && !editWeblinks && (
                                    <button
                                        onClick={() => {
                                            return setEditWeblinks(true)
                                        }}
                                        className='shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-text1 dark:text-darktext1 font-bold uppercase'
                                    >
                                        Edit Weblinks
                                    </button>
                                )}
                                {editWeblinks && (
                                    <button
                                        onClick={() => setEditWeblinks(false)}
                                        className='shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                                    >
                                        View Weblinks
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    <div className='flex flex-col gap-1 items-center'>
                        {!product.supplier_sku && !product.upc && !product.model_number && (
                            <div className='group relative z-[4]'>
                                <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                <div className='hidden group-hover:flex border absolute top-[100%] left-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                                    Each product must have supplier sku, upc or model number
                                </div>
                            </div>
                        )}
                        <button
                            onClick={() => {
                                setShowIdentifiers((previousState) => !previousState)
                                setShowWeblinks(false)
                            }}
                            title='Alternate Identifiers'
                            className='mb-[8px]'
                        >
                            {showIdentifiers ? (
                                <FaCaretLeft className='w-[20px] h-[20px] text-red dark:text-lightred' />
                            ) : (
                                <BiBarcodeReader className='w-[20px] h-[20px]' />
                            )}
                        </button>
                    </div>
                    {showIdentifiers && (
                        <div
                            ref={identifiersRef}
                            className='p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px] flex flex-col gap-[8px] absolute top-0 left-[100%] bg-white dark:bg-darkaccent z-[5] shadow-small w-[400px]'
                        >
                            <Input
                                label='supplier sku'
                                value={product.supplier_sku}
                                id='supplier_sku'
                                name='supplier_sku'
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                label='upc'
                                value={product.upc}
                                id='upc'
                                name='upc'
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            {/* <Input
                                label='Model/Part Number'
                                value={ product.model_part_number}
                                id='model_part_number'
                                name='model_part_number'
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            /> */}
                            <Input
                                label='Model Number'
                                value={product.model_number}
                                id='model_number'
                                name='model_number'
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            {/* <Input
                                label='Part Number'
                                value={ product.part_number}
                                id='part_number'
                                name='part_number'
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            /> */}
                        </div>
                    )}
                </div>
                <div ref={productTypesRef} className='relative'>
                    <Input
                        id='product_type'
                        name='product_type'
                        label='Product Type'
                        value={product.product_type || ''}
                        onChange={handleChange}
                        type='text'
                        readOnly={true}
                        onclick={() => {
                            setShowProductTypes((previousState) => !previousState)
                            setTimeout(() => {
                                searchRef.current?.focus()
                            }, 10)
                        }}
                    />
                    <button
                        onClick={() => {
                            setShowProductTypes((previousState) => !previousState)
                            setTimeout(() => {
                                searchRef.current?.focus()
                            }, 10)
                        }}
                        className='absolute top-[50%] right-[4px] z-[4]'
                    >
                        {showProductTypes ? (
                            <FaCaretUp className='text-darkgrey dark:text-offwhite' />
                        ) : (
                            <FaPen className='text-blue dark:text-accent' />
                        )}
                    </button>
                </div>
                <div className='flex flex-col gap-1'>
                    <Select
                        disabled={true}
                        label='Listing Status'
                        id='status'
                        name='status'
                        onChange={handleChange}
                        value={product.status}
                    >
                        <option value='calculating-margins'>Calculating Margins</option>
                        <option value='ready'>Ready to list</option>
                        <option value='needs-review'>Needs Review * </option>
                        <option value='unlistable'>Unlistable</option>
                        <option value='listed'>Listed</option>
                    </Select>
                </div>
            </div>
            <div
                className={cn(
                    'relative grid grid-rows-[24px_30px_34px_34px] grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] py-[8px] gap-x-2 gap-y-5 mr-3'
                )}
            >
                <div className='relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] px-2  gap-x-2 col-span-4 items-center'>
                    <div className=' text-base font-semibold'>Cost</div>
                    <div className=' text-base font-semibold'>Shipping</div>
                    <div className=' text-base font-semibold'>List Price</div>
                    <div className=' text-base font-semibold'>Profit (Margin)</div>
                </div>
                <div className='relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] px-2  gap-x-2 col-span-4 border rounded-md  border-lightgrey dark:border-darkgrey items-center'>
                    <div className='absolute -top-5 left-2'>original</div>
                    <div className=' flex'>
                        <div className={cn(editablePricingValues.cost && 'line-through', 'self-center mt-[-1px]')}>
                            $
                        </div>
                        <Input
                            value={product.cost.toFixed(2)}
                            id='cost'
                            name='cost'
                            onChange={handlePricingValueChange}
                            type='number'
                            readOnly={true}
                            className={cn(
                                editablePricingValues.cost && 'line-through',
                                'min-h-[28px] bg-bg1 dark:bg-darkbg1 p-0'
                            )}
                        />
                    </div>
                    <div className=' flex relative'>
                        <div
                            className={cn(
                                editablePricingValues.shipping_fee && 'line-through',
                                'self-center mt-[-1px]'
                            )}
                        >
                            $
                        </div>
                        <Input
                            value={product.shipping_fee.toFixed(2)}
                            id='shipping_fee'
                            name='shipping_fee'
                            onChange={handlePricingValueChange}
                            type='number'
                            readOnly={true}
                            className={cn(
                                editablePricingValues.shipping_fee && 'line-through',
                                'min-h-[28px] bg-bg1 dark:bg-darkbg1 p-0'
                            )}
                        />
                    </div>
                    <div className=' flex relative'>
                        <div
                            className={cn(editablePricingValues.list_price && 'line-through', 'self-center mt-[-1px]')}
                        >
                            $
                        </div>
                        <Input
                            value={product.list_price.toFixed(2)}
                            id='list_price'
                            name='list_price'
                            onChange={handlePricingValueChange}
                            type='number'
                            readOnly={true}
                            className={cn(
                                editablePricingValues.list_price && 'line-through',
                                'min-h-[28px] bg-bg1 dark:bg-darkbg1 p-0'
                            )}
                        />
                    </div>
                    <div className={cn('text-[12px]', ' font-bold flex flex-col gap-2 w-fit')}>
                        {profitMarginInsert}
                    </div>
                </div>

                <div className={cn('relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] px-2 gap-x-2 col-span-4 ')}>
                    <div className='absolute -top-5 left-2'>current</div>
                    <div className=''>
                        <Input
                            value={editablePricingValues?.cost ?? product.cost.toFixed(2)}
                            id='cost'
                            name='cost'
                            onChange={handlePricingValueChange}
                            type='number'
                            readOnly={true}
                        />
                    </div>
                    <div className=''>
                        <Input
                            value={editablePricingValues?.shipping_fee ?? product.shipping_fee.toFixed(2)}
                            id='shipping_fee'
                            name='shipping_fee'
                            onChange={handlePricingValueChange}
                            type='number'
                            readOnly={true}
                        />
                    </div>
                    <div className=''>
                        <Input
                            value={editablePricingValues?.list_price ?? product.list_price.toFixed(2)}
                            id='list_price'
                            name='list_price'
                            onChange={handlePricingValueChange}
                            type='number'
                            readOnly={true}
                        />
                    </div>
                    <div className={cn('text-[12px]', ' mt-1 font-bold flex flex-col gap-2')}>
                        {editableProfitMarginInsert}
                    </div>
                </div>

                <div className='flex flex-col col-start-4 items-start justify-end'>
                    {(product.latest_comment || product.latest_comment == '') && (
                        <button onClick={() => setShowCommentModal(true)} className=' shrink-0 underline min-w-[80px] '>
                            See All Edits
                        </button>
                    )}
                </div>
            </div>
            <div className={cn('py-[8px] flex flex-col gap-[8px]')}>
                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'>
                    Lowest Price
                </label>
                <div className='flex gap-[8px] items-center justify-between border border-lightgrey rounded-[4px] dark:border-darkgrey px-[4px]'>
                    <a
                        target='_blank'
                        className='px-[4px] py-[4px] flex gap-[4px] items-center font-bold'
                        href={product.lowest_competitor_link}
                    >
                        ${(Number(product.lowest_competitor_price) || 0).toFixed(2)} <FaLink />
                    </a>
                </div>
                <>
                    <Input
                        label='Price'
                        value={parseFloat(product?.lowest_competitor_price)?.toFixed(2)}
                        id='lowest_competitor_price'
                        name='lowest_competitor_price'
                        onChange={handleChange}
                        type='number'
                        readOnly={true}
                    />
                    <Input
                        label='link'
                        value={product.lowest_competitor_link || ''}
                        id='lowest_competitor_link'
                        name='lowest_competitor_link'
                        onChange={handleChange}
                        type='text'
                        readOnly={true}
                    />
                </>
            </div>

            <div className={cn('py-2 px-1 relative')}>
                <button
                    className='absolute top-[2px] left-16 underline'
                    onClick={() => setNoteExpand(noteExpand ? false : true)}
                >
                    {noteExpand ? 'collapse' : 'expand '}
                </button>

                <Textarea
                    label='Notes'
                    outerClassName={cn(
                        noteExpand && 'h-full min-h-[159px]',
                        !noteExpand && 'h-full max-h-[170px] overflow-auto',
                        'flex flex-col gap-[4px] w-full '
                    )}
                    className={cn(
                        'w-full h-full !max-w-none p-[8px] bg-transparent border-lightgrey dark:border-0 dark:border-darkgrey dark:bg-darkness rounded-[4px] focus:outline-none !max-h-none'
                    )}
                    id='notes'
                    name='notes'
                    onChange={handleChange}
                    value={product.notes ?? ''}
                    readOnly={true}
                />
            </div>
            <div className='absolute top-1 right-1 z-[6]'></div>
            <div className={cn('pointer-events-none', 'absolute top-[2px] right-10 ')}></div>

            <div className='flex flex-col justify-between relative'>
                {/* <button onClick={() => {}} className='p-[8px] h-fit'> */}
                {/* <BsThreeDotsVertical className='text-[20px]' /> */}
                {/* </button> */}

                <div className={cn('pointer-events-none', 'absolute bottom-0 right-0')}></div>
            </div>
            {showCommentModal && <ProductCommentsModal product_id={product.id} setShowModal={setShowCommentModal} />}
        </div>
    )
}

export type CompanyProductType = {
    id?: string | number
    upc: string
    sku: string
    model_number: string
    part_number: string
    product_type: string
    status: string
    weblinks: { title: string; link: string }[]
    cost: number
    shipping_fee: number
    list_price: number
    new_cost: number
    new_shipping_fee: number
    new_list_price: number
    lowest_competitor_price: number
    lowest_competitor_link: string
    shopify_product_id?: string | number
    shopify_variant_id?: string | number
    is_variant?: boolean
    listed_by?: string | number
    notes: string
    working_comment: string
    latest_comment: string
    company_id?: string | number
    store_id?: string | number
    related_variants_group_id?: string | number | null
    is_primary_variant?: number | null
}

export const defaultCompanyProduct: CompanyProductType = {
    product_type: '',
    status: '',
    upc: '',
    sku: '',
    model_number: '',
    part_number: '',
    weblinks: [],
    cost: 0,
    shipping_fee: 0,
    list_price: 0,
    new_cost: 0,
    new_shipping_fee: 0,
    new_list_price: 0,
    lowest_competitor_price: 0,
    lowest_competitor_link: '',
    notes: '',
    working_comment: '',
    latest_comment: '',
}
