import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FaPlus, FaTrash } from 'react-icons/fa'

export default function ManufacturerOrderNumbers({ order_id }: { order_id: string }) {
    const manuOrderNumbersQuery = useQuery({
        queryKey: ['orders', order_id, 'manuOrderNumbers'],
        queryFn: () => vFetch(`/v2/orders/manufacturerOrderNumbers?order_id=${order_id}`),
    })
    const saveManuOrderNumbersQuery = useMutation({
        mutationFn: ({ order_numbers }: { order_numbers: string[] }) =>
            vFetch(`/v2/orders/manufacturerOrderNumbers`, {
                method: 'PUT',
                body: JSON.stringify({
                    order_id,
                    order_numbers,
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['orders'] })
        },
    })
    const orderNumbers: string[] = manuOrderNumbersQuery.data?.orderNumbers || []
    const [editableOrderNumbers, setEditableOrderNumbers] = useState<string[]>([])
    const edited = JSON.stringify(orderNumbers) !== JSON.stringify(editableOrderNumbers)
    const queryClient = useQueryClient()
    const handleSave = () => {
        saveManuOrderNumbersQuery.mutate({ order_numbers: editableOrderNumbers.filter((v) => v) })
    }

    useEffect(() => {
        setEditableOrderNumbers(orderNumbers)
    }, [orderNumbers])
    return (
        <div className='grid gap-[8px] bg-white dark:bg-darkaccent p-[16px] shadow-small rounded dark:text-offwhite'>
            <h2 className='text-[20px] font-bold mb-[4px]'>Manufacturer Order Numbers</h2>
            {editableOrderNumbers.map((_orderNumber, index) => (
                <OrderNumberInput
                    orderIndex={index}
                    orderNumbers={editableOrderNumbers}
                    setOrderNumbers={setEditableOrderNumbers}
                />
            ))}
            <button
                onClick={() => setEditableOrderNumbers((prev) => [...prev, ''])}
                className='flex gap-1 items-center text-sm'
            >
                <FaPlus />
                <p className=''>Add Order Number</p>
            </button>
            {edited && (
                <button
                    onClick={handleSave}
                    className='font-bold text-sm leading-none mx-auto py-2 px-4 rounded bg-accent1 dark:bg-darkaccent1 text-white dark:text-darkbg1'
                >
                    Save Changes
                </button>
            )}
        </div>
    )
}

function OrderNumberInput({
    orderIndex,
    orderNumbers,
    setOrderNumbers,
}: {
    orderIndex: number
    orderNumbers: string[]
    setOrderNumbers: Dispatch<SetStateAction<string[]>>
}) {
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setOrderNumbers((prev) => {
            const newState = structuredClone(prev)
            newState[orderIndex] = target.value
            return newState
        })
    }
    const handleRemove = () => {
        setOrderNumbers((prev) => {
            const newState = structuredClone(prev)
            newState.splice(orderIndex, 1)
            return newState
        })
    }
    return (
        <div className='flex gap-2 items-center w-full'>
            <Input
                type='text'
                value={orderNumbers[orderIndex]}
                onChange={handleChange}
                id='ordernumber'
                name='ordernumber'
                placeholder='Enter an order number...'
            />
            <button onClick={handleRemove} className='text-sm text-red dark"text-lightred'>
                <FaTrash />
            </button>
        </div>
    )
}
