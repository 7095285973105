import { Dispatch, FormEventHandler, SetStateAction, useEffect, useRef } from 'react'

export default function RichText({ rawHTML, handleSet }: { rawHTML: string; handleSet: Function }) {
    const contentEditableRef = useRef(null)

    const handleChange = ({ target }: { target: HTMLElement }) => {
        const goLower = (el: HTMLElement) => {
            let fontWeight = ''
            if (el.style.fontWeight && el.style.fontWeight !== '400') {
                fontWeight = el.style.fontWeight
            }
            el.removeAttribute('style')
            el.removeAttribute('class')
            if (fontWeight) el.style.fontWeight = fontWeight
            if (el.children.length > 0) {
                Array.from(el.children).forEach((child) => goLower(child as HTMLElement))
            }
        }
        Array.from(target.children).forEach((child) => goLower(child as HTMLElement))
        handleSet(target.innerHTML.replaceAll('\n', ''))
    }

    useEffect(() => {
        if (rawHTML && contentEditableRef.current) {
            ;(contentEditableRef.current as HTMLElement).innerHTML = rawHTML
        }
    }, [])
    return (
        <div
            ref={contentEditableRef}
            className='js-richtext dark:text-offwhite bg-lightgrey dark:bg-faintplus w-full h-[200px] !max-w-none !m-0 overflow-auto p-[8px] rounded-[4px] leading-[1.3] focus:outline-none'
            contentEditable='true'
            onInput={handleChange as any as FormEventHandler<HTMLDivElement>}
        ></div>
    )
}
