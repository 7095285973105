import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import { useSearchParams } from 'react-router-dom'
import ArticleView from './ArticleView'
import { Button } from '../../custom_components/component_Basics/Button'
import { reverse } from 'dns'
import { articleCardDateFormatter } from '../helpers'

export default function DepartmentView({
    setView,
    selectedArticle,
    setSelectedArticle,
    selectedDepartment,
}: {
    setView: any
    selectedArticle: any
    setSelectedArticle: any
    selectedDepartment: any
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const articleID = searchParams.get('articleID') //
    const [isLoading, setIsLoading] = useState(true) //
    const [isError, setIsError] = useState(false)

    const [articleList, setArticleList] = useState([]) //
    const pinnedArticles = articleList.filter((article: any) => article.pinned)
    const articles = articleList.filter(
        (article: any) => !article.pinned && article.status === 'Published' && article.hidden === 0
    )
    const getArticles = async () => {
        vFetch(
            `/knowledge/articles?department_id=${selectedDepartment.id}&fields=title,excerpt,featured_image,pinned,id,created_at,status,hidden`,
            {
                cb: (res: any) => {
                    setIsLoading(false)
                    if (!res.success) {
                        setIsError(true)
                    }
                    if (res.success) {
                        const foundArticle = res.articles.find((article: any) => article.id == articleID)
                        if (foundArticle) {
                            setSelectedArticle(foundArticle)
                        }
                        setArticleList(res.articles)
                    }
                },
            }
        )
    }
    const handleSetArticle = (article: any) => {
        setSelectedArticle(article)
        setSearchParams((prev: any) => {
            prev.set('articleID', article.id)
            return prev
        })
    }
    useEffect(() => {
        getArticles()
    }, [selectedDepartment])

    return (
        <>
            {articleID && selectedArticle && (
                <ArticleView
                    selectedArticle={selectedArticle}
                    setSelectedArticle={setSelectedArticle}
                    setView={setView}
                />
            )}
            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}
            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}
            {!articleID && !isError && !isLoading && (
                <>
                    <div className='h-full'>
                        <div className='grid overflow-auto pr-2 pb-4'>
                            {pinnedArticles.length > 0 && (
                                <div>
                                    <div className='font-bold text-2xl my-6'>Pinned Articles</div>
                                    <div className='grid grid-cols-1 gap-5'>
                                        {pinnedArticles?.map((article: any, index: number) => (
                                            <div
                                                key={`pinned-article-${article.id}`}
                                                className={`flex ${
                                                    index % 2 === 1 ? 'flex-row-reverse' : ''
                                                } items-center border bg-neutral-50 border-lightgrey dark:bg-darkbg1 dark:border-none shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden pr-4`}
                                            >
                                                <button
                                                    onClick={() => handleSetArticle(article)}
                                                    className='h-full rounded w-2/5 shrink-0'
                                                >
                                                    <img
                                                        src={article.featured_image}
                                                        className='pl-4 py-4 object-cover max-h-[200px] h-full w-full rounded'
                                                    />
                                                </button>
                                                <div className='p-4 w-3/5'>
                                                    <button
                                                        className='text-lg font-semibold leading-tight text-left'
                                                        onClick={() => handleSetArticle(article)}
                                                    >
                                                        {article.title}
                                                    </button>
                                                    <div className='opacity-50'>{selectedDepartment.label}</div>

                                                    <div className='mt-1 leading-tight line-clamp-3 text-left text-sm'>
                                                        {article.excerpt}
                                                    </div>
                                                    <div className='mt-1 p-2 border border-t-0 border-x-0 dark:border-darkgrey'></div>
                                                    <div className='flex flex-row items-cente gap-2'>
                                                        <div className='mt-3.5 shrink-0 rounded-full flex items-center justify-center w-[36px] h-[36px] text-xl font-bold text-white dark:text-offwhite bg-orange-500'>
                                                            {article.user_firstName[0]}
                                                            {article.user_lastName[0]}
                                                        </div>
                                                        <div>
                                                            <div className='mt-4 font-semibold leading-none'>
                                                                {article.user_firstName} {article.user_lastName}
                                                            </div>
                                                            <div className='text-sm'>
                                                                {articleCardDateFormatter.format(
                                                                    new Date(article.created_at)
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {articles.length > 0 && (
                                <div className='pt-8'>
                                    <div className='font-bold text-2xl mb-8'>
                                        All {selectedDepartment.label} Articles
                                    </div>
                                    <div className='grid grid-cols-3 gap-5 pb-1'>
                                        {articles?.map((article: any, index: number) => (
                                            <div
                                                key={`article-${article.id}`}
                                                className='flex flex-col border bg-neutral-50 dark:bg-darkbg1 border-lightgrey dark:border-none shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden p-4'
                                            >
                                                <button onClick={() => handleSetArticle(article)}>
                                                    <img
                                                        src={article.featured_image}
                                                        className='object-cover max-h-[300px] h-full w-full rounded'
                                                    />
                                                </button>
                                                <div className='pt-4'>
                                                    {/* <div className='pt-2'> */}
                                                    <button
                                                        className='text-md font-semibold leading-tight text-left'
                                                        onClick={() => handleSetArticle(article)}
                                                    >
                                                        {article.title}
                                                    </button>
                                                    <div className='opacity-50 text-sm'>{selectedDepartment.label}</div>
                                                    <div className='mt-1 leading-tight line-clamp-3 text-left text-sm'>
                                                        {article.excerpt}
                                                    </div>
                                                    <div className='p-1 w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                                                    <div className='flex items-center gap-2'>
                                                        <div className='mt-3 flex shrink-0 rounded-full items-center justify-center w-[34px] h-[34px] text-lg font-bold text-white dark:text-offwhite bg-orange-500'>
                                                            {article?.user_firstName[0]}
                                                            {article?.user_lastName[0]}
                                                        </div>
                                                        <div>
                                                            <div className='mt-4 font-semibold text-sm leading-none'>
                                                                {article.user_firstName} {article.user_lastName}
                                                            </div>
                                                            <div className='mt-0.5 flex flex-row items-center gap-2'>
                                                                <div className='text-sm'>
                                                                    {articleCardDateFormatter.format(
                                                                        new Date(article.created_at)
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        {pinnedArticles.length === 0 && articles.length === 0 && (
                            <div className='text-2xl mt-6 ml-4 dark:text-offwhite'>
                                <p>This department currently has no articles </p>
                                {/* <p className='mt-5'>Select "Create a New Article" in the sidebar to make one!</p> */}
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    )
}
