import { useEffect, useRef, useState } from 'react'
import { sendToast, vFetch } from '../../helpers'

export default function NoteModal({ selectedOrders = [], setShowModal, setSelectedOrders }) {
    const [note, setNote] = useState('')
    const modalRef = useRef()
    const handleSubmit = async () => {
        for (let order of selectedOrders) {
            await vFetch(`/orders/note/${order}`, {
                method: 'POST',
                body: JSON.stringify({
                    note,
                }),
            })
        }
        sendToast({ message: 'Notes added!' })
        setShowModal(false)
        setSelectedOrders([])
    }
    useEffect(() => {
        const listenToWindow = (e) => {
            if (e.target === modalRef.current) {
                setShowModal(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed w-full h-full bg-[rgb(0,0,0,0.5)] left-0 top-0 grid place-items-center z-[50]'
        >
            <div className='p-[16px] bg-white dark:bg-darkaccent rounded-[4px] relative'>
                <button
                    onClick={() => setShowModal(false)}
                    className='text-[32px] leading-[1] font-bold absolute top-0 right-[8px] text-red dark:text-lightred'
                >
                    &times;
                </button>
                <h2 className='font-bold uppercase mb-[8px] dark:text-offwhite'>
                    Add Order Notes ({selectedOrders.length} order{selectedOrders.length !== 1 ? 's' : ''})
                </h2>
                <textarea
                    value={note}
                    onChange={({ target }) => setNote(target.value)}
                    className='dark:bg-darkness dark:text-offwhite p-[8px] rounded-[4px] border-none focus:outline-none w-[500px]'
                ></textarea>
                <button
                    onClick={handleSubmit}
                    className='py-[4px] px-[16px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkness font-bold block mx-auto'
                >
                    SUBMIT
                </button>
            </div>
        </div>
    )
}
