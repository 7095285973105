import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    authorAndTypeRegex,
    formatCSTTimestampToDate,
    formatISOToCSTTimestamp,
    sendToast,
    sortByAlphanumeric,
    taskIdRegex,
    timestampRegex,
    vFetch,
} from '../../helpers'
import { TaskEditInit } from '../../tasks/tasks.types'
import { OrderInit } from '../orders.types'

type NewNoteFieldProps = {
    order: OrderInit
    setOrder: Function
    tasks: TaskEditInit[]
    task_type?: string
    addNewTask?: Function
}

export default function NewNoteField({ order, setOrder, tasks, task_type, addNewTask }: NewNoteFieldProps) {
    const user = useSelector<any, any>((state) => state.user)
    const [submitAs, setSubmitAs] = useState<string>(task_type || '')
    const [newNote, setNewNote] = useState<string>('')

    useEffect(() => {
        const newNoteTextArea = document.getElementById('new_note') as HTMLElement
        const minHeight = parseInt(
            window.getComputedStyle(newNoteTextArea, null).getPropertyValue('min-height').replace('px', '')
        )

        if (newNoteTextArea.scrollHeight > minHeight) {
            newNoteTextArea.style.height = '0'
            newNoteTextArea.style.height = `${newNoteTextArea.scrollHeight + 2}px`
        }
    }, [newNote])
    useEffect(() => {
        setSubmitAs('')
    }, [tasks])

    const inputStyle =
        'bg-lightgrey dark:bg-darkness p-[4px] border-[1px] border-transparent focus:border-darkgrey focus:dark:border-accent outline-0 rounded'

    function handleSubmit() {
        if (!newNote) return sendToast({ message: 'Please enter a note before clicking submit.' })
        const oldNotes = (order.note || '').slice().trim()
        let addedNote = newNote.trim()

        const relatedTask = tasks.find((t) => t.task_type === submitAs)
        const timestampIncluded = addedNote.match(timestampRegex)
        const abbreviatedType = submitAs
            ? `${submitAs
                  .split(' ')
                  .filter((v) => v)
                  .map((w) => w[0].toUpperCase())
                  .join('')}`
            : ``

        const notesWithoutEndTimestamp = addedNote
            .slice(0, addedNote.length - (timestampIncluded?.slice(-1)[0].length || 0))
            .trim()
        const timestampRemoved =
            timestampIncluded &&
            (notesWithoutEndTimestamp.match(timestampRegex) || []).length !== timestampIncluded.length

        const originalTimestamp = timestampRemoved
            ? (timestampIncluded || [''])[(timestampIncluded || []).length - 1]
            : ''

        if (!timestampRemoved) {
            addedNote += ` ${formatISOToCSTTimestamp()} ${user.user_id} ${abbreviatedType}`
        } else if (originalTimestamp) {
            const [author, type] = (originalTimestamp.match(authorAndTypeRegex) || [''])[0]
                .trim()
                .split(' ')
                .filter((v) => v)
            const originalDate = formatCSTTimestampToDate(originalTimestamp)
            const timestampedToday =
                (originalDate.getFullYear() === new Date().getFullYear() &&
                    originalDate.getMonth() == new Date().getMonth() &&
                    originalDate.getDate() === new Date().getDate()) ||
                (originalDate.getTime() / 1000 > Date.now() - 600 && originalDate.getTime() / 1000 < Date.now() + 600)
            const timestampByOriginalUser = author.toLowerCase() === user.user_id.toLowerCase()
            if (timestampedToday && timestampByOriginalUser)
                addedNote =
                    notesWithoutEndTimestamp + ` ${formatISOToCSTTimestamp()} ${user.user_id} ${abbreviatedType}`
        }

        const noteTasks = tasks.map((t) =>
            [
                t.task_type
                    .split(' ')
                    .map((w) => w[0].toUpperCase())
                    .join(''),
                t.id,
            ].join(':')
        )
        if (
            abbreviatedType &&
            !noteTasks?.find((noteTask) => noteTask === `${abbreviatedType}:${relatedTask?.id}`) &&
            !abbreviatedType.match(/^[0-9]/)
        ) {
            noteTasks.push(`${abbreviatedType}:${relatedTask?.id}`)
        }
        const oldNotesWithoutTasks = oldNotes.replaceAll(taskIdRegex, '').trim()
        const orderNotes = [noteTasks.join('\n'), oldNotesWithoutTasks.trim(), addedNote].join('\n\n')

        vFetch(`/orders/${order.id}`, {
            method: 'POST',
            body: JSON.stringify({ note: orderNotes, task_id: relatedTask?.id, task_type: relatedTask?.task_type }),
            cb: (res: any) => {
                if (res.success) {
                    setNewNote('')
                    if (addNewTask) setSubmitAs('')
                    setOrder({ ...order, note: orderNotes })
                }
            },
        })
    }

    return (
        <div className='w-[100%] dark:bg-darkaccent relative bg-white rounded'>
            <textarea
                id='new_note'
                className='order-hub-screen__notes__textarea block min-h-[39px] h-[39px] w-[100%] p-[8px] max-w-none border-[1px] border-[#cdcdcd] rounded dark:focus:outline-[1px] dark:focus:outline dark:focus:outline-accent dark:bg-darkness dark:text-offwhite dark:border-blue shadow-md'
                name='new_order_note'
                value={newNote}
                placeholder='New order note...'
                onChange={({ target }: any) => setNewNote(target.value)}
                maxLength={4940 - String(order.note).length}
            />
            <div className='flex justify-between'>
                {tasks.length > 0 && tasks.every((t) => !!t.id) ? (
                    <>
                        {!task_type && (
                            <select
                                className={inputStyle + ' rounded-t-none'}
                                value={submitAs}
                                onChange={({ target }: any) => setSubmitAs(target.value)}
                            >
                                <option className='hidden' value=''>
                                    Submit as
                                </option>
                                {/* <option value={`${user.store_names[localStorage.getItem('store_id') || '']}`}>
                                    {`${user.store_names[localStorage.getItem('store_id') || '']}`}
                                </option> */}

                                {tasks
                                    .map((t) => t.task_type)
                                    .sort((a: any, b: any) => sortByAlphanumeric(a, b))
                                    .map((task_type) => (
                                        <option key={`submit-as-${task_type}`} value={task_type}>
                                            {task_type}
                                        </option>
                                    ))}
                            </select>
                        )}
                    </>
                ) : (
                    <>
                        {tasks.length > 0 && newNote && addNewTask && tasks.every((t) => !!t.id) && (
                            <button
                                className='px-[8px] py-[2px] uppercase text-fire border-[2px] border-fire font-semibold rounded'
                                onClick={() => addNewTask()}
                            >
                                Create Task
                            </button>
                        )}
                    </>
                )}
                {newNote && (
                    <button
                        className='block relative mr-0 ml-[auto] top-0 py-[4px] px-[8px] bg-blue text-white font-bold dark:bg-accent dark:text-darkness border-none text-center uppercase rounded-b cursor-pointer shadow-small'
                        onClick={handleSubmit}
                    >
                        Submit Note
                    </button>
                )}
            </div>
        </div>
    )
}
