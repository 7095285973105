import { ChangeEvent, useRef, useState } from 'react'
import { sendToast, vFetch } from '../../helpers'
import {
    VendorContactEditInit,
    VendorContactsMap,
    VendorInit,
    defaultVendor,
    defaultVendorContact,
} from '../vendors.types'
import VendorContactsList from './VendorContactsList'
import Input from '../../procurement/components/Input'
import { Button } from '../../custom_components/component_Basics/Button'
import {
    useCreateCompany,
    useCreateCompanyVendorMap,
    useImportInfoBlock,
    useSearchCompanies,
} from '../../procurement/api/useQueries'
import { LEAD_TYPE_OPTIONS, LIFECYCLE_STATUS_OPTIONS, defaultCompanyFilterParams } from '../../procurement/constants'
import { FaCaretUp, FaPen } from 'react-icons/fa'

type VendorsListProps = {
    vendorsInfo: VendorContactsMap
    expanded: string[]
    setVendorsInfo: Function
    setExpanded: Function
    setVendorToEdit: Function
    setVendorContactToEdit: Function
    refreshVendorsList: Function
}

export default function VendorsListCompanyMapping({
    expanded,
    vendorsInfo,
    setVendorsInfo,
    setExpanded,
    setVendorToEdit,
    setVendorContactToEdit,
    refreshVendorsList,
}: VendorsListProps) {
    const [expandedReturnPolicy, setExpandedReturnPolicy] = useState<string[]>([])
    const [editableFields, setEditableFields] = useState<any>({})
    const [vendorMapFields, setVendorMapFields] = useState<any>({})
    const [selectedCompany, setSelectedCompany] = useState<any>()
    const [companiesSearch, setCompaniesSearch] = useState<any>()
    const companiesSearchRef = useRef<HTMLInputElement>(null)
    const companiesRef = useRef<HTMLDivElement>(null)
    const [showCompanies, setShowCompanies] = useState(false)

    const preStyle = 'whitespace-pre-wrap font-normal font-bai text-[16px]'

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }
    const createCompany = useCreateCompanyVendorMap()

    function handleUpdateVendorMapFields(is_duplicate: 0 | 1, needs_review: 0 | 1, vendor: any) {
        vFetch(`/vendors/map`, {
            method: 'PUT',
            body: JSON.stringify({
                is_duplicate,
                needs_review,
                vendorName: vendor.name,
            }),
            cb: (res: any) => {
                refreshVendorsList()
            },
        })
    }
    const scoreOptionSelections = [
        { groupId: 2, optionId: 4 },
        { groupId: 1, optionId: 3 },
        { groupId: 3, optionId: 9 },
        { groupId: 4, optionId: 11 },
        { groupId: 5, optionId: 16 },
    ]
    const handleToggleCompanyEdit = () => {
        setShowCompanies((toggled) => {
            if (toggled) {
                return false
            }

            ;(companiesRef.current?.querySelector('input') as HTMLElement).focus()
            return true
        })
    }
    let params: any = {
        filters: {
            ...defaultCompanyFilterParams,
            lastCursor: undefined,
            assigned_to: [],
            lead_type: [],
            lifecycle_status: [],
            pageParam: 1,
            sortOption: undefined,
        },
    }

    const importInfoBlock = useImportInfoBlock()

    const companiesSearchQuery = useSearchCompanies({ ...params, search: companiesSearch })
    const { companies, lastCursor: lastCursorSearch } = companiesSearchQuery?.data || {}

    const filteredCompanies = companies
        ?.filter((company: any) => company)
        ?.filter((company: any) => {
            return company?.name.toLowerCase().includes(companiesSearch?.toLowerCase())
        })

    const linkToCompany = (vendor: any) => {
        vFetch(`/vendors/link`, {
            method: 'PUT',
            body: JSON.stringify({
                company_id: selectedCompany.id,
                vendorName: vendor.name,
            }),
            cb: (res: any) => {
                refreshVendorsList()
                setSelectedCompany(undefined)
            },
        })
    }
    const linkInfoToCompany = (vendor: any) => {
        importInfoBlock.mutate({ vendorName: vendor.name, companyId: selectedCompany.id })
    }
    const handleCreate = (vendor: any) => {
        const name = editableFields.name ?? vendor.name
        const vendor_name = editableFields.shopify_vendor_name ?? vendor.name
        if (!name || !vendor_name) {
            return sendToast({ message: 'name, vendor name required' })
        }
        createCompany.mutate(
            {
                editableCompany: {
                    logo: '',
                    name: name,
                    shopify_vendor_name: vendor_name,
                    product_type: '',
                    phone: editableFields.phone ?? '',
                    email: editableFields.email ?? '',
                    domain: editableFields.domain ?? '',
                    lead_type: 'vendor_import',
                    facebook: '',
                    instagram: '',
                    industry: '',
                    description: '',
                    city: '',
                    state: '',
                    zip: '',
                    timezone: 'America/Chicago',
                    number_of_employees: '',
                    lifecycle_status: editableFields.lifecycle_status ?? 'vendor_import',
                    linkedin: '',
                    notes: '',
                    competition_score: '',
                    reviews_score: '',
                    map_enforcement_score: '',
                    price_point_score: '',
                    refurbs_score: '',
                    accept_logo: '',
                    has_edi: '',
                    has_api: '',
                    store_id: '',
                    parent_company: '',
                },
                scoreOptionSelections,
            },
            {
                onSuccess: (data) => {
                    refreshVendorsList()
                    setEditableFields({})
                    setSelectedCompany(undefined)
                },
            }
        )
    }

    function clickHereToSetUpVendor(property: string, vendor: VendorInit, paddingLeft?: boolean) {
        return (
            <div className='p-[8px] border-b-[1px] border-darkgrey'>
                <span className={paddingLeft ? 'pl-[16px]' : ''}>
                    <strong>{property}</strong> has not been set up yet.
                </span>{' '}
                <span
                    className='text-blue dark:text-accent cursor-pointer hover:underline'
                    onClick={() => setVendorToEdit(vendor)}
                >
                    Click here to set it up.
                </span>
            </div>
        )
    }
    return (
        <div className='relative border-b-[1px] border-darkgrey'>
            {!Object.keys(vendorsInfo).length ? (
                <div className='flex dark:text-offwhite justify-center border-x-[1px] border-darkgrey border-t-0 p-[8px]'>
                    <h2 className='text-[20px] font-semibold'>No vendors found</h2>
                </div>
            ) : (
                Object.keys(vendorsInfo).map((v) => {
                    const vendor = vendorsInfo[v]
                    //come back and add real accordion style later
                    return (
                        <div
                            className={`grid duration-300 items-center text-[16px] border-darkgrey ${
                                expanded.includes(v)
                                    ? `h-full border-[2px] my-[10px] bg-[rgb(224,228,235)]/10 dark:bg-darkaccent/20`
                                    : `h-[40px] border-x-[1px] ${
                                          vendor.contacts.length > 0
                                              ? 'hover:bg-[rgb(199,202,209)]/75 [&:nth-child(even)]:bg-[rgb(224,228,235)]/75 [&:nth-child(even)]:hover:bg-[rgb(199,202,209)]/75 dark:[&:nth-child(even)]:bg-darkaccent/75 dark:[&:nth-child(even)]:hover:bg-blue/75 dark:hover:bg-blue/75'
                                              : 'text-white dark:text-offwhite bg-red [&:nth-child(even)]:bg-red/80 hover:bg-red/60 [&:nth-child(even)]:hover:bg-red/60'
                                      }`
                            }`}
                        >
                            <div
                                className={`relative flex flex-wrap items-center font-bold cursor-pointer ${
                                    expanded.includes(v)
                                        ? vendor.contacts.length > 0
                                            ? 'bg-[rgb(199,202,209)]/75 dark:bg-blue/50'
                                            : 'bg-red dark:bg-red/60'
                                        : ''
                                }`}
                            >
                                <div
                                    className='flex p-[8px] grow'
                                    onClick={() => {
                                        setExpanded(
                                            expanded.includes(v) ? expanded.filter((e) => e !== v) : [...expanded, v]
                                        )
                                    }}
                                >
                                    <div className='flex w-[16px]'>{expanded.includes(v) ? '-' : '+'}</div>
                                    <div className='min-w-[1000px] grid grid-cols-[2fr_1fr_1fr_1fr_1fr]'>
                                        <p>{v} </p>
                                        <p>Contacts: {vendor.contacts.length}</p>
                                        <p>Products: {vendor.product_count}</p>
                                        <p>Company ID: {vendor.company_id}</p>
                                        {vendor.has_company ? <p>COMPANY EXISTS</p> : <p></p>}
                                    </div>
                                </div>
                                {expanded.includes(v) && (
                                    <div className='flex gap-[8px] px-[8px] items-center flex-wrap'>
                                        {vendor ? (
                                            <button
                                                className='px-[8px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                                                onClick={() => setVendorToEdit(vendor)}
                                            >
                                                Edit Vendor
                                            </button>
                                        ) : (
                                            <button
                                                className='px-[8px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                                                onClick={() => setVendorToEdit({ ...defaultVendor, name: v })}
                                            >
                                                Create Vendor
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>

                            {expanded.includes(v) && (
                                <div>
                                    <div className='border-4 border-rose-900 p-3 flex flex-col gap-8'>
                                        <p>MAP SETUP FIELDS</p>
                                        <div className='flex gap-5'>
                                            <Input
                                                label='COMPANY NAME'
                                                id='name'
                                                name='name'
                                                onChange={handleChange}
                                                type='text'
                                                value={editableFields.name ?? v}
                                            />
                                            <Input
                                                label='SHOPIFY VENDOR NAME'
                                                id='shopify_vendor_name'
                                                name='shopify_vendor_name'
                                                onChange={handleChange}
                                                type='text'
                                                value={editableFields.shopify_vendor_name ?? v}
                                            />
                                        </div>
                                        <div className='flex gap-5'>
                                            <Input
                                                label='Domain'
                                                id='domain'
                                                name='domain'
                                                onChange={handleChange}
                                                type='text'
                                                value={editableFields.domain}
                                            />
                                            <Input
                                                id='phone'
                                                label='Phone'
                                                type='text'
                                                name='phone'
                                                onChange={handleChange}
                                                value={editableFields.phone}
                                            />
                                            <Input
                                                id='email'
                                                label='email'
                                                type='text'
                                                name='email'
                                                onChange={handleChange}
                                                value={editableFields.email}
                                            />
                                        </div>
                                        <div className='flex gap-3'>
                                            <div className='flex flex-col gap-[4px] w-full'>
                                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                    Lead Type
                                                </label>
                                                <select
                                                    autoComplete='off'
                                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                    id='lead_type'
                                                    name='lead_type'
                                                    onChange={handleChange}
                                                    value={editableFields.lead_type}
                                                >
                                                    {LEAD_TYPE_OPTIONS.map((leadType) => (
                                                        <option className='dark:bg-darkness/90' value={leadType.value}>
                                                            {leadType.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='flex flex-col gap-[4px] w-full'>
                                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                    Lifecycle stage
                                                </label>
                                                <select
                                                    autoComplete='off'
                                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                    id='lifecycle_status'
                                                    name='lifecycle_status'
                                                    onChange={handleChange}
                                                    value={editableFields.lifecycle_status}
                                                >
                                                    {LIFECYCLE_STATUS_OPTIONS.map((stage) => (
                                                        <option className='dark:bg-darkness/90' value={stage.value}>
                                                            {stage.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='flex gap-5'>
                                            <Button variant={'outline'} onClick={() => handleCreate(vendor)}>
                                                CREATE COMPANY
                                            </Button>
                                            <Button
                                                variant={'outline'}
                                                onClick={() => handleUpdateVendorMapFields(0, 1, vendor)}
                                            >
                                                REVIEW LATER
                                            </Button>
                                            <Button
                                                variant={'outline'}
                                                onClick={() => handleUpdateVendorMapFields(1, 0, vendor)}
                                            >
                                                HIDE DUPLICATE
                                            </Button>

                                            <div ref={companiesRef} className='relative ml-20'>
                                                <Input
                                                    id='compant'
                                                    name='company'
                                                    label='Company Link'
                                                    value={selectedCompany?.name ?? ''}
                                                    onChange={() => {}}
                                                    type='text'
                                                    readOnly={true}
                                                />
                                                <div className='flex gap-2 absolute top-[40%] right-[4px] z-[4] p-0 justify-center items-center'>
                                                    <button onClick={handleToggleCompanyEdit}>
                                                        {showCompanies ? (
                                                            <FaCaretUp className='text-darkgrey dark:text-offwhite p-0' />
                                                        ) : (
                                                            <FaPen className='text-blue dark:text-accent p-0' />
                                                        )}
                                                    </button>
                                                </div>
                                                {showCompanies && (
                                                    <div className='absolute z-[30] flex flex-col top-[100%] left-0 bg-white dark:bg-darkaccent w-full shadow-small border  border-darkgrey rounded-[4px] max-h-[250px] overflow-auto'>
                                                        <input
                                                            ref={companiesSearchRef}
                                                            className='bg-transparent p-[8px] focus:outline-none border-b border-lightgrey dark:border-darkgrey rounded-[4px]'
                                                            type='text'
                                                            placeholder='Search'
                                                            value={companiesSearch}
                                                            onChange={({ target }) => setCompaniesSearch(target.value)}
                                                        />

                                                        {filteredCompanies
                                                            ?.filter((v: any) => v)
                                                            .map((company: any) => (
                                                                <button
                                                                    onClick={() => {
                                                                        setSelectedCompany(company)
                                                                        setCompaniesSearch('')
                                                                        setTimeout(() => {
                                                                            setShowCompanies(false)
                                                                        }, 10)
                                                                    }}
                                                                    className='text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                                                >
                                                                    {company.name}
                                                                </button>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                            <Button variant={'outline'} onClick={() => linkToCompany(vendor)}>
                                                Link to existing company
                                            </Button>
                                            <Button variant={'outline'} onClick={() => linkInfoToCompany(vendor)}>
                                                Import Info to Company
                                            </Button>
                                        </div>
                                    </div>

                                    {/* ignore all of this  */}
                                    {/* ignore all of this  */}
                                    {/* ignore all of this  */}
                                    {/* ignore all of this  */}
                                    {/* ignore all of this  */}
                                    <div className='pointer-events-none'>
                                        {vendor.order_submission_url &&
                                        vendor.order_submission_url.toLowerCase() !== 'n/a' ? (
                                            <div className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}>
                                                <div className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                    <h3 className='font-bold text-[16px]'>Order Submission URL</h3>
                                                </div>
                                                <pre className={preStyle + ' p-[8px]'}>
                                                    {vendor.order_submission_url}
                                                </pre>
                                            </div>
                                        ) : (
                                            clickHereToSetUpVendor('Order submission URL', vendor)
                                        )}
                                        {vendor.order_submission_info &&
                                        vendor.order_submission_info.toLowerCase() !== 'n/a' ? (
                                            <div className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}>
                                                <div className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                    <h3 className='font-bold text-[16px]'>Order Submission Info</h3>
                                                </div>
                                                <pre className={preStyle + ' p-[8px]'}>
                                                    {vendor.order_submission_info}
                                                </pre>
                                            </div>
                                        ) : (
                                            clickHereToSetUpVendor('Order submission info', vendor)
                                        )}
                                        {vendor.payment_info ? (
                                            <div className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}>
                                                <div className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                    <h3 className='font-bold text-[16px]'>Payment Info</h3>
                                                </div>
                                                <pre className={preStyle + ' p-[8px]'}>{vendor.payment_info}</pre>
                                            </div>
                                        ) : (
                                            clickHereToSetUpVendor('Payment info', vendor)
                                        )}
                                        <div className={`flex flex-col p-[0px] border-darkgrey`}>
                                            <div
                                                className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px] cursor-pointer'
                                                onClick={() =>
                                                    setExpandedReturnPolicy(
                                                        expandedReturnPolicy.includes(v)
                                                            ? expandedReturnPolicy.filter((exV) => exV !== v)
                                                            : [...expandedReturnPolicy, v]
                                                    )
                                                }
                                            >
                                                <h3 className='flex font-bold text-[16px]'>
                                                    <span className='w-[16px]'>
                                                        {expandedReturnPolicy.includes(v) ? '-' : '+'}
                                                    </span>
                                                    Return Policy
                                                </h3>
                                            </div>
                                            {expandedReturnPolicy.includes(v) && (
                                                <div>
                                                    {vendor.return_instructions ? (
                                                        <div
                                                            className={`flex flex-col p-[0px] border-darkgrey border-b-[1px]`}
                                                        >
                                                            <div className='flex items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                                <h3 className='font-bold pl-[16px] text-[16px]'>
                                                                    Return Instructions
                                                                </h3>
                                                            </div>
                                                            <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                                {vendor.return_instructions}
                                                            </pre>
                                                        </div>
                                                    ) : (
                                                        clickHereToSetUpVendor('Return instructions', vendor, true)
                                                    )}
                                                    {vendor.return_policy_buyers_remorse ? (
                                                        <div
                                                            className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}
                                                        >
                                                            <div className='flex items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                                <h3 className='font-bold pl-[16px] text-[16px]'>
                                                                    Buyer's Remorse
                                                                </h3>
                                                            </div>
                                                            <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                                {vendor.return_policy_buyers_remorse}
                                                            </pre>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {clickHereToSetUpVendor("Buyer's Remorse", vendor, true)}
                                                        </div>
                                                    )}
                                                    {vendor.return_policy_doa ? (
                                                        <div
                                                            className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}
                                                        >
                                                            <div className='items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                                <h3 className='font-bold pl-[16px] text-[16px]'>DOA</h3>
                                                            </div>
                                                            <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                                {vendor.return_policy_doa}
                                                            </pre>
                                                        </div>
                                                    ) : (
                                                        <div>{clickHereToSetUpVendor('DOA', vendor, true)}</div>
                                                    )}
                                                    {vendor.return_policy_warranty_repair ? (
                                                        <div
                                                            className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}
                                                        >
                                                            <div className='flex items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                                <h3 className='font-bold pl-[16px] text-[16px]'>
                                                                    Warranty
                                                                </h3>
                                                            </div>
                                                            <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                                {vendor.return_policy_warranty_repair}
                                                            </pre>
                                                        </div>
                                                    ) : (
                                                        <div>{clickHereToSetUpVendor('Warranty', vendor, true)}</div>
                                                    )}
                                                    {vendor.additional_info && (
                                                        <div
                                                            className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}
                                                        >
                                                            <div className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                                <h3 className='font-bold text-[16px] pl-[16px]'>
                                                                    Additional Info
                                                                </h3>
                                                            </div>
                                                            <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                                {vendor.additional_info}
                                                            </pre>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {vendor.contacts.length > 0 ? (
                                            <>
                                                <div className='flex justify-between items-center border-b-[1px] border-darkgrey w-[100%] p-[8px]'>
                                                    <h3 className='font-bold text-[16px]'>Contacts</h3>
                                                    <button
                                                        className='flex gap-[3px] items-center px-[8px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                                                        onClick={() =>
                                                            setVendorContactToEdit({
                                                                ...defaultVendorContact,
                                                                vendor: v,
                                                            })
                                                        }
                                                    >
                                                        <div></div>+
                                                        <span className='inline-block truncate max-w-[150px]'>{v}</span>
                                                        contact
                                                    </button>
                                                </div>
                                                <VendorContactsList
                                                    vendorsInfo={vendorsInfo}
                                                    setVendorsInfo={setVendorsInfo}
                                                    contacts={vendor.contacts}
                                                    setVendorContactToEdit={setVendorContactToEdit}
                                                    refreshVendorsList={refreshVendorsList}
                                                />
                                            </>
                                        ) : (
                                            <div className='flex justify-between items-center border-b-[1px] border-darkgrey w-[100%] p-[8px]'>
                                                <h3 className='font-bold text-[16px]'>Contacts</h3>
                                                <button
                                                    className='flex gap-[3px] items-center px-[8px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                                                    onClick={() =>
                                                        setVendorContactToEdit({
                                                            ...defaultVendorContact,
                                                            vendor: v,
                                                        })
                                                    }
                                                >
                                                    <div></div>+
                                                    <span className='inline-block truncate max-w-[150px]'>{v}</span>
                                                    contact
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    {/* ignore all of this  */}
                                    {/* ignore all of this  */}
                                    {/* ignore all of this  */}
                                </div>
                            )}
                        </div>
                    )
                })
            )}
        </div>
    )
}
