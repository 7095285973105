import { useContext, useEffect } from 'react'
import { BiHappyBeaming } from 'react-icons/bi'
import { BsCircle, BsCircleFill, BsClock, BsClockFill, BsFillBellFill } from 'react-icons/bs'
import { RiCloseCircleLine } from 'react-icons/ri'
import { Link, useLocation } from 'react-router-dom'
import { WebSocketContext, vFetch } from '../helpers'

export default function Notifications({ notificationsRef, showNotifications, setShowNotifications }) {
    const { notifications, setNotifications, setPathname } = useContext(WebSocketContext)
    const location = useLocation()

    useEffect(() => {
        setPathname(location.pathname)
    }, [location.pathname])

    const handleDeleteNotification = (notification) => {
        const initialState = [...notifications]
        setNotifications((prev) => {
            return prev.filter((p) => p.id !== notification.id)
        })
        vFetch(`/notifications/${notification.id}`, {
            method: 'DELETE',
            cb: (res) => {
                if (!res.success) {
                    setNotifications(initialState)
                }
            },
        })
    }
    const handleChangeMarkAsRead = (notification, forceRead) => {
        const initialState = { ...notification }
        setNotifications((prev) => {
            const newArr = [...prev]
            const currentIndex = newArr.findIndex((n) => n.id === notification.id)
            newArr[currentIndex] = {
                ...newArr[currentIndex],
                marked_as_read: forceRead ? 1 : notification.marked_as_read == 1 ? 0 : 1,
                remind_daily: notification.remind_daily,
                email_sent: notification.email_sent,
            }
            return newArr
        })
        vFetch(`/notifications/${notification.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                marked_as_read: forceRead ? 1 : notification.marked_as_read == 1 ? 0 : 1,
                remind_daily: notification.remind_daily,
                email_sent: notification.email_sent,
            }),
            cb: (res) => {
                if (res.success) {
                } else {
                    setNotifications((prev) => {
                        const newArr = [...prev]
                        const currentIndex = newArr.findIndex((n) => n.id === notification.id)
                        newArr[currentIndex] = {
                            ...initialState,
                        }
                        return newArr
                    })
                }
            },
            catchCb: (err) => {
                setNotifications((prev) => {
                    const newArr = [...prev]
                    const currentIndex = newArr.findIndex((n) => n.id === notification.id)
                    newArr[currentIndex] = {
                        ...initialState,
                    }
                    return newArr
                })
            },
        })
    }
    const handleMarkAllAsRead = () => {
        notifications.forEach((n) => handleChangeMarkAsRead(n, true))
    }
    const handleChangeEmailReminders = (notification) => {
        const initialState = { ...notification }
        setNotifications((prev) => {
            const newArr = [...prev]
            const currentIndex = newArr.findIndex((n) => n.id === notification.id)
            newArr[currentIndex] = {
                ...newArr[currentIndex],
                marked_as_read: notification.marked_as_read,
                remind_daily: notification.remind_daily == 1 ? 0 : 1,
                email_sent: notification.email_sent,
            }
            return newArr
        })
        vFetch(`/notifications/${notification.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                marked_as_read: notification.marked_as_read,
                remind_daily: notification.remind_daily == 1 ? 0 : 1,
                email_sent: notification.email_sent,
            }),
            cb: (res) => {
                if (res.success) {
                } else {
                    setNotifications((prev) => {
                        const newArr = [...prev]
                        const currentIndex = newArr.findIndex((n) => n.id === notification.id)
                        newArr[currentIndex] = {
                            ...initialState,
                        }
                        return newArr
                    })
                }
            },
            catchCb: (err) => {
                setNotifications((prev) => {
                    const newArr = [...prev]
                    const currentIndex = newArr.findIndex((n) => n.id === notification.id)
                    newArr[currentIndex] = {
                        ...initialState,
                    }
                    return newArr
                })
            },
        })
    }
    return (
        <div ref={notificationsRef} className='relative'>
            <BsFillBellFill
                size={'25px'}
                className='fill-accent1 dark:fill-darkaccent1 cursor-pointer'
                onClick={() => setShowNotifications((prev) => !prev)}
            />
            {notifications &&
                notifications.length > 0 &&
                notifications.filter((n) => n.marked_as_read == 0).length > 0 && (
                    <div className='pointer-events-none bg-red border-2 dark:bg-white shadow-[0_0_5px_white] dark:border-darkaccent border-white absolute top-0 right-0 w-[12px] h-[12px] rounded-full'>
                        &nbsp;
                    </div>
                )}
            {showNotifications && notifications && (
                <div className='absolute w-[400px] max-h-[400px] overflow-auto right-[-100px] top-[175%] bg-white dark:bg-darkaccent rounded-[4px] p-[8px] shadow-small'>
                    {notifications.map((n, index) => (
                        <div
                            className={`flex items-center gap-[8px] my-[4px] rounded-[4px] p-[4px] text-[14px] ${
                                n.marked_as_read == 0
                                    ? 'bg-[rgb(0,60,90,0.1)] font-bold'
                                    : 'bg-[whitesmoke] dark:bg-darkness/50 dark:hover:bg-darkness hover:bg-lightgrey'
                            }`}
                        >
                            <button className='p-[4px]' onClick={() => handleChangeMarkAsRead(n)}>
                                <BsCircle
                                    className={`text-blue w-[8px] h-[8px] ${n.marked_as_read == 1 ? '' : 'hidden'}`}
                                />
                                <BsCircleFill
                                    className={`text-blue w-[8px] h-[8px] ${n.marked_as_read == 0 ? '' : 'hidden'}`}
                                />
                            </button>
                            {n.url ? (
                                <Link
                                    onClick={() => {
                                        setShowNotifications(false)
                                        handleChangeMarkAsRead(n, true)
                                    }}
                                    to={n.url}
                                >
                                    {n.message}
                                </Link>
                            ) : (
                                <p
                                    onClick={() => {
                                        handleChangeMarkAsRead(n, true)
                                    }}
                                >
                                    {n.message}
                                </p>
                            )}
                            <button
                                className='ml-auto text-[20px] leading-[1px] p-[4px] mr-[-4px]'
                                onClick={() => handleChangeEmailReminders(n)}
                            >
                                <BsClock
                                    className={`text-blue w-[16px] h-[16px] ${n.remind_daily == 0 ? '' : 'hidden'}`}
                                />
                                <BsClockFill
                                    className={`text-blue w-[16px] h-[16px] ${n.remind_daily == 1 ? '' : 'hidden'}`}
                                />
                            </button>
                            <button className='text-[20px] leading-[1px]' onClick={() => handleDeleteNotification(n)}>
                                <RiCloseCircleLine className='text-blue' />
                            </button>
                        </div>
                    ))}
                    {notifications.length === 0 && (
                        <div className='flex items-center justify-center gap-[4px] p-[4px] bg-[rgb(0,60,90,0.1)] text-blue w-full text-center font-bold'>
                            <p>You're all caught up!</p>
                            <BiHappyBeaming className='w-[24px] h-[24px] text-blue' />
                        </div>
                    )}
                    {notifications.length > 0 && notifications.filter((n) => n.marked_as_read == 0).length > 0 && (
                        <button
                            onClick={handleMarkAllAsRead}
                            className='p-[4px] bg-blue text-white w-full text-center font-bold'
                        >
                            Mark all as read
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}
