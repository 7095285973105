import { useEffect, useState } from 'react'
import { LoadingGear, useDebounce, vFetch } from '../helpers/index'
import {
    CollectionProcessingTimesRow,
    ProcessingFilterType,
    ProcessingTimesRow,
    ProcessingTimesView,
    ProductProcessingTimesRow,
    VariantProcessingTimesRow,
} from './ProcessingTimesScreen.types'
import { FaSyncAlt } from 'react-icons/fa'
import MessageList from './components/messages/MessageList'
import Pagination from './components/Pagination'
import ProductList from './components/products/ProductList'
import MessageModal from './components/messages/MessageModal'
import ProductModal from './components/products/ProductModal'
import CollectionList from './components/collections/CollectionList'
import CollectionModal from './components/collections/CollectionModal'
import VariantList from './components/variants/VariantList'
import VariantModal from './components/variants/VariantModal'
import Refresh from './components/Refresh'
import MoreActions from './components/MoreActions'

export default function ProcessingTimesScreen() {
    // TODO: FIX TYPE OF ROUTE REDUCER
    const [messages, setMessages] = useState<ProcessingTimesRow[]>([])
    const [productProcessingTimes, setProductProcessingTimes] = useState<ProductProcessingTimesRow[]>([])
    const [collectionProcessingTimes, setCollectionProcessingTimes] = useState<CollectionProcessingTimesRow[]>([])
    const [variantProcessingTimes, setVariantProcessingTimes] = useState<VariantProcessingTimesRow[]>([])
    const [search, setSearch] = useState('')
    const [view, setView] = useState<ProcessingTimesView>('messages')
    const [filter, setFilter] = useState<ProcessingFilterType>('')
    const [selectedList, setSelectedList] = useState<any[]>([])
    const [page, setPage] = useState(1)
    const [resetPage, setResetPage] = useState<boolean>(false)
    const [selectedProcessingTime, setSelectedProcessingTime] = useState<ProcessingTimesRow | null>(null)
    const [needsRefresh, setNeedsRefresh] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(true)
    const [selectedProduct, setSelectedProduct] = useState<ProductProcessingTimesRow | null>(null)
    const [selectedCollection, setSelectedCollection] = useState<CollectionProcessingTimesRow | null>(null)
    const [selectedVariant, setSelectedVariant] = useState<VariantProcessingTimesRow | null>(null)
    const debouncedSearch = useDebounce(search, 300)
    const abortController = new AbortController()
    useEffect(() => {
        if (view === 'products') {
            setLoading(true)
            getProducts(abortController).then((res) => {
                setProductProcessingTimes(res)
                setSelectedList(res)
                setNeedsRefresh(false)
                setLoading(false)
            })
        } else if (view === 'collections') {
            setLoading(true)
            getCollections(abortController).then((res) => {
                setCollectionProcessingTimes(res)
                setSelectedList(res)
                setNeedsRefresh(false)
                setLoading(false)
            })
        } else if (view === 'variants') {
            setLoading(true)
            getVariants(abortController).then((res) => {
                setVariantProcessingTimes(res)
                setSelectedList(res)
                setNeedsRefresh(false)
                setLoading(false)
            })
        }
    }, [debouncedSearch])
    useEffect(() => {
        if (needsRefresh) {
            setLoading(true)
            if (view === 'messages') {
                getMessages(abortController).then((res) => {
                    setMessages(() => res)
                    setSelectedList(res)
                    setNeedsRefresh(false)
                    setLoading(false)
                })
            } else if (view === 'products') {
                getProducts(abortController).then((res) => {
                    setProductProcessingTimes(res)
                    setSelectedList(res)
                    setNeedsRefresh(false)
                    setLoading(false)
                })
            } else if (view === 'collections') {
                getCollections(abortController).then((res) => {
                    setCollectionProcessingTimes(res)
                    setSelectedList(res)
                    setNeedsRefresh(false)
                    setLoading(false)
                })
            } else if (view === 'variants') {
                getVariants(abortController).then((res) => {
                    setVariantProcessingTimes(res)
                    setSelectedList(res)
                    setNeedsRefresh(false)
                    setLoading(false)
                })
            } else {
                setLoading(false)
            }
        }
        return () => abortController.abort()
    }, [view, needsRefresh])

    useEffect(() => {
        setFilter('')
        setNeedsRefresh(true)
    }, [view])

    /* ----- GETTERS ----- */

    /**
     * Returns a promise containing a list of all messages or an empty list
     */

    async function getMessages(abortController: AbortController): Promise<ProcessingTimesRow[]> {
        return (await vFetch('/processingTimes', {
            cb: (res: any) => res.processingTimes || ([] as ProcessingTimesRow[]),
            catchCb: () => [],
            abortController,
        })) as ProcessingTimesRow[]
    }
    /**
     * Returns a promise containing a list of active product message information or an empty list
     */
    async function getProducts(abortController: AbortController): Promise<ProductProcessingTimesRow[]> {
        return (await vFetch(`/processingTimes/products?limit=250&search=${search.toLowerCase()}`, {
            cb: (res: any) => res.productProcessingTimes || [],
            catchCb: () => [],
            abortController,
        })) as ProductProcessingTimesRow[]
    }

    async function getCollections(abortController: AbortController): Promise<CollectionProcessingTimesRow[]> {
        return (await vFetch(`/processingTimes/collections?limit=250&search=${search.toLowerCase()}`, {
            cb: (res: any) => res.collectionProcessingTimes || [],
            catchCb: () => [],
            abortController,
        })) as CollectionProcessingTimesRow[]
    }
    async function getVariants(abortController: AbortController): Promise<VariantProcessingTimesRow[]> {
        return (await vFetch(`/processingTimes/variants?limit=250&search=${search.toLowerCase()}`, {
            cb: (res: any) => res.variantProcessingTimes || [],
            catchCb: () => [],
            abortController,
        })) as VariantProcessingTimesRow[]
    }
    const syncQueryParams = view === 'messages' ? `?products=true&variants=true&collections=true` : `?${view}=true`
    const options = [
        {
            displayText: `Sync ${view} with Shopify`,
            icon: FaSyncAlt,
            action: () => {
                const confirm = window.confirm('Are you sure?')
                if (confirm) {
                    vFetch(`/processingTimes/sync${syncQueryParams}`)
                }
            },
        },
    ]
    return (
        <>
            <div className='flex justify-between mb-[8px]'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Processing Times</h1>
                <div className='flex gap-[8px]'>
                    <MoreActions options={options} />
                    <Refresh setNeedsRefresh={setNeedsRefresh} />
                </div>
            </div>
            <div className='flex gap-[4px] mb-[8px]'>
                <button
                    onClick={() => setView('messages')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'messages'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Messages
                </button>
                <button
                    onClick={() => setView('products')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'products'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-offwhite dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Products
                </button>
                <button
                    onClick={() => setView('collections')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'collections'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-offwhite dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Collections
                </button>
                <button
                    onClick={() => setView('variants')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'variants'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-offwhite dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Variants
                </button>
                <button
                    onClick={() =>
                        setSelectedProcessingTime({
                            type: 'PDP',
                        } as ProcessingTimesRow)
                    }
                    className='ml-auto py-[4px] px-[16px] bg-blue text-white rounded-[4px] dark:bg-accent dark:text-darkness font-bold uppercase'
                >
                    + New Message
                </button>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <div className='flex justify-between border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
                    <div className='w-full p-[8px] flex'>
                        <input
                            type='text'
                            className='w-full bg-transparent dark:text-white focus:outline-none'
                            placeholder='Search'
                            value={search}
                            onChange={({ target }) => {
                                setSearch(target.value)
                                setResetPage(true)
                            }}
                        />
                    </div>
                    {view === 'messages' && (
                        <div className='flex gap-[8px] items-center text-[14px] text-darkgrey'>
                            {(filter !== '' || search.length > 0) && (
                                <button
                                    onClick={() => {
                                        setFilter('')
                                        setSearch('')
                                    }}
                                    className={`rounded-[4px] font-bold py-[4px] px-[8px] bg-red dark:bg-lightred text-white`}
                                >
                                    CLEAR
                                </button>
                            )}
                            <button
                                onClick={() => setFilter('PDP')}
                                className={`rounded-[99vw] font-bold py-[4px] px-[8px] ${
                                    filter === 'PDP'
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                }`}
                            >
                                PDP
                            </button>
                            <button
                                onClick={() => setFilter('CART')}
                                className={`rounded-[99vw] font-bold py-[4px] px-[8px] ${
                                    filter === 'CART'
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                }`}
                            >
                                CART
                            </button>
                            <button
                                onClick={() => setFilter('STARTING')}
                                className={`rounded-[99vw] font-bold py-[4px] px-[8px] ${
                                    filter === 'STARTING'
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                }`}
                            >
                                STARTING
                            </button>
                            <button
                                onClick={() => setFilter('ENDING')}
                                className={`rounded-[99vw] font-bold py-[4px] px-[8px] ${
                                    filter === 'ENDING'
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                }`}
                            >
                                ENDING
                            </button>
                            <button
                                onClick={() => setFilter('RECENT')}
                                className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                                    filter === 'RECENT'
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                }`}
                            >
                                RECENTLY EDITED
                            </button>
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className='w-full h-[40vw] grid place-items-center shadow-small bg-white dark:bg-darkaccent'>
                        <LoadingGear />
                    </div>
                ) : (
                    <>
                        {view === 'messages' && (
                            <MessageList
                                messages={messages}
                                setSelectedProcessingTime={setSelectedProcessingTime}
                                search={search}
                                filter={filter}
                                page={page}
                            />
                        )}
                        {view === 'products' && (
                            <ProductList
                                productProcessingTimes={productProcessingTimes}
                                setSelectedProduct={setSelectedProduct}
                                search={search}
                                filter={filter}
                                page={page}
                            />
                        )}
                        {view === 'collections' && (
                            <CollectionList
                                collectionProcessingTimes={collectionProcessingTimes}
                                setSelectedCollection={setSelectedCollection}
                                search={search}
                                filter={filter}
                                page={page}
                            />
                        )}
                        {view === 'variants' && (
                            <VariantList
                                variantProcessingTimes={variantProcessingTimes}
                                setSelectedVariant={setSelectedVariant}
                                search={search}
                                filter={filter}
                                page={page}
                            />
                        )}
                    </>
                )}
            </div>
            <MessageModal
                selectedProcessingTime={selectedProcessingTime}
                setSelectedProcessingTime={setSelectedProcessingTime}
                setNeedsRefresh={setNeedsRefresh}
            />
            <ProductModal
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                setNeedsRefresh={setNeedsRefresh}
            />
            <CollectionModal
                selectedCollection={selectedCollection}
                setSelectedCollection={setSelectedCollection}
                setNeedsRefresh={setNeedsRefresh}
            />
            <VariantModal
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                setNeedsRefresh={setNeedsRefresh}
            />
            <Pagination
                page={page}
                setPage={setPage}
                list={selectedList}
                resetPage={resetPage}
                setResetPage={setResetPage}
            />
        </>
    )
}
