import { useContext } from 'react'
import {
    useCompanyContactsQuery,
    useCompanyDetailTasksQuery,
    useCompanyEmailsQuery,
    useCompanyFilesQuery,
    useCompanyIncomingEmailsQuery,
    useCompanyNotesQuery,
    useTasksQuery,
} from '../../api/useQueries'
import { DATE_TODAY, defaultTaskFilterParams } from '../../constants'
import { MONTH_NAME_MAP, ProcurementContext, ProcurementContextType, createdAtSort } from '../../helpers'
import BasicEvent from '../listItems/BasicEvent'
import BasicEventGroup from '../listItems/BasicEventGroup'
import BasicEventSkeleton from '../listItems/BasicEventSkeleton'
import CompanyEmail from '../listItems/CompanyEmail'
import CompanyNoteV2 from '../listItems/CompanyNoteV2'
import IncomingEmail from '../listItems/IncomingEmail'
import TaskListItem from '../listItems/TaskListItem'
import { CompanyTaskType } from '../modals/quickActions/CreateTask'

export default function ActivityListV2({ company }: any) {
    const { selectedCompany, users } = useContext<ProcurementContextType>(ProcurementContext)
    const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    const companyContactsQuery = useCompanyContactsQuery({ companyId: selectedCompany.id })
    const { isLoading: loadingContacts } = companyContactsQuery
    const { contacts } = companyContactsQuery.data || {}

    const companyEventsQuery = useCompanyNotesQuery({ companyId: selectedCompany.id, users })
    const { isLoading: loadingEvents } = companyEventsQuery
    const { events }: { events: EventType[] } = companyEventsQuery?.data || {}

    const companyTasksQuery = useCompanyDetailTasksQuery({ companyId: selectedCompany.id })
    const { isLoading: loadingTasks } = companyTasksQuery
    const { tasks }: { tasks: CompanyTaskType[] } = companyTasksQuery?.data || {}

    const companyIncomingEmailsQuery = useCompanyIncomingEmailsQuery({
        companyId: company.id,
        companyEmail: company.email,
        contactList: contacts || [],
    })
    const { isLoading: loadingIncomingEmails } = companyIncomingEmailsQuery
    const { emails: incomingEmails }: { emails: any[] } = companyIncomingEmailsQuery?.data || {}

    const companyEmailsQuery = useCompanyEmailsQuery({ companyId: selectedCompany.id })
    const { isLoading: loadingEmails } = companyEmailsQuery
    const { emails }: { emails: any[] } = companyEmailsQuery?.data || {}

    const isLoading = loadingContacts || loadingEvents || loadingTasks || loadingIncomingEmails || loadingEmails

    // seed cache for other tabs
    const tasksTabPrefetch = useTasksQuery({
        filters: {
            ...defaultTaskFilterParams,
            company_id: selectedCompany.id,
            statuses: ['OPEN'],
            pageParam: 1,
            selectedTaskDueDate: DATE_TODAY.toISOString(),
        },
        search: '',
    })
    const companyFilesQuery = useCompanyFilesQuery({ companyId: selectedCompany.id })
    //

    let activities: EventType[] = []
    if (events) {
        activities = [...events]
    }
    if (tasks) {
        activities = [
            ...activities,
            ...tasks?.map((task: CompanyTaskType) => ({ ...task, type: 'task' }) as any as EventType),
        ]
    }
    if (emails) {
        activities = [...activities, ...emails?.map((email) => ({ ...email, type: 'email' }) as any as EventType)]
    }
    if (incomingEmails) {
        activities = [
            ...activities,
            ...incomingEmails?.map((email) => ({ ...email, type: 'incoming-email' }) as any as EventType),
        ]
    }
    activities = activities.sort(createdAtSort)

    const tempMap: any = {}
    for (const event of activities) {
        const createdAtDate = new Date(event.created_at || (event as any).delivered_at)
        const key = `${
            MONTH_NAME_MAP[createdAtDate.getMonth() as keyof typeof MONTH_NAME_MAP]
        } ${createdAtDate.getFullYear()}`
        if (!tempMap[key]) {
            tempMap[key] = []
        }
        tempMap[key].push(event)
    }
    const monthMap = tempMap

    return (
        <div className='p-[16px] pl-0 overflow-auto'>
            {isLoading && (
                <div className='flex flex-col gap-3'>
                    {skeletons.map((skeleton) => (
                        <BasicEventSkeleton key={skeleton} />
                    ))}
                </div>
            )}
            {!isLoading && (
                <div className='flex flex-col gap-[8px]'>
                    {Object.entries(monthMap)?.map(([key, value]: any) => (
                        <div key={key + value} className='flex flex-col gap-[8px]'>
                            <div
                                key={key}
                                className='max-w-full text-[18px] p-[8px] border-b border-darklightgrey dark:border-darkgrey mb-[8px]'
                            >
                                {key}
                            </div>
                            {value.map((event: any) => {
                                if (event.type === 'note') return <CompanyNoteV2 key={event.id} note={event} />
                                if (event.type === 'task')
                                    return (
                                        <TaskListItem
                                            key={event.id}
                                            task={event as any as CompanyTaskType}
                                            urlParamTaskId={undefined}
                                        />
                                    )
                                if (event.type === 'email') {
                                    return <CompanyEmail key={event.id} email={event} />
                                }
                                if (event.type === 'incoming-email') {
                                    return <IncomingEmail key={event.thread_id} email={event} />
                                }
                                if (event.type === 'basic-event-group') {
                                    return <BasicEventGroup key={event.created_at + event.events[0].id} event={event} />
                                }
                                return <BasicEvent key={event.id} event={event} />
                            })}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
