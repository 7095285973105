import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { TailSpin } from 'react-loading-icons'
import { AiFillCaretDown } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import SalesTimeFilters from './Components/SalesTimeFilters'
import { closePopout, fetchActiveUsers, formatMoney, sendToast, userShopifyRepName, vFetch } from '../helpers'
import { SalesGoalInit } from './sales.types'
import { UserlistDropdown } from '../custom_components/UserlistDropdown'
import { UserInit } from '../users/users.types'

export default function GoalsScreen() {
    const goalRef = useRef<HTMLDivElement>(null)
    const assignRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const user = useSelector<any, any>((state) => state.user)
    const mFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    if (user.type !== 'Admin' && user.type !== 'SuperAdmin') {
        navigate('/sales')
    }

    const [loading, setLoading] = useState<boolean>(false)
    const [goals, setGoals] = useState<SalesGoalInit[]>([])
    const [repName, setRepName] = useState<string>('Show All')
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), 0, 1))
    const [endDate, setEndDate] = useState<Date>(new Date(new Date().getFullYear(), 12, 0, 23, 59, 59, 999))
    const [sortMode, setSortMode] = useState<string>('type')

    const [salespeople, setSalespeople] = useState<UserInit[]>([])
    const [showSalespeople, setShowSalespeople] = useState<boolean>(false)
    const [showCreateGoal, setShowCreatingGoal] = useState<boolean>(false)
    const [goalButton, setGoalButton] = useState<string>('Add Goal')
    const [id, setId] = useState<number>(0)
    const [assignedTo, setAssignedTo] = useState<string[]>([])
    const [assignToTeam, setAssignToTeam] = useState<boolean>(false)
    const [goalPeriod, setGoalPeriod] = useState<string>('monthly')
    const years: number[] = []
    for (let i = new Date().getFullYear(); i < new Date().getFullYear() + 10; i++) {
        years.push(i)
    }
    const quarters: number[] = []
    for (let i = 1; i <= 4; i++) {
        quarters.push(i)
    }
    const months: any = {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December',
    }
    const [sYear, setSYear] = useState<number>(new Date().getFullYear())
    const [eYear, setEYear] = useState<number>(new Date().getFullYear())
    const [quarter, setQuarter] = useState<number>(Math.ceil((new Date().getMonth() + 1) / 3))
    const [sMonth, setSMonth] = useState<number>(new Date().getMonth() + 1)
    const [eMonth, setEMonth] = useState(new Date().getMonth() + 1)
    const [sDays, setSDays] = useState<number[]>([])
    const [eDays, setEDays] = useState<number[]>([])
    const [sDay, setSDay] = useState<number>(1)
    const [eDay, setEDay] = useState<number>(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate())
    const [orderTotal, setOrderTotal] = useState<number>()
    const [orderVolume, setOrderVolume] = useState<number>()

    useEffect(() => {
        const listenToWindow = (e: any) => {
            if (goalRef.current === e.target) {
                setShowCreatingGoal(false)
            }
            if (!assignRef.current?.contains(e.target as Element)) {
                setShowSalespeople(false)
            }
        }

        window.addEventListener('click', listenToWindow)
        return () => {
            window.removeEventListener('click', listenToWindow)
        }
    }, [])

    function refreshGoals() {
        setLoading(true)
        fetchActiveUsers(setSalespeople, ['sales'])

        vFetch(`/sales`, {
            cb: (res: any) => {
                if (res.success) {
                    const stripDate = (ISOString: any) => ISOString.split('T')[0].split('-')
                    const goals = res.goals.map((g: any) => {
                        const daysLong = Math.round(
                            (new Date(
                                stripDate(g.end_date)[0],
                                stripDate(g.end_date)[1] - 1,
                                stripDate(g.end_date)[2],
                                23,
                                59,
                                59,
                                999
                            ).getTime() -
                                new Date(
                                    stripDate(g.start_date)[0],
                                    stripDate(g.start_date)[1] - 1,
                                    stripDate(g.start_date)[2]
                                ).getTime()) /
                                86400000
                        )

                        const tempGoal = {
                            ...g,
                            start_date: new Date(
                                stripDate(g.start_date)[0],
                                stripDate(g.start_date)[1] - 1,
                                stripDate(g.start_date)[2]
                            ),
                            end_date: new Date(
                                stripDate(g.end_date)[0],
                                stripDate(g.end_date)[1] - 1,
                                stripDate(g.end_date)[2],
                                23,
                                59,
                                59,
                                999
                            ),
                        }
                        tempGoal.type =
                            daysLong >= 365 &&
                            daysLong <= 366 &&
                            tempGoal.start_date.getMonth() === 0 &&
                            tempGoal.start_date.getDate() === 1
                                ? 'Year'
                                : daysLong >= 89 &&
                                    daysLong <= 92 &&
                                    tempGoal.start_date.getDate() === 1 &&
                                    (tempGoal.start_date.getMonth() === 0 ||
                                        tempGoal.start_date.getMonth() === 3 ||
                                        tempGoal.start_date.getMonth() === 6 ||
                                        tempGoal.start_date.getMonth() === 9)
                                  ? 'Quarter'
                                  : daysLong >= 28 && daysLong <= 31 && tempGoal.start_date.getDate() === 1
                                    ? 'Month'
                                    : 'Spec.'

                        return tempGoal
                    })

                    setGoals(
                        goals.filter((g: any) => {
                            if (
                                (g.start_date.getTime() >= startDate.getTime() && g.end_date <= endDate.getTime()) ||
                                (g.start_date.getTime() >= startDate.getTime() &&
                                    g.start_date.getTime() <= endDate.getTime()) ||
                                (g.end_date.getTime() >= startDate.getTime() &&
                                    g.end_date.getTime() <= endDate.getTime())
                            ) {
                                return repName === 'Show All'
                                    ? true
                                    : repName === 'Team'
                                      ? !g.assigned_to
                                      : g.assigned_to === repName
                            }
                        })
                    )
                    setLoading(false)
                }
            },
        })
    }
    useEffect(() => {
        refreshGoals()
    }, [])
    useEffect(() => {
        refreshGoals()
    }, [startDate, endDate, repName])
    useEffect(() => {
        if (goalPeriod === 'quarterly') {
            const startMonth = (quarter - 1) * 3 + 1
            const endMonth = startMonth + 2
            const endDay = new Date(sYear, endMonth, 0).getDate()
            setSMonth(startMonth)
            setEMonth(endMonth)
            setEDay(endDay)
        }
    }, [sYear, quarter, goalPeriod])
    useEffect(() => {
        const tempSDays = []
        for (let i = 1; i <= new Date(sYear, sMonth, 0).getDate(); i++) {
            tempSDays.push(i)
        }
        setSDays(tempSDays)
        if (goalPeriod !== 'yearly' && goalPeriod !== 'quarterly' && goalPeriod !== 'specify') {
            setQuarter(Math.floor((sMonth - 1) / 3 + 1))
            setSDay(1)
        }
        if ((sMonth > eMonth && sYear >= eYear) || sYear > eYear) {
            if (goalPeriod !== 'yearly' && goalPeriod !== 'quarterly') setEMonth(sMonth)
            setEYear(sYear)
        }
        if (goalPeriod === 'quarterly') {
            setEYear(sYear)
        }
        if (goalPeriod === 'monthly') {
            setEYear(sYear)
            setEMonth(sMonth)
            setEDay(tempSDays.length)
        }
    }, [sYear, sMonth, goalPeriod])
    useEffect(() => {
        if (sDay >= eDay && sYear >= eYear && sMonth >= eMonth) {
            setEDay(sDay)
        }
    }, [sDay])
    useEffect(() => {
        const tempEDays = []
        for (let i = 1; i <= new Date(eYear, eMonth, 0).getDate(); i++) {
            tempEDays.push(i)
        }
        setEDays(tempEDays)
        if (
            goalPeriod !== 'yearly' &&
            goalPeriod !== 'quarterly' &&
            goalPeriod !== 'monthly' &&
            goalPeriod !== 'specify'
        ) {
            setEDay(1)
        }
        if ((eMonth < sMonth && eYear <= sYear) || eYear < sYear) {
            if (goalPeriod !== 'yearly' && goalPeriod !== 'quarterly') setSMonth(eMonth)
            setSYear(eYear)
        }
    }, [eYear, eMonth, goalPeriod])
    useEffect(() => {
        if (eDay < sDay && eMonth <= sMonth && eYear <= sYear) {
            setSDay(eDay)
        }
    }, [eDay])
    useEffect(() => {
        if (goalPeriod === 'yearly') {
            setSYear(sYear)
            setSMonth(1)
            setSDay(1)
            setEYear(sYear)
            setEMonth(12)
            setEDay(31)
        }
    }, [goalPeriod])

    function sortGoals(goals: SalesGoalInit[]) {
        const sortedGoals = goals

        if (sortMode.includes('start_date')) {
            sortedGoals.sort((a, b) => {
                return a.end_date.getTime() > b.end_date.getTime() ? -1 : 1
            })
            sortedGoals.sort((a, b) => {
                return a.start_date.getTime() > b.start_date.getTime() ? -1 : 1
            })
        }
        if (sortMode.includes('end_date')) {
            sortedGoals.sort((a, b) => {
                return a.start_date.getTime() > b.start_date.getTime() ? -1 : 1
            })
            sortedGoals.sort((a, b) => {
                return a.end_date.getTime() > b.end_date.getTime() ? -1 : 1
            })
        }
        if (sortMode.includes('type')) {
            sortedGoals.sort((a, b) => {
                return a.end_date.getTime() > b.end_date.getTime() ? -1 : 1
            })
            sortedGoals.sort((a, b) => {
                return a.type === 'Year' && b.type !== 'Year'
                    ? -1
                    : a.type === 'Quarter' && b.type !== 'Quarter' && b.type !== 'Year'
                      ? -1
                      : a.type === 'Month' && b.type !== 'Year' && b.type !== 'Quarter' && b.type !== 'Month'
                        ? -1
                        : 1
            })
        }

        return sortMode.includes('reverse') ? sortedGoals.reverse() : sortedGoals
    }

    const canSubmitGoal = !!orderTotal && assignedTo.length > 0

    // goal handlers
    function handleSubmitNewGoal() {
        if (!canSubmitGoal)
            return sendToast({
                message: `Please add (${[orderTotal ? '' : 'order total', assignedTo.length ? '' : 'assignment']
                    .filter((v) => v)
                    .join(', ')}) before clicking submit.`,
            })
        setLoading(true)
        const assigned_to = assignedTo[0] === 'Team' || !assignedTo.length ? [null] : assignedTo

        if (goalButton === 'Add Goal') {
            vFetch(`/sales`, {
                method: 'POST',
                body: JSON.stringify({
                    assigned_to: assigned_to,
                    start_date: `${sYear}-${sMonth < 10 ? '0' + sMonth : sMonth}-${sDay < 10 ? '0' + sDay : sDay}`,
                    end_date: `${eYear}-${eMonth < 10 ? '0' + eMonth : eMonth}-${eDay < 10 ? '0' + eDay : eDay}`,
                    total: orderTotal || null,
                    volume: orderVolume || null,
                }),
                cb: (res: any) => {
                    if (res.success) {
                        setShowCreatingGoal(false)
                        setLoading(false)
                        refreshGoals()
                    }
                },
            })
        } else if (goalButton === 'Update Goal') {
            vFetch(`/sales`, {
                method: 'PUT',
                body: JSON.stringify({
                    id: id,
                    assigned_to: assigned_to,
                    start_date: `${sYear}-${sMonth < 10 ? '0' + sMonth : sMonth}-${sDay < 10 ? '0' + sDay : sDay}`,
                    end_date: `${eYear}-${eMonth < 10 ? '0' + eMonth : eMonth}-${eDay < 10 ? '0' + eDay : eDay}`,
                    total: orderTotal || null,
                    volume: orderVolume || null,
                }),
                cb: (res: any) => {
                    if (res.success) {
                        setShowCreatingGoal(false)
                        setLoading(false)
                        refreshGoals()
                    }
                },
            })
        }
    }
    function handleDeleteGoal() {
        vFetch(`/sales`, {
            method: 'DELETE',
            body: JSON.stringify({
                id: id,
            }),
            cb: (res: any) => {
                if (res.success) {
                    setShowCreatingGoal(false)
                    setLoading(false)
                    refreshGoals()
                }
            },
        })
    }

    return (
        <div>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            {showCreateGoal && (
                <div
                    ref={goalRef}
                    className='grid fixed grid-rows-[80%] top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-40'
                >
                    <div className='js-create-goal w-[550px] bg-white dark:bg-darkaccent mt-[64px] rounded p-[16px]'>
                        <div className='grid gap-[16px]'>
                            <div className='flex gap-[16px]'>
                                <div ref={assignRef} className='relative js-dropdown z-3 js-goals-assign-to'>
                                    <label className='rounded-t px-[8px] font-semibold bg-offwhite dark:bg-blue/50 dark:text-offwhite'>
                                        Assign To
                                    </label>
                                    <UserlistDropdown
                                        users={salespeople.map((u) => userShopifyRepName(u))}
                                        selectedUsers={assignedTo}
                                        setSelectedUsers={(users: string[]) => setAssignedTo(users)}
                                    />
                                </div>
                                {goalButton === 'Add Goal' && (
                                    <div className='flex items-end gap-[8px] dark:text-offwhite'>
                                        <input
                                            type='checkbox'
                                            className='mb-[5px]'
                                            checked={assignToTeam}
                                            onChange={({ target }) => {
                                                setAssignedTo(target.checked ? ['Team'] : [])
                                                setAssignToTeam(!!target.checked)
                                            }}
                                        />
                                        <label>Assign To Team</label>
                                    </div>
                                )}
                                {goalButton === 'Update Goal' &&
                                    ((!goals.find((g) => g.id === id)?.total_delta &&
                                        !goals.find((g) => g.id === id)?.volume_delta) ||
                                        user.type === 'Admin' ||
                                        user.type === 'SuperAdmin') && (
                                        <div className='flex items-center ml-auto'>
                                            <button
                                                className='justify-self-end px-[8px] font-semibold bg-[rgb(255,200,200)] border-[1px] border-[red] rounded'
                                                onClick={handleDeleteGoal}
                                            >
                                                Delete Goal
                                            </button>
                                        </div>
                                    )}
                            </div>
                            <div className='grid gap-[8px] dark:bg-darkness p-[8px] rounded'>
                                <div className='flex dark:text-offwhite'>
                                    <button
                                        className={`p-[8px] font-semibold ${
                                            goalPeriod === 'yearly' ? 'bg-accent text-black' : 'text-white bg-blue'
                                        }`}
                                        onClick={(event) => setGoalPeriod('yearly')}
                                    >
                                        Yearly
                                    </button>
                                    <button
                                        className={`p-[8px] font-semibold ${
                                            goalPeriod === 'quarterly' ? 'bg-accent text-black' : 'text-white bg-blue'
                                        }`}
                                        onClick={(event) => setGoalPeriod('quarterly')}
                                    >
                                        Quarterly
                                    </button>
                                    <button
                                        className={`p-[8px] font-semibold ${
                                            goalPeriod === 'monthly' ? 'bg-accent text-black' : 'text-white bg-blue'
                                        }`}
                                        onClick={(event) => setGoalPeriod('monthly')}
                                    >
                                        Monthly
                                    </button>
                                    <button
                                        className={`p-[8px] font-semibold ${
                                            goalPeriod === 'specify' ? 'bg-accent text-black' : 'text-white bg-blue'
                                        }`}
                                        onClick={(event) => setGoalPeriod('specify')}
                                    >
                                        Specify
                                    </button>
                                </div>
                                <div className='flex flex-wrap gap-[16px] justify-between '>
                                    <div className='grid'>
                                        <label className='rounded-t px-[8px] font-semibold bg-offwhite dark:bg-blue/50 dark:text-offwhite'>
                                            Start Date
                                        </label>
                                        <div className='flex gap-[8px] border-[1px] border-t-0 border-[lightgrey] dark:border-blue dark:bg-darkness dark:text-offwhite'>
                                            <select
                                                className='dark:bg-darkness dark:text-offwhite'
                                                value={sYear}
                                                onChange={({ target }) => setSYear(Number(target.value))}
                                            >
                                                {years.map((y) => (
                                                    <option key={y} value={y}>
                                                        {y}
                                                    </option>
                                                ))}
                                            </select>
                                            {goalPeriod === 'quarterly' && (
                                                <div>
                                                    <select
                                                        className='dark:bg-darkness dark:text-offwhite'
                                                        value={quarter}
                                                        onChange={({ target }) => setQuarter(Number(target.value))}
                                                    >
                                                        {quarters.map((q) => (
                                                            <option value={q}>Q{q}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                            {goalPeriod === 'monthly' || goalPeriod === 'specify' ? (
                                                <select
                                                    className='dark:bg-darkness dark:text-offwhite'
                                                    value={sMonth}
                                                    onChange={({ target }) => setSMonth(Number(target.value))}
                                                >
                                                    {Object.keys(months).map((m) => (
                                                        <option key={months[m]} value={Number(m) + 1}>
                                                            {months[m]}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <span>{months[sMonth - 1]}</span>
                                            )}
                                            {goalPeriod === 'specify' ? (
                                                <select
                                                    className='dark:bg-darkness dark:text-offwhite'
                                                    value={sDay}
                                                    onChange={({ target }) => setSDay(Number(target.value))}
                                                >
                                                    {sDays.map((d) => (
                                                        <option key={d} value={d}>
                                                            {d}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <span className='px-[8px]'>{sDay}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='grid'>
                                        <label className='rounded-t px-[8px] font-semibold bg-offwhite dark:bg-blue/50 dark:text-offwhite'>
                                            End Date
                                        </label>
                                        <div className='flex gap-[8px] border-[1px] border-t-0 border-[lightgrey] dark:border-blue dark:bg-darkness dark:text-offwhite'>
                                            {goalPeriod === 'specify' ? (
                                                <select
                                                    className='dark:bg-darkness dark:text-offwhite'
                                                    value={eYear}
                                                    onChange={({ target }) => setEYear(Number(target.value))}
                                                >
                                                    {years.map((y) => (
                                                        <option key={y} value={y}>
                                                            {y}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <span className='px-[8px]'>{eYear}</span>
                                            )}
                                            {goalPeriod === 'specify' ? (
                                                <select
                                                    className='dark:bg-darkness dark:text-offwhite'
                                                    value={eMonth}
                                                    onChange={({ target }) => setEMonth(Number(target.value))}
                                                >
                                                    {Object.keys(months).map((m) => (
                                                        <option key={months[m]} value={Number(m) + 1}>
                                                            {months[m]}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <span>{months[eMonth - 1]}</span>
                                            )}
                                            {goalPeriod === 'specify' ? (
                                                <select
                                                    className='dark:bg-darkness dark:text-offwhite'
                                                    value={eDay}
                                                    onChange={({ target }) => setEDay(Number(target.value))}
                                                >
                                                    {eDays.map((d) => (
                                                        <option key={d} value={d}>
                                                            {d}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <span className='px-[8px]'>{eDay}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-[16px] justify-between'>
                                <div className='grid relative'>
                                    <label className='rounded-t px-[8px] font-semibold bg-offwhite dark:bg-blue/50 dark:text-offwhite'>
                                        Order Total
                                    </label>
                                    <input
                                        className='absolute bottom-[0px] px-[4px] outline outline-[0px] outline-[#cdcdcd] dark:outline-darkgrey focus:outline border-0 dark:bg-darkness/0 dark:text-white  dark:focus:outline-darkgrey z-1 pointer-events-none'
                                        value={orderTotal ? '$' : ''}
                                    />
                                    <input
                                        required
                                        className='pl-[16px] outline outline-[1px] outline-[#cdcdcd] dark:outline-darkgrey focus:outline focus:border-0 dark:bg-darkness dark:text-white dark:focus:outline-darkgrey z-2'
                                        type='number'
                                        value={orderTotal}
                                        onChange={({ target }) =>
                                            setOrderTotal(
                                                target.value ? Number(Number(target.value).toFixed(2)) : undefined
                                            )
                                        }
                                    />
                                </div>
                                <div className='grid'>
                                    <label className='rounded-t px-[8px] font-semibold bg-offwhite dark:bg-blue/50 dark:text-offwhite'>
                                        Order Volume
                                    </label>
                                    <input
                                        className='px-[4px] outline outline-[1px] outline-[#cdcdcd] dark:outline-darkgrey focus:outline focus:border-0 dark:bg-darkness dark:text-white  dark:focus:outline-darkgrey z-2'
                                        type='number'
                                        value={orderVolume}
                                        onChange={({ target }) => setOrderVolume(Number(target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                        {((goalButton === 'Update Goal' &&
                            !goals.find((g) => g.id === id)?.total_delta &&
                            !goals.find((g) => g.id === id)?.volume_delta) ||
                            goalButton === 'Add Goal') && (
                            <div className='flex mt-[16px] justify-center'>
                                <button
                                    className={`py-[4px] px-[16px] ${
                                        !canSubmitGoal && '!bg-grey !cursor-not-allowed'
                                    } dark:bg-accent bg-blue rounded-[4px] dark:text-darkaccent text-white uppercase font-bold cursor-pointer`}
                                    onClick={handleSubmitNewGoal}
                                >
                                    {goalButton}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className='flex justify-between'>
                <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Sales Goals Manager</h2>
                <div className='flex items-center'>
                    <button
                        className='js-create-goal py-[2px] px-[16px] dark:bg-accent bg-blue rounded-[4px] dark:text-darkaccent text-white uppercase font-bold'
                        onClick={() => {
                            setShowCreatingGoal(true)
                            setGoalButton('Add Goal')
                            setGoalPeriod('monthly')
                            setAssignedTo([])
                            setOrderTotal(undefined)
                            setOrderVolume(undefined)
                        }}
                    >
                        + New Goal
                    </button>
                </div>
            </div>
            <SalesTimeFilters
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                reps={salespeople}
                repName={repName}
                setRepName={setRepName}
            />

            {/* OPEN GOALS */}
            <div className='dark:text-offwhite text-[14px] shadow-small'>
                <h2 className='text-[20px] mt-[16px]'>Open</h2>
                <div className='grid grid-cols-[90px_90px_60px_130px_130px_75px_85px_85px] justify-between font-bold bg-blue dark:bg-darkaccent border-[1px] border-darkgrey text-white dark:text-offwhite'>
                    <div
                        className='relative p-[8px] cursor-pointer'
                        onClick={() => {
                            setSortMode(sortMode === 'start_date' ? 'start_date_reverse' : 'start_date')
                        }}
                    >
                        <span className='whitespace-nowrap'>Start Date</span>
                        <div className='absolute top-[50%] translate-y-[-50%] right-[-8px]'>
                            {sortMode.includes('start_date') ? (
                                <AiFillCaretDown
                                    className={`dark:fill-offwhite duration-200 ${
                                        sortMode.includes('reverse') ? 'rotate-180' : ''
                                    }`}
                                />
                            ) : undefined}
                        </div>
                    </div>
                    <div
                        className='relative p-[8px] cursor-pointer'
                        onClick={() => {
                            setSortMode(sortMode === 'end_date' ? 'end_date_reverse' : 'end_date')
                        }}
                    >
                        <span>End Date</span>
                        <div
                            className='absolute top-[50%] translate-y-[-50%] right-[-8px]'
                            onClick={() => setSortMode(sortMode === 'end_date' ? 'end_date_reverse' : 'end_date')}
                        >
                            {sortMode.includes('end_date') && (
                                <AiFillCaretDown
                                    className={`dark:fill-offwhite duration-200 ${
                                        sortMode.includes('reverse') ? 'rotate-180' : ''
                                    }`}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className='relative p-[8px] cursor-pointer'
                        onClick={() => setSortMode(sortMode === 'type' ? 'type_reverse' : 'type')}
                    >
                        <span>Type</span>
                        <div
                            className='absolute top-[50%] translate-y-[-50%] right-[-8px]'
                            onClick={() => setSortMode(sortMode === 'type' ? 'type_reverse' : 'type')}
                        >
                            {sortMode.includes('type') && (
                                <AiFillCaretDown
                                    className={`dark:fill-offwhite duration-200 ${
                                        sortMode.includes('reverse') ? 'rotate-180' : ''
                                    }`}
                                />
                            )}
                        </div>
                    </div>
                    <div className='p-[8px]'>
                        <span>Assignee</span>
                    </div>
                    <div className='p-[8px]'>
                        <span>Total</span>
                    </div>
                    <div className='p-[8px]'>
                        <span>Volume</span>
                    </div>
                    <div className='p-[8px]'>
                        <span>Total Δ</span>
                    </div>
                    <div className='p-[8px]'>
                        <span>Volume Δ</span>
                    </div>
                </div>
                <div className='border-[1px] border-darkgrey border-t-0'>
                    {sortGoals(goals.filter((g) => !g.total_delta && !g.volume_delta)).map((g) => (
                        <div
                            onClick={() => {
                                setGoalButton('Update Goal')
                                setGoalPeriod('specify')
                                setId(g.id)
                                setAssignedTo(g.assigned_to?.split(',') || [])
                                setSYear(g.start_date.getFullYear())
                                setSMonth(g.start_date.getMonth() + 1)
                                setSDay(g.start_date.getDate())
                                setEYear(g.end_date.getFullYear())
                                setEMonth(g.end_date.getMonth() + 1)
                                setEDay(g.end_date.getDate())
                                setOrderTotal(g.total)
                                setOrderVolume(g.volume)
                                setShowCreatingGoal(true)
                            }}
                            className='js-create-goal grid grid-cols-[90px_90px_60px_130px_130px_75px_85px_85px] justify-between hover:bg-[rgb(199,202,209)] [&:nth-child(even)]:bg-[rgb(224,228,235)] [&:nth-child(even)]:hover:bg-[rgb(199,202,209)] dark:[&:nth-child(even)]:bg-darkaccent dark:[&:nth-child(even)]:hover:bg-blue dark:bg-darkness dark:text-offwhite dark:hover:bg-blue cursor-pointer'
                        >
                            <div className='p-[8px] px-[8px]'>
                                {g.start_date.getMonth() + 1}/{g.start_date.getDate()}/{g.start_date.getFullYear()}
                            </div>
                            <div className='p-[8px] px-[8px]'>
                                {g.end_date.getMonth() + 1}/{g.end_date.getDate()}/{g.end_date.getFullYear()}
                            </div>
                            <div className='p-[8px] px-[8px]'>{g.type}</div>
                            <div className='p-[8px] px-[8px]'>
                                {g.assigned_to?.length === 0 ? 'Team' : g.assigned_to}
                            </div>
                            <div className='p-[8px] px-[8px]'>{formatMoney(g.total!)}</div>
                            <div className='p-[8px] px-[8px]'>{g.volume}</div>
                            <div className='p-[8px] px-[8px]'>{g.total_delta ? mFormat(g.total_delta) : undefined}</div>
                            <div className='p-[8px] px-[8px]'>{g.volume_delta}</div>
                        </div>
                    ))}
                </div>
            </div>

            {/* PAST GOALS */}
            <div className='dark:text-offwhite text-[14px] shadow-small'>
                <h2 className='text-[20px] mt-[16px]'>Past</h2>
                <div className='grid grid-cols-[90px_90px_60px_130px_130px_75px_85px_85px] justify-between font-bold bg-blue dark:bg-darkaccent border-[1px] border-darkgrey text-white dark:text-offwhite'>
                    <div
                        className='relative p-[8px] cursor-pointer'
                        onClick={() => {
                            setSortMode(sortMode === 'start_date' ? 'start_date_reverse' : 'start_date')
                        }}
                    >
                        <span className='whitespace-nowrap'>Start Date</span>
                        <div className='absolute top-[50%] translate-y-[-50%] right-[-8px]'>
                            {sortMode.includes('start_date') ? (
                                <AiFillCaretDown
                                    className={`dark:fill-offwhite duration-200 ${
                                        sortMode.includes('reverse') ? 'rotate-180' : ''
                                    }`}
                                />
                            ) : undefined}
                        </div>
                    </div>
                    <div
                        className='relative p-[8px] cursor-pointer'
                        onClick={() => {
                            setSortMode(sortMode === 'end_date' ? 'end_date_reverse' : 'end_date')
                        }}
                    >
                        <span>End Date</span>
                        <div
                            className='absolute top-[50%] translate-y-[-50%] right-[-8px]'
                            onClick={() => setSortMode(sortMode === 'end_date' ? 'end_date_reverse' : 'end_date')}
                        >
                            {sortMode.includes('end_date') && (
                                <AiFillCaretDown
                                    className={`dark:fill-offwhite duration-200 ${
                                        sortMode.includes('reverse') ? 'rotate-180' : ''
                                    }`}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className='relative p-[8px] cursor-pointer'
                        onClick={() => {
                            setSortMode(sortMode === 'type' ? 'type_reverse' : 'type')
                        }}
                    >
                        <span>Type</span>
                        <div
                            className='absolute top-[50%] translate-y-[-50%] right-[-8px]'
                            onClick={() => setSortMode(sortMode === 'type' ? 'type_reverse' : 'type')}
                        >
                            {sortMode.includes('type') && (
                                <AiFillCaretDown
                                    className={`dark:fill-offwhite duration-200 ${
                                        sortMode.includes('reverse') ? 'rotate-180' : ''
                                    }`}
                                />
                            )}
                        </div>
                    </div>
                    <div className='p-[8px]'>
                        <span>Assignee</span>
                    </div>
                    <div className='p-[8px]'>
                        <span>Total</span>
                    </div>
                    <div className='p-[8px]'>
                        <span>Volume</span>
                    </div>
                    <div className='p-[8px]'>
                        <span>Total Δ</span>
                    </div>
                    <div className='p-[8px]'>
                        <span>Volume Δ</span>
                    </div>
                </div>
                <div className='border-[1px] border-darkgrey border-t-0'>
                    {sortGoals(goals.filter((g) => g.total_delta || g.volume_delta)).map((g) => (
                        <div
                            onClick={() => {
                                setGoalButton('Update Goal')
                                setGoalPeriod('specify')
                                setId(g.id)
                                setAssignedTo(g.assigned_to?.split(',') || [])
                                setSYear(g.start_date.getFullYear())
                                setSMonth(g.start_date.getMonth() + 1)
                                setSDay(g.start_date.getDate())
                                setEYear(g.end_date.getFullYear())
                                setEMonth(g.end_date.getMonth() + 1)
                                setEDay(g.end_date.getDate())
                                setOrderTotal(g.total)
                                setOrderVolume(g.volume)
                                setShowCreatingGoal(true)
                            }}
                            className='js-create-goal grid grid-cols-[90px_90px_60px_130px_130px_75px_85px_85px] justify-between hover:bg-[rgb(199,202,209)] [&:nth-child(even)]:bg-[rgb(224,228,235)] [&:nth-child(even)]:hover:bg-[rgb(199,202,209)] dark:[&:nth-child(even)]:bg-darkaccent dark:[&:nth-child(even)]:hover:bg-blue dark:bg-darkness dark:text-offwhite dark:hover:bg-blue cursor-pointer'
                        >
                            <div className='p-[8px] px-[8px]'>
                                {g.start_date.getMonth() + 1}/{g.start_date.getDate()}/{g.start_date.getFullYear()}
                            </div>
                            <div className='p-[8px] px-[8px]'>
                                {g.end_date.getMonth() + 1}/{g.end_date.getDate()}/{g.end_date.getFullYear()}
                            </div>
                            <div className='p-[8px] px-[8px]'>{g.type}</div>
                            <div className='p-[8px] px-[8px]'>{g.assigned_to || 'Team'}</div>
                            <div className='p-[8px] px-[8px]'>{formatMoney(g.total!)}</div>
                            <div className='p-[8px] px-[8px]'>{g.volume}</div>
                            <div className='p-[8px] px-[8px]'>{formatMoney(g.total_delta!)}</div>
                            <div className='p-[8px] px-[8px]'>{g.volume_delta}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
