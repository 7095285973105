import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { vFetch } from '../helpers'

const DEFAULT_ACCESS = ['product-setup', 'shipping']
const STANDARD_ACCESS = ['top-movers', 'scraper', 'sales', 'calls']
export default function AccountScreen() {
    const user = useSelector((state) => state.user)
    const [userData, setUserData] = useState({})
    const [initialUserData, setInitialUserData] = useState({})
    const [access, setAccess] = useState([])
    const [initialAccess, setInitialAccess] = useState()
    const [detailsEdited, setDetailsEdited] = useState(false)
    const [passwordEdited, setPasswordEdited] = useState(false)
    const [passwordMismatch, setPasswordMismatch] = useState(false)
    const [notificationsEdited, setNotificationsEdited] = useState(false)
    const [passwordGroup, setPasswordGroup] = useState({
        password: '',
        'new-password': '',
        'confirm-new-password': '',
    })

    useEffect(() => {
        vFetch(`/users/${user.email}`, {
            cb: (res) => {
                if (res.success) {
                    setUserData({
                        ...res.user,
                        shopify_rep_name: res.user.shopify_rep_name || '',
                    })
                    setInitialUserData({
                        ...res.user,
                        shopify_rep_name: res.user.shopify_rep_name || '',
                    })
                    const userAccess = JSON.parse(res.user.access)
                    const userNotifications = JSON.parse(res.user.notification_subscriptions)
                    const userEmails = JSON.parse(res.user.non_notification_reminder_subscriptions)

                    const accessMap = {}
                    if (userAccess.includes('*')) {
                        for (let item of [...DEFAULT_ACCESS, ...STANDARD_ACCESS]) {
                            accessMap[item] = {
                                name: item,
                                notify: userNotifications.find((n) => n === item) ? true : false,
                                email: userNotifications.find((n) => n === item) ? true : false,
                            }
                        }
                        setAccess(Object.values(accessMap))
                        setInitialAccess(Object.values(accessMap))
                    } else {
                        for (let item of [...userAccess, ...STANDARD_ACCESS]) {
                            accessMap[item] = {
                                name: item,
                                notify: userNotifications.find((n) => n === item) ? true : false,
                                email: userNotifications.find((n) => n === item) ? true : false,
                            }
                        }
                        setAccess(Object.values(accessMap))
                        setInitialAccess(Object.values(accessMap))
                    }
                }
            },
        })
    }, [])
    useEffect(() => {
        if (
            initialUserData.first_name !== userData.first_name ||
            initialUserData.last_name !== userData.last_name ||
            initialUserData.shopify_rep_name !== userData.shopify_rep_name
        ) {
            setDetailsEdited(true)
        } else {
            setDetailsEdited(false)
        }
    }, [userData, initialUserData])
    useEffect(() => {
        if (JSON.stringify(access) !== JSON.stringify(initialAccess)) {
            setNotificationsEdited(true)
        } else {
            setNotificationsEdited(false)
        }
    }, [access, initialAccess])
    useEffect(() => {
        if (
            passwordGroup.password &&
            passwordGroup['new-password'] &&
            passwordGroup['confirm-new-password'] &&
            passwordGroup['new-password'] === passwordGroup['confirm-new-password']
        ) {
            setPasswordEdited(true)
            setPasswordMismatch(false)
        } else if (
            passwordGroup.password &&
            passwordGroup['new-password'] &&
            passwordGroup['confirm-new-password'] &&
            passwordGroup['new-password'] !== passwordGroup['confirm-new-password']
        ) {
            setPasswordEdited(false)
            setPasswordMismatch(true)
        } else {
            setPasswordEdited(false)
            setPasswordMismatch(false)
        }
    }, [passwordGroup])
    const handleChangeNotifications = ({ target }) => {
        if (target.name.includes('-notify')) {
            const name = target.name.replace('-notify', '')
            setAccess((prev) => {
                const newAccessArray = [...prev]
                const editedIndex = newAccessArray.findIndex((item) => item.name === name)
                if (editedIndex !== -1) {
                    newAccessArray[editedIndex] = {
                        ...newAccessArray[editedIndex],
                        notify: target.checked,
                    }
                }
                return newAccessArray
            })
        } else {
            const name = target.name.replace('-email', '')
            setAccess((prev) => {
                const newAccessArray = [...prev]
                const editedIndex = newAccessArray.findIndex((item) => item.name === name)
                if (editedIndex !== -1) {
                    newAccessArray[editedIndex] = {
                        ...newAccessArray[editedIndex],
                        email: target.checked,
                    }
                }
                return newAccessArray
            })
        }
    }
    const handleChangeUserData = ({ target }) => {
        setUserData((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }
    const handlePasswordGroupChange = ({ target }) => {
        setPasswordGroup((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }
    const handleSubmitDetailsChange = () => {
        vFetch(`/users/${user.email}`, {
            method: 'PUT',
            body: JSON.stringify({
                first_name: userData.first_name,
                last_name: userData.last_name,
                shopify_rep_name: userData.shopify_rep_name,
            }),
            cb: (res) => {
                if (res.success) setInitialUserData(userData)
            },
        })
    }
    const handleSubmitPasswordChange = () => {
        vFetch(`/users/password/${user.email}`, {
            method: 'PUT',
            body: JSON.stringify({
                old_password: passwordGroup.password,
                password: passwordGroup['new-password'],
            }),
            cb: (res) => {
                if (res.success) {
                    setPasswordGroup({
                        password: '',
                        'new-password': '',
                        'confirm-new-password': '',
                    })
                }
            },
        })
    }
    const handleSubmitNotificationsChange = () => {
        vFetch(`/users/${user.email}`, {
            method: 'PUT',
            body: JSON.stringify({
                notification_subscriptions: access.map((a) => (a.notify ? a.name : null)).filter((v) => v),
                non_notification_reminder_subscriptions: access.map((a) => (a.email ? a.name : null)).filter((v) => v),
            }),
            cb: (res) => {
                if (res.success) {
                    setInitialAccess(access)
                }
            },
        })
    }
    return (
        <>
            <div className='grid grid-cols-2 gap-[16px]'>
                <div className='shadow-small rounded-[4px] p-[16px] dark:bg-darkaccent relative pt-[32px]'>
                    {detailsEdited && (
                        <button
                            onClick={handleSubmitDetailsChange}
                            className='py-[4px] px-[16px] text-white bg-blue shadow-hot dark:bg-fire dark:text-darkness absolute top-0 right-0 font-bold uppercase'
                        >
                            Save Changes
                        </button>
                    )}
                    <p className='text-[12px] uppercase font-bold absolute top-0 left-0 p-[2px] bg-blue dark:bg-accent text-white dark:text-darkness rounded-tl-[4px] '>
                        Account Details
                    </p>
                    <div className='flex gap-[8px] w-full mb-[4px]'>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label
                                className='text-[12px] font-bold text-darkgrey leading-[1] uppercase dark:text-offwhite'
                                htmlFor='email'
                            >
                                First Name
                            </label>
                            <input
                                autoComplete='off'
                                id='first_name'
                                name='first_name'
                                className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:border-none dark:text-white focus:outline-none'
                                type='text'
                                value={userData.first_name}
                                onChange={handleChangeUserData}
                                disabled
                            />
                        </div>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label
                                className='text-[12px] font-bold text-darkgrey leading-[1] uppercase dark:text-offwhite'
                                htmlFor='email'
                            >
                                Last Name
                            </label>
                            <input
                                autoComplete='off'
                                id='last_name'
                                name='last_name'
                                className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:border-none dark:text-white focus:outline-none'
                                type='text'
                                value={userData.last_name}
                                onChange={handleChangeUserData}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[4px]'>
                        <label
                            className='text-[12px] font-bold text-darkgrey leading-[1] uppercase dark:text-offwhite'
                            htmlFor='email'
                        >
                            Email
                        </label>
                        <input
                            autoComplete='off'
                            id='email'
                            className='py-[2px] px-[4px] rounded-[4px] bg-lightgrey dark:bg-faint dark:border-none dark:text-offwhite focus:outline-none'
                            type='text'
                            value={userData.email}
                            disabled
                        />
                    </div>
                    <div className='flex flex-col gap-[4px]'>
                        <label
                            className='text-[12px] font-bold text-darkgrey leading-[1] uppercase dark:text-offwhite'
                            htmlFor='email'
                        >
                            Shopify Rep Name
                        </label>
                        <input
                            autoComplete='off'
                            id='shopify_rep_name'
                            name='shopify_rep_name'
                            className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:border-none dark:text-white focus:outline-none'
                            type='text'
                            value={userData.shopify_rep_name}
                            onChange={handleChangeUserData}
                        />
                    </div>
                </div>
                <div className='shadow-small rounded-[4px] p-[16px] dark:bg-darkaccent relative pt-[32px]'>
                    {passwordMismatch ? (
                        <button className='py-[4px] px-[16px] text-white bg-red text-[12px] shadow-hot dark:bg-lightred dark:text-darkness absolute top-0 right-0 font-bold uppercase'>
                            New password does not match
                        </button>
                    ) : (
                        passwordEdited && (
                            <button
                                onClick={handleSubmitPasswordChange}
                                className='py-[4px] px-[16px] text-white bg-blue shadow-hot dark:bg-fire dark:text-darkness absolute top-0 right-0 font-bold uppercase'
                            >
                                Save Changes
                            </button>
                        )
                    )}
                    <p className='text-[12px] uppercase font-bold absolute top-0 left-0 p-[2px] bg-blue dark:bg-accent text-white dark:text-darkness rounded-tl-[4px] '>
                        Change Password
                    </p>
                    <div className='flex flex-col gap-[4px] mb-[4px]'>
                        <label
                            className='text-[12px] font-bold text-darkgrey leading-[1] uppercase dark:text-offwhite'
                            htmlFor='email'
                        >
                            Password
                        </label>
                        <input
                            autoComplete='new-password'
                            id='password'
                            name='password'
                            className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:border-none dark:text-white focus:outline-none'
                            type='password'
                            value={passwordGroup.password}
                            onChange={handlePasswordGroupChange}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[4px]'>
                        <label
                            className='text-[12px] font-bold text-darkgrey leading-[1] uppercase dark:text-offwhite'
                            htmlFor='email'
                        >
                            New Password
                        </label>
                        <input
                            autoComplete='new-password'
                            id='new-password'
                            name='new-password'
                            className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:border-none dark:text-white focus:outline-none'
                            type='password'
                            value={passwordGroup['new-password']}
                            onChange={handlePasswordGroupChange}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px]'>
                        <label
                            className='text-[12px] font-bold text-darkgrey leading-[1] uppercase dark:text-offwhite'
                            htmlFor='email'
                        >
                            Confirm New Password
                        </label>
                        <input
                            autoComplete='new-password'
                            id='confirm-new-password'
                            name='confirm-new-password'
                            className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:border-none dark:text-white focus:outline-none'
                            type='password'
                            value={passwordGroup['confirm-new-password']}
                            onChange={handlePasswordGroupChange}
                        />
                    </div>
                </div>
                <div className='shadow-small rounded-[4px] p-[16px] dark:bg-darkaccent relative pt-[32px] col-span-2'>
                    {notificationsEdited && (
                        <button
                            onClick={handleSubmitNotificationsChange}
                            className='py-[4px] px-[16px] text-white bg-blue shadow-hot dark:bg-fire dark:text-darkness absolute top-0 right-0 font-bold uppercase'
                        >
                            Save Changes
                        </button>
                    )}
                    <p className='text-[12px] uppercase font-bold absolute top-0 left-0 p-[2px] bg-blue dark:bg-accent text-white dark:text-darkness rounded-tl-[4px] '>
                        Notifications
                    </p>
                    <div className='grid grid-cols-[1fr_200px_200px]'>
                        <div className='grid grid-cols-[1fr_100px_100px] bg-white dark:bg-darkaccent border-b border-lightgrey dark:border-grey pb-[8px] mb-[8px] sticky top-0 col-span-full'>
                            <p className='text-[12px] font-bold text-darkgrey dark:text-offwhite uppercase'>Type</p>
                            <p className='text-[12px] font-bold text-darkgrey dark:text-offwhite uppercase'>Notify</p>
                            <p className='text-[12px] font-bold text-darkgrey dark:text-offwhite uppercase'>Email</p>
                        </div>
                        {access.map((acc, index) => (
                            <div
                                className={`grid grid-cols-[1fr_100px_92px] py-[8px] dark:bg-darkaccent p-[8px] col-span-full border border-lightgrey dark:border-grey rounded-[4px] ${index !== access.length - 1 ? 'mb-[8px]' : ''
                                    }`}
                            >
                                <p className='font-bold uppercase text-darkgrey dark:text-offwhite'>
                                    {acc.name.replaceAll('-', ' ')}
                                </p>
                                <div className='p-[3px] rounded-[99vw] overflow-hidden w-[48px] h-[24px] relative'>
                                    <input
                                        onChange={handleChangeNotifications}
                                        checked={acc.notify}
                                        id={acc.name + '-notify'}
                                        name={acc.name + '-notify'}
                                        type='checkbox'
                                        className='peer z-[1] appearance-none w-[20px] h-[20px] bg-grey rounded-[50%] block transition-all absolute top-[50%] translate-y-[-50%] left-[3px] checked:left-[24px]'
                                    />
                                    <label
                                        htmlFor={acc.name + '-notify'}
                                        className='bg-lightgrey dark:bg-darkness peer-checked:bg-fire dark:peer-checked:bg-accent transition-all w-full h-full absolute top-0 left-0 z-[0]'
                                    >
                                        &nbsp;
                                    </label>
                                </div>
                                <div className='p-[3px] rounded-[99vw] overflow-hidden w-[48px] h-[24px] relative'>
                                    <input
                                        onChange={handleChangeNotifications}
                                        checked={acc.email}
                                        id={acc.name + '-email'}
                                        name={acc.name + '-email'}
                                        type='checkbox'
                                        className='peer z-[1] appearance-none w-[20px] h-[20px] bg-grey rounded-[50%] block transition-all absolute top-[50%] translate-y-[-50%] left-[3px] checked:left-[24px]'
                                    />
                                    <label
                                        htmlFor={acc.name + '-email'}
                                        className='bg-lightgrey dark:bg-darkness peer-checked:bg-fire dark:peer-checked:bg-accent transition-all w-full h-full absolute top-0 left-0 z-[0]'
                                    >
                                        &nbsp;
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
