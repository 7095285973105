import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { EmailTemplateEditType, EmailTemplateType, FilterRulesType } from 'followUpEmail/FollowUpEmail.types'
import EmailEditor from 'followUpEmail/components/EmailEditor'
import EmailSettingsEditor from 'followUpEmail/components/EmailSettingsEditor'
import OrderTagsEditor from 'followUpEmail/components/OrderTagsEditor'
import useModal from 'followUpEmail/hooks/useModal'
import ScheduledEmailList from 'followUpEmail/lists/ScheduledEmailList'
import { TimeHandler, vFetch } from 'helpers'
import { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'

export default function EditTemplateModal({ templateId }: { templateId: string }) {
    const timeHandler = new TimeHandler()
    const toggleModal = () =>
        setSearchParams((prev) => {
            prev.delete('view')
            prev.delete('template_id')
            return prev
        })
    const templateQuery = useQuery({
        queryKey: ['marketing', 'email', 'templates', templateId],
        queryFn: () => vFetch(`/marketing/email/templates?id=${templateId}`),
    })
    const template: EmailTemplateType = (templateQuery.data?.emailTemplates || [])[0] || {}
    const modalRef = useModal({
        toggleModal,
    })
    const [_searchParams, setSearchParams] = useSearchParams()
    const [editedFields, setEditedFields] = useState<EmailTemplateEditType>({
        id: templateId,
    })
    const [rules, setRules] = useState<FilterRulesType>({})
    const [editedDelayFields, setEditedDelayFields] = useState<any>({})
    const parsedStartsOn = `${new Date(template.starts_on).getFullYear()}-${`0${
        new Date(template.starts_on).getMonth() + 1
    }`.slice(-2)}-${`0${new Date(template.starts_on).getDate()}`.slice(-2)}`

    const weeks = Math.floor((template.delay || 0) / (7 * 24 * 60 * 60 * 1000))
    const days = Math.floor(((template.delay || 0) % (7 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000))
    const hours = Math.floor(((template.delay || 0) % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
    const minutes = Math.floor(((template.delay || 0) % (60 * 60 * 1000)) / (60 * 1000))
    const delay = {
        weeks,
        days,
        hours,
        minutes,
    }

    const delayInMS =
        (editedDelayFields.weeks !== undefined
            ? (editedDelayFields.weeks || 0) * 7 * 24 * 60 * 60 * 1000
            : (delay.weeks || 0) * 7 * 24 * 60 * 60 * 1000) +
        (editedDelayFields.days !== undefined
            ? (editedDelayFields.days || 0) * 24 * 60 * 60 * 1000
            : (delay.days || 0) * 24 * 60 * 60 * 1000) +
        (editedDelayFields.hours !== undefined
            ? (editedDelayFields.hours || 0) * 60 * 60 * 1000
            : (delay.hours || 0) * 60 * 60 * 1000) +
        (editedDelayFields.minutes !== undefined
            ? (editedDelayFields.minutes || 0) * 60 * 1000
            : (delay.minutes || 0) * 60 * 1000)

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditedFields((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }
    const queryClient = useQueryClient()
    const updateTemplateMutation = useMutation({
        mutationFn: async ({ template }: { template: EmailTemplateEditType }) =>
            vFetch('/marketing/email/templates', {
                method: 'PUT',
                body: JSON.stringify(template),
            }),
        onSuccess: () => {
            toggleModal()
            return queryClient.invalidateQueries({ queryKey: ['marketing'] })
        },
    })
    const deleteTemplateMutation = useMutation({
        mutationFn: async ({ id }: { id: string }) =>
            vFetch('/marketing/email/templates', {
                method: 'DELETE',
                body: JSON.stringify({ id }),
            }),
        onSuccess: () => {
            toggleModal()
            return queryClient.invalidateQueries({ queryKey: ['marketing'] })
        },
    })
    const handleUpdate = () => {
        const [year, month, date] = (editedFields.starts_on || parsedStartsOn).split('-')
        const starts_on =
            year && month && date ? timeHandler.getISOString(Number(year), Number(month), Number(date)) : ''
        updateTemplateMutation.mutate({
            template: {
                ...editedFields,
                filter_rules: rules,
                delay: delayInMS,
                starts_on,
                id: templateId,
            },
        })
    }
    const handleDelete = () => {
        deleteTemplateMutation.mutate({
            id: templateId,
        })
    }

    useEffect(() => {
        if (template.filter_rules) {
            setRules(JSON.parse(template.filter_rules as any))
        }
    }, [template.filter_rules])

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center z-50'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-4 rounded shadow-md max-w-[90%] max-h-[90%] overflow-auto relative'>
                <button onClick={toggleModal} className='absolute top-2 right-2 text-lg text-red dark:text-lightred'>
                    <FaTimes />
                </button>
                <h2 className='font-bold mb-2'>Edit Automated Email</h2>
                <div className='flex gap-2'>
                    <div className='flex flex-col gap-2 w-[350px]'>
                        <EmailSettingsEditor
                            template={template}
                            editedFields={editedFields}
                            setTemplate={setEditedFields}
                            delay={delay}
                            editedDelayFields={editedDelayFields}
                            setDelay={setEditedDelayFields}
                            editMode={true}
                        />
                    </div>
                    <div className='flex flex-col gap-2 min-w-[350px]'>
                        <EmailEditor template={template} editedFields={editedFields} handleChange={handleChange} />
                    </div>
                    <div className='flex flex-col gap-2 min-w-[350px]'>
                        <h3 className='font-bold text-sm'>Rules</h3>
                        <OrderTagsEditor event_type={template.event_type} rules={rules} setRules={setRules} />
                    </div>
                </div>
                <div className='flex items-center justify-center gap-4 my-4'>
                    <Button variant={'outline'} className='h-fit leading-none py-2 px-4' onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button
                        variant={'destructive'}
                        className='h-fit bg-red dark:bg-lightred text-white dark:text-white leading-none py-2 px-4'
                        onClick={handleDelete}
                    >
                        Delete Template
                    </Button>
                </div>
                <h2 className='font-bold mb-2 w-full pt-2 border-t border-lightgrey dark:border-darkgrey'>
                    Scheduled Emails
                </h2>
                <ScheduledEmailList template_id={templateId} />
            </div>
        </div>
    )
}
