import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import ResourceList from '../ResourceList'
import PreviewBox from '../PreviewBox'
import { sendToast, vFetch } from '../../../helpers'
import { TimeHandler } from '../../../helpers'
import { useSelector } from 'react-redux'

const timeHandler = new TimeHandler()

export default function PriceCutsModal({
    selectedPriceCut,
    setSelectedPriceCut,
    setNeedsRefresh,
}: {
    selectedPriceCut: any
    setSelectedPriceCut: Dispatch<SetStateAction<any>>
    setNeedsRefresh: Dispatch<SetStateAction<boolean>>
}) {
    const sessionToken = localStorage.getItem('session_token')
    const { settings } = useSelector((state: any) => state)
    const [affectedResourcesLoading, setAffectedResourcesLoading] = useState<boolean>(false)
    const [affectedResources, setAffectedResources] = useState<any[]>([])
    const [editablePriceCut, setEditablePriceCut] = useState<any | null>(selectedPriceCut)
    const [edited, setEdited] = useState<boolean>(false)
    const [resourceListEdited, setResourceListEdited] = useState<boolean>(false)
    const [pushList, setPushList] = useState<any[]>([...affectedResources])
    const [popList, setPopList] = useState<any[]>([])
    const isNotNewPriceCut = selectedPriceCut?.name ? true : false

    useEffect(() => {
        if (
            JSON.stringify(selectedPriceCut).split('').sort().join('') ===
            JSON.stringify(editablePriceCut).split('').sort().join('')
        ) {
            setEdited(false)
        } else {
            setEdited(true)
        }
    }, [selectedPriceCut, editablePriceCut])

    // BUILD THIS THING LATER
    // useEffect(() => {
    //     if (editablePriceCut) {
    //         getScheduleMap().then(console.log)
    //     }
    // }, [editablePriceCut?.status, pushList])

    useEffect(() => {
        if (selectedPriceCut) {
            setEditablePriceCut({
                ...selectedPriceCut,
                starts_on: selectedPriceCut?.starts_on
                    ? new TimeHandler().getLocalYMD(selectedPriceCut?.starts_on)
                    : undefined,
                starts_on_time: `${new Date(selectedPriceCut?.starts_on || new Date().setHours(0, 0, 0, 0))
                    ?.getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(selectedPriceCut?.starts_on || new Date().setHours(0, 0, 0, 0))
                    ?.getMinutes()
                    .toString()
                    .padStart(2, '0')}`,
                ends_on: selectedPriceCut?.ends_on
                    ? new TimeHandler().getLocalYMD(selectedPriceCut?.ends_on)
                    : undefined,
                ends_on_time: `${new Date(selectedPriceCut?.ends_on || new Date().setHours(0, 0, 0, 0))
                    ?.getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(selectedPriceCut?.ends_on || new Date().setHours(0, 0, 0, 0))
                    ?.getMinutes()
                    .toString()
                    .padStart(2, '0')}`,
            })
        } else {
            setEditablePriceCut(null)
        }
        if (selectedPriceCut?.affected_resources) {
            const gids = selectedPriceCut.affected_resources.split(',')
            const productIds = gids
                .filter((gid: string) => gid.includes('/Product/'))
                .map((gid: string) => gid.replace('gid://shopify/Product/', ''))
            // const collectionIds = gids.filter((gid: string) => gid.includes("/Collection/")).map((gid: string) => gid.replace("gid://shopify/Collection/", ""))
            const variantIds = gids
                .filter((gid: string) => gid.includes('/ProductVariant/'))
                .map((gid: string) => gid.replace('gid://shopify/ProductVariant/', ''))
            const fetches = [
                vFetch(
                    `/priceCuts/connections?parent_id=${encodeURIComponent(selectedPriceCut.id)}&group_by=parent_gid`,
                    {
                        cb: (res: any) => {
                            if (res.success)
                                return res.priceCutsConnections.map((pcc: any) => ({ ...pcc, gid: pcc.parent_gid }))
                            return []
                        },
                    }
                ),
                productIds.length
                    ? vFetch(
                          `/variants/set?fields=gid,parent_gid,title,unit_cost,price,compare_at_price,sku,is_default&current_cost=true&parent_ids=${productIds.join(
                              ','
                          )}`,
                          {
                              cb: (res: any) => {
                                  if (res.success) {
                                      const productMap: any = {}
                                      for (let variant of res.variants) {
                                          if (!productMap[variant.parent_gid]) {
                                              productMap[variant.parent_gid] = {
                                                  title: variant.product_title,
                                                  gid: variant.parent_gid,
                                                  variants: [],
                                              }
                                          }
                                          if (variant.is_default) {
                                              productMap[variant.parent_gid].original_cost = variant.unit_cost
                                              productMap[variant.parent_gid].sku = variant.sku
                                          }
                                          productMap[variant.parent_gid].variants.push({
                                              ...variant,
                                              original_cost: variant.unit_cost,
                                          })
                                      }
                                      return Object.values(productMap)
                                  }
                                  return []
                              },
                          }
                      )
                    : [],
                // vFetch(`/collections/set?fields=gid,title&ids=${collectionIds.join(",")}`, {
                //     cb: (res: any) => {
                //         if (res.success) return res.collections
                //         return []
                //     }
                // }),
                variantIds.length
                    ? vFetch(
                          `/variants/set?fields=gid,parent_gid,title,unit_cost,price,compare_at_price,sku&current_cost=true&ids=${variantIds.join(
                              ','
                          )}`,
                          {
                              cb: (res: any) => {
                                  if (res.success)
                                      return res.variants.map((v: any) => ({ ...v, unit_cost: v.unit_cost }))
                                  return []
                              },
                          }
                      )
                    : [],
            ]
            setAffectedResourcesLoading(true)
            Promise.all(fetches).then((res) => {
                const [priceCutsConnections, products, variants] = res
                const resourcesToMap = [...products, ...variants]
                const mappedAffectedResources = priceCutsConnections.map((pcc: any) => {
                    const foundResource = resourcesToMap.find((r) => r.gid === pcc.parent_gid) || {}
                    return {
                        ...foundResource,
                        ...pcc,
                        original_cost: pcc.original_cost || foundResource.original_cost,
                    }
                })
                setAffectedResources((previousState) => mappedAffectedResources)

                setAffectedResourcesLoading(false)
            })
        } else {
            setAffectedResources([])
        }
    }, [selectedPriceCut])

    // Set show submit
    useEffect(() => {
        if (popList.length > 0 || pushList.find((resource) => !affectedResources.find((r) => r.gid === resource.gid))) {
            setResourceListEdited(true)
        } else {
            setResourceListEdited(false)
        }
    }, [pushList, popList])

    // Initialize pushList
    useEffect(() => {
        setPushList([...affectedResources])
    }, [affectedResources])

    const handleChangeEditablePriceCut = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        setEditablePriceCut(
            (previousState: any) =>
                ({
                    ...previousState,
                    [event.target.name]: event.target.value,
                }) as any
        )
    }

    const handleCreate = () => {
        if (editablePriceCut) {
            const [startsOn, endsOn] = getStartAndEndDates(
                editablePriceCut.starts_on,
                editablePriceCut.starts_on_time,
                editablePriceCut.ends_on,
                editablePriceCut.ends_on_time
            )
            vFetch(`/priceCuts`, {
                method: 'POST',
                body: JSON.stringify({
                    name: editablePriceCut.name,
                    amount: parseFloat(editablePriceCut.amount),
                    amount_unit: editablePriceCut.amount_unit,
                    status: editablePriceCut.status,
                    pdp_line_1: editablePriceCut.pdp_line_1,
                    pdp_line_2: editablePriceCut.pdp_line_2,
                    cart_line_1: editablePriceCut.cart_line_1,
                    starts_on: editablePriceCut.status === 'SCHEDULED' ? startsOn : null,
                    ends_on: editablePriceCut.status === 'SCHEDULED' ? endsOn : null,
                    resources: pushList.map((resource) => resource.gid),
                    overrides: pushList
                        .filter(
                            (resource) =>
                                (resource.amount_override && resource.amount_unit_override) ||
                                (resource.cost && resource.cost_unit)
                        )
                        .map((resource) => ({
                            gid: resource.gid,
                            amount_override: parseFloat(resource.amount_override),
                            amount_unit_override: resource.amount_unit_override,
                            cost: parseFloat(resource.cost),
                            cost_unit: resource.cost_unit,
                            original_cost: parseFloat(resource.original_cost || 0),
                        })),
                }),
                cb: (res: any) => {
                    if (res.success) clearProcessingTime()
                },
            })
        }
    }

    const handleUpdate = () => {
        if (editablePriceCut) {
            const [startsOn, endsOn] = getStartAndEndDates(
                editablePriceCut.starts_on,
                editablePriceCut.starts_on_time,
                editablePriceCut.ends_on,
                editablePriceCut.ends_on_time
            )
            vFetch(`/priceCuts`, {
                method: 'PUT',
                body: JSON.stringify({
                    id: editablePriceCut.id,
                    name: editablePriceCut.name,
                    amount: parseFloat(editablePriceCut.amount),
                    amount_unit: editablePriceCut.amount_unit,
                    pdp_line_1: editablePriceCut.pdp_line_1,
                    pdp_line_2: editablePriceCut.pdp_line_2,
                    cart_line_1: editablePriceCut.cart_line_1,
                    status: editablePriceCut.status,
                    starts_on: editablePriceCut.status === 'SCHEDULED' ? startsOn : null,
                    ends_on: editablePriceCut.status === 'SCHEDULED' ? endsOn : null,
                    resources_to_push: pushList.map((resource) => resource?.gid),
                    resources_to_pop: popList.map((resource) => resource?.gid),
                    overrides: pushList
                        .filter(
                            (resource) =>
                                (resource.amount_override && resource.amount_unit_override) ||
                                (resource.cost && resource.cost_unit)
                        )
                        .map((resource) => ({
                            gid: resource.gid,
                            amount_override: parseFloat(resource.amount_override),
                            amount_unit_override: resource.amount_unit_override,
                            cost: parseFloat(resource.cost),
                            cost_unit: resource.cost_unit,
                            original_cost: parseFloat(resource.original_cost || 0),
                        })),
                }),
                cb: (res: any) => {
                    if (res.success) clearProcessingTime()
                },
            })
        }
    }

    const handleDelete = () => {
        if (editablePriceCut) {
            vFetch(`/priceCuts`, {
                method: 'DELETE',
                body: JSON.stringify({ id: editablePriceCut.id }),
                cb: (res: any) => {
                    if (res.success) clearProcessingTime()
                },
            })
        }
    }
    const clearProcessingTime = (refresh?: boolean) => {
        setSelectedPriceCut(null)
        setPushList([])
        setPopList([])
        setEdited(false)
        if (refresh) {
            setTimeout(() => {
                setNeedsRefresh(true)
            }, 500)
        }
    }

    async function getScheduleMap() {
        const [startsOn, endsOn] = getStartAndEndDates(
            editablePriceCut.starts_on,
            editablePriceCut.starts_on_time,
            editablePriceCut.ends_on,
            editablePriceCut.ends_on_time
        )
        const scheduleMap = await vFetch(`/priceCuts/schedule`, {
            method: 'POST',
            body: JSON.stringify({
                name: editablePriceCut.name,
                amount: parseFloat(editablePriceCut.amount),
                amount_unit: editablePriceCut.amount_unit,
                status: editablePriceCut.status,
                starts_on: editablePriceCut.status === 'SCHEDULED' ? startsOn : null,
                ends_on: editablePriceCut.status === 'SCHEDULED' ? endsOn : null,
                resources: pushList.map((resource) => resource.gid),
            }),
            cb: (res: any) => res.scheduleMap || {},
            catchCb: (err: any) => {
                return {}
            },
        })
        return scheduleMap || {}
    }

    function getStartAndEndDates(starts_on: string, starts_on_time: string, ends_on: string, ends_on_time: string) {
        const startsOnSplit = starts_on ? starts_on.split('-') : null
        const startsOnTimeSplit = starts_on_time ? starts_on_time.split(':') : ['0', '0']
        let startsOn
        if (startsOnSplit) {
            const [year, month, date] = startsOnSplit
            const [hours, minutes] = startsOnTimeSplit
            startsOn = timeHandler.getISOString(
                parseInt(year),
                parseInt(month),
                parseInt(date),
                parseInt(hours),
                parseInt(minutes)
            )
        }
        const endsOnSplit = ends_on ? ends_on.split('-') : null
        const endsOnTimeSplit = ends_on_time ? ends_on_time.split(':') : ['0', '0']
        let endsOn
        if (endsOnSplit) {
            const [year, month, date] = endsOnSplit
            const [hours, minutes] = endsOnTimeSplit
            endsOn = timeHandler.getISOString(
                parseInt(year),
                parseInt(month),
                parseInt(date),
                parseInt(hours),
                parseInt(minutes)
            )
        }
        return [startsOn, endsOn]
    }
    return (
        <>
            {editablePriceCut && (
                <div className='fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'>
                    <div className='p-[32px] w-[90vw] h-[90vh] flex gap-[32px] overflow-auto bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative'>
                        <button
                            onClick={() => clearProcessingTime(false)}
                            className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                        >
                            &times;
                        </button>
                        <div className='flex flex-col gap-[16px] w-1/2'>
                            {isNotNewPriceCut ? (
                                <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mt-[-16px] ml-[-16px]'>
                                    Edit Price Cut
                                </h2>
                            ) : (
                                <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mt-[-16px] ml-[-16px]'>
                                    Create Price Cut
                                </h2>
                            )}
                            <div className='flex flex-col gap-[8px] w-full'>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Name
                                    </label>
                                    <input
                                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='name'
                                        name='name'
                                        onChange={handleChangeEditablePriceCut}
                                        value={editablePriceCut?.name}
                                    />
                                </div>
                                <div className='flex gap-[8px]'>
                                    <div className='flex flex-col gap-[4px] w-full'>
                                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Status
                                        </label>
                                        <select
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            id='status'
                                            name='status'
                                            onChange={handleChangeEditablePriceCut}
                                            value={editablePriceCut?.status}
                                        >
                                            <option value='PAUSED'>Paused</option>
                                            <option value='SCHEDULED'>Scheduled</option>
                                            <option value='ACTIVE'>Active</option>
                                        </select>
                                    </div>
                                </div>
                                {editablePriceCut.status === 'SCHEDULED' && (
                                    <div className='flex gap-[8px]'>
                                        <div className='flex flex-col gap-[4px] w-1/4'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Starts On
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                                type='date'
                                                id='starts_on'
                                                name='starts_on'
                                                onChange={handleChangeEditablePriceCut}
                                                value={editablePriceCut?.starts_on?.split('T')[0]}
                                            />
                                        </div>
                                        <div className='flex flex-col gap-[4px] w-1/4'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                &nbsp;
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                                type='time'
                                                id='starts_on_time'
                                                name='starts_on_time'
                                                onChange={handleChangeEditablePriceCut}
                                                value={editablePriceCut?.starts_on_time}
                                            />
                                        </div>
                                        <div className='flex flex-col gap-[4px] w-1/4'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Ends On
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                                type='date'
                                                id='ends_on'
                                                name='ends_on'
                                                onChange={handleChangeEditablePriceCut}
                                                value={editablePriceCut?.ends_on?.split('T')[0]}
                                            />
                                        </div>
                                        <div className='flex flex-col gap-[4px] w-1/4'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                &nbsp;
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                                type='time'
                                                id='ends_on_time'
                                                name='ends_on_time'
                                                onChange={handleChangeEditablePriceCut}
                                                value={editablePriceCut?.ends_on_time}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className='flex gap-[8px]'>
                                    <div className='flex flex-col gap-[4px] w-3/4'>
                                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Amount Reduction
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            type='number'
                                            id='amount'
                                            name='amount'
                                            onChange={handleChangeEditablePriceCut}
                                            value={editablePriceCut?.amount}
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px] w-1/4'>
                                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Amount Unit
                                        </label>
                                        <select
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            id='amount_unit'
                                            name='amount_unit'
                                            onChange={handleChangeEditablePriceCut}
                                            value={editablePriceCut?.amount_unit}
                                        >
                                            <option value='FIXED'>Fixed</option>
                                            <option value='PERCENT'>Percent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='p-[8px] rounded-[4px] bg-lightgrey dark:bg-darkness'>
                                    <p className='text-[12px] font-bold uppercase text-white leading-[1] mt-[-8px] ml-[-8px] mb-[4px] p-[4px] bg-blue dark:bg-accent dark:text-darkness w-fit rounded-tl-[4px]'>
                                        PROCESSING TIME - OPTIONAL
                                    </p>
                                    <div className='flex flex-col gap-[4px] mb-[4px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            PDP Line 1
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-white focus:outline-none dark:text-white dark:bg-faintplus p-[4px] rounded-[4px]'
                                            id='pdp_line_1'
                                            name='pdp_line_1'
                                            onChange={handleChangeEditablePriceCut}
                                            value={editablePriceCut?.pdp_line_1}
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px] mb-[4px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            PDP Line 2
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-white focus:outline-none dark:text-white dark:bg-faintplus p-[4px] rounded-[4px]'
                                            id='pdp_line_2'
                                            name='pdp_line_2'
                                            onChange={handleChangeEditablePriceCut}
                                            value={editablePriceCut?.pdp_line_2}
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Dynamic Cart Message
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-white focus:outline-none dark:text-white dark:bg-faintplus p-[4px] rounded-[4px]'
                                            id='cart_line_1'
                                            name='cart_line_1'
                                            onChange={handleChangeEditablePriceCut}
                                            value={editablePriceCut?.cart_line_1}
                                        />
                                    </div>
                                </div>
                            </div>
                            <PreviewBox processingTime={editablePriceCut} />
                            <div className='flex gap-[16px] mt-auto justify-center'>
                                {isNotNewPriceCut && (
                                    <button
                                        onClick={handleDelete}
                                        className='py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-red dark:text-white dark:bg-lightred uppercase'
                                    >
                                        Delete
                                    </button>
                                )}
                                {(edited || resourceListEdited) && (
                                    <button
                                        onClick={isNotNewPriceCut ? handleUpdate : handleCreate}
                                        className='py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-blue dark:text-darkness dark:bg-accent uppercase'
                                    >
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                        <ResourceList
                            setResourceListEdited={setResourceListEdited}
                            affectedResourcesLoading={affectedResourcesLoading}
                            affectedResources={affectedResources}
                            pushList={pushList}
                            setPushList={setPushList}
                            setPopList={setPopList}
                            amount={editablePriceCut?.amount}
                            amount_unit={editablePriceCut.amount_unit}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
