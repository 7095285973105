import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { Button } from '../../custom_components/component_Basics/Button'
import { vFetch } from '../../helpers'
import { FaTrashCan } from 'react-icons/fa6'
import { MdEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { isUserOrAdmin } from '../helpers'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import EditArticleView from './EditArticleView'
import { IoMdCheckmark } from 'react-icons/io'
import { articleCardDateFormatter } from '../helpers'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import UsersName from '../components/UsersName'

export default function MyContributionsArticleView({
    selectedArticle,
    setSelectedArticle,
    setView,
}: {
    selectedArticle: any
    setSelectedArticle: any
    setView: any
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const myArticleID = searchParams.get('myArticleID')
    const [isLoading, setIsLoading] = useState(true) //
    const [isError, setIsError] = useState(false)
    const [article, setArticle] = useState<any>({})
    const [reviews, setReviews] = useState<any>({})
    const currentUser = useSelector((state: any) => state.user) //
    const canEdit = isUserOrAdmin(currentUser, selectedArticle) //
    const [showEditButton, setShowEditButton] = useState(true) //
    const [editArticle, setEditArticle] = useState(false) //
    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || { users: [] }
    const user = users?.find((user) => {
        return user.id === selectedArticle.user_id
    })
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [selectedArticleReview, setSelectedArticleReview] = useState()
    const queryClient = useQueryClient()

    const getArticles = async () => {
        vFetch(`/knowledge/articles?id=${selectedArticle.id}`, {
            cb: (res: any) => {
                setIsLoading(false)
                if (!res.success) {
                    setIsError(true)
                }
                if (res.success) {
                    setArticle(res.articles[0])
                }
            },
        })
    }
    const articleReviewQuery = useQuery({
        queryKey: ['knowledge', 'articles', 'reviews'],
        queryFn: async () => {
            return await vFetch(`/knowledge/articles/reviews?article_id=${selectedArticle.id}`, {
                cb: (res: any) => {
                    if (!res.success) {
                        return []
                    }
                    return res.articleReviews
                },
            })
        },
    })
    const articleReviews = articleReviewQuery?.data || []

    function handleCancelArticleEdit() {
        setShowEditButton(true)
        setEditArticle(false)
        setConfirmCancel(false)
    }
    const handleEditArticleClick = () => {
        setShowEditButton(false)
    }
    const handleDeleteArticle = (id: any) => {
        return vFetch('/knowledge/articles', {
            method: 'DELETE',
            body: JSON.stringify({ id }),
            cb: (res: any) => {
                if (res.success) {
                    setView('departmentView')
                    setSearchParams((prev) => {
                        prev.delete(`article${selectedArticle.id}`)
                        prev.delete('articleID')
                        prev.delete('departmentView')
                        return prev
                    })
                    return queryClient.invalidateQueries({
                        queryKey: ['knowledge', 'articles', 'global'],
                    })
                }
            },
        })
    }
    const handleDeleteAttempt = () => {
        setConfirmCancel(true)
    }
    const handleCancelArticleDelete = () => {
        setShowEditButton(true)
        setEditArticle(false)
        setConfirmCancel(false)
    }
    const handleClickEditButton = (selectedArticle: any) => {
        setView('myEditArticleView')
        setSelectedArticle(selectedArticle)
        setSearchParams((prev: any) => {
            prev.set('myEditArticle', selectedArticle.id)
            return prev
        })
    }
    const handleBackButton = () => {
        setSelectedArticle(undefined)
        setSearchParams((prev) => {
            prev.delete('myArticleID')
            return prev
        })
    }
    useEffect(() => {
        getArticles()
    }, [selectedArticle])

    return (
        <div className='relative flex flex-col items-center px-2 mt-4 mb-4'>
            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}
            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}
            {!isLoading && !isError && (
                <div className='self-start'>
                    <div className='mt-4'>
                        <Button onClick={handleBackButton} variant={'outline'} className='mx-auto text-xs'>
                            BACK
                        </Button>
                    </div>
                    <div className='w-full absolute top-[-3px] left-1/2 translate-x-[-50%] translate-y-[-50%]'>
                        {article.status === 'Pending' && (
                            <div className='px-[35%] bg-yellow-300/75 border border-yellow-400/75 rounded'>
                                Your Article is under review
                            </div>
                        )}
                        {article.status === 'Published' && (
                            <div className='px-[35%] bg-green-300/50 border border-green-400/25 rounded'>
                                Your Article is published
                            </div>
                        )}
                        {article.status === 'Rejected' && (
                            <div className='px-[35%] bg-red/50 border border-red/75 rounded'>
                                Your Article was rejected
                            </div>
                        )}
                    </div>
                    {canEdit && !editArticle && (
                        <div className='absolute right-5 top-4 mt-2'>
                            {showEditButton ? (
                                <button
                                    className='text-black dark:text-offwhite text-xl font-bold'
                                    onClick={handleEditArticleClick} //
                                >
                                    <BsThreeDotsVertical />
                                </button>
                            ) : (
                                <div>
                                    <button
                                        onClick={handleCancelArticleEdit} //
                                        className='shrink-0 whitespace-nowrap
                                 dark:text-offwhite text-xl font-bold'
                                    >
                                        <FaTimes className='text-red w-[22px] h-[22px]' />
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    {!showEditButton && !editArticle && !confirmCancel && (
                        <div className='absolute right-6 top-13 flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                            <button
                                onClick={() => handleClickEditButton(selectedArticle)}
                                value='Edit'
                                className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'
                            >
                                <MdEdit className='w-[20px]' />
                                Edit Article
                            </button>
                            <button
                                value='Delete'
                                onClick={handleDeleteAttempt}
                                className='flex gap-2 items-center mb-2 mx-2 text-red/75 dark:text-offwhite'
                            >
                                <FaTrashCan className='w-[20px] text-red/75 dark:text-lightred text-sm' />
                                Delete Article
                            </button>
                        </div>
                    )}
                </div>
            )}

            {confirmCancel && (
                <div className='absolute right-6 top-12 items-center flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                    <div className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'>Are you sure?</div>
                    <button
                        value='Yes - Delete'
                        onClick={() => handleDeleteArticle(selectedArticle.id)}
                        className='p-1 px-[22px] rounded flex gap-2 items-center mb-1.5 mx-2 text-white dark:text-offwhite bg-red/75 dark:bg-lightred'
                    >
                        Yes
                    </button>
                    <button
                        value='No - Cancel'
                        onClick={handleCancelArticleDelete}
                        className='p-1 px-6 rounded flex gap-2 items-center mb-2 mx-2 text-white dark:text-offwhite bg-grey'
                    >
                        No
                    </button>
                </div>
            )}
            {!isLoading && !isError && (
                <div className='max-w-[913px] mx-auto'>
                    <div>
                        <h1 className='mt-5 font-semibold text-5xl'>{article.title}</h1>
                        <h3 className='mt-4 max-w-[913px] text-lg '>{article.excerpt}</h3>
                        <p></p>
                    </div>
                    <img className='w-full max-w-[800px] mt-5 rounded' src={article.featured_image} />
                    <div className='mt-3 p-2 w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                    <div className='mt-3 flex self-start'>
                        {/* <div className='mt-3 shrink-0 rounded-full flex items-center justify-center w-[40px] h-[40px] text-2xl font-bold text-white dark:text-offwhite bg-orange-500'>
                    {article.user_firstName[0]}
                    {article.user_lastName[0]}
                </div> */}
                        <div>
                            <div className='mr-1 font-semibold leading-none'>
                                by {article.user_firstName} {article.user_lastName}
                            </div>
                            <div className='mt-1 self-start text-sm text-grey'>
                                Updated: {articleCardDateFormatter.format(new Date(article.updated_at))}
                            </div>
                        </div>
                    </div>
                    <div className='ql-snow'>
                        <div
                            style={{ padding: '0px' }}
                            className='ql-editor mt-7'
                            dangerouslySetInnerHTML={{ __html: article.content }}
                        ></div>
                    </div>
                    <div className='mt-6 p-2 w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                </div>
            )}
            {!isLoading && !isError && articleReviews.length > 0 && (
                <div className='flex flex-col p-4 w-[100%] max-w-[913px] mt-2 bg-white  dark:bg-darkaccent'>
                    {/* <div className='flex flex-col p-4 w-[100%] max-w-[1000px] mt-10 bg-white  dark:bg-darkaccent rounded border border-lightgrey dark:border-darkgrey'> */}
                    {articleReviews.length === 1 ? (
                        <p className='text-xl font-bold pb-4 ml-1'>Article Review</p>
                    ) : (
                        <p className='text-xl font-bold pb-4 ml-1'>Article Reviews</p>
                    )}
                    <div
                        className='min-h-[40px] w-[100%] max-w-none 
                    dark:bg-darkbg1 dark:text-offwhite pb-0 border rounded border-lightgrey dark:border-darkgrey border-b-0'
                    >
                        {articleReviews?.map((review: any) => (
                            <div className='p-4 border-b rounded border-lightgrey dark:border-darkgrey'>
                                <div className='flex gap-2'>
                                    <div className='shrink-0 rounded-full flex items-center justify-center w-[34px] h-[34px] text-xl font-bold text-white dark:text-offwhite bg-orange-500'>
                                        {review.author[0]}
                                    </div>
                                    <div className='flex flex-col leading-none'>
                                        <div className=''>{review.author}</div>
                                        <div className='text-sm text-darkgrey dark:text-grey'>
                                            {articleCardDateFormatter.format(new Date(review.created_at))}
                                        </div>
                                    </div>
                                    {/* <div className=''>
                                        <UsersName questionRow={review} verb={'posted'} textSize={'text-md'} />
                                    </div> */}
                                </div>
                                {/* <div className=''>{articleCardDateFormatter.format(new Date(review.created_at))}</div> */}
                                <div className='mt-2'> {review.message} </div>
                                {/* <div className='mt-2'>
                                    {review.author} reviewed{' '}
                                    {articleCardDateFormatter.format(new Date(review.created_at))}
                                </div> */}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
