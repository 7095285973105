import { useContext, useState } from 'react'
import { BsArrowDown } from 'react-icons/bs'
import { useCompanyFilesQuery } from '../../api/useQueries'
import { CompanyContext, ProcurementContext, ProcurementContextType } from '../../helpers'
import FilterToolBar from '../FilterToolBar'
import CompanyFileV2 from '../listItems/CompanyFileV2'
import CSVMapper from '../modals/CSVMapper'

export default function FileListV2() {
    const { selectedCompany, users } = useContext<ProcurementContextType>(ProcurementContext)
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [showCSVMapper, setShowCSVMapper] = useState(false)
    const [selectedFile, setSelectedFile] = useState()

    const [search, setSearch] = useState('')

    const companyFilesQuery = useCompanyFilesQuery({ companyId: selectedCompany.id })
    const { files }: { files: any[] } = companyFilesQuery?.data || {}

    const filteredFiles = files?.filter((file) => {
        return (
            file.name?.toLowerCase().includes(search.toLowerCase()) ||
            file.mime_type?.toLowerCase().includes(search.toLowerCase())
        )
    })

    return (
        <div className='px-[16px] pl-0'>
            {!showCSVMapper && (
                <>
                    <div className='mb-[8px] w-full flex gap-[16px]'>
                        <FilterToolBar search={search} setSearch={setSearch} filterBarObject={undefined} />
                        <button
                            onClick={() => setShowQuickModal('file')}
                            className='min-h-[32px] self-center shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                        >
                            + New File
                        </button>
                        <a
                            href={
                                'https://fpdash-bucket.s3.amazonaws.com/procurement/1/1782/product_import_template_csv.csv'
                            }
                            className='flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        >
                            <span className='rounded-full border   border-darkgrey dark:border-offwhite'>
                                <BsArrowDown className='text-darkgrey dark:text-offwhite p-[4px] w-[24px] h-[24px]' />
                            </span>
                            <p className='font-medium leading-none uppercase text-[12px] whitespace-nowrap'>
                                Template <br /> Download
                            </p>
                        </a>
                    </div>
                    <div className='flex flex-col overflow-auto'>
                        <div className='sticky top-0 grid grid-cols-[1fr_1fr_1fr_1fr_50px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Type</div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>File Type</div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                Uploaded On
                            </div>
                        </div>
                        {filteredFiles?.map((file: any) => (
                            <CompanyFileV2
                                key={file.id}
                                file={file}
                                setSelectedFile={setSelectedFile}
                                setShowCSVMapper={setShowCSVMapper}
                            />
                        ))}
                        <div className='m-auto mt-20 p-2 opacity-80 rounded-md text-darkgrey dark:text-lightgrey border border-darkgrey dark:border-darkgrey'>
                            Upload a .csv file to import products
                        </div>
                    </div>
                </>
            )}
            {showCSVMapper && <CSVMapper file={selectedFile} setShowCSVMapper={setShowCSVMapper} />}
        </div>
    )
}
