import { ChangeEvent, useEffect, useState } from 'react'
import { MessageModalProps, ProcessingTimesRow, ResourceType } from '../../ProcessingTimesScreen.types'
import ResourceList from '../ResourceList'
import PreviewBox from '../PreviewBox'
import { TimeHandler, vFetch } from '../../../helpers'
const timeHandler = new TimeHandler()

export default function MessageModal({
    selectedProcessingTime,
    setSelectedProcessingTime,
    setNeedsRefresh,
}: MessageModalProps) {
    const [affectedResourcesLoading, setAffectedResourcesLoading] = useState<boolean>(false)
    const [affectedResources, setAffectedResources] = useState<ResourceType[]>([])
    const [editableProcessingTime, setEditableProcessingTime] = useState<ProcessingTimesRow | null>(
        selectedProcessingTime
    )
    const [edited, setEdited] = useState<boolean>(false)
    const [resourceListEdited, setResourceListEdited] = useState<boolean>(false)
    const [pushList, setPushList] = useState<ResourceType[]>([...affectedResources])
    const [popList, setPopList] = useState<ResourceType[]>([])
    const isNotNewProcessingTime = selectedProcessingTime?.name ? true : false

    useEffect(() => {
        if (
            JSON.stringify(selectedProcessingTime).split('').sort().join('') ===
            JSON.stringify(editableProcessingTime).split('').sort().join('')
        ) {
            setEdited(false)
        } else {
            setEdited(true)
        }
    }, [selectedProcessingTime, editableProcessingTime])
    useEffect(() => {
        setEditableProcessingTime(selectedProcessingTime)
        if (selectedProcessingTime?.affected_resources) {
            const gids = selectedProcessingTime.affected_resources.split(',')
            const productIds = gids
                .filter((gid) => gid.includes('/Product/'))
                .map((gid) => gid.replace('gid://shopify/Product/', ''))
            const collectionIds = gids
                .filter((gid) => gid.includes('/Collection/'))
                .map((gid) => gid.replace('gid://shopify/Collection/', ''))
            const variantIds = gids
                .filter((gid) => gid.includes('/ProductVariant/'))
                .map((gid) => gid.replace('gid://shopify/ProductVariant/', ''))
            const fetches = [
                productIds.length
                    ? vFetch(`/products/set?fields=gid,title&ids=${productIds.join(',')}`, {
                          cb: (res: any) => {
                              if (res.success) return res.products
                              return []
                          },
                      })
                    : [],
                collectionIds.length
                    ? vFetch(
                          `/v2/collections/set?fields=admin_graphql_api_id AS gid,title&ids=${collectionIds.join(',')}`,
                          {
                              cb: (res: any) => {
                                  if (res.success) return res.collections
                                  return []
                              },
                          }
                      )
                    : [],
                variantIds.length
                    ? vFetch(`/variants/set?fields=gid,title,sku&ids=${variantIds.join(',')}`, {
                          cb: (res: any) => {
                              if (res.success) return res.variants
                              return []
                          },
                      })
                    : [],
            ]
            setAffectedResourcesLoading(true)
            Promise.all(fetches).then((res) => {
                const [products, collections, variants] = res
                setAffectedResources((previousState) => [...products, ...collections, ...variants])
                setAffectedResourcesLoading(false)
            })
        } else {
            setAffectedResources([])
        }
    }, [selectedProcessingTime])

    // Set show submit
    useEffect(() => {
        if (popList.length > 0 || pushList.find((resource) => !affectedResources.find((r) => r.gid === resource.gid))) {
            setResourceListEdited(true)
        } else {
            setResourceListEdited(false)
        }
    }, [pushList, popList])

    // Initialize pushList
    useEffect(() => {
        setPushList([...affectedResources])
    }, [affectedResources])

    const handleChangeEditableProcessingTime = (
        event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        setEditableProcessingTime(
            (previousState) =>
                ({
                    ...previousState,
                    [event.target.name]: event.target.value,
                }) as ProcessingTimesRow
        )
    }

    const handleCreate = () => {
        if (editableProcessingTime) {
            const startsOnSplit = editableProcessingTime.starts_on ? editableProcessingTime.starts_on.split('-') : null
            let startsOn
            if (startsOnSplit) {
                const [year, month, date] = startsOnSplit
                startsOn = timeHandler.getISOString(parseInt(year), parseInt(month), parseInt(date))
            }
            const endsOnSplit = editableProcessingTime.ends_on ? editableProcessingTime.ends_on.split('-') : null
            let endsOn
            if (endsOnSplit) {
                const [year, month, date] = endsOnSplit
                endsOn = timeHandler.getISOString(parseInt(year), parseInt(month), parseInt(date))
            }
            vFetch(`/processingTimes`, {
                method: 'POST',
                body: JSON.stringify({
                    name: editableProcessingTime.name,
                    type: editableProcessingTime.type,
                    pdp_line_1: editableProcessingTime.pdp_line_1,
                    pdp_line_2: editableProcessingTime.pdp_line_2,
                    cart_line_1: editableProcessingTime.cart_line_1,
                    starts_on: startsOn,
                    ends_on: endsOn,
                    affected_resources: pushList.map((resource) => resource.gid),
                }),
                cb: (res: any) => {
                    if (res.success) clearProcessingTime()
                },
            })
        }
    }

    const handleUpdate = () => {
        if (editableProcessingTime) {
            const startsOnSplit = editableProcessingTime.starts_on ? editableProcessingTime.starts_on.split('-') : null
            let startsOn
            if (startsOnSplit) {
                const [year, month, date] = startsOnSplit
                startsOn = timeHandler.getISOString(parseInt(year), parseInt(month), parseInt(date))
            }
            const endsOnSplit = editableProcessingTime.ends_on ? editableProcessingTime.ends_on.split('-') : null
            let endsOn
            if (endsOnSplit) {
                const [year, month, date] = endsOnSplit
                endsOn = timeHandler.getISOString(parseInt(year), parseInt(month), parseInt(date))
            }
            vFetch(`/processingTimes`, {
                method: 'PUT',
                body: JSON.stringify({
                    id: editableProcessingTime.id,
                    name: editableProcessingTime.name,
                    type: editableProcessingTime.type,
                    pdp_line_1: editableProcessingTime.pdp_line_1,
                    pdp_line_2: editableProcessingTime.pdp_line_2,
                    cart_line_1: editableProcessingTime.cart_line_1,
                    starts_on: startsOn,
                    ends_on: endsOn,
                    resources_to_push: pushList.map((resource) => resource.gid),
                    resources_to_pop: popList.map((resource) => resource.gid),
                }),
                cb: (res: any) => {
                    if (res.success) clearProcessingTime()
                },
            })
        }
    }

    const handleDelete = () => {
        if (editableProcessingTime) {
            vFetch(`/processingTimes`, {
                method: 'DELETE',
                body: JSON.stringify({
                    id: editableProcessingTime.id,
                }),
                cb: (res: any) => {
                    if (res.success) clearProcessingTime()
                },
            })
        }
    }
    const clearProcessingTime = (refresh?: boolean) => {
        setSelectedProcessingTime(null)
        setPushList([])
        setPopList([])
        setEdited(false)
        if (refresh) {
            setTimeout(() => {
                setNeedsRefresh(true)
            }, 500)
        }
    }
    return (
        <>
            {editableProcessingTime && (
                <div className='fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'>
                    <div className='p-[32px] w-[90vw] h-[90vh] flex gap-[32px] overflow-auto bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative'>
                        <button
                            onClick={() => clearProcessingTime(false)}
                            className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                        >
                            &times;
                        </button>
                        <div className='flex flex-col gap-[16px] w-1/2'>
                            {isNotNewProcessingTime ? (
                                <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mt-[-16px] ml-[-16px]'>
                                    Edit Processing Time
                                </h2>
                            ) : (
                                <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mt-[-16px] ml-[-16px]'>
                                    Create Processing Time
                                </h2>
                            )}
                            <div className='flex flex-col gap-[8px] w-full'>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Name
                                    </label>
                                    <input
                                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='name'
                                        name='name'
                                        onChange={handleChangeEditableProcessingTime}
                                        value={editableProcessingTime?.name}
                                    />
                                </div>
                                <div className='flex gap-[8px]'>
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Starts On
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            type='date'
                                            id='starts_on'
                                            name='starts_on'
                                            onChange={handleChangeEditableProcessingTime}
                                            value={editableProcessingTime?.starts_on?.split('T')[0]}
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Ends On
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            type='date'
                                            id='ends_on'
                                            name='ends_on'
                                            onChange={handleChangeEditableProcessingTime}
                                            value={editableProcessingTime?.ends_on?.split('T')[0]}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Type
                                    </label>
                                    <select
                                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='type'
                                        name='type'
                                        onChange={handleChangeEditableProcessingTime}
                                        value={editableProcessingTime?.type}
                                        disabled={isNotNewProcessingTime}
                                    >
                                        <option value='PDP'>PDP</option>
                                        <option value='CART'>CART</option>
                                    </select>
                                </div>
                                {editableProcessingTime.type !== 'CART' ? (
                                    <>
                                        <div className='flex flex-col gap-[4px]'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Line 1
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                id='pdp_line_1'
                                                name='pdp_line_1'
                                                onChange={handleChangeEditableProcessingTime}
                                                value={editableProcessingTime?.pdp_line_1}
                                            />
                                        </div>
                                        <div className='flex flex-col gap-[4px]'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Line 2
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                id='pdp_line_2'
                                                name='pdp_line_2'
                                                onChange={handleChangeEditableProcessingTime}
                                                value={editableProcessingTime?.pdp_line_2}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='flex flex-col gap-[4px]'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Dynamic Cart Message
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                id='cart_line_1'
                                                name='cart_line_1'
                                                onChange={handleChangeEditableProcessingTime}
                                                value={editableProcessingTime?.cart_line_1}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <PreviewBox processingTime={editableProcessingTime} />
                            <div className='flex gap-[16px] mt-auto justify-center'>
                                {isNotNewProcessingTime && (
                                    <button
                                        onClick={handleDelete}
                                        className='py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-red dark:text-white dark:bg-lightred uppercase'
                                    >
                                        Delete
                                    </button>
                                )}
                                {(edited || resourceListEdited) && (
                                    <button
                                        onClick={isNotNewProcessingTime ? handleUpdate : handleCreate}
                                        className='py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-blue dark:text-darkness dark:bg-accent uppercase'
                                    >
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                        <ResourceList
                            setResourceListEdited={setResourceListEdited}
                            affectedResourcesLoading={affectedResourcesLoading}
                            affectedResources={affectedResources}
                            pushList={pushList}
                            setPushList={setPushList}
                            setPopList={setPopList}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
