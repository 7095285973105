import { useEffect, useRef, useState } from 'react'
import { BsTrash3Fill } from 'react-icons/bs'
import { sendToast, sortByAlphanumeric, vFetch } from '../helpers'
import CloseButton from '../custom_components/CloseButton'

export default function RoleManager({ users, roles, setShowManageRoles, setNeedsRefresh, showDeveloperSetRoles }) {
    const outer = useRef()
    const [newUsers, setNewUsers] = useState(users)
    const [newRoles, setNewRoles] = useState(roles)
    const [selectedRole, setSelectedRole] = useState()

    function countUsersInRole(role) {
        let usersInRole = 0
        for (let user of newUsers) {
            if (user.roles.includes(role)) {
                usersInRole++
            }
        }
        return usersInRole
    }

    const [edited, setEdited] = useState(false)

    useEffect(() => {
        if (JSON.stringify(newUsers) !== JSON.stringify(users)) setEdited(true)
        else setEdited(false)
    }, [newUsers])

    useEffect(() => {
        setNewRoles(roles)
    }, [selectedRole])

    function handleSubmit() {
        if (!edited) return sendToast({ message: 'Nothing was changed.' })
        const difference = newUsers.filter((u, i) => JSON.stringify(u) !== JSON.stringify(users[i]))
        vFetch(`/users/roles`, {
            method: 'PUT',
            body: JSON.stringify({ users: difference }),
            cb: (res) => {
                if (res.success) {
                    setNeedsRefresh(true)
                    vFetch(`/users`, {
                        cb: (res2) => {
                            if (res2.success) {
                                const resUsers = res2.users
                                    .map((user) => {
                                        return {
                                            ...user,
                                            access: JSON.parse(user.access),
                                            roles: JSON.parse(user.roles),
                                        }
                                    })
                                    .sort((a, b) => sortByAlphanumeric(a, b, 'last_name', 'first_name'))
                                setNewUsers(resUsers)
                                const tempRoles = []
                                resUsers.forEach((user) =>
                                    user.roles.forEach((role) => {
                                        if (!tempRoles.includes(role)) tempRoles.push(role)
                                    })
                                )
                                setNewRoles(tempRoles.sort((a, b) => sortByAlphanumeric(a, b)))
                            }
                        },
                    })
                    setSelectedRole()
                }
            },
        })
    }

    function handleRoleNameChange({ target }) {
        let newName = target.value
        if (target.value[0] === '_' && !showDeveloperSetRoles) {
            sendToast({
                message: 'Sorry, the first character cannot be an underscore unless you are a developer/SuperAdmin.',
            })
            newName = newName.substr(1)
        }
        setSelectedRole({ ...selectedRole, name: newName })
        setNewUsers(users)
    }

    return (
        <div
            className='fixed flex top-[50px] left-[216px] h-[calc(100%-50px)] w-[calc(100%-216px)] bg-black/20 justify-center items-center duration-300'
            ref={outer}
            onClick={({ target }) => (target === outer.current ? setShowManageRoles(false) : '')}
        >
            <div className='relative min-w-[500px] min-h-[60vh] max-h-[80vh] overflow-y-auto p-[16px] bg-white dark:bg-darkaccent dark:text-offwhite shadow-small rounded duration-300'>
                <CloseButton onClick={() => setShowManageRoles(false)} />
                <h2 className='uppercase text-[24px] font-light tracking-[2px]'>Role Manager</h2>
                <div className='grid gap-[16px]'>
                    <ul className='grid'>
                        <div className='flex justify-between items-center'>
                            <h3 className='font-bold uppercase dark:text-grey text-[20px]'>Roles</h3>
                            <button
                                className='rounded font-bold uppercase bg-blue dark:bg-accent text-white dark:text-black px-[8px] py-[2px]'
                                onClick={() => setSelectedRole({ name: '', new: true })}
                            >
                                + Role
                            </button>
                        </div>
                        {newRoles
                            .filter((role) => (showDeveloperSetRoles ? true : role[0] !== '_'))
                            .map((r) => (
                                <li
                                    className='flex px-[2px] hover:bg-grey/50 rounded dark:hover:bg-blue items-center justify-between capitalize'
                                    onClick={({ target }) => {
                                        if (!target.classList.contains('JS-delete-role')) {
                                            setNewUsers(users)
                                            setNewRoles(roles)
                                            setSelectedRole({ name: r })
                                        }
                                    }}
                                >
                                    <span>
                                        {r} ({countUsersInRole(r)})
                                    </span>
                                    <div
                                        className='flex items-center cursor-pointer JS-delete-role'
                                        onClick={() => {
                                            setNewUsers(
                                                users.map((u) => {
                                                    return { ...u, roles: u.roles.filter((role) => role !== r) }
                                                })
                                            )
                                            setSelectedRole({ name: r, delete: true })
                                        }}
                                    >
                                        <BsTrash3Fill className='dark:fill-accent pointer-events-none' />
                                    </div>
                                </li>
                            ))}
                    </ul>
                    <div
                        className={`duration-300 shadow-[inset_0_0_10px_rgba(0,0,0,0.25)] ${
                            selectedRole ? 'h-auto overflow-auto p-[8px]' : 'h-0 overflow-hidden'
                        }`}
                    >
                        {selectedRole && !selectedRole.delete && (
                            <div>
                                <div className='flex justify-center'>
                                    {/* : <h3 className="font-bold capitalize dark:text-grey text-[20px] text-center">{selectedRole.name}</h3> */}
                                    <input
                                        className='capitalize px-[2px] border-[1px] border-black dark:border-blue outline-0 focus:dark:border-accent dark:bg-darkness rounded'
                                        placeholder='Role Name'
                                        value={selectedRole.name}
                                        onChange={handleRoleNameChange}
                                    />
                                </div>
                                {selectedRole.new && roles.includes(selectedRole.name) ? (
                                    <p className='text-center p-[16px]'>
                                        That role already exists. If you want to edit that role, click on it. Otherwise,
                                        please choose a different name.
                                    </p>
                                ) : (
                                    <ul className='grid gap-[2px]'>
                                        {newUsers
                                            .sort((a, b) => sortByAlphanumeric(a, b, 'last_name', 'first_name'))
                                            .map((u) => (
                                                <li className='flex gap-[8px] dark:text-offwhite'>
                                                    <input
                                                        type='checkbox'
                                                        checked={u.roles.includes(selectedRole.name.toLowerCase())}
                                                        onChange={() =>
                                                            setNewUsers(
                                                                newUsers
                                                                    .filter((user) => user.email !== u.email)
                                                                    .concat({
                                                                        ...u,
                                                                        roles: u.roles.includes(selectedRole.name)
                                                                            ? u.roles.filter(
                                                                                  (role) =>
                                                                                      role !==
                                                                                      selectedRole.name.toLowerCase()
                                                                              )
                                                                            : [
                                                                                  ...u.roles,
                                                                                  selectedRole.name.toLowerCase(),
                                                                              ],
                                                                    })
                                                            )
                                                        }
                                                    />
                                                    {u.first_name} {u.last_name}
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                        )}
                        {selectedRole && selectedRole.delete && (
                            <div className='grid'>
                                <span>
                                    Are you sure you want to delete{' '}
                                    <strong className='capitalize'>{selectedRole.name}</strong>?
                                </span>
                                <ul className='list-disc'>
                                    <span className='font-bold'>Affected users:</span>
                                    {users
                                        .filter((u) => u.roles.includes(selectedRole.name))
                                        .map((u) => (
                                            <li className='ml-[16px]'>{u.first_name + ' ' + u.last_name}</li>
                                        ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {selectedRole && (
                        <div className='flex justify-center'>
                            <button
                                className={`rounded font-bold uppercase ${
                                    edited
                                        ? selectedRole.delete
                                            ? 'bg-red !text-white'
                                            : 'bg-blue dark:bg-accent'
                                        : 'bg-grey cursor-auto'
                                } text-white dark:text-black px-[8px] py-[2px]`}
                                onClick={handleSubmit}
                            >
                                {selectedRole.delete ? 'Delete' : 'Submit'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
