export const SCAC_CODE_MAP: {
    [key: string]: {
        carrier_name: string
        shopifyShippingCarrierName: string | null,
        trackingUrl: string | null,
        fp_carrier_account_number: string,
    }
} = {
    AACT: {
        carrier_name: 'AAA Cooper',
        shopifyShippingCarrierName: null,
        trackingUrl: 'https://www.aaacooper.com/pwb/Transit/ProTrackResults.aspx',
        fp_carrier_account_number: '1876629',
    },
    ARCB: {
        carrier_name: 'ABF',
        shopifyShippingCarrierName: null,
        trackingUrl: 'https://arcb.com/tools/tracking.html',
        fp_carrier_account_number: '01969Y-0001',
    },
    DYLT: {
        carrier_name: 'Daylight',
        shopifyShippingCarrierName: null,
        trackingUrl: 'https://www.dylt.com',
        fp_carrier_account_number: '20837457',
    },
    FEXF: {
        carrier_name: 'FedEx',
        shopifyShippingCarrierName: 'FedEx',
        trackingUrl: null,
        fp_carrier_account_number: '889890029',
    },
    ODFL: {
        carrier_name: 'Old Dominion',
        shopifyShippingCarrierName: 'Old Dominion',
        trackingUrl: null,
        fp_carrier_account_number: '13489133',
    },
    GMLS: {
        carrier_name: 'R & L Carriers',
        shopifyShippingCarrierName: 'R+L Carriers',
        trackingUrl: null,
        fp_carrier_account_number: 'F84287',
    },
    SAIA: {
        carrier_name: 'Saia',
        shopifyShippingCarrierName: null,
        trackingUrl: 'https://www.saia.com/track',
        fp_carrier_account_number: '1024300',
    },
    SMTL: {
        carrier_name: 'Southwest Motor Transport',
        shopifyShippingCarrierName: null,
        trackingUrl:
            'https://profoundui.smtl.com/profoundui/start?pgm=profoundui/mwp705c&p1=0&l1=1&p2=none&l2=4&p3=none&l3=4',
        fp_carrier_account_number: '0090456',
    },
    TFIN: {
        carrier_name: 'TForce',
        shopifyShippingCarrierName: 'TForce Final Mile',
        trackingUrl: null,
        fp_carrier_account_number: '29094664-20200115',
    },
    CNWY: {
        carrier_name: 'XPO',
        shopifyShippingCarrierName: 'XPO Logistics',
        trackingUrl: null,
        fp_carrier_account_number: 'FAPUR001900',
    },
    RDWY: {
        carrier_name: 'YRC',
        shopifyShippingCarrierName: null,
        trackingUrl: 'https://my.yrc.com/tools/track',
        fp_carrier_account_number: '3681',
    },
    UPSC: {
        carrier_name: 'UPS',
        shopifyShippingCarrierName: 'UPS',
        trackingUrl: null,
        fp_carrier_account_number: '174425',
    },
    CTII: {
        carrier_name: 'Central Transport',
        shopifyShippingCarrierName: 'Central Transport',
        trackingUrl: null,
        fp_carrier_account_number: '78233452814',
    }
}
export const SHIPPING_DESTINATION_TYPES = {
    H: 'Home Address',
    BS: 'Place of Business',
    TM: 'Terminal',
}


export const STOCKING_ADDRESS = {
    zip: '78233',
    city: 'San Antonio',
    phone: '(888) 999-1522',
    company: 'FactoryPure',
    country: 'United States of America',
    address1: '13818 Lookout Rd',
    address2: '',
    province: 'Texas',
    country_code: 'USA',
    province_code: 'TX',
    first_name: '',
    last_name: ''
}