import { CheckboxIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { InventoryEmailType } from 'edi/EDIScreens.types'
import { sendToast, sendToastWarning } from 'helpers'
import { useCompanyNamesQuery, useShopifyVendorsQuery } from 'procurement/api/useQueries'
import Select from 'procurement/components/Select'
import { productSetupQueries } from 'productSetupV2/api/productSetupQueryKeys'
import {
    useCreateCompanySenderAddress,
    useDeleteCompanySenderAddress,
    useDeleteInventoryEmailMap,
    useManualInventorySheetUpload,
    useSaveInventoryMap,
} from 'productSetupV2/api/useQueries'
import { ChangeEvent, useRef, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

export default function ExternalEmailMapSetup({
    email,
    companyInfo,
    files,
    inventoryEmailMapsInfo,
}: {
    email: InventoryEmailType
    companyInfo: any
    files: any
    inventoryEmailMapsInfo: any
}) {
    const queryClient = useQueryClient()
    const [showDelete, setShowDelete] = useState<number | string | undefined>()
    const { emailId } = useParams()

    const [headingsMap, setHeadingsMap] = useState<{}>({})
    const selectedFields = new Set(Object.values(headingsMap))

    const manualInventorySheetUpload = useManualInventorySheetUpload()
    const saveInventoryMap = useSaveInventoryMap()
    const [displayedFileIndex, setDisplayedFileIndex] = useState(0)

    const [showShopifyVendors, setShowShopifyVendors] = useState(false)
    const shopifyVendorsRef = useRef<HTMLDivElement>(null)
    const shopifyVendorSearchRef = useRef<HTMLInputElement>(null)
    const [shopifyVendorSearch, setShopifyVendorSearch] = useState('')

    const inventorySheetFieldOptions = [
        {
            value: 'description',
            label: 'Description',
        },
        {
            value: 'supplier_sku',
            label: 'supplier sku',
        },
        {
            value: 'upc',
            label: 'UPC',
        },
        {
            value: 'model_number',
            label: 'model number',
        },
        {
            value: 'list_price',
            label: 'list price',
        },
        {
            value: 'quantity1',
            label: 'Quantity 1',
        },
        {
            value: 'quantity2',
            label: 'Quantity 2',
        },
        {
            value: 'quantity3',
            label: 'Quantity 3',
        },
        {
            value: 'quantity4',
            label: 'Quantity 4',
        },
        {
            value: 'quantity5',
            label: 'Quantity 5',
        },
        {
            value: 'restock_date',
            label: 'Incoming Amount Available Date',
        },
        {
            value: 'restock_amount',
            label: 'Incoming Amount',
        },
    ]

    const shopifyVendorsQuery = useShopifyVendorsQuery()
    const { vendors: shopifyVendorNames }: { vendors: string[] } = shopifyVendorsQuery?.data || {}
    const companyNamesQuery = useCompanyNamesQuery()
    const {
        companies: companyNames,
    }: { companies: { name: string; id: string | number; shopify_vendor_name: string }[] } =
        companyNamesQuery?.data || {}

    const createCompanySenderAddress = useCreateCompanySenderAddress()
    const deleteCompanySenderAddress = useDeleteCompanySenderAddress()
    const deleteInventoryEmailMap = useDeleteInventoryEmailMap()

    const filteredShopifyVendorNames = shopifyVendorNames
        ?.filter((vendorName: any) => vendorName)
        ?.filter((vendorName: any) => {
            return vendorName.toLowerCase().includes(shopifyVendorSearch?.toLowerCase())
        })

    const selectedVendorNames = companyInfo?.map((info: any) => info.shopify_vendor_name)
    const handleAddVendor = (vendorName: string | number) => {
        if (selectedVendorNames?.includes(vendorName)) {
            return sendToast({ message: 'Vendor already selected' })
        }
        const foundCompany = companyNames.find((company) => company.shopify_vendor_name === vendorName)
        if (!foundCompany) {
            sendToastWarning({
                message: `${vendorName} has no linked company. Please Link shopify vendor to company on dashboard`,
            })
            return
        }
        createCompanySenderAddress.mutate({ from: email.sent_from, companyId: foundCompany.id })
    }

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setHeadingsMap({
            ...headingsMap,
            [target.name]: target.value,
        })
    }

    const handleRemoveVendor = (companyId: string | number) => {
        deleteCompanySenderAddress.mutate({ from: email.sent_from, companyId })
    }

    const unlinkedVendors = shopifyVendorNames?.filter((vendorName) => {
        const companyShopifyVendorNames = companyNames?.map((companyName) => companyName.shopify_vendor_name)
        if (!companyShopifyVendorNames?.includes(vendorName)) {
            return vendorName
        }
    })

    const handleSelectedMap = (src: string, keyMap: any) => {
        const selectedFields = Object.values(keyMap)
        if (!selectedFields.includes('quantity1')) {
            return sendToastWarning({ message: 'Upload must include selection for quantity' })
        }
        if (
            !selectedFields.includes('supplier_sku') &&
            !selectedFields.includes('upc') &&
            !selectedFields.includes('model_number')
        ) {
            return sendToastWarning({
                message: 'Upload must include selection for at least one upc, model number or supplier sku',
            })
        }
        if (emailId && companyInfo.length > 0) {
            sendToast({
                message: 'Inventory Update started.',
            })
            manualInventorySheetUpload.mutate(
                {
                    keyMap,
                    src,
                    vendors: companyInfo.map((info: any) => info.shopify_vendor_name),
                    emailId,
                },
                {
                    onSettled: () => {
                        return queryClient.invalidateQueries({
                            queryKey: productSetupQueries.productSetup.inventory._def,
                        })
                    },
                }
            )
        }
    }
    const handleMapDelete = (id: string | number) => {
        deleteInventoryEmailMap.mutate(
            { id },
            {
                onSettled: () => {
                    return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
                },
            }
        )
    }

    const handleSaveMap = (src: string, headingString: string) => {
        if (!companyInfo) {
            return sendToastWarning({ message: 'Upload must include vendor' })
        }
        const selectedFields = Object.values(headingsMap)
        if (!selectedFields.includes('quantity1')) {
            return sendToastWarning({ message: 'Upload must include selection for quantity' })
        }
        if (
            !selectedFields.includes('supplier_sku') &&
            !selectedFields.includes('upc') &&
            !selectedFields.includes('model_number')
        ) {
            return sendToastWarning({
                message: 'Upload must include selection for at least one upc, model number or supplier sku',
            })
        }
        saveInventoryMap.mutate(
            {
                keyMap: headingsMap,
                companyIds: companyInfo.map((info: any) => info.id),
                headingString,
            },
            {
                onSettled: () => {
                    return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
                },
            }
        )
    }
    const handleSaveAndImport = (src: string, headingString: string) => {
        if (!companyInfo) {
            return sendToastWarning({ message: 'Upload must include vendor' })
        }
        const selectedFields = Object.values(headingsMap)
        if (!selectedFields.includes('quantity1')) {
            return sendToastWarning({ message: 'Upload must include selection for quantity' })
        }
        if (
            !selectedFields.includes('supplier_sku') &&
            !selectedFields.includes('upc') &&
            !selectedFields.includes('model_number')
        ) {
            return sendToastWarning({
                message: 'Upload must include selection for at least one upc, model number or supplier sku',
            })
        }
        saveInventoryMap.mutate({
            keyMap: headingsMap,
            companyIds: companyInfo.map((info: any) => info.id),
            headingString,
        })
        if (emailId && companyInfo.length > 0) {
            sendToast({
                message: 'Inventory Update started.',
            })
            manualInventorySheetUpload.mutate({
                keyMap: headingsMap,
                src,
                vendors: companyInfo.map((info: any) => info.shopify_vendor_name),
                emailId,
            })
        }
    }

    const headings = files[displayedFileIndex]?.heading_string?.split(',')
    return (
        <>
            <div className='w-1/3 relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                    Related Vendors
                </p>
                <div className='flex flex-col gap-1 rounded-[4px] dark:text-offwhite'>
                    <div className='flex flex-col'>
                        <p>
                            {!selectedVendorNames && (
                                <div className='flex justify-between'>
                                    <p>No related vendors found for this sender</p>
                                </div>
                            )}
                            {companyInfo?.map((info: any) => {
                                return (
                                    <div className='flex justify-between'>
                                        <p>{info.shopify_vendor_name}</p>
                                        <div className='flex gap-2 items-center'>
                                            {deleteCompanySenderAddress.isPending &&
                                                deleteCompanySenderAddress.variables.companyId == info.id && (
                                                    <FetchingSpinner
                                                        isFetching={deleteCompanySenderAddress.isPending}
                                                    />
                                                )}
                                            <Button
                                                onClick={() => {
                                                    handleRemoveVendor(info.id)
                                                }}
                                                size={'icon'}
                                                variant={'ghost'}
                                                className='text-danger text-xl'
                                                disabled={deleteCompanySenderAddress.isPending}
                                            >
                                                &times;
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </p>
                    </div>
                    <div ref={shopifyVendorsRef} className='relative'>
                        <div className='flex gap-2 items-center'>
                            <Button
                                className=' min-w-[90px]'
                                size={'sm'}
                                variant={'outline'}
                                onClick={(e) => {
                                    setShowShopifyVendors(showShopifyVendors ? false : true)
                                    setTimeout(() => {
                                        shopifyVendorSearchRef?.current?.focus()
                                    }, 10)
                                }}
                            >
                                {showShopifyVendors ? 'cancel' : '+ add new'}
                            </Button>
                            {createCompanySenderAddress.isPending && (
                                <FetchingSpinner isFetching={createCompanySenderAddress.isPending} />
                            )}
                        </div>
                        <div className='flex gap-2 absolute top-[40%] right-[4px] z-[4] p-0 justify-center items-center'></div>
                        {showShopifyVendors && (
                            <div className='absolute z-[30] flex flex-col top-[100%] left-0 bg-white dark:bg-darkaccent w-full shadow-small border  border-darkgrey rounded-[4px] max-h-[250px] overflow-auto'>
                                <input
                                    ref={shopifyVendorSearchRef}
                                    className='bg-transparent p-[8px] focus:outline-none border-b border-lightgrey dark:border-darkgrey rounded-[4px]'
                                    type='text'
                                    placeholder='Search'
                                    value={shopifyVendorSearch}
                                    onChange={({ target }) => setShopifyVendorSearch(target.value)}
                                />
                                {filteredShopifyVendorNames
                                    ?.filter((v) => v)
                                    .map((vendorName) => (
                                        <button
                                            onClick={() => {
                                                handleAddVendor(vendorName)
                                                setShopifyVendorSearch('')
                                                setTimeout(() => {
                                                    setShowShopifyVendors(false)
                                                }, 10)
                                            }}
                                            className='text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                        >
                                            <div className='flex justify-between items-center'>
                                                <p>{vendorName}</p>
                                                {unlinkedVendors?.includes(vendorName) && (
                                                    <ExclamationTriangleIcon className='text-danger' />
                                                )}
                                                {selectedVendorNames?.includes(vendorName) && (
                                                    <CheckboxIcon className='text-success' />
                                                )}
                                            </div>
                                        </button>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='flex gap-4 max-h-[500px]'>
                <div className='w-1/3 overflow-scroll flex flex-col justify-between relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                    <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                        Create Heading Map
                    </p>
                    <div className='rounded-[4px] dark:text-offwhite'>
                        {files?.length > 0 && (
                            <div className='flex gap-4 justify-between items-center mb-2'>
                                <p className=''>
                                    {
                                        files[displayedFileIndex]?.src?.split('/')[
                                            files[displayedFileIndex]?.src?.split('/').length - 1
                                        ]
                                    }
                                </p>
                                <div className='flex gap-2'>
                                    <Button
                                        onClick={() => {
                                            setDisplayedFileIndex((prev: any) => {
                                                if (prev - 1 < 0) {
                                                    return files?.length - 1
                                                } else {
                                                    return prev - 1
                                                }
                                            })
                                        }}
                                        className='max-h-[24px] max-w-[24px]'
                                        size={'icon'}
                                        variant={'outline'}
                                    >
                                        {'<'}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDisplayedFileIndex((prev: any) => {
                                                if (prev + 1 < files?.length) {
                                                    return prev + 1
                                                } else {
                                                    return 0
                                                }
                                            })
                                        }}
                                        className='max-h-[24px] max-w-[24px]'
                                        size={'icon'}
                                        variant={'outline'}
                                    >
                                        {'>'}
                                    </Button>
                                </div>
                            </div>
                        )}
                        <div className='flex flex-col mb-[16px] '>
                            <div className='flex gap-4 flex-col'>
                                {headings?.map((heading: string) => {
                                    return (
                                        <Select
                                            label={heading}
                                            id={heading}
                                            name={heading}
                                            onChange={handleChange}
                                            value={headingsMap[heading.trim() as keyof typeof headingsMap] ?? ''}
                                            className='capitalize dark:bg-darkbg1'
                                        >
                                            <option value={''}></option>
                                            {inventorySheetFieldOptions.map(
                                                (option: { value: string; label: string }) => {
                                                    if (
                                                        option.value === 'quantity2' &&
                                                        !selectedFields.has('quantity1')
                                                    ) {
                                                        return
                                                    }
                                                    if (
                                                        option.value === 'quantity3' &&
                                                        !selectedFields.has('quantity2')
                                                    ) {
                                                        return
                                                    }
                                                    if (
                                                        option.value === 'quantity4' &&
                                                        !selectedFields.has('quantity3')
                                                    ) {
                                                        return
                                                    }
                                                    if (
                                                        option.value === 'quantity5' &&
                                                        !selectedFields.has('quantity4')
                                                    ) {
                                                        return
                                                    }
                                                    if (
                                                        (option.value === 'sku' || option.value === 'supplier_sku') &&
                                                        selectedFields.has('sku, supplier_sku')
                                                    ) {
                                                        return (
                                                            <option disabled={true} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        )
                                                    }
                                                    if (
                                                        option.value === 'sku, supplier_sku' &&
                                                        (selectedFields.has('sku') ||
                                                            selectedFields.has('supplier_sku'))
                                                    ) {
                                                        return (
                                                            <option disabled={true} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        )
                                                    }
                                                    if (!selectedFields.has(option.value)) {
                                                        return <option value={option.value}>{option.label}</option>
                                                    } else {
                                                        return (
                                                            <option disabled={true} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        )
                                                    }
                                                }
                                            )}
                                        </Select>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between items-'>
                        <div
                            onClick={() => {
                                if (email.status == 'Success') {
                                    return sendToastWarning({
                                        message: 'Inventory sheet has already been consumed',
                                    })
                                }
                            }}
                            className='cursor-pointer'
                        >
                            <Button
                                variant={'outline'}
                                disabled={email.status == 'Success'}
                                onClick={() =>
                                    handleSaveAndImport(
                                        files[displayedFileIndex]?.src,
                                        files[displayedFileIndex]?.heading_string
                                    )
                                }
                            >
                                Save map and import
                            </Button>
                        </div>
                        <div className=''>
                            <Button
                                variant={'outline'}
                                disabled={saveInventoryMap.isPending}
                                onClick={() =>
                                    handleSaveMap(
                                        files[displayedFileIndex]?.src,
                                        files[displayedFileIndex]?.heading_string
                                    )
                                }
                            >
                                Save map
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='w-2/3 overflow-scroll relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                    <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                        Current Maps
                    </p>
                    <div className='rounded-[4px] dark:text-offwhite'>
                        <div className='flex flex-col mb-[16px]'>
                            <div className='flex gap-4 flex-col'>
                                {inventoryEmailMapsInfo?.map((emailMapInfo: any) => {
                                    const emailMap: { [key: string]: string } = JSON.parse(emailMapInfo.inventory_map)

                                    return (
                                        <div className='border p-1 px-2 rounded-md flex flex-col gap-3'>
                                            <div className='flex justify-between'>
                                                <div className='flex gap-6'>
                                                    <p>{emailMapInfo.shopify_vendor_name}</p>
                                                    <div
                                                        className='cursor-pointer'
                                                        onClick={() => {
                                                            if (email.status == 'Success') {
                                                                return sendToastWarning({
                                                                    message:
                                                                        'Inventory sheet has already been consumed',
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            variant={'outline'}
                                                            size={'sm'}
                                                            onClick={() =>
                                                                handleSelectedMap(
                                                                    files[displayedFileIndex]?.src,
                                                                    emailMap
                                                                )
                                                            }
                                                            disabled={email.status == 'Success'}
                                                            className='place-self-end max-h-[24px]'
                                                        >
                                                            Import using this map
                                                        </Button>
                                                    </div>
                                                </div>
                                                {showDelete != emailMapInfo.id && (
                                                    <button onClick={() => setShowDelete(emailMapInfo.id)}>
                                                        <FaTrash className='text-red dark:text-lightred' />
                                                    </button>
                                                )}
                                                {showDelete == emailMapInfo.id && (
                                                    <div className='flex justify-end gap-2'>
                                                        <Button
                                                            variant={'outline'}
                                                            size={'sm'}
                                                            className='pointer-events-auto text-danger h-[24px]'
                                                            onClick={() => {
                                                                return handleMapDelete(emailMapInfo.id)
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                        <Button
                                                            variant={'outline'}
                                                            size={'sm'}
                                                            className='pointer-events-auto h-[24px]'
                                                            onClick={() => setShowDelete(undefined)}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='flex flex-col justify-between'>
                                                {Object.entries(emailMap)?.map(([key, value]) => {
                                                    return (
                                                        <div className='flex gap-2'>
                                                            <p>{key}</p>
                                                            <p>{`=>`}</p>
                                                            <p>{value}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
