import { useRef, useState } from 'react'
import CloseButton from '../custom_components/CloseButton'
import { sortByAlphanumeric, vFetch } from '../helpers'

export default function CreateUserModal({ accessList, setShowCreate, roles, stores }) {
    const [newUser, setNewUser] = useState({
        access: '',
        email: '',
        roles: [],
        type: 'Client',
        store_ids: '',
    })
    const [customRoles, setCustomRoles] = useState([])
    const outer = useRef()

    const createUser = (e) => {
        vFetch(`/users/setup`, {
            method: 'POST',
            body: JSON.stringify({
                email: newUser.email,
                type: newUser.type,
                access: newUser.access.sort((a, b) => sortByAlphanumeric(a, b)),
                roles: newUser.roles.sort((a, b) => sortByAlphanumeric(a, b)),
                store_ids: newUser.store_ids.sort((a, b) => sortByAlphanumeric(a, b)),
            }),
            cb: (res) => {
                if (res.success) setShowCreate(false)
            },
        })
    }

    return (
        <div
            className='flex fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'
            onClick={({ target }) => (target === outer.current ? setShowCreate(false) : '')}
            ref={outer}
        >
            <div
                className='relative grid gap-[16px] bg-white dark:bg-darkaccent shadow-small p-[32px] min-w-[60%] rounded'
                onSubmit={createUser}
            >
                <CloseButton onClick={() => setShowCreate(false)} />
                <h2 className='uppercase text-[24px] font-light tracking-[2px]'>New User</h2>
                <div className='grid gap-[16px]'>
                    <input
                        onChange={({ target }) =>
                            setNewUser((previousState) => ({ ...previousState, email: target.value }))
                        }
                        className='bg-[whitesmoke] dark:bg-darkness grow px-[4px] rounded'
                        value={newUser.email}
                        type='email'
                        name='email'
                        id='email'
                        placeholder='Email Address'
                    />
                    <select
                        onChange={({ target }) =>
                            setNewUser((previousState) => ({ ...previousState, type: target.value }))
                        }
                        className='bg-[whitesmoke] dark:bg-darkness grow rounded'
                        value={newUser.type}
                        name='type'
                        id='type'
                    >
                        <option value='Client'>Client</option>
                        <option value='Admin'>Admin</option>
                        <option value='SuperAdmin'>SuperAdmin</option>
                    </select>
                </div>
                <div className='flex gap-[32px]'>
                    <div className='basis-[50%] grow shadow-[inset_0_0_10px_rgba(0,0,0,0.25)] p-[8px]'>
                        <div className='grid gap-[8px]'>
                            <p className='font-bold'>Access</p>
                            <div
                                className='flex gap-[8px] items-center'
                                key={'*'}
                                onClick={() =>
                                    setNewUser({ ...newUser, access: newUser.access.includes('*') ? [] : ['*'] })
                                }
                            >
                                <input
                                    type='checkbox'
                                    value={'*'}
                                    name={'*'}
                                    checked={newUser.access.includes('*')}
                                    onChange={() =>
                                        setNewUser({ ...newUser, access: newUser.access.includes('*') ? [] : ['*'] })
                                    }
                                />
                                <label for={'*'}>All Routes</label>
                            </div>
                            {accessList
                                .filter((a) => a !== '*')
                                .map((route) => (
                                    <div
                                        className='flex gap-[8px] items-center'
                                        key={route}
                                        onClick={() =>
                                            setNewUser({
                                                ...newUser,
                                                access: newUser.access.includes('*')
                                                    ? accessList.filter((a) => a !== '*' && a !== route)
                                                    : newUser.access.includes(route)
                                                        ? newUser.access.filter((access) => access !== route)
                                                        : [...newUser.access, route],
                                            })
                                        }
                                    >
                                        <input
                                            type='checkbox'
                                            value={route}
                                            name={route}
                                            checked={newUser.access.includes(route) || newUser.access.includes('*')}
                                            onChange={() =>
                                                setNewUser({
                                                    ...newUser,
                                                    access: newUser.access.includes('*')
                                                        ? accessList.filter((a) => a !== '*' && a !== route)
                                                        : newUser.access.includes(route)
                                                            ? newUser.access.filter((access) => access !== route)
                                                            : [...newUser.access, route],
                                                })
                                            }
                                        />
                                        <label for={route}>
                                            {route
                                                .replace('*', 'All Routes')
                                                .split('-')
                                                .map((val) => `${val.substring(0, 1).toUpperCase()}${val.substring(1)}`)
                                                .join(' ')}
                                        </label>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div class='flex gap-[16px] flex-col'>
                        <div className='basis-[50%] p-[16px] pr-[8px] shadow-[inset_0_0_10px_rgba(0,0,0,0.25)] max-h-[300px]'>
                            <p className='text-[14px] uppercase font-bold text-[#4a4a4a] dark:text-grey mb-[4px]'>
                                Roles
                            </p>
                            <div id='custom-roles-container' className='overflow-y-auto h-[calc(100%-48px)]'>
                                {roles.map((role, index) => (
                                    <div
                                        key={role + index}
                                        className='flex gap-[8px] w-[100%] py-[4px] border-b-[1px] border-[#cdcdcd] dark:border-darkgrey'
                                    >
                                        <input
                                            className=''
                                            id={role + 'role'}
                                            type='checkbox'
                                            name={role + 'role'}
                                            checked={newUser.roles.includes(role)}
                                            onChange={(e) =>
                                                newUser.roles.includes(role)
                                                    ? setNewUser({
                                                        ...newUser,
                                                        roles: newUser.roles.filter(
                                                            (clickedRole) => clickedRole !== role
                                                        ),
                                                    })
                                                    : setNewUser({ ...newUser, roles: [...newUser.roles, role] })
                                            }
                                        />
                                        <label className='block w-[100%] cursor-pointer' for={role + 'role'}>
                                            {role
                                                .split('-')
                                                .map((val) => `${val.substring(0, 1).toUpperCase()}${val.substring(1)}`)
                                                .join(' ')}
                                        </label>
                                    </div>
                                ))}
                                {customRoles.map((role, index) => (
                                    <div
                                        key={'customRole' + index}
                                        className='flex gap-[8px] w-[100%] py-[4px] pr-[4px] border-b-[1px] border-[#cdcdcd] dark:border-darkgrey'
                                    >
                                        <input
                                            className='customRole'
                                            id={role + '-checkbox'}
                                            type='checkbox'
                                            name={role + '-checkbox'}
                                            checked={newUser.roles.includes(role)}
                                            onChange={({ target }) => {
                                                newUser.roles.includes(role)
                                                    ? setNewUser({
                                                        ...newUser,
                                                        roles: newUser.roles.filter(
                                                            (clickedRole) => clickedRole !== role
                                                        ),
                                                    })
                                                    : setNewUser({ ...newUser, roles: [...newUser.roles, role] })
                                                if (!target.checked)
                                                    setCustomRoles(customRoles.filter((cR, cI) => cI !== index))
                                            }}
                                        />
                                        <input
                                            className='block w-[100%] dark:bg-darkness border-[1px] border-blue focus:border-accent outline-none rounded px-[4px]'
                                            id={role}
                                            name={role}
                                            value={role}
                                            // commenting this out, in favor of allowing the custom role to be deleted by unchecking the box, rather than relying on onBlur and onChange to handle that
                                            // onClick={({target}) => setNewUser({...newUser, roles:newUser.roles.includes(role) ? newUser.roles.filter(r => r !== role) : [...newUser.roles, role]})}
                                            onBlur={({ target }) => {
                                                setCustomRoles(
                                                    customRoles.filter((cR, cI) => cR || (cI !== index && !cR))
                                                )
                                            }}
                                            onChange={({ target }) => {
                                                setNewUser({
                                                    ...newUser,
                                                    roles: newUser.roles
                                                        .filter((uR) => uR !== role)
                                                        .concat(target.value ? [target.value] : []),
                                                })
                                                setCustomRoles(
                                                    customRoles.map((cR, cI) => (cI === index ? target.value : cR))
                                                )
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='grid w-[100%] py-[4px]  justify-center'>
                                <button
                                    className='rounded font-bold uppercase bg-blue dark:bg-accent text-white dark:text-black px-[8px] py-[2px]'
                                    onClick={() => {
                                        setTimeout(() => {
                                            document.getElementById('custom-roles-container').scroll(0, 1000000)
                                        }, 1)
                                        setCustomRoles([...customRoles, ''])
                                    }}
                                >
                                    + Custom Role
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className='basis-[50%] p-[16px] pr-[8px] shadow-[inset_0_0_10px_rgba(0,0,0,0.25)] max-h-[300px]'>
                                <div class='stores'>
                                    <p className='text-[14px] uppercase font-bold text-[#4a4a4a] dark:text-grey mb-[4px]'>
                                        Stores
                                    </p>
                                    {stores.map((store, index) => (
                                        <div
                                            key={store.name + index}
                                            className='flex gap-[8px] w-[100%] py-[4px] border-b-[1px] border-[#cdcdcd] dark:border-darkgrey'
                                        >
                                            <input
                                                className=''
                                                id={store.name + 'store'}
                                                type='checkbox'
                                                name={store.name + 'store'}
                                                checked={newUser.store_ids.includes(store.id)}
                                                onChange={(e) =>
                                                    newUser.store_ids.includes(store.id)
                                                        ? setNewUser({
                                                            ...newUser,
                                                            store_ids: newUser.store_ids.filter(
                                                                (clickedStore) => clickedStore !== store.id
                                                            ),
                                                        })
                                                        : setNewUser({
                                                            ...newUser,
                                                            store_ids: [...newUser.store_ids, store.id],
                                                        })
                                                }
                                            />
                                            <label className='block w-[100%] cursor-pointer capitalize' for={store.name + 'store'}>
                                                {store.name}

                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center gap-[16px]'>
                    <button
                        className='border-[1px] border-grey px-[4px] py-[2px] hover:bg-offwhite dark:hover:bg-darkness/50'
                        type='submit'
                        onClick={createUser}
                    >
                        SUBMIT
                    </button>
                    <button
                        className='border-[1px] border-grey px-[4px] py-[2px] hover:bg-offwhite dark:hover:bg-darkness/50'
                        type='button'
                        onClick={() => setShowCreate(false)}
                    >
                        CANCEL
                    </button>
                </div>
            </div>
        </div>
    )
}
