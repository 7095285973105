import { cn } from 'helpers'
import { FC, useEffect, useRef, useState } from 'react'
import { IconType } from 'react-icons'

export default function MoreActions({
    options,
    displayContent = 'More Actions',
    align = 'right',
}: {
    options: Option[]
    displayContent?: string | { icon: IconType }
    align?: 'left' | 'right'
}) {
    const [showOptions, setShowOptions] = useState(false)
    const container = useRef(null)
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!(container.current! as HTMLElement).contains(e.target as HTMLElement)) {
                setShowOptions(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div ref={container} className='relative'>
            <button
                onClick={() => setShowOptions((previousState) => !previousState)}
                className='text-[14px] font-medium rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite p-[6px] z-50 leading-[1]'
            >
                {typeof displayContent === 'string' && displayContent}
                {typeof displayContent === 'object' && <displayContent.icon />}
            </button>
            {showOptions && (
                <div className={`top-[32px] absolute ${align}-0 `}>
                    <div
                        className={`flex flex-col  relative rounded-[4px] dark:border-[2px] dark:border-darkgrey shadow-small z-[100] bg-white dark:bg-darkaccent`}
                    >
                        {options.map((option) => (
                            <button
                                disabled={option.disabled || false}
                                key={option?.displayText}
                                className={cn(
                                    'text-[14px] p-2 leading-[1]',
                                    !option.disabled && 'hover:bg-lightgrey dark:hover:bg-darkbg2',
                                    'font-medium whitespace-nowrap flex items-center gap-[6px] text-darkgrey dark:text-offwhite',
                                    option.font && option.font
                                )}
                                onClick={() => {
                                    option.action()
                                    setShowOptions(false)
                                }}
                            >
                                {option.icon &&
                                    option.icon({ className: option.font || 'text-darkgrey dark:text-offwhite' })}
                                {option.displayText}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

type Option = {
    displayText: string
    icon: FC<{ className: string }> | undefined
    action: Function
    disabled?: boolean
    font?: string
}
