import { parseResObject, vFetch } from '../../helpers'
import { auditHistoryType } from '../../procurement/components/CompanyProductEditHistory'

const AUDIT_BASE_URL = '/audit'

export const getAuditHistory = async (
    table: string,
    record_id: string | number | undefined,
    parent_id: string | number | undefined,
    lastCursor?: any,
    filters?: { user_ids: number[]; fields: string[] },
    limit?: number
) => {
    const queryParams = [
        table ? `table=${table}` : null,
        record_id ? `record_id=${record_id}` : null,
        parent_id ? `parent_id=${parent_id}` : null,
        lastCursor ? `last_cursor=${lastCursor}` : null,
        filters?.fields ? `fields=${filters.fields.join(',')}` : null,
        filters?.user_ids ? `user_ids=${filters.user_ids.join(',')}` : null,
        limit ? `limit=${limit}` : null,
    ]
        .filter((v) => v)
        .join('&')
    return await vFetch(`${AUDIT_BASE_URL}?${queryParams}`, {
        cb: (res: any) => {
            res.auditHistory.forEach((event: auditHistoryType) => {
                return parseResObject(event)
            })
            return res
        },
    })
}
