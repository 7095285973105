import { useEffect, useState } from 'react'
import { FaAngleDown, FaAngleUp, FaBookOpen, FaCode } from 'react-icons/fa'
import { IoPerson } from 'react-icons/io5'
import { MdEdit, MdOutlineArticle } from 'react-icons/md'
import { cn, vFetch } from '../../helpers'
import Input from '../../procurement/components/Input'
import IconDropDownModal from './IconDropDownModal'
import { setSelected } from '../../redux/selected'
import { iconMap } from '../constants'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import DeleteDepartmentModal from './DeleteDepartmentModal'
import { useQueryClient } from '@tanstack/react-query'

export default function Department({
    getDepartments,
    department,
    id,
    icon,
    label,
    setView,
    selectedDepartment,
    setSelectedDepartment,
    selectedArticle, //
    setSelectedArticle,
}: {
    getDepartments: any
    department: any
    id: any
    icon: any
    label: any
    setView: any
    selectedDepartment: any
    setSelectedDepartment: any
    selectedArticle: any //
    setSelectedArticle: any
}) {
    //
    const [searchParams, setSearchParams] = useSearchParams()
    const [editMode, setEditMode] = useState(false)
    const [showEditOptions, setShowEditOptions] = useState(false)
    const [showIconDropdownModal, setShowIconDropdownModal] = useState(false)
    const [editableDepartment, setEditableDepartment] = useState<undefined | string>()
    const [selectedIcon, setSelectedIcon] = useState<undefined | string>()
    const currentIcon = selectedIcon || icon
    const isEdited = editableDepartment || selectedIcon ? true : false
    const Icon = iconMap[currentIcon as keyof typeof iconMap] || IoPerson
    const currentUser = useSelector((state: any) => state.user)
    const canEdit = currentUser.type === 'Admin' || currentUser.type === 'SuperAdmin'
    const articleID = searchParams.get('articleID') //
    const [pinnedArticles, setPinnedArticles] = useState([])
    const [departmentHighlight, setDepartmentHighlight] = useState(selectedDepartment)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showDeleteDepartmentModal, setShowDeleteDepartmentModal] = useState(false)
    const queryClient = useQueryClient()

    const handleOpenEditMode = () => {
        setShowEditOptions(true)
        setEditMode(true)
    }
    const handleCancel = () => {
        setShowEditOptions(false)
        setEditMode(false)
        setShowIconDropdownModal(false)
        setEditableDepartment(undefined)
        setShowDeleteConfirmation(false)
        setShowDeleteDepartmentModal(false)
    }
    const handleIconClick = () => {
        setShowIconDropdownModal((prev) => !prev)
    }

    const handleUpdateDepartment = () => {
        vFetch('/knowledge/departments', {
            method: 'PUT',
            body: JSON.stringify({ id: id, label: editableDepartment, icon: selectedIcon }), //
            cb: (res: any) => {
                getDepartments()
                setEditableDepartment(undefined)
                setEditMode(false)
            },
        })
    }

    const handleDeleteDepartment = () => {
        return vFetch('/knowledge/departments', {
            method: 'DELETE',
            body: JSON.stringify({ id }),
            cb: (res: any) => {
                if (res.success) {
                    getDepartments()
                    setShowEditOptions(false)
                    setEditMode(false)
                    setEditableDepartment(undefined)
                    return queryClient.invalidateQueries({
                        queryKey: ['knowledge', 'articles', 'global'],
                    })
                }
            },
        })
    }
    const getArticles = async () => {
        vFetch(`/knowledge/articles?department_id=${selectedDepartment.id}&pinned=true`, {
            cb: (res: any) => {
                if (!res.success) {
                }
                if (res.success) {
                    setPinnedArticles(res.articles)
                }
            },
        })
    }
    const handleSetDepartment = () => {
        setSearchParams((prev) => {
            prev.set('section', 'departmentView')
            prev.set('departmentID', department.id)
            prev.delete('allArticles')
            prev.delete('articleID')
            prev.delete('myArticleID')
            prev.delete('myEditArticle')
            prev.delete('articleView')
            prev.delete('editArticleView')
            prev.delete('questionID')
            prev.delete('createArticleView')
            prev.delete('adminArticleView')
            prev.delete('adminPanel')
            return prev
        })
        setSelectedDepartment(department)
        setSelectedArticle(undefined)
        setDepartmentHighlight(true)
        setView('departmentView')
    }
    const handleSetArticle = (article: any) => {
        setSelectedArticle(article)
        setSearchParams((prev: any) => {
            prev.set('articleID', article.id)
            return prev
        })
    }
    useEffect(() => {
        if (selectedDepartment?.id === department.id) {
            getArticles()
        } else {
            setPinnedArticles([])
        }
    }, [selectedDepartment])
    return (
        <>
            <div className='relative flex justify-between items-center group w-full'>
                <div className=''>
                    <div
                        className={`flex w-[190px] mb-1 pl-1
                    ${
                        selectedDepartment?.id === department.id && !editMode
                            ? 'bg-lightgrey dark:bg-darkbg2 pr-2 rounded'
                            : ''
                    }`}
                    >
                        <div className=''>
                            {!editMode && (
                                <button onClick={handleSetDepartment} className=''>
                                    <div className='flex gap-2 items-center w-full'>
                                        <Icon className='text-blue dark:text-accent w-5' />
                                        <div>{label}</div>
                                    </div>
                                </button>
                            )}
                        </div>

                        {/* <div>
                            {canEdit && !editMode && (
                                <button
                                    onClick={handleOpenEditMode}
                                    className='opacity-0 transition-all group-hover:opacity-100 mr-3'
                                >
                                test
                                    <MdEdit className='w-[20px]' />
                                </button>
                            )}
                        </div> */}
                    </div>
                    {selectedDepartment?.id === department.id && !editMode && pinnedArticles.length > 0 && (
                        <div className='text-xs mb-1 ml-3'>
                            {pinnedArticles?.map((article: any, index: number) => (
                                <div
                                    key={`pinned-global-article${article.id}`}
                                    className={`flex items-center gap-1 pl-1 py-0.5 ${
                                        selectedArticle?.id === article.id
                                            ? 'bg-lightgrey dark:bg-darkbg2 pr-2 rounded'
                                            : ''
                                    }`}
                                >
                                    <div>
                                        <MdOutlineArticle className='w-4 h-4' />
                                    </div>
                                    <button
                                        className='text-left text-xs line-clamp-1'
                                        onClick={() => handleSetArticle(article)}
                                    >
                                        {article.title}
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}

                    {editMode && (
                        <div className='flex gap-1 items-stretch mt-0'>
                            <div className='flex gap-0.5 items-center bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-1 rounded-[4px]'>
                                <button onClick={handleIconClick} className='flex gap-0.5'>
                                    <Icon className='text-blue dark:text-accent text-sm w-5' />
                                    {showIconDropdownModal ? (
                                        <FaAngleUp className='text-blue dark:text-offwhite w-5' />
                                    ) : (
                                        <FaAngleDown className='text-blue dark:text-offwhite w-5' />
                                    )}
                                </button>
                            </div>
                            <Input
                                type='text'
                                id={label}
                                name='department'
                                value={editableDepartment ?? label} //
                                onChange={({ target }) => {
                                    setEditableDepartment(target.value)
                                }}
                                readOnly={!editMode}
                                className={
                                    !editMode
                                        ? 'dark:bg-darkbg1 focus:outline-none text-sm font-bold border-0 h-fit'
                                        : 'text-sm pl-1 h-5'
                                }
                            />
                        </div>
                    )}
                    {editMode && showIconDropdownModal && <IconDropDownModal setSelectedIcon={setSelectedIcon} />}
                </div>
                {canEdit && !editMode && (
                    <button
                        onClick={handleOpenEditMode}
                        className=' absolute top-0.5 right-0 opacity-0 transition-all group-hover:opacity-100 mr-3'
                    >
                        <MdEdit className='w-[20px]' />
                    </button>
                )}
            </div>

            {editMode && showEditOptions && !showDeleteConfirmation && (
                <div className='flex justify-between mb-2'>
                    <button
                        onClick={() => setShowDeleteDepartmentModal(true)}
                        className='mt-1 text-xs text-white bg-red/75 
                    text:white dark:text-offwhite rounded px-1'
                    >
                        DELETE
                    </button>

                    <div className='flex gap-1 mr-0.5'>
                        <button
                            disabled={!isEdited}
                            onClick={handleUpdateDepartment}
                            className={cn(
                                !isEdited && 'opacity-60', // new
                                `mt-1 text-xs bg-blue text-offwhite dark:bg-accent dark:text-darkaccent rounded px-1`
                            )}
                        >
                            SAVE
                        </button>
                        <button
                            onClick={handleCancel}
                            className='mt-1 text-xs text-white bg-darkgrey text:white dark:text-offwhite rounded px-1'
                        >
                            CANCEL
                        </button>
                    </div>
                </div>
            )}
            {showDeleteDepartmentModal && (
                <DeleteDepartmentModal handleDeleteDepartment={handleDeleteDepartment} handleCancel={handleCancel} />
            )}
        </>
    )
}
