import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { MetafieldProps } from '../ProductSetup.types'
import { LoadingGear, vFetch } from '../../helpers'
import NewOptionModal from '../components/NewOptionModal'

export default function Group({ product, handleUpdateMetafield }: MetafieldProps) {
    const needsGroup = !product.custom_group
    const groupRef = useRef<HTMLDivElement>(null)
    const loopRef = useRef<HTMLDivElement>(null)
    const [groupMap, setGroupMap] = useState<any>({})
    const [loading, setLoading] = useState(true)
    const [groups, setGroups] = useState<string[]>([])
    const [filteredGroups, setFilteredGroups] = useState<string[]>([])
    const [group, setGroup] = useState(product.custom_group || '')
    const [options, setOptions] = useState<{ key: string; value: string }[]>([])
    const [edited, setEdited] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showGroups, setShowGroups] = useState(false)
    const [showValue, setShowValue] = useState<string | undefined>(undefined)

    const handleChangeOption = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        setEdited(true)
        setOptions((previousState) => {
            const newState = structuredClone(previousState)
            newState[index] = {
                ...newState[index],
                value: e.target.value,
            }
            return newState
        })
    }

    useEffect(() => {
        vFetch(`/v2/products/metaGroups?group_name=${product.custom_group}`, {
            cb: (res: any) => {
                setGroupMap(res.groupMap)
                setOptions(
                    Object.keys(res.groupMap).map((key) => ({
                        key,
                        value:
                            (product.custom_options?.find((option) => option.split(':')[0] === key) || '').split(
                                ':'
                            )[1] || '',
                    }))
                )
            },
        }).then(() => setLoading(false))
        vFetch(`/v2/products/metaGroups/distinct`, {
            cb: (res: any) => {
                setGroups(res.groups || [])
                setFilteredGroups(res.groups || [])
            },
        })
    }, [product])

    useEffect(() => {
        setFilteredGroups((previousState) => {
            return groups.filter((str) => str.toLowerCase().includes(group.toLowerCase()))
        })
    }, [group])

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!groupRef?.current?.contains(e.target as Element)) {
                setShowGroups(false)
            }
            if (!loopRef?.current?.contains(e.target as Element)) {
                setShowValue(undefined)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small'>
            <p className='mt-[-16px] ml-[-16px] rounded-tl-[4px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                Group
            </p>
            {!loading ? (
                <div>
                    <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                        <label
                            className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                            htmlFor='group'
                        >
                            Group
                        </label>
                        <div ref={groupRef} className='relative w-full flex gap-[8px]'>
                            <input
                                onFocus={() => setShowGroups(true)}
                                id='group'
                                type='text'
                                value={group}
                                name='group'
                                className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                onChange={({ target }) => setGroup(target.value)}
                            />
                            {showGroups && (
                                <div className='absolute z-[5] top-[100%] w-full overflow-auto max-h-[100px] left-0 bg-white dark:bg-darkaccent rounded-b-[4px] flex flex-col gap-[4px] shadow-small'>
                                    {filteredGroups.map((g) => (
                                        <button
                                            className='block text-left w-full py-[4px] px-[8px] border-b border-lightgrey dark:border-darkgrey'
                                            onClick={() => {
                                                setGroup(g)
                                                setShowGroups(false)
                                            }}
                                        >
                                            {g}
                                        </button>
                                    ))}
                                </div>
                            )}
                            {group !== product.custom_group && (
                                <button
                                    className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] font-bold uppercase'
                                    onClick={() =>
                                        handleUpdateMetafield([
                                            {
                                                ownerId: `gid://shopify/Product/${product.id}`,
                                                namespace: 'custom',
                                                key: 'group',
                                                type: 'single_line_text_field',
                                                value: group,
                                            },
                                        ])
                                    }
                                >
                                    Save
                                </button>
                            )}
                        </div>
                    </div>
                    {!needsGroup && (
                        <>
                            <label className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                Options
                            </label>
                            <div className='flex flex-col gap-[4px]'>
                                {options.map((option, index) => (
                                    <div ref={loopRef} className='flex gap-[16px] items-center relative'>
                                        <p className='text-[18px] font-bold shrink-0'>{option.key} : </p>
                                        <input
                                            onFocus={() => setShowValue(option.key)}
                                            id={option.key}
                                            type={option.key}
                                            value={option.value}
                                            name={option.key}
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={(e) => handleChangeOption(e, index)}
                                        />
                                        {showValue === option.key && (
                                            <div className='absolute z-[5] top-[100%] w-full overflow-auto max-h-[100px] left-0 bg-white dark:bg-darkaccent rounded-b-[4px] flex flex-col gap-[4px] shadow-small'>
                                                {(groupMap[option.key] || []).map((g: string) => (
                                                    <button
                                                        className='block text-left w-full py-[4px] px-[8px] border-b border-lightgrey dark:border-darkgrey'
                                                        onClick={() => {
                                                            setOptions((previousState) => {
                                                                const newState = structuredClone(previousState)
                                                                newState[index] = {
                                                                    ...newState[index],
                                                                    value: g,
                                                                }
                                                                return newState
                                                            })
                                                            setShowValue(undefined)
                                                        }}
                                                    >
                                                        {g}
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <button
                                    className='py-[2px] px-[4px] bg-blue text-white font-bold dark:bg-accent dark:text-darkaccent rounded-[4px] w-fit text-[14px] uppercase'
                                    onClick={() => setShowCreateModal(true)}
                                >
                                    + New Option
                                </button>
                            </div>
                            {edited && (
                                <button
                                    className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                                    onClick={() => {
                                        handleUpdateMetafield([
                                            {
                                                ownerId: `gid://shopify/Product/${product.id}`,
                                                namespace: 'custom',
                                                key: 'options',
                                                type: 'list.single_line_text_field',
                                                value: JSON.stringify(
                                                    options
                                                        .filter((option) => option.value)
                                                        .map(({ key, value }) => `${key}:${value}`)
                                                ),
                                            },
                                        ])
                                        setEdited(false)
                                    }}
                                >
                                    Save Changes
                                </button>
                            )}
                            {showCreateModal && (
                                <NewOptionModal
                                    setOptions={setOptions}
                                    setShowModal={setShowCreateModal}
                                    setEdited={setEdited}
                                />
                            )}
                        </>
                    )}
                </div>
            ) : (
                <div className='grid place-items-center p-[32px]'>
                    <LoadingGear />
                </div>
            )}
        </div>
    )
}
