import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { buildEmailFromTemplate, sortByAlphanumeric, vFetch } from '../helpers/index'
import { FP_SIGNATURE_LOGO_URL } from '../tasks/constants.js'
import EmailCustomerModal from './EmailCustomerModal'
import EmailManufacturerModal from './EmailManufacturerModal'
import CloseButton from '../custom_components/CloseButton'
import { CustomerInit } from '../customers/customers.types'
import { OrderCustomer, OrderInit } from '../orders/orders.types'
import { UserInit } from '../users/users.types'

type EmailModalProps = {
    eventSource: {
        foreign_id: number | null | undefined
        foreign_table: string
    }
    mode: string | null
    setMode: Function
    handleSubmitEmailEvent?: Function
    customer: OrderCustomer
    order: OrderInit
}
export default function EmailModal({
    mode,
    setMode,
    handleSubmitEmailEvent,
    customer,
    order,
    eventSource,
}: EmailModalProps) {
    const user = useSelector<any, any>((state) => state.user)
    const outer = useRef(null)
    const [selectedTemplate, setSelectedTemplate] = useState<any>({})
    const [submitText, setSubmitText] = useState('SEND')
    const defaultEmail = {
        respond_to: 'sales@factorypure.com',
        send_to: '',
        cc: '',
        subject: '',
        body: '',
        greeting: 'Hi,',
        html: '',
    }
    const defaultTemplate = {
        name: 'Select a template',
        respond_to: 'sales@factorypure.com',
        cc: '',
        subject: '',
        greeting: '',
        html: '',
        include_signature: 1,
    }
    const [allTemplates, setAllTemplates] = useState([defaultTemplate])
    const [templates, setTemplates] = useState([defaultTemplate])
    const [email, setEmail] = useState<any>(defaultEmail)
    const [vendorContactMap, setVendorContactMap] = useState<any>({})
    const [legacyVendorContactMap, setLegacyVendorContactMap] = useState<any>({})
    const [vendorList, setVendorList] = useState<string[]>([])
    const [selectedVendor, setSelectedVendor] = useState(0)

    useEffect(() => {
        vFetch(`/emailTemplates/all`, {
            cb: (res: any) => {
                if (res.success) {
                    setAllTemplates([
                        defaultTemplate,
                        ...res.emailTemplates.sort((a: any, b: any) => sortByAlphanumeric(a, b, 'name')),
                    ])
                    setTemplates(
                        res.emailTemplates.filter(
                            (t: any) => t.belongs_to === mode || t.belongs_to === 'any' || !t.belongs_to
                        )
                    )
                }
            },
        })
    }, [])
    useEffect(() => {
        if (order.line_items) {
            const vendors = new Set(order.line_items.map((i) => i.vendor))
            vFetch(`/vendors/contacts?vendors=${encodeURIComponent(Array.from(vendors).join(','))}`, {
                cb: (res: any) => {
                    if (res.success) {
                        const resVendorContactMap: any = {}
                        Object.keys(res.vendorContactMap).forEach((v) => (resVendorContactMap[v] = []))
                        Object.keys(res.vendorContactMap).forEach((v) => {
                            res.vendorContactMap[v].forEach((contact: any) => {
                                if (
                                    !resVendorContactMap[v].find((object: any) => object.email === contact.email) &&
                                    contact.email
                                ) {
                                    resVendorContactMap[v].push(contact)
                                }
                            })
                        })
                        setLegacyVendorContactMap(resVendorContactMap)
                    }
                },
            })
        }
    }, [order.line_items])
    useEffect(() => {
        if (order.line_items) {
            const vendors = new Set(order.line_items.map((i) => i.vendor))
            vFetch(`/procurement/contacts?vendorNames=${encodeURIComponent(Array.from(vendors).join(','))}`, {
                cb: (res: any) => {
                    if (res.success) {
                        for (let contact of res.contacts) {
                            if (!vendorContactMap[contact.vendor]) {
                                vendorContactMap[contact.vendor] = []
                            }
                            vendorContactMap[contact.vendor].push(contact)
                        }
                        const resVendorContactMap: any = {}
                        if (Object.keys(res.contacts).length) {
                            Array.from(vendors).forEach((vendor: string) => (resVendorContactMap[vendor] = []))
                            Array.from(vendors).forEach((vendor: string) => {
                                res.contacts.forEach((contact: any) => {
                                    if (
                                        !resVendorContactMap[vendor].find(
                                            (object: any) => object.email === contact.email
                                        ) &&
                                        contact.email
                                    ) {
                                        resVendorContactMap[vendor].push(contact)
                                    }
                                })
                            })
                        }
                        setVendorContactMap(resVendorContactMap)
                        setVendorList(Array.from(vendors))
                    }
                },
            })
        }
    }, [order.line_items])

    useEffect(() => {
        const { subject, body, greeting, html } = buildEmailFromTemplate(
            selectedTemplate.name === 'Select a template'
                ? {
                      ...defaultTemplate,
                      greeting: mode === 'customer' ? 'Hi {{ customer.first_name }},' : 'Hi {{ supplier.name }},',
                  }
                : selectedTemplate,
            {
                order,
                customer,
                supplier: { name: vendorList[selectedVendor] || '' },
            }
        )
        setTemplates(allTemplates.filter((t: any) => t.belongs_to === mode || t.belongs_to === 'any' || !t.belongs_to))
        if (mode === 'customer') {
            setEmail((previousState: any) => ({
                send_to: customer.email,
                respond_to: 'sales@factorypure.com',
                cc: '',
                subject,
                body,
                greeting: greeting || `Hi ${customer.first_name},`,
                html: '',
            }))
        } else if (mode === 'manufacturer') {
            setEmail((previousState: any) => ({
                send_to:
                    (vendorList.length > 0 &&
                        vendorList[selectedVendor] && [
                            legacyVendorContactMap[vendorList[selectedVendor]]?.find((v: any) => v.email).email,
                        ]) ||
                    [],
                respond_to: 'sales@factorypure.com',
                cc: '',
                subject,
                body,
                greeting:
                    greeting ||
                    `Hi ${
                        vendorList.length > 0 && vendorList[selectedVendor] ? ` ${vendorList[selectedVendor]}` : ''
                    },`,
                html: '',
            }))
        } else {
            setSelectedTemplate(defaultTemplate)
            setSubmitText('SEND')
        }
    }, [mode, customer, selectedVendor])

    const handleSendEmail = () => {
        const { foreign_id, foreign_table } = eventSource
        setSubmitText('SENDING...')
        vFetch(`/email`, {
            method: 'POST',
            body: JSON.stringify({
                ...email,
                emailText: email.body.trim(),
                user_id: user.id,
                foreign_id,
                foreign_table,
                body: `<pre style="font-family: arial, sans-serif; word-wrap: break-word; white-space: pre-wrap; margin: 8px 0px;">${email.greeting.trim()}\n\n${email.body.trim()}</pre>${signatureHTMLString(
                    user
                )}`.replaceAll(/  /gm, ''),
            }),
            cb: (res: any) => {
                if (res.success) {
                    if (handleSubmitEmailEvent) {
                        handleSubmitEmailEvent(
                            `${email.greeting}\n\n${email.body}`,
                            email.cc,
                            email.respond_to,
                            email.subject,
                            email.send_to
                        )
                    }
                    setMode(null)
                }
            },
        })
    }

    return (
        <>
            {mode !== null && (
                <div
                    className={`grid fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] z-[10] place-items-center`}
                    ref={outer}
                    onMouseDown={({ target }) => {
                        if (outer.current === target) {
                            setMode(null)
                            setSelectedTemplate(defaultTemplate)
                        }
                    }}
                >
                    <div className='w-[80%] max-w-[600px] max-h-[90%] overflow-auto bg-white rounded-[4px] shadow-small dark:bg-darkaccent p-[16px] relative flex flex-col gap-[4px]'>
                        <CloseButton
                            onClick={() => {
                                setMode(null)
                                setSelectedTemplate(defaultTemplate)
                            }}
                        />
                        {/* <div className="w-[24px] h-[24px] absolute text-red font-bold leading-[1] top-[2px] right-[2px] text-[24px] cursor-pointer" onClick={() => setMode(null)}>&times;</div> */}
                        {mode === 'customer' && (
                            <EmailCustomerModal
                                templates={templates}
                                email={email}
                                setEmail={setEmail}
                                handleSendEmail={handleSendEmail}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                submitText={submitText}
                                vendorList={vendorList}
                                selectedVendor={selectedVendor}
                                order={order}
                                customer={customer}
                            />
                        )}
                        {mode === 'manufacturer' && (
                            <EmailManufacturerModal
                                templates={templates}
                                email={email}
                                setEmail={setEmail}
                                handleSendEmail={handleSendEmail}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                submitText={submitText}
                                vendorList={vendorList}
                                vendorContactMap={vendorContactMap}
                                legacyVendorContactMap={legacyVendorContactMap}
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                order={order}
                                customer={customer}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

const signatureHTMLString = (user: any) => {
    return `<div id="email-signature" style="display:grid;gap:8px 8px; row-gap:8px; column-gap:8px; grid-gap:8px;">
        <div style="display:grid">
            <strong>${user.firstName} with FactoryPure</strong>
            <strong>Customer Service: 888-978-4993 (M-F 8-5 PM CT)</strong>
            <strong>Pre-Sales After Hours: 888-999-1522</strong>
        </div>
        <img 
            src="${FP_SIGNATURE_LOGO_URL}" 
            alt="FactoryPure Logo"
            width="120"
            height="40"
        />
        <div style="display:flex; align-items:end">
            <a style="font-size:10pt; color:#1155cc; text-decoration:underline;" href="mailto:sales@factorypure.com" rel="noopener" target="_blank">
                sales@factorypure.com
            </a>
            <span style="font-size:10pt;color:#1f497d;">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <a style="font-size:10pt; color:#1155cc; text-decoration:underline;" title="FactoryPure" href="http://www.factorypure.com/" rel="noopener" target="_blank">
                www.factorypure.com
            </a>
        </div>
    </div>`.replaceAll(/  /gm, '')
}

const oldSignatureHTMLString = (user: any) => {
    // return `<div>
    //     <p><strong>${user.firstName} with FactoryPure</strong><br/>
    //     <strong>Customer Service: 888-978-4993 (M-F 8-5 PM CT)</strong><br/>
    //     <strong>Pre-Sales After Hours: 888-999-1522</strong></p>
    //     <img
    //         src="${FP_SIGNATURE_LOGO_URL}"
    //         alt="FactoryPure Logo"
    //         width="120"
    //         height="40"
    //     />
    //     <div>
    //         <u>
    //             <span style="font-size:10pt;color:#0563c1">
    //                 <a style="color:#1155cc" href="mailto:sales@factorypure.com" rel="noopener" target="_blank">
    //                     sales@factorypure.com
    //                 </a>
    //             </span>
    //         </u>
    //         <span style="font-size:10pt;color:#1f497d">&nbsp;&nbsp;</span>
    //         <span style="font-size:10pt">|&nbsp;&nbsp;</span>
    //         <u>
    //             <span style="font-size:10pt;color:#0563c1">
    //                 <span style="color:#0563c1">
    //                     <a style="color:#1155cc" title="FactoryPure" href="http://www.factorypure.com/" rel="noopener" target="_blank">
    //                         www.factorypure.com
    //                     </a>
    //                 </span>
    //             </span>
    //         </u>
    //     </div>
    // </div>`
}
