import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from './component_Basics/Button'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { ToggleSwitch } from './ToggleSwitch'
import TaskListItemSkeleton from '../procurement/components/listItems/TaskListItemSkeleton'
import { CalendarProcurementTasks } from './CalendarProcurementTasks'
import { FetchingSpinner } from './FetchingSpinner'
import { NoResults } from './NoResults'
import { SkeletonBasic } from './component_Basics/SkeletonBasic'

export default function ComponentList() {
    const user = useSelector<any, any>((state) => state.user)
    const navigate = useNavigate()
    useEffect(() => {
        if (!user.access.includes('calls') && !user.access.includes('*')) {
            navigate('/home')
        }
    }, [])
    return (
        <>
            {user && (
                <h1>
                    <div className='grid grid-cols-7 gap-6 bg-bg1 dark:bg-darkbg1 p-2 rounded-md'>
                        <span>size = lg</span>
                        <Button size={'lg'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'lg'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'lg'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'lg'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'lg'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'lg'} variant={'link'}>
                            link
                        </Button>{' '}
                        <span>size = default</span>
                        <Button size={'default'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'default'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'default'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'default'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'default'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'default'} variant={'link'}>
                            link
                        </Button>{' '}
                        <span>size = sm</span>
                        <Button size={'sm'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'sm'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'sm'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'sm'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'sm'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'sm'} variant={'link'}>
                            link
                        </Button>
                        <span>size = icon</span>
                        <Button size={'icon'} variant={'default'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'destructive'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'outline'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'secondary'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'ghost'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'link'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                    </div>
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-2'>
                            <p>ToggleSwitch</p>
                            <ToggleSwitch />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Skeleton</p>
                            <SkeletonBasic className='h-[100px] w-[275px]' />
                            <TaskListItemSkeleton />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Day Picker</p>
                            <p className='text-sm'>Will accept custom day cells </p>
                            <CalendarProcurementTasks date={undefined} setDate={undefined} />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Background fetch spinner</p>
                            <FetchingSpinner isFetching={true} />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>No results banner</p>
                            <NoResults margin={'ml-8'} content={'Sorry, Fathers Day!'} />
                        </div>
                    </div>
                </h1>
            )}
        </>
    )
}
