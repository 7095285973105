import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectModalDiv } from '../redux/appFunctions'
import './ModalDiv.css'
import { sendToast, vFetch } from '../helpers'

export function renderModalDiv(event = {}) {
    const overlay = document.getElementById('layout__modal-div')

    overlay.style.zIndex = 10000
    setTimeout(() => {
        overlay.style.opacity = 1
    }, 100)
}

export function closeModalDiv(event, force) {
    const overlay = document.getElementById('layout__modal-div')

    if (force) {
        overlay.style.zIndex = -10
        overlay.style.opacity = 0
        return
    } else if (event.target === overlay || event.target.name === 'NO' || event.target.name === 'CLOSE') {
        overlay.style.zIndex = -10
        overlay.style.opacity = 0
    }
}

export default function ModalDiv() {
    const modalOptions = useSelector(selectModalDiv)
    const user = useSelector((state) => state.user)
    const navigate = useNavigate()
    const fileUpload = useRef()
    const [files, setFiles] = useState([])
    const [base64Files, setBase64Files] = useState([])
    const fileUploadRef = useRef()
    const { func, args, name, purpose, heading, subheading } = modalOptions
    const userRequestOptions = []

    const requestTopicMap = [
        'Product Setup',
        'Shipping',
        'Tasks',
        'Sales',
        'Calls',
        'Users',
        'Admin',
        'Scrape',
        'Knowledge',
        'General / Other',
    ]

    if (!user.access.includes('*')) {
        requestTopicMap.forEach((topic) => {
            if (user.access.includes(topic.toLowerCase())) {
                userRequestOptions.push(topic)
            }
        })
        userRequestOptions.push('General / Other')
    }

    const requestTypeMap = {
        Feature: {},
        'Bug Fix': {},
    }
    const defaultHelpInput = {
        request_topic: requestTopicMap[0],
        request_type: Object.keys(requestTypeMap)[0],
        description: '',
    }
    const [helpInput, setHelpInput] = useState(defaultHelpInput)

    const functionMap = {
        deleteTask: async () => {
            await vFetch(`/tasks`, {
                method: 'DELETE',
                body: JSON.stringify({
                    id: args['task'].id,
                    order_id: args['task'].order_id,
                    draft_order_id: args['task'].draft_order_id,
                    note: args['task'].order_notes,
                    task_type: args['task'].task_type,
                }),
                cb: (res) => {
                    if (res.success) navigate('/tasks/manager')
                    else window.alert('Something went wrong.')
                },
            })
        },
        markTaskCompleted: async () => {
            await vFetch(`/tasks/${args['task'].id}`, {
                method: 'POST',
                cb: (res) => {
                    if (res.success) navigate('/tasks/manager')
                    else window.alert('Something went wrong. ')
                },
            })
        },
        markIssueClosed: async () => {
            await vFetch(`/issues/${args['issue'].issue_id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    status: 'CLOSED',
                    files: null,
                }),
                cb: (res) => {
                    if (res.success) navigate('/developments/manager')
                    else window.alert('Something went wrong. ')
                },
            })
        },

        deleteIssue: async () => {
            await vFetch(`/issues/${args['issue'].issue_id}`, {
                method: 'DELETE',
                cb: (res) => {
                    if (res.success) navigate('/developments/manager')
                    else window.alert('Something went wrong. ')
                },
            })
        },
    }

    const handleDrop = (e) => {
        e.preventDefault()
        setFiles(e.dataTransfer.files)
    }

    const handleRemoveFile = (index) => {
        setBase64Files([...base64Files.filter((f, i) => i !== index)])
    }

    function handleHelpChange({ target }) {
        setHelpInput({
            ...helpInput,
            [target.name]: target.value,
        })
    }

    const handleHelpSubmit = async (event) => {
        event.preventDefault()

        if (helpInput.description) {
            const response = await vFetch(`/issues`, {
                method: 'POST',
                body: JSON.stringify({
                    requester: user,
                    topic: helpInput.request_topic,
                    request_type: helpInput.request_type,
                    description: helpInput.description,
                    files: base64Files,
                }),
            })

            if (response.ok) {
                const textAreas = document.getElementsByClassName(
                    'min-h-[175px] max-w-[1440px] overflow-hidden dark:bg-darkness dark:text-offwhite dark:outline-grey dark:focus:outline'
                )
                for (let element of textAreas) {
                    element.value = ''
                }
                setHelpInput(defaultHelpInput)
                closeModalDiv(undefined, true)
            } else {
                sendToast('Something went wrong. Please tell the developers.')
            }
        }
    }

    function handleAreYouSure() {
        closeModalDiv(undefined, true)
        functionMap[func]()
    }

    useEffect(() => {
        const textAreas = document.getElementsByClassName(
            'min-h-[175px] max-w-[1440px] overflow-hidden dark:bg-darkness dark:text-offwhite dark:outline-grey dark:focus:outline'
        )

        for (let element of textAreas) {
            const minHeight = parseInt(
                window.getComputedStyle(element, null).getPropertyValue('min-height').replace('px', '')
            )

            if (element.scrollHeight > minHeight && element.scrollHeight < window.innerHeight * 0.8) {
                element.style.height = '0'
                element.style.height = `${element.scrollHeight}px`
            }
        }
    }, [helpInput])

    useEffect(() => {
        const getData = async () => {
            async function readFileAsDataURL(file) {
                let result_base64 = await new Promise((resolve) => {
                    let fileReader = new FileReader()
                    fileReader.onload = (e) => resolve(fileReader.result)
                    fileReader.readAsDataURL(file)
                })
                return result_base64
            }
            let tempFiles = []
            for (let file of Array.from(files)) {
                tempFiles.push(await readFileAsDataURL(file))
            }
            setBase64Files([...base64Files, ...tempFiles])
        }
        getData()
    }, [files])

    return (
        <>
            <div
                id='layout__modal-div'
                className='flex w-[100%] h-[100%] fixed left-0 top-0 justify-center justify-items-center items-center content-center bg-[rgba(0,0,0,0.3)]'
            >
                {purpose === 'HELPINPUT' && (
                    <div
                        id='layout__modal-div__inner-div'
                        className='flex flex-wrap relative max-w-[1440px] w-[80%] justify-center bg-white dark:bg-darkaccent gap-y-[16px] gap-x-[64px] p-[32px]'
                    >
                        <button
                            className='absolute right-0 top-0 h-[24px] w-[24px] text-white bg-blue border-0 dark:text-black dark:bg-fire dark:shadow-hot outline-none cursor-pointer font-bold'
                            name='CLOSE'
                            onClick={closeModalDiv}
                        >
                            X
                        </button>

                        <h2 className='w-[100%] text-center dark:text-offwhite'>{heading}</h2>
                        <form className='flex flex-col w-[100%] gap-[16px]' onSubmit={handleHelpSubmit}>
                            <div className='flex gap-[8px] w-[100%]'>
                                <div className='relative w-[100%]'>
                                    <label className='text-[10px] font-bold text-[#4a4a4a] dark:text-grey uppercase'>
                                        TOPIC
                                    </label>
                                    <select
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] rounded p-[4px] dark:bg-darkness dark:text-offwhite'
                                        defaultValue={Object.keys(requestTypeMap)[0]}
                                        name='request_topic'
                                        onChange={handleHelpChange}
                                    >
                                        {user.access.includes('*')
                                            ? requestTopicMap.map((topic) => (
                                                  <option value={topic} key={topic}>
                                                      {topic}
                                                  </option>
                                              ))
                                            : userRequestOptions.map((topic) => <option value={topic}>{topic}</option>)}
                                    </select>
                                    <label className='text-[10px] font-bold text-[#4a4a4a] dark:text-grey uppercase'>
                                        REQUEST TYPE
                                    </label>
                                    <select
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] rounded p-[4px] dark:bg-darkness dark:text-offwhite'
                                        defaultValue={Object.keys(requestTypeMap)[0]}
                                        name='request_type'
                                        onChange={handleHelpChange}
                                    >
                                        {Object.keys(requestTypeMap).map((type) => (
                                            <option value={type} key={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label className='dark:text-grey'>{subheading}</label>
                                <textarea
                                    className='min-h-[175px] max-w-[1440px] overflow-hidden dark:bg-darkness dark:text-offwhite dark:outline-grey dark:focus:outline'
                                    name='description'
                                    required
                                    onChange={handleHelpChange}
                                />
                            </div>
                            <label
                                htmlFor='file-upload'
                                className='flex flex-col items-center justify-center w-[100%] h-[80px] border-[1px] border-[#cdcdcd] dark:border-grey rounded appearance-none text-[#4a4a4a] dark:text-offwhite cursor-pointer'
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={handleDrop}
                            >
                                <div className='py-[4px] px-[8px] bg-blue text-white dark:bg-fire dark:text-black font-bold rounded leading-[1]'>
                                    Browse
                                </div>
                                or drag files here
                                <input
                                    ref={fileUploadRef}
                                    id='file-upload'
                                    className='hidden'
                                    accept='image/*'
                                    type='file'
                                    onChange={(e) => setFiles(e.target.files)}
                                    multiple
                                />
                            </label>

                            <div className='flex gap-[6px]'>
                                {base64Files.map((f, idx) => (
                                    <div
                                        onClick={() => handleRemoveFile(idx)}
                                        className="w-[100px] h-[70px] rounded relative place-items-center cursor-pointer 
                                    after:content-['-'] after:grid after:place-items-center after:w-[14px] after:h-[12px] after:pb-[2px] after:leading-[10px] after:text-[12px] after:rounded-full after:bg-[#a1a1a1] after:absolute after:top-0 after:right-0 after:pointer-events-none"
                                    >
                                        <img
                                            className="w-[90%] h-[90%] object-contain rounded border-[1px] border-[#cdcdcd]
                                    after:content-['-'] after:block after:w-[12px] after:h-[12px] after:rounded-full after:bg-[#a1a1a1]"
                                            src={f}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div>
                                <input
                                    className='relative block top-0 py-[8px] px-[16px] m-[auto] bg-blue text-white border-0 text-center uppercase rounded cursor-pointer'
                                    type='submit'
                                    value='SUBMIT REQUEST'
                                />
                            </div>
                        </form>
                    </div>
                )}
                {purpose === 'REDIRECT' && (
                    <div
                        id='layout__modal-div__inner-div'
                        className='flex flex-wrap relative max-w-[1440px] w-[80%] justify-center bg-white dark:bg-darkaccent gap-y-[16px] gap-x-[64px] p-[32px]'
                    >
                        <button
                            className='absolute right-0 top-0 h-[24px] w-[24px] text-white bg-blue border-0 dark:text-black dark:bg-fire dark:shadow-hot outline-none cursor-pointer'
                            name='CLOSE'
                            onClick={closeModalDiv}
                        >
                            X
                        </button>
                        <h2 className='w-[100%] text-center dark:text-offwhite'>Are you sure you want to {name}?</h2>
                        <button
                            className='text-white bg-blue dark:text-black dark:bg-accent rounded text-[12px] font-semibold py-[5px] px-[16px] my-[16px] min-w-[75px] cursor-pointer'
                            onClick={handleAreYouSure}
                        >
                            {name}
                        </button>
                        <button
                            className='text-white bg-blue dark:text-black dark:bg-accent rounded text-[12px] font-semibold py-[5px] px-[16px] my-[16px] min-w-[75px] cursor-pointer'
                            name='NO'
                            onClick={closeModalDiv}
                        >
                            NO
                        </button>
                    </div>
                )}
                {purpose === 'DANGER' && (
                    <div
                        id='layout__modal-div__inner-div'
                        className='flex flex-wrap relative max-w-[1440px] w-[80%] justify-center bg-white dark:bg-darkaccent gap-y-[16px] gap-x-[64px] p-[32px]'
                    >
                        <button
                            className='absolute right-0 top-0 h-[24px] w-[24px] text-white bg-blue border-0 dark:text-black dark:bg-fire dark:shadow-hot outline-none cursor-pointer'
                            name='CLOSE'
                            onClick={closeModalDiv}
                        >
                            X
                        </button>
                        <h2 className='w-[100%] text-center dark:text-offwhite'>
                            Are you sure you want to <strong>{name}</strong>?
                        </h2>
                        <button
                            className='semibold py-[5px] px-[16px] min-w-[75px] text-black text-[12px] font-extrabold p-[5px] bg-[rgb(255,200,200)] border-[2px] border-[rgb(255,0,0)] rounded my-[16px] cursor-pointer'
                            onClick={handleAreYouSure}
                        >
                            {name}
                        </button>
                        <button
                            className='text-white bg-blue dark:text-black dark:bg-accent rounded text-[12px] font-extrabold py-[5px] px-[16px] my-[16px] min-w-[75px] cursor-pointer'
                            name='NO'
                            onClick={closeModalDiv}
                        >
                            NO
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}
