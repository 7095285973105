import { CheckIcon } from '@radix-ui/react-icons'
import Input from 'procurement/components/Input'
import { useGetProductVariantsQuery, useUpdateVariantFields } from 'productSetupV2/api/useQueries'
import { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cn, sendToast, sendToastWarning, vFetch } from '../../helpers'
import Select from 'procurement/components/Select'

export default function VariantsV2({
    product_id,
    refreshProductSetupEvents,
    showHistory,
    setEdited,
}: {
    product_id: number | string
    refreshProductSetupEvents: Function
    showHistory: boolean
    setEdited: any
}) {
    const user = useSelector<any, any>((state) => state.user)
    const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(0)

    const [editableFields, setEditableFields] = useState<any>({})
    const isEdited = Boolean(Object.keys(editableFields).length)

    const handleEditableChange = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }

    const handleFocus = (event: any) => event.target.select()
    const handleBlur = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const field = target.name
        if (field === 'unit_cost' || field === 'compare_at_price' || field === 'price') {
            if (target.value && editableFields[field]) {
                setEditableFields({
                    ...editableFields,
                    [target.name]: parseFloat(target.value).toFixed(2),
                })
            }
        }
    }
    const getProductVariantsQuery = useGetProductVariantsQuery(product_id)
    const { variants: productVariants }: { variants: any[] } = getProductVariantsQuery?.data || {}
    const selectedVariant =
        productVariants?.length > 0
            ? JSON.parse(
                  JSON.stringify(
                      productVariants[selectedVariantIndex] || {},
                      (key, value) => (value === null ? '' : value),
                      2
                  )
              )
            : {}

    const inventory_management = editableFields?.inventory_management ?? selectedVariant?.inventory_management
    const tracked = inventory_management === 'shopify'
    const updateVariantFields = useUpdateVariantFields()

    const handleSaveVariant = async (variant: VariantType, skipToast?: boolean) => {
        if (editableFields?.price === '' || editableFields?.price === '0.00') {
            return sendToast({ message: 'Price cannot be blank' })
        }
        const moneyFields = ['price', 'compare_at_price', 'unit_cost']
        let events: Object[] = []
        Object.entries(editableFields).forEach(([key, value]) => {
            const previousValue = selectedVariant[key as keyof typeof variant]
            if (!(value === previousValue)) {
                events.push({
                    step: `identifiers`,
                    field: key,
                    variant_name: variant.title,
                    updated_value: value,
                    previous_value: moneyFields.includes(key) ? previousValue.toFixed(2) : previousValue,
                    product_id: product_id,
                    user_id: user.id,
                })
            }
        })
        vFetch(`/productSetupEvents`, {
            method: 'POST',
            body: JSON.stringify({
                events: events,
            }),
        })
        const comparePriceInsert =
            typeof editableFields.compare_at_price === 'string'
                ? editableFields.compare_at_price === ''
                    ? '0.00'
                    : editableFields.compare_at_price
                : null
        const unitCostInsert =
            typeof editableFields.unit_cost === 'string'
                ? editableFields.unit_cost === ''
                    ? '0.00'
                    : editableFields.unit_cost
                : null
        updateVariantFields.mutate(
            {
                // Order of fields is critical -Alex
                variant: {
                    ...variant,
                    id: `gid://shopify/ProductVariant/${variant.id}`,
                    original: { ...variant },
                    custom_supplier_sku: variant.custom_supplier_sku || null,
                    custom_supplier_model_number: variant.custom_supplier_model_number || null,
                    custom_upc: variant.custom_upc || null,
                    custom_part_number: variant.custom_part_number || null,
                    custom_ignore_auto_updates: variant.custom_ignore_auto_updates,
                    ...editableFields,
                    price: typeof editableFields.price === 'string' ? editableFields.price : selectedVariant.price,
                    compare_at_price: comparePriceInsert ? comparePriceInsert : selectedVariant.compare_at_price,
                    unit_cost: unitCostInsert ? unitCostInsert : selectedVariant.unit_cost,
                },
            },
            {
                onSuccess: () => {
                    refreshProductSetupEvents(product_id)
                    return setEditableFields({})
                },
            }
        )
    }

    const handleChangeVariant = async (index: number) => {
        if (isEdited) {
            return sendToastWarning({ message: 'Save or discard pending changes' })
        }
        setSelectedVariantIndex(index)
    }

    const margin = (
        (((editableFields?.price ?? selectedVariant?.price) -
            (editableFields?.unit_cost ?? selectedVariant?.unit_cost)) /
            (editableFields?.price ?? selectedVariant?.price)) *
        100
    )?.toFixed(2)
    const profit = (
        (editableFields?.price ?? selectedVariant?.price) - (editableFields?.unit_cost ?? selectedVariant?.unit_cost)
    )?.toFixed(2)

    useEffect(() => {
        setEdited(isEdited)
    }, [isEdited])

    console.log(selectedVariant)
    console.log(selectedVariant.unit_cost)

    return (
        <>
            <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small min-h-[630px] '>
                <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                    Variants
                </p>

                <>
                    <div className='flex gap-[16px]'>
                        <div className='w-1/3 flex flex-col gap-[8px]'>
                            <p className='uppercase font-light text-[18px] dark:text-offwhite mb-[8px] border-b '>
                                Select Variant
                            </p>
                            <div className='h-[500px] overflow-scroll flex flex-col gap-2'>
                                {productVariants?.map((variant, index: number) => (
                                    <button
                                        onClick={() => handleChangeVariant(index)}
                                        disabled={variant.id === selectedVariant?.id}
                                        className={`${
                                            variant.id === selectedVariant?.id
                                                ? 'bg-blue dark:bg-accent text-white dark:text-darkaccent'
                                                : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                        } py-[4px] px-[16px] rounded-[4px] font-bold`}
                                    >
                                        {variant.title}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className='w-1/3 flex flex-col gap-6'>
                            <div>
                                <p className='uppercase font-light text-[18px] dark:text-offwhite mb-[8px] border-b '>
                                    In-House Identifiers
                                </p>
                                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='sku'
                                    >
                                        SKU
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='sku'
                                            type='text'
                                            value={editableFields?.sku ?? selectedVariant?.sku}
                                            name='sku'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleEditableChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='barcode'
                                    >
                                        Barcode
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='barcode'
                                            type='text'
                                            value={editableFields?.barcode ?? selectedVariant?.barcode}
                                            name='barcode'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleEditableChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p className='uppercase font-light text-[18px] dark:text-offwhite mb-[8px] border-b '>
                                    Supplier Identifiers
                                </p>
                                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_supplier_sku'
                                    >
                                        Supplier SKU
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='custom_supplier_sku'
                                            type='text'
                                            value={
                                                editableFields?.custom_supplier_sku ??
                                                selectedVariant?.custom_supplier_sku
                                            }
                                            name='custom_supplier_sku'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleEditableChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_supplier_model_number'
                                    >
                                        Model Name/Number
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='custom_supplier_model_number'
                                            type='text'
                                            value={
                                                editableFields?.custom_supplier_model_number ??
                                                selectedVariant?.custom_supplier_model_number
                                            }
                                            name='custom_supplier_model_number'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleEditableChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_upc'
                                    >
                                        UPC
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='custom_upc'
                                            type='text'
                                            value={editableFields?.custom_upc ?? selectedVariant.custom_upc}
                                            name='custom_upc'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleEditableChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_part_number'
                                    >
                                        Part Number
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='custom_part_number'
                                            type='text'
                                            value={
                                                editableFields?.custom_part_number ??
                                                selectedVariant?.custom_part_number
                                            }
                                            name='custom_part_number'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleEditableChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/3 flex flex-col gap-6'>
                            <div>
                                <p className='uppercase font-light text-[18px] dark:text-offwhite mb-[8px] border-b '>
                                    Pricing
                                </p>
                                <div className='flex flex-col gap-4'>
                                    <div className={cn('flex gap-4 w-full mb-[8px]', showHistory && 'flex-col')}>
                                        <div className={cn('w-1/2', showHistory && 'w-full')}>
                                            <Input
                                                value={
                                                    editableFields?.price ??
                                                    (selectedVariant?.price
                                                        ? selectedVariant?.price?.toFixed(2)
                                                        : '0.00')
                                                }
                                                label='Price'
                                                id='price'
                                                name='price'
                                                placeholder='0.00'
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={handleEditableChange}
                                                type='number'
                                                leading={'$'}
                                            />
                                        </div>
                                        <div className={cn('w-1/2', showHistory && 'w-full')}>
                                            <Input
                                                value={
                                                    editableFields?.compare_at_price ??
                                                    (selectedVariant.compare_at_price === ''
                                                        ? '0.00'
                                                        : selectedVariant?.compare_at_price?.toFixed(2))
                                                }
                                                label='Compare-at price'
                                                placeholder='0.00'
                                                id='compare_at_price'
                                                name='compare_at_price'
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={handleEditableChange}
                                                type='number'
                                                leading={'$'}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={cn(
                                            'flex gap-4 w-full mb-[8px] border-t pt-2',
                                            showHistory && 'flex-col'
                                        )}
                                    >
                                        <div className={cn('w-1/2', showHistory && 'w-full')}>
                                            <Input
                                                value={
                                                    editableFields?.unit_cost ??
                                                    (selectedVariant?.unit_cost
                                                        ? selectedVariant?.unit_cost?.toFixed(2)
                                                        : '0.00')
                                                }
                                                label='Cost per item'
                                                id='unit_cost'
                                                placeholder='0.00'
                                                name='unit_cost'
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={handleEditableChange}
                                                type='number'
                                                leading={'$'}
                                            />
                                        </div>
                                        <div className={cn('w-1/2', showHistory && 'w-full')}>
                                            <Input
                                                value={profit}
                                                label='Profit'
                                                id='cost'
                                                name='cost'
                                                onChange={() => {}}
                                                type='number'
                                                readOnly={true}
                                                //leading={'$'}
                                            />
                                        </div>
                                        <div className={cn('w-1/2', showHistory && 'w-full')}>
                                            <Input
                                                value={margin + '%'}
                                                label='Margin'
                                                id='cost'
                                                name='cost'
                                                onChange={() => {}}
                                                type='text'
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p className='uppercase font-light text-[18px] dark:text-offwhite mb-[8px] border-b '>
                                    Quantity
                                </p>
                                <div className='flex gap-2 items-center mb-2'>
                                    <p className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                        Inventory Tracked
                                    </p>
                                    <div
                                        onClick={() => {
                                            // inventory_management = 'shopify' = tracked
                                            // inventory_management = '' = untracked
                                            const currentState = editableFields.inventory_management
                                            if (typeof currentState != 'string') {
                                                return setEditableFields({
                                                    ...editableFields,
                                                    inventory_management: tracked ? '' : 'shopify',
                                                })
                                            }
                                            if (
                                                typeof currentState === 'string' &&
                                                selectedVariant.inventory_management != currentState
                                            ) {
                                                const newFields = { ...editableFields }
                                                delete newFields.inventory_management
                                                return setEditableFields(newFields)
                                            }
                                        }}
                                        className={cn(
                                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                            tracked
                                                ? 'bg-primary text-primary-foreground'
                                                : 'opacity-50 [&_svg]:invisible'
                                        )}
                                    >
                                        <CheckIcon className={cn('h-4 w-4')} />
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <div className='flex flex-col gap-[4px] w-full'>
                                        <label
                                            className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                            htmlFor='available'
                                        >
                                            Available
                                        </label>
                                        <div className='relative w-full'>
                                            <input
                                                id='available'
                                                type='text'
                                                value={selectedVariant.inventory_quantity}
                                                name='available'
                                                autoComplete='off'
                                                readOnly={true}
                                                className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                                onChange={() => {}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Select
                                    className={cn()}
                                    label='Ignore Auto Updates'
                                    id='custom_ignore_auto_updates'
                                    name='custom_ignore_auto_updates'
                                    onChange={handleEditableChange}
                                    value={
                                        editableFields.custom_ignore_auto_updates ??
                                        selectedVariant.custom_ignore_auto_updates
                                    }
                                >
                                    <option value='1'>True</option>
                                    <option value='0'>False</option>
                                </Select>
                            </div>
                        </div>
                    </div>

                    {isEdited && (
                        <div className='flex gap-3 items-center mx-auto'>
                            <button
                                className={cn(
                                    updateVariantFields.isPending && 'opacity-[0.6] pointer-events-none',
                                    'py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] font-bold uppercase min-w-[142px]'
                                )}
                                onClick={() => handleSaveVariant(selectedVariant)}
                                disabled={updateVariantFields.isPending}
                            >
                                {updateVariantFields.isPending ? 'SAVING...' : 'SAVE Changes'}
                            </button>
                            <button
                                disabled={updateVariantFields.isPending}
                                onClick={() => {
                                    setEditableFields({})
                                }}
                                className={cn(
                                    'py-[4px] px-[8px]  bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite rounded-[4px] font-bold uppercase',
                                    updateVariantFields.isPending && 'opacity-[0.6] pointer-events-none'
                                )}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </>
            </div>
        </>
    )
}

type VariantType = {
    id: string
    sku?: string
    barcode?: string
    custom_supplier_sku?: string
    custom_supplier_model_number?: string
    custom_upc?: string
    custom_part_number?: string
    custom_ignore_auto_updates?: string
    title?: string
}
