import { DayMap, MonthMap, dayMap, formatDateToLocale, monthMap } from '../../helpers'
import { UserInit } from '../../users/users.types'
import { PrivateEventType } from '../tasks.types'
import { LuInfo } from 'react-icons/lu'

export default function PrivateEvent({
    events,
    setViewModal,
    userList,
}: {
    events: PrivateEventType[]
    setViewModal: any
    userList: UserInit[]
}) {
    const latestEvent = events[1] ? events[1] : events[0]
    const eventDate = formatDateToLocale(latestEvent.created_at)
    const date = eventDate.split(' ').slice(0, 2).join(' ')
    const time = eventDate.split(' ').slice(3).join(' ')
    const user =
        userList.find((u: any) => parseInt(latestEvent.user_id) === parseInt(u.id)) ||
        userList.find((u: any) => latestEvent.user_id === u.user_id)

    const day = new Date(Date.parse(latestEvent.created_at)).toDateString().split(' ')[0]
    return (
        <>
            <div className='flex'>
                <div className='px-[2px] font-bold text-[12px] uppercase text-center'>
                    Last {latestEvent.event_type} - {user?.user_id} - {day + ' '}
                    {date} {time}
                </div>
                <button className='px-[6px]  font-normal text-center cursor-pointer' onClick={() => setViewModal(true)}>
                    <LuInfo></LuInfo>
                </button>
            </div>
        </>
    )
}
