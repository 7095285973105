import { useQuery } from '@tanstack/react-query'
import { Chart } from 'chart.js'
import { vFetch } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'

const timeFormatter = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
}).format

export default function MainSessionChart() {
    const [showFilterAdd, setShowFilterAdd] = useState(false)
    const [filters, setFilters] = useState<any>({})
    const [selectedOption, setSelectedOption] = useState('')
    const [currentFilterValue, setCurrentFilterValue] = useState('')
    const filterOptions = [
        {
            label: 'ISP',
            value: 'isp',
        },
        {
            label: 'IP Address',
            value: 'ip_address',
        },
        {
            label: 'City',
            value: 'city',
        },
        {
            label: 'State',
            value: 'region',
        },
        {
            label: 'Zip',
            value: 'zip',
        },
        {
            label: 'Path',
            value: 'path',
        },
    ].filter((option) => !Object.keys(filters).find((key) => key === option.value))

    const settings = useSelector((state: any) => state.settings)
    const params = Object.entries(filters)
        .map(([key, val]: any) => `${key}=${encodeURIComponent(val)}`)
        .join('&')
    const sessionsQuery = useQuery({
        queryKey: ['sessions', filters],
        queryFn: () => vFetch(`/analytics/sessions?limit=10${params ? `&${params}` : ''}`),
    })
    const sessionsGraphQuery = useQuery({
        queryKey: ['sessions', 'timeframe', filters],
        queryFn: () => vFetch(`/analytics/sessions/timeframe?days=1&fields=id,created_at${params ? `&${params}` : ''}`),
    })
    const sessions = sessionsQuery?.data?.sessions || []
    const sessionsGraphPoints = sessionsGraphQuery?.data?.sessions || []

    const chartContainer = useRef<any>(null)
    const chart = useRef<any>(null)

    const lightOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                    borderColor: '#eee',
                },
                ticks: {
                    color: '#4a4a4a',
                },
            },
            y: {
                min: 0,
                grid: {
                    display: true,
                    borderColor: '#eee',
                    color: '#eee',
                },
                ticks: {
                    color: '#4a4a4a',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                borderColor: 'orange',
                tension: 0.5,
                pointStyle: false,
            },
        },
    }
    const darkOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                    borderColor: 'white',
                },
                ticks: {
                    color: '#eeefea',
                },
            },
            y: {
                min: 0,
                grid: {
                    display: true,
                    borderColor: 'white',
                    color: '#4a4a4a',
                },
                ticks: {
                    color: '#eeefea',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                borderColor: '#42EFD0',
                tension: 0.5,
                pointStyle: false,
            },
        },
    }

    useEffect(() => {
        if (chartContainer.current) {
            if (chart.current) {
                chart.current.destroy()
            }

            const sessionsMap: any = {}
            const xLables = []

            for (const session of sessionsGraphPoints) {
                const sessionDate = new Date(session.created_at)
                const day = sessionDate.getDate()
                const hour = sessionDate.getHours()
                if (!sessionsMap[`${day}-${hour}`]) {
                    sessionsMap[`${day}-${hour}`] = 0
                    xLables.push(hour)
                }
                sessionsMap[`${day}-${hour}`]++
            }
            chart.current = new Chart(chartContainer.current, {
                type: 'line',
                data: {
                    labels: xLables,
                    datasets: [
                        {
                            data: Object.values(sessionsMap),
                        },
                    ],
                },
                options: settings.theme == 'dark' ? darkOptions : lightOptions,
            })
        }
    }, [sessionsGraphPoints, settings])

    return (
        <div>
            <h2 className='text-xl font-bold'>Sessions Over The last 24 Hours</h2>
            <div className='flex gap-2 flex-wrap relative items-center mb-2'>
                <button
                    className='rounded-[99vw] bg-lightgrey dark:bg-darkgrey px-2 py-1 text-sm leading-none font-bold'
                    onClick={() => setShowFilterAdd(true)}
                >
                    + Filter
                </button>
                {Object.entries(filters).map(([key, val]: any) => (
                    <button
                        onClick={() =>
                            setFilters((prev: any) => {
                                delete prev[key]
                                return prev
                            })
                        }
                        className='rounded-[99vw] bg-blue text-white dark:bg-accent dark:text-darkbg2 px-2 py-1 text-sm leading-none font-bold flex gap-1'
                    >
                        <span>
                            {key} = {val}
                        </span>
                        <FaTimes />
                    </button>
                ))}
                {showFilterAdd && (
                    <div className='flex gap-2 p-2 bg-lightgrey dark:bg-darkbg1 rounded'>
                        <button onClick={() => setShowFilterAdd(false)}>
                            <FaTimes />
                        </button>
                        <select
                            value={selectedOption}
                            onChange={({ target }) => setSelectedOption(target.value)}
                            className='rounded-[99vw] text-white dark:text-offwhite bg-darkgrey px-2 py-1 text-sm leading-none font-bold'
                        >
                            <option value=''>Select a filter parameter</option>
                            {filterOptions.map((option) => (
                                <option value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <p>=</p>
                        <input
                            value={currentFilterValue}
                            onChange={({ target }) => setCurrentFilterValue(target.value)}
                            className='rounded-[99vw] text-white dark:text-offwhite bg-darkgrey px-2 py-1 text-sm leading-none font-bold '
                            placeholder={`Enter ${selectedOption || 'value'} here...`}
                        />
                        <button onClick={() => setFilters({ ...filters, [selectedOption]: currentFilterValue })}>
                            Add Filter
                        </button>
                    </div>
                )}
            </div>
            <div className='p-2 rounded shadow-md mb-2 bg-white dark:bg-darkbg1'>
                <h2 className='font-bold mb-2'>Total Session Count: {sessionsGraphPoints.length}</h2>
                <div style={{ position: 'relative', height: '302px', width: '100%' }}>
                    <canvas ref={chartContainer}></canvas>
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='grid grid-cols-[120px_2fr_1fr_1fr_100px_50px_120px] gap-2 text-sm font-bold p-2 bg-lightgrey dark:bg-darkbg2 rounded shadow-md break-all sticky top-[50px] z-10'>
                    {/* <p>id: {session.id}</p> */}
                    <p>Domain</p>
                    <p>Path</p>
                    {/* <p>search: {session.search}</p> */}
                    {/* <p>hash: {session.hash}</p> */}
                    {/* <p>href: {session.href}</p> */}
                    <p>ISP</p>
                    <p>IP Address</p>
                    <p>City</p>
                    <p>State</p>
                    {/* <p>region_name: {session.region_name}</p> */}
                    {/* <p>zip: {session.zip}</p> */}
                    {/* <p>country_code: {session.country_code}</p> */}
                    {/* <p>country: {session.country}</p> */}
                    {/* <p>timezone: {session.timezone}</p> */}
                    <p>Created</p>
                </div>
                {sessions.map((session: any) => (
                    <div className='grid grid-cols-[120px_2fr_1fr_1fr_100px_50px_120px] gap-2 text-sm font-bold p-2 dark:bg-darkbg1 rounded shadow-md break-all'>
                        {/* <p>id: {session.id}</p> */}
                        <p>{session.domain}</p>
                        <p>{session.path}</p>
                        {/* <p>search: {session.search}</p> */}
                        {/* <p>hash: {session.hash}</p> */}
                        {/* <p>href: {session.href}</p> */}
                        <p>{session.isp}</p>
                        <p>{session.ip_address}</p>
                        <p>{session.city}</p>
                        <p>{session.region}</p>
                        {/* <p>region_name: {session.region_name}</p> */}
                        {/* <p>zip: {session.zip}</p> */}
                        {/* <p>country_code: {session.country_code}</p> */}
                        {/* <p>country: {session.country}</p> */}
                        {/* <p>timezone: {session.timezone}</p> */}
                        <p>{timeFormatter(new Date(session.created_at))}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
